// import { HtmlNodeModel, HtmlNode } from '@logicflow/core';
import {
  HtmlResize
} from '@logicflow/extension';
import '../../../uml.css'
import $ from 'jquery'
import {
  MessageBox
} from 'element-ui'
import img_pre from '../../../assets/title_pre.png'
import img_nor from '../../../assets/title_nor.png'
import {
  sendUpdate,
  add_Operation
} from '../../../tools/send.js'
import { zhHansLanguage } from '../../../lang/zh-Hans.js'
import { zhHantLanguage } from '../../../lang/zh-Hant.js'
import { enLanguage } from '../../../lang/en.js'

class CardModel extends HtmlResize.model {
  initNodeData(data) {
    super.initNodeData(data)
    this.text.editable = false; // 禁止节点文本编辑
    // 设置节点宽高和鼠标移动多少距离后开始缩放节点
    const width = data.properties.nodeSize && data.properties.nodeSize.width ? data.properties.nodeSize.width : 250;
    const height = data.properties.nodeSize && data.properties.nodeSize.height ? data.properties.nodeSize.height : 250;
    // const height = 250
    this.width = width;
    this.height = height;
    this.minWidth = 80;
    this.minHeight = 80;
    this.gridSize = 1;
    const unable_to_connect_with_oneself = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip43 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip43 : enLanguage.tip43),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor)
        return targetNode.id != sourceNode.id;
      },
    };
    this.sourceRules.push(unable_to_connect_with_oneself);
    const unable_to_connect_with_theme = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.unable_to_connect_with_theme : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.unable_to_connect_with_theme : enLanguage.unable_to_connect_with_theme),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceNode, sourceAnchor, targetAnchor)
        return targetNode.type != "icon-theme";
      },
    }
    this.sourceRules.push(unable_to_connect_with_theme);
    const unable_to_connect_with_other = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.unable_to_connect_with_other : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.unable_to_connect_with_other : enLanguage.unable_to_connect_with_other),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor)
        let edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id)
        let isConnect = false
        for (let i = 0; i < edges.length; i++) {
          if (edges[i].sourceNodeId == sourceNode.id && edges[i].targetNodeId == targetNode.id) {
            isConnect = true
            break
          }
        }
        return isConnect == false;
      },
    }
    this.sourceRules.push(unable_to_connect_with_other);
    // 设置锚点
    // this.anchorsOffset = [
    //   [width / 2, 0],
    //   [0, height / 2],
    //   [-width / 2, 0],
    //   [0, -height / 2],
    // ]
  }
  setAttributes() {
    // if (this.properties.radio == 'videoList' && this.properties.videoList && this.properties.videoList.length != 0 && this.properties.styleRadio == "2" && this.properties.outerHeight) {
    //   this.height = this.properties.outerHeight
    // }
    // else if (this.properties.radio == 'videoList' && this.properties.videoList && this.properties.videoList.length != 0 && this.properties.styleRadio == "1" && this.properties.height) {
    //   this.height = this.properties.height
    // }
    if ((this.properties.iconRadio == true && this.properties.nodeSize && this.properties.nodeSize.height && parseInt(this.properties.nodeSize.height) <= 80) || (this.properties.iconRadio == true && this.properties.nodeSize && this.properties.nodeSize.width && parseInt(this.properties.nodeSize.width) <= 80)) {
      this.width = 80
      this.height = 80
    }
    if ((this.properties.iconRadio == false && this.properties.nodeSize && this.properties.nodeSize.height && parseInt(this.properties.nodeSize.height) == 250) || (this.properties.iconRadio == false && this.properties.nodeSize && this.properties.nodeSize.width && parseInt(this.properties.nodeSize.width) == 250)) {
      this.width = 250
      this.height = 250
    }
    // if(this.properties.height && this.properties.width) {
    //   this.width = this.properties.width
    //   this.height = this.properties.height
    // }
  }
  // getResizeOutlineStyle(){
  //   const style = super.getResizeOutlineStyle();
  //   style.stroke = "#007BFF";
  //   style.strokeWidth = 2
  //   style.strokeDasharray = "3,3"
  //   style.fill = "transparent"
  //   return style;
  // }
}
class CardNode extends HtmlResize.view {
  contentTitle(eleDom, textTips, lf, isShowDescribe) {
    const itemDom = document.createElement("div"); //增加提个DOM p标签，用于展示 自定义title
    itemDom.setAttribute('class', 'robotHeaderBar-Value'); //添加class命名，用于设置自定义title样式
    // itemDom.style = "border: 1px solid #ffffff;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    const div = document.createElement("div");
    div.style = "background: #FFFFFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;";

    const img = document.createElement("img");
    img.src = img_nor;
    div.appendChild(img);
    itemDom.appendChild(div);

    eleDom.parentNode.style = "overflow: visible;";
    // let itemDomShow = false; //用于判断是否添加了节点
    let itemClickDomShow = isShowDescribe;
    const p = document.createElement("p");
    // let conSetTimeout = null;
    // const setTime = 1000; //延迟1.5秒
    //鼠标移入事件
    // const eleMouseenter = function () {
    //   conSetTimeout = setTimeout(function () {
    //     //移入增加DOM节点 （自定义title）
    //     //eleDom表示，需要在哪个DOM下增加title
    //     // eleDom.appendChild(itemDom);
    //     // itemDomShow = true;
    //     // eleDom.parentNode.style= "overflow: visible;";
    //     p.textContent = textTips; //参数textTips 代表需要展示的自定义title文本
    //     p.style = "margin-left:10px;margin-right:10px;"
    //     itemDom.appendChild(p);
    //     img.src = img_pre;
    //     itemDomShow = true;
    //     // itemDom.style = "border: 1px solid #007BFF;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    //     itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    //     div.style = "background: #007BFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;border-bottom-left-radius: 6px;border-top-left-radius: 6px;border: 1px solid rgb(0, 123, 255);";
    //   }, setTime);
    // };
    //鼠标移出
    // const eleMouseleave = function () {
    //   //清除延时器
    //   clearTimeout(Number(conSetTimeout));
    //   if (itemDomShow && !itemClickDomShow) {
    //     //删除节点
    //     // eleDom.removeChild(itemDom);
    //     // itemDomShow = false;
    //     // eleDom.parentNode.style= "overflow: hidden;";
    //     itemDom.removeChild(p);
    //     itemDomShow = false;
    //     img.src = img_nor;
    //     itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    //     // itemDom.style = "border: 1px solid #ffffff;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    //     div.style = "background: #FFFFFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;";
    //   }
    // };
    // 点击后也设置为不显示自定义title
    // const eleClick = function (e) {
    //   if (itemClickDomShow) {
    //     itemDom.removeChild(p);
    //     itemClickDomShow = false;
    //     img.src = img_nor;
    //     // itemDom.style = "border: 1px solid #ffffff;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    //     itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    //     div.style = "background: #FFFFFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;";
    //     lf.graphModel.getNodeModelById(lf.model.id).setProperties({
    //       isShowDescribe: false
    //     });
    //     let nodes = []
    //     nodes.push(lf.graphModel.getNodeModelById(lf.model.id).getData())
    //     sendUpdate(nodes, [], "update")
    //   } else {
    //     p.textContent = textTips; //参数textTips 代表需要展示的自定义title文本
    //     p.style = "margin-left:10px;margin-right:10px;"
    //     itemDom.appendChild(p);
    //     img.src = img_pre;
    //     itemClickDomShow = true;
    //     // itemDom.style = "border: 1px solid #007BFF;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    //     itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    //     div.style = "background: #007BFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;border-bottom-left-radius: 6px;border-top-left-radius: 6px;border: 1px solid rgb(0, 123, 255);";
    //     lf.graphModel.getNodeModelById(lf.model.id).setProperties({
    //       isShowDescribe: true
    //     });
    //     let nodes = []
    //     nodes.push(lf.graphModel.getNodeModelById(lf.model.id).getData())
    //     sendUpdate(nodes, [], "update")
    //   }
    //   e.stopPropagation();
    // };

    // div.addEventListener("mouseenter", eleMouseenter);
    // div.addEventListener("mouseleave", eleMouseleave);
    // div.addEventListener("click", eleClick);
    div.title = textTips;

    if (itemClickDomShow) {
      p.textContent = textTips; //参数textTips 代表需要展示的自定义title文本
      p.style = "margin-left:10px;margin-right:10px;"
      itemDom.appendChild(p);
      img.src = img_pre;
      // itemDom.style = "border: 1px solid #007BFF;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
      itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
      div.style = "background: #007BFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;border-bottom-left-radius: 6px;border-top-left-radius: 6px;border: 1px solid rgb(0, 123, 255);";
    }

    eleDom.appendChild(itemDom);
  }
  addTransparent(id, checked) {
    if (document.getElementById("div_" + id)) {
      const div = document.getElementById("div_" + id)
      let itemDomShow = false; //用于判断是否添加了节点
      let conSetTimeout = null;
      const setTime = 100; //延迟1.5秒
      //鼠标移入事件
      const eleMouseenter = function () {
        conSetTimeout = setTimeout(function () {
          if (checked) {
            itemDomShow = true;
            div.style = "width:80px;height:80px;display: flex;justify-content: center;align-items: center;background: #dcdfe6;";
          }
        }, setTime);
      };
      //鼠标移出
      const eleMouseleave = function () {
        //清除延时器
        clearTimeout(Number(conSetTimeout));
        if (itemDomShow && checked) {
          itemDomShow = false;
          div.style = "width:80px;height:80px;display: flex;justify-content: center;align-items: center;background: none;";
        }
      };
      div.addEventListener("mouseenter", eleMouseenter);
      div.addEventListener("mouseleave", eleMouseleave);
    }
  }
  setHtml(rootEl) {
    const {
      id,
      properties
    } = this.props.model;
    var color = (properties.titleColor && properties.titleColor != '') ? properties.titleColor : '#6266BC'
    let cardTitle = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.card : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.card : enLanguage.card)
    const el = document.createElement('div');
    el.className = 'uml-wrapper';
    // el.title = properties.describe ? properties.describe : ''
    // el.style = "border: 1px solid " + color + ';';
    var html = ``;
    if ((properties.iconRadio == true && properties.nodeSize && properties.nodeSize.height && parseInt(properties.nodeSize.height) <= 80) || (properties.iconRadio == true && properties.nodeSize && properties.nodeSize.width && parseInt(properties.nodeSize.width) <= 80)) {
      el.style = 'width:80px;height:80px;display: flex;justify-content: center;align-items: center;background:none';
      el.id = "div_" + id;
      if (properties.iconList && properties.iconList.length != 0 && !properties.checked) {
        html = `<div style="width:60px;height:60px;background:url(${properties.iconList[0].url}) no-repeat;background-size:cover;">
        </div>
        `
      } else if (properties.iconList && properties.iconList.length != 0 && properties.checked) {
        html = `<div style="width:60px;height:60px;">
        </div>
        `
      }
      el.innerHTML = html;
      // 需要先把之前渲染的子节点清除掉。
      rootEl.innerHTML = '';
      rootEl.appendChild(el);
      this.addTransparent(id, properties.checked ? properties.checked : false)

      if ((properties.iconRadio == true && properties.nodeSize && properties.nodeSize.height && parseInt(properties.nodeSize.height) <= 80) || (properties.iconRadio == true && properties.nodeSize && properties.nodeSize.width && parseInt(properties.nodeSize.width) <= 80)) {
        if (properties.iconList && properties.iconList.length != 0 && !properties.checked) {
          $("#div_" + id).parent().css("background", "none")
          $("#div_" + id).parent().css("box-shadow", "none")
        } else if (properties.iconList && properties.iconList.length != 0 && properties.checked) {
          $("#div_" + id).parent().css("background", "none")
          $("#div_" + id).parent().css("box-shadow", "none")
        }
        // else {
        //   $("#div_" + id).parent().css("background", "none")
        //   $("#div_" + id).parent().css("box-shadow", "none")
        // }
      }
    } else {
      // if (properties.isShowTitle) {
      //   el.style = "background:none;border:none;"
      // }
      html = `
      <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
        <i class="el-icon-s-custom"></i>
        <div class="is_show_user_name">${properties.user_name}</div>
        <i class="el-icon-edit"></i>
        <div class="apostrophe"></div>
      </div>
      <div>
        <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;' : 'display:none;'}">${properties.title ? properties.title : cardTitle}</div>
        <i class="el-icon-circle-close closeImg" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
        <div class="uml-body">
          <div></div>
        </div>
      </div>
      `;
      // if (properties.radio == 'icon' && properties.iconList && properties.iconList.length != 0 && !properties.checked) {
      //   el.style = 'width:80px;height:80px;display: flex;justify-content: center;align-items: center;background:none';
      //   el.id = "div_" + id;
      //   html = `<div style="width:60px;height:60px;background:url(${properties.iconList[0].url}) no-repeat;background-size:cover;">
      //   </div>
      //   `
      // }
      // if (properties.radio == 'icon' && properties.iconList && properties.iconList.length != 0 && properties.checked) {
      //   html = `<div style="width:60px;height:60px;">
      //   </div>
      //   `
      // }
      if (properties.radio == 'content' && properties.content && properties.content != '') {
        // let fontsize = $('.uml-body').css('font-size');
        // var len = properties.contentLength;
        // // console.log(len)
        // if (0 < len && len == 1) {
        //   fontsize = "160px";
        // } else if (1 < len && len <= 3) {
        //   fontsize = "80px";
        // } else if (3 < len && len <= 5) {
        //   fontsize = "40px";
        // } else if (5 < len && len <= 7) {
        //   fontsize = "20px";
        // } else if (7 < len && len <= 9) {
        //   fontsize = "12px";
        // }
        // else if(30 < properties.content.length && properties.content.length <= 40) {
        //   fontsize = "14px";
        // }
        // else {
        //   fontsize = "12px";
        // }
        el.id = "content-" + id
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
          <i class="el-icon-s-custom"></i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon-edit"></i>
          <div class="apostrophe"></div>
        </div>
        <div style="height:100%;" class="regular_card" >
          <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;' : 'display:none;'}">${properties.title ? properties.title : cardTitle}</div>
          <i class="${!properties.isShowTitle ? 'el-icon-circle-close closeImg' : 'el-icon-circle-close closeImgTheme'}" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
          <div id="content_${id}" style="overflow-y:auto;word-break: break-all;word-wrap: break-word;color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 400;line-height: 22px;padding:16px;">
            ${properties.content}
          </div>
        </div>
        `;
      }
      if (properties.radio == 'iframeUrl' && properties.iframeUrl && properties.iframeUrl != '' && !properties.isShowTitle) {
        el.id = "iframeUrl-" + id;
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
        <i class="el-icon-s-custom"></i>
        <div class="is_show_user_name">${properties.user_name}</div>
        <i class="el-icon-edit"></i>
        <div class="apostrophe"></div>
      </div>
        <div style="height:100%;">
          <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;' : 'display:none;'}">${properties.title ? properties.title : cardTitle}</div>
          <i class="${!properties.isShowTitle ? 'el-icon-circle-close closeImg' : 'el-icon-circle-close closeImgTheme'}" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
          <div class="uml-body" style="width: 100%;padding:0;border-top:none;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div id="iframeUrl_${id}" style="width: 100%;height:100%;">
              <iframe src=${properties.iframeUrl.indexOf("https://") != -1 || properties.iframeUrl.indexOf("http://") != -1 ? properties.iframeUrl : '//' + properties.iframeUrl} style="position: absolute;transform: scale(.5, .5) translate(-47%, -50%);width: 189%;height:171%;top: ${!properties.is_show_user ? '34px' : '62px'};left: 0;" frameborder="no" border="0" sandbox="allow-forms allow-scripts allow-same-origin"></iframe></div>
            </div>
        </div>
        `;
      }
      if (properties.radio == 'iframeUrl' && properties.iframeUrl && properties.iframeUrl != '' && properties.isShowTitle) {
        el.id = "iframeUrl-" + id;
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
        <i class="el-icon-s-custom"></i>
        <div class="is_show_user_name">${properties.user_name}</div>
        <i class="el-icon-edit"></i>
        <div class="apostrophe"></div>
      </div>
        <div style="height:100%;">
          <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;' : 'display:none;'}">${properties.title ? properties.title : cardTitle}</div>
          <i class="${!properties.isShowTitle ? 'el-icon-circle-close closeImg' : 'el-icon-circle-close closeImgTheme'}" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
          <div class="uml-body" style="width: 100%;padding:0;border-top:none;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div id="iframeUrl_${id}" style="width: 100%;height:100%;">
              <iframe src=${properties.iframeUrl.indexOf("https://") != -1 || properties.iframeUrl.indexOf("http://") != -1 ? properties.iframeUrl : '//' + properties.iframeUrl} style="position: absolute;transform: scale(.5, .5) translate(-47%, -50%);width: 189%;height:171%;top: ${!properties.is_show_user ? '23px' : '36.5px'};left: 0;" frameborder="no" border="0" sandbox="allow-forms allow-scripts allow-same-origin"></iframe></div>
            </div>
        </div>
        `;
      }
      if (properties.radio == 'embedCode' && properties.embedCode && properties.embedCode != '') {
        el.id = "embedCode-" + id;
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
        <i class="el-icon-s-custom"></i>
        <div class="is_show_user_name">${properties.user_name}</div>
        <i class="el-icon-edit"></i>
        <div class="apostrophe"></div>
      </div>
        <div style="height:100%;">
          <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;' : 'display:none;'}">${properties.title ? properties.title : cardTitle}</div>
          <i class="${!properties.isShowTitle ? 'el-icon-circle-close closeImg' : 'el-icon-circle-close closeImgTheme'}" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
          <div class="uml-body" style="width: 100%;height:${!properties.isShowTitle ? 'calc(100% - 30px)' : '100%'};border-top:none;padding: 0;display: flex;flex-direction: row;justify-content: center;align-items: center;">
            <div style="width: 100%;display:flex;flex-direction: row;align-items:center;justify-content: center;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;" id="embedCode_${id}">
              ${properties.embedCode}
            </div>
          </div>
        </div>
        `;
      }
      if (properties.radio == 'imageList' && properties.imageList && properties.imageList.length != 0) {
        // console.log(properties.imageList)
        el.id = "imageList_" + id;
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;background: #fff;' : 'display:none;background: #fff;'}">
        <i class="el-icon-s-custom"></i>
        <div class="is_show_user_name">${properties.user_name}</div>
        <i class="el-icon-edit"></i>
        <div class="apostrophe"></div>
      </div>
        <div style="width: 100%;height: 100%;">
          <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;' : 'display:none;'}">${properties.title ? properties.title : cardTitle}</div>
          <i class="${!properties.isShowTitle ? 'el-icon-circle-close closeImg' : 'el-icon-circle-close closeImgTheme'}" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
          <div class="uml-body" style="${!properties.isShowTitle ? 'height:calc(100% - 30px);' : 'height:100%;'}width: 100%;padding:0;border-top:none;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div style="width: 100%;height:100%;display:flex;align-items:center;justify-content: center;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;"><img id="img_${id}" style="width: 100%;height: auto;max-width: 100%;max-height: 100%;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;" draggable="false" src="${properties.imageList[0].url}"/></div>
          </div>
        </div>
        `;
      }
      if (properties.radio == 'videoList' && properties.videoList && properties.videoList.length != 0 && properties.styleRadio == "1") {
        // console.log(properties.videoList)
        el.style = "background:#fff;border:none;"
        el.id = "video-" + id
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;background: #fff;' : 'display:none;background: #fff;'}">
        <i class="el-icon-s-custom"></i>
        <div class="is_show_user_name">${properties.user_name}</div>
        <i class="el-icon-edit"></i>
        <div class="apostrophe"></div>
      </div>
        <div style="width: 100%;height: 100%;">
          <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;' : 'display:none;'}">${properties.title ? properties.title : cardTitle}</div>
          <i class="el-icon-circle-close closeImg" id="${id}" style="${properties.isShowDelete ? 'display:block;z-index: 2000;' : 'display:none;'}"></i>
          <div class="uml-body" style="${!properties.isShowTitle ? 'height:calc(100% - 30px);' : 'height:100%;'}border-top:none;width: 100%;;padding:0;display: flex;flex-direction: row;justify-content: center;align-items: center;">
            <div style="width: 100%;display: flex;align-items: center;justify-content: center;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;"><video id="video_${id}" style="width: auto;height: auto;max-width: 100%;max-height: 100%;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;" controls src="${properties.videoList[0].url}"></video></div>
          </div>
        </div>
        `;
      }
      if (properties.radio == 'videoList' && properties.videoList && properties.videoList.length != 0 && properties.styleRadio == "2") {
        // console.log(properties)
        // console.log(id)
        el.style = "height: auto;";
        el.id = "video-" + id
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;background: #fff;' : 'display:none;background: #fff;'}">
        <i class="el-icon-s-custom"></i>
        <div class="is_show_user_name">${properties.user_name}</div>
        <i class="el-icon-edit"></i>
        <div class="apostrophe"></div>
      </div>
        <div style="width: 100%;height: auto;">
          <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;' : 'display:none;'}">${properties.title ? properties.title : cardTitle}</div>
          <i class="el-icon-circle-close closeImg" id="${id}" style="${properties.isShowDelete ? 'display:block;z-index: 2000;' : 'display:none;'}"></i>
          <div class="uml-body" style="border-top:none;width: 100%;height: auto;padding:0;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div style="width: 100%;height: auto;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;"><video id="video_${id}" style="width: auto;height:auto;max-width: 100%;max-height: 100%;display: block;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;" controls src="${properties.videoList[0].url}"></video></div>
          </div>
        </div>
        `;
      }
      if (properties.radio == 'audioList' && properties.audioList && properties.audioList.length != 0) {
        // console.log(properties.audioList)
        el.id = "audio-" + id
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
          <i class="el-icon-s-custom"></i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon-edit"></i>
          <div class="apostrophe"></div>
        </div>
        <div style="width: 100%;height: 100%;">
          <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;' : 'display:none;'}">${properties.title ? properties.title : cardTitle}</div>
          <i class="${!properties.isShowTitle ? 'el-icon-circle-close closeImg' : 'el-icon-circle-close closeImgTheme'}" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
          <div class="uml-body" style="border-top:none;display:flex;width: 100%;height:${!properties.isShowTitle ? 'calc(100% - 30px)' : '100%'};justify-content: center;align-items: center;padding:0;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div style="display:flex;width: 100%;justify-content: center;align-items: center;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;"><audio id="audio_${id}" src="${properties.audioList[0].url}" controls /></div>
          </div>
        </div>
        `;
      }
      if (properties.radio == 'fileList' && properties.fileList && properties.fileList.length != 0 && (properties.fileList[0].url.indexOf('.pdf') != -1 || properties.fileList[0].url.indexOf('.PDF') != -1)) {
        el.id = "fileList-" + id;
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;z-index: 9999;position: relative;background: #fff;' : 'display:none;z-index: 9999;position: relative;background: #fff;'}">
          <i class="el-icon-s-custom"></i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon-edit"></i>
          <div class="apostrophe"></div>
        </div>
        <div style="height:100%;">
          <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;position: relative;z-index: 9999;' : 'display:none;position: relative;z-index: 9999;'}">${properties.title ? properties.title : cardTitle}</div>
          <i class="${!properties.isShowTitle && !properties.is_show_user ? 'el-icon-circle-close closeImg' : 'el-icon-circle-close closeImgTheme'}" id="${id}" style="${properties.isShowDelete ? 'display:block;z-index: 9999;' : 'display:none;z-index: 9999;'}"></i>
          <div class="uml-body" style="width: 100%;padding:0;border-top:none;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div id="file_${id}" style="width: 100%;height:100%;">
              <iframe id="myframe_${id}" draggable="false" src=${'https://cloud.cocorobo.cn/pdf.js/web/viewer.html?file=' + properties.fileList[0].url} style="position: absolute;transform: scale(.5, .5) translate(-47%, -50%);width: 189%;height:206%;top: ${!properties.isShowTitle && properties.is_show_user ? '28px' : (!properties.isShowTitle && !properties.is_show_user ? '13px' : (properties.isShowTitle && properties.is_show_user ? '33px' : '3px'))};left: 0;right: 0;" frameborder="no" border="0"></iframe></div>
            </div>
        </div>
        `;
      }
      if (properties.radio == 'fileList' && properties.fileList && properties.fileList.length != 0 && (properties.fileList[0].url.indexOf('.doc') != -1 || properties.fileList[0].url.indexOf('.DOC') != -1 || properties.fileList[0].url.indexOf('.docx') != -1 || properties.fileList[0].url.indexOf('.DOCX') != -1)) {
        el.id = "fileList-" + id;
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;z-index: 9999;position: relative;background: #fff;' : 'display:none;z-index: 9999;position: relative;background: #fff;'}">
          <i class="el-icon-s-custom"></i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon-edit"></i>
          <div class="apostrophe"></div>
        </div>
        <div style="height:100%;">
          <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;position: relative;z-index: 9999;' : 'display:none;position: relative;z-index: 9999;'}">${properties.title ? properties.title : cardTitle}</div>
          <i class="${!properties.isShowTitle && !properties.is_show_user ? 'el-icon-circle-close closeImg' : 'el-icon-circle-close closeImgTheme'}" id="${id}" style="${properties.isShowDelete ? 'display:block;z-index: 9999;' : 'display:none;z-index: 9999;'}"></i>
          <div class="uml-body" style="width: 100%;padding:0;border-top:none;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div id="file_${id}" style="width: 100%;height:100%;">
              <iframe id="myframe_${id}" draggable="false" src=${'https://view.officeapps.live.com/op/view.aspx?src=' + properties.fileList[0].url} style="position: absolute;transform: scale(.5, .5) translate(-47%, -50%);width: 187%;height:206%;top: ${!properties.isShowTitle ? '-16px' : '3px'};left: 0;right: 0;" frameborder="no" border="0"></iframe></div>
            </div>
        </div>
        `;
      }
      if (properties.radio == 'fileList' && properties.fileList && properties.fileList.length != 0 && (properties.fileList[0].url.indexOf('.ppt') != -1 || properties.fileList[0].url.indexOf('.PPT') != -1 || properties.fileList[0].url.indexOf('.pptx') != -1 || properties.fileList[0].url.indexOf('.PPTX') != -1)) {
        el.id = "fileList-" + id;
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;z-index: 9999;position: relative;background: #fff;' : 'display:none;z-index: 9999;position: relative;background: #fff;'}">
          <i class="el-icon-s-custom"></i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon-edit"></i>
          <div class="apostrophe"></div>
        </div>
        <div style="height:100%;">
          <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;position: relative;z-index: 9999;' : 'display:none;position: relative;z-index: 9999;'}">${properties.title ? properties.title : cardTitle}</div>
          <i class="${!properties.isShowTitle && !properties.is_show_user ? 'el-icon-circle-close closeImg' : 'el-icon-circle-close closeImgTheme'}" id="${id}" style="${properties.isShowDelete ? 'display:block;z-index: 9999;' : 'display:none;z-index: 9999;'}"></i>
          <div class="uml-body" style="width: 100%;padding:0;border-top:none;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div id="file_${id}" style="width: 100%;height:100%;">
              <iframe id="myframe_${id}" draggable="false" src=${'https://view.officeapps.live.com/op/view.aspx?src=' + properties.fileList[0].url} style="position: absolute;transform: scale(.5, .5) translate(-47%, -50%);width: 189%;height:206%;top: ${!properties.isShowTitle ? '-16px' : '3px'};left: 0;right: 0;" frameborder="no" border="0"></iframe></div>
            </div>
        </div>
        `;
      }
      if (properties.radio == 'fileList' && properties.fileList && properties.fileList.length != 0 && (properties.fileList[0].url.indexOf('.xls') != -1 || properties.fileList[0].url.indexOf('.XLS') != -1 || properties.fileList[0].url.indexOf('.xlsx') != -1 || properties.fileList[0].url.indexOf('.XLSAX') != -1)) {
        el.id = "fileList-" + id;
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;z-index: 9999;position: relative;background: #fff;' : 'display:none;z-index: 9999;position: relative;background: #fff;'}">
          <i class="el-icon-s-custom"></i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon-edit"></i>
          <div class="apostrophe"></div>
        </div>
        <div style="height:100%;">
          <div class="uml-head" style="background-color:${color};${!properties.isShowTitle ? 'display:block;position: relative;z-index: 9999;' : 'display:none;position: relative;z-index: 9999;'}">${properties.title ? properties.title : cardTitle}</div>
          <i class="${!properties.isShowTitle && !properties.is_show_user ? 'el-icon-circle-close closeImg' : 'el-icon-circle-close closeImgTheme'}" id="${id}" style="${properties.isShowDelete ? 'display:block;z-index: 9999;' : 'display:none;z-index: 9999;'}"></i>
          <div class="uml-body" style="width: 100%;padding:0;border-top:none;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            <div id="file_${id}" style="width: 100%;height:100%;">
              <iframe id="myframe_${id}" draggable="false" src=${'https://view.officeapps.live.com/op/view.aspx?src=' + properties.fileList[0].url} style="position: absolute;transform: scale(.5, .5) translate(-47%, -50%);width: 189%;height:206%;top: ${!properties.isShowTitle ? '-11px' : '3px'};left: 0;right: 0;" frameborder="no" border="0"></iframe></div>
            </div>
        </div>
        `;
      }
      el.innerHTML = html;
      // 需要先把之前渲染的子节点清除掉。
      rootEl.innerHTML = '';
      rootEl.appendChild(el);
      let that = this;
      if(document.getElementById(id)) {
        document.getElementById(id).addEventListener('click', (event) => {
          let tip18 = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip18 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip18 : enLanguage.tip18)
          let tip2 = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip2 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip2 : enLanguage.tip2)
          let confirm = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.confirm : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.confirm : enLanguage.confirm)
          let cancel = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.cancel : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.cancel : enLanguage.cancel)
          let nodes = []
          nodes.push(that.props.graphModel.getElement(id).getData())

          MessageBox.confirm(tip18, tip2, {
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            type: 'warning'
          }).then(() => {
            let newEdges = []
            let outgoingEdgeModel = that.props.graphModel.getNodeOutgoingEdge(id);
            if (outgoingEdgeModel.length != 0) {
              for (let j = 0; j < outgoingEdgeModel.length; j++) {
                let edge = that.props.graphModel.getEdgeModelById(outgoingEdgeModel[j].id)
                newEdges.push(edge.getData())
              }
            }
            let incomingEdgeModel = that.props.graphModel.getNodeIncomingEdge(id);
            if (incomingEdgeModel.length != 0) {
              for (let j = 0; j < incomingEdgeModel.length; j++) {
                let edge = that.props.graphModel.getEdgeModelById(incomingEdgeModel[j].id)
                newEdges.push(edge.getData())
              }
            }
            sendUpdate(nodes, newEdges, "delete")
            that.props.graphModel.deleteNode(id)

            that.props.graphModel.clearSelectElements()
            let newData = {
              "nodes": nodes,
              "edges": newEdges,
              "backgroundUrl": localStorage.getItem("background")
            }
            add_Operation(newData, "deleteNode")
          }).catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除'
            // });
          });
          event.stopPropagation();
        })
      }

      if(document.getElementById('myframe_' + id)) {
        document.getElementById('myframe_' + id).addEventListener('mousedown', function(event) {
          event.preventDefault();
          return false;
        });
        document.getElementById('myframe_' + id).addEventListener('mousemove', function(event) {
          event.preventDefault();
          return false;
        });
        document.getElementById('myframe_' + id).addEventListener('mouseup', function(event) {
          event.preventDefault();
          return false;
        });
      }

      if (properties.radio == 'videoList' && properties.videoList && properties.videoList.length != 0 && properties.styleRadio == "2") {
        $("#video-" + id).parent().css("box-shadow", "none")
        // $("#video-" + id).parent().css("background", "none")
      }

      // let lf = this.props
      // if (properties.radio == 'content' && properties.content && properties.content != '' && properties.isShowTitle) {
      //   $("#content-" + id).parent().css("background", "#FFFFFF")
      //   $("#content-" + id).parent().css("box-shadow", "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)")
      //   lf.model.width = Math.round($("#content_" + id).width()) != 0 ? Math.round($("#content_" + id).width()) + 32 : 250
      //   lf.model.height = properties.nodeSize && properties.nodeSize.height ? properties.nodeSize.height : 250
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //     $("#content_" + id).css("height", properties.is_show_user ? (properties.nodeSize.height - 27) + "px" : properties.nodeSize.height + "px")
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'content' && properties.content && properties.content != '' && !properties.isShowTitle) {
      //   $("#content-" + id).parent().css("background", "#FFFFFF")
      //   $("#content-" + id).parent().css("box-shadow", "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)")
      //   lf.model.width = Math.round($("#content_" + id).width()) != 0 ? Math.round($("#content_" + id).width()) + 32 : 250
      //   lf.model.height = properties.nodeSize && properties.nodeSize.height ? properties.nodeSize.height : 250
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //     $("#content_" + id).css("height", properties.is_show_user ? (properties.nodeSize.height - 57) + "px" : (properties.nodeSize.height - 30) + "px")
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'embedCode' && properties.embedCode && properties.embedCode != '' && properties.isShowTitle) {
      //   $("#embedCode-" + id).parent().css("background", "#FFFFFF")
      //   $("#embedCode-" + id).parent().css("box-shadow", "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)")
      //   lf.model.width = Math.round($("#embedCode_" + id).width())
      //   lf.model.height = properties.is_show_user ? Math.round($("#embedCode_" + id).height()) + 77 : Math.round($("#embedCode_" + id).height()) + 50
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'embedCode' && properties.embedCode && properties.embedCode != '' && !properties.isShowTitle) {
      //   $("#embedCode-" + id).parent().css("background", "#FFFFFF")
      //   $("#embedCode-" + id).parent().css("box-shadow", "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)")
      //   lf.model.width = Math.round($("#embedCode_" + id).width())
      //   lf.model.height = properties.is_show_user ? Math.round($("#embedCode_" + id).height()) + 87 : Math.round($("#embedCode_" + id).height()) + 60
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'iframeUrl' && properties.iframeUrl && properties.iframeUrl != '' && properties.isShowTitle) {
      //   $("#iframeUrl-" + id).parent().css("background", "#FFFFFF")
      //   $("#iframeUrl-" + id).parent().css("box-shadow", "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)")
      //   lf.model.width = Math.round($("#iframeUrl_" + id).width())
      //   lf.model.height = properties.is_show_user ? 307 : 280
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'iframeUrl' && properties.iframeUrl && properties.iframeUrl != '' && !properties.isShowTitle) {
      //   $("#iframeUrl-" + id).parent().css("background", "#FFFFFF")
      //   $("#iframeUrl-" + id).parent().css("box-shadow", "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)")
      //   lf.model.width = Math.round($("#iframeUrl_" + id).width())
      //   lf.model.height = properties.is_show_user ? 307 : 280
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'imageList' && properties.imageList && properties.imageList.length != 0 && properties.isShowTitle) {
      //   $("#imageList_" + id).parent().css("box-shadow", "none")
      //   $("#imageList_" + id).parent().css("background", "none")
      //   lf.model.width = 250
      //   lf.model.height = properties.is_show_user ? 255 : 228
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'imageList' && properties.imageList && properties.imageList.length != 0 && !properties.isShowTitle) {
      //   $("#imageList_" + id).parent().css("background", "#FFFFFF")
      //   $("#imageList_" + id).parent().css("box-shadow", "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)")
      //   lf.model.width = 250
      //   lf.model.height = properties.is_show_user ? 285 : 258
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'videoList' && properties.videoList && properties.videoList.length != 0 && properties.isShowTitle) {
      //   $("#video-" + id).parent().css("box-shadow", "none")
      //   $("#video-" + id).parent().css("background", "none")
      //   lf.model.width = 250
      //   lf.model.height = properties.is_show_user ? 168 : 141
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'videoList' && properties.videoList && properties.videoList.length != 0 && !properties.isShowTitle) {
      //   $("#video-" + id).parent().css("box-shadow", "none")
      //   $("#video-" + id).parent().css("background", "none")
      //   lf.model.width = 250
      //   lf.model.height = properties.is_show_user ? 198 : 171
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'audioList' && properties.audioList && properties.audioList.length != 0 && properties.isShowTitle) {
      //   $("#audio-" + id).parent().css("background", "#FFFFFF")
      //   $("#audio-" + id).parent().css("box-shadow", "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)")
      //   lf.model.width = Math.round($("#audio_" + id).width())
      //   lf.model.height = properties.is_show_user ? 131 : 104
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'audioList' && properties.audioList && properties.audioList.length != 0 && !properties.isShowTitle) {
      //   $("#audio-" + id).parent().css("background", "#FFFFFF")
      //   $("#audio-" + id).parent().css("box-shadow", "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)")
      //   lf.model.width = Math.round($("#audio_" + id).width())
      //   lf.model.height = properties.is_show_user ? 141 : 114
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'fileList' && properties.fileList && properties.fileList.length != 0 && properties.isShowTitle) {
      //   $("#fileList-" + id).parent().css("background", "#FFFFFF")
      //   $("#fileList-" + id).parent().css("box-shadow", "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)")
      //   lf.model.width = Math.round($("#file_" + id).width())
      //   lf.model.height = properties.is_show_user ? 307 : 280
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'fileList' && properties.fileList && properties.fileList.length != 0 && !properties.isShowTitle) {
      //   $("#fileList-" + id).parent().css("background", "#FFFFFF")
      //   $("#fileList-" + id).parent().css("box-shadow", "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)")
      //   lf.model.width = Math.round($("#file_" + id).width())
      //   lf.model.height = 277
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.width) {
      //     lf.model.properties.nodeSize.width = lf.model.width
      //   }
      //   if(lf.model.properties.nodeSize && lf.model.properties.nodeSize.height) {
      //     lf.model.properties.nodeSize.height = lf.model.height
      //   }
      //   let anchors = lf.model.anchors
      //   let edges = lf.model.graphModel.edges
      //   for (let i = 0; i < edges.length; i++) {
      //     for (let j = 0; j < anchors.length; j++) {
      //       if (edges[i].targetAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].endPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].endPoint.y = anchors[j].y
      //         break
      //       }
      //       if (edges[i].sourceAnchorId == anchors[j].id) {
      //         lf.model.graphModel.edges[i].startPoint.x = anchors[j].x
      //         lf.model.graphModel.edges[i].startPoint.y = anchors[j].y
      //         break
      //       }
      //     }
      //   }
      // }

      // if (properties.radio == 'content' && properties.content && properties.content != '') {
      //   let fontsize = $('.uml-body').css('font-size');
      //   if (fontsize == "12px") {
      //     $(".uml-body p").css({
      //       "-webkit-transform": "scale(0.83)"
      //     })
      //   }
      // }

      // if (properties.radio == 'videoList' && properties.videoList && properties.videoList.length != 0 && properties.styleRadio == "2") {
      //   console.log("#video-" + id)
      //   console.log($("#video-" + id))
      //   if($("#video-" + id)) {
      //     console.log($("#video-" + id + " div").outerHeight(true))
      //   }
      // }

      // if (properties.describe) {
      //   const textTips = properties.describe ? properties.describe : '';
      //   //添加自定义属性 target-title
      //   //itemContainer表示，需要在哪个DOM下增加title（自定义属性）
      //   el.setAttribute("target-title", textTips);
      //   //判断这个DOM节点是否包含这个自定义属性，
      //   if (el.getAttributeNode("target-title")) {
      //     //有的话直接调用函数。（用于多个相同DOM命名，统一添加，）
      //     const textTips = el.getAttribute('target-title');
      //     //如果用的地方比较少，不需要多个添加，可以直接调用函数
      //     //itemContainer 表示，需要在哪个DOM下增加title
      //     //textTips  需要展示的title文本
      //     this.contentTitle(el, textTips, this.props, properties.isShowDescribe);
      //   }
      // }
    }
  }
}

export default {
  type: 'icon-card',
  view: CardNode,
  model: CardModel
}