import EvaluationNode from './EvaluationNode'

// 左上角ICON为消息的节点
class QAToolsNode extends EvaluationNode.view {
  getImageHref () {
    return require('@/assets/tools/暂无-问答工具.png');
  }
}


export default {
  type: 'icon-QA-tools',
  view: QAToolsNode,
  model: EvaluationNode.model
}