<template>
  <!-- <svg class="icon" viewBox="0 0 1024 1024" :width="size" :height="size">
    <path d="M967.111111 967.111111h-113.777778v-113.777778c0-221.866667-176.355556-398.222222-398.222222-398.222222H113.777778V341.333333h341.333333c284.444444 0 512 227.555556 512 512v113.777778z" fill="#0D1733" p-id="1011">
    </path>
    <path d="M409.6 762.311111L51.2 398.222222 409.6 39.822222l85.333333 79.644445-284.444444 278.755555 284.444444 284.444445z" fill="#0D1733" p-id="1012">
    </path>
  </svg> -->

  <svg class="icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" :width="size" :height="size" viewBox="0 0 20 20"
    enable-background="new 0 0 20 20" xml:space="preserve">
    <image id="image0" width="20" height="20" x="0" y="0" href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjMwOyYjMTQ2
OyYjMTY0OyYjMjMzOyYjMTQ4OyYjMTI4OyI+CjxwYXRoIGlkPSJWZWN0b3IgNDQgKFN0cm9rZSki
IGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNyAyLjYxMzI1TDIg
NS41TDcgOC4zODY3NVY2SDEwLjVDMTIuMTU2OSA2IDEzLjUgNy4zNDMxNSAxMy41IDlDMTMuNSAx
MC42NTY5IDEyLjE1NjkgMTIgMTAuNSAxMkgyQzEuNzIzODYgMTIgMS41IDEyLjIyMzkgMS41IDEy
LjVDMS41IDEyLjc3NjEgMS43MjM4NiAxMyAyIDEzSDEwLjVDMTIuNzA5MSAxMyAxNC41IDExLjIw
OTEgMTQuNSA5QzE0LjUgNi43OTA4NiAxMi43MDkxIDUgMTAuNSA1SDdWMi42MTMyNVoiIGZpbGw9
ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuOSIvPgo8L2c+Cjwvc3ZnPgo=" />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: '24'
    }
  }
}
</script>
