<template>
    <div class="container" ref="main">
      <!-- <Diagram v-if="isLogin == 1" @login="login" @full="full" /> -->
      <Diagram v-if="isLogin == 1 && !isExistLogin" :groupDatas="groupData" :data="data" @login="login" :group="group" :theme="title" :groupUsers="groupUsers" />
      <Login @login="login" @getData="getData" @getUserGroup="getUserGroup" @getGroup="getGroup" @getGroupUsers="getGroupUsers" @getTitle="getTitle" v-else />
    </div>
  </template>
  
  <script>
  import Diagram from './Diagram.vue'
  import Login from './login/Login.vue'
  // import { Loading } from 'element-ui';
  // import bg from './assets/bg.png'
  import { touchInit } from "../tools/touchevent.js";
  import { destoryApp } from '../tools/spriteUtilities'
  export default {
    name: 'Home',
    data() {
      return {
        isLogin: 0,
        isFull: false,
        screenWidth: 0,
        screenHeight: 0,
        heightRate: 0,
        data: {},
        group: [],
        groupUsers: [],
        isExistLogin: false,
        title: '',
        groupData:[]
      }
    },
    watch: {
      // isFull(newVal) {
      //   if (newVal) {
      //     // let bodyStyle = document.createElement('style')
      //     var width = (this.screenWidth) / (this.heightRate)
      //     var height = (this.screenHeight) / (this.heightRate)
      //     // bodyStyle.innerHTML = `body{width:1920px; height:` + height + `px;}`
      //     // document.documentElement.firstElementChild.appendChild(bodyStyle)
      //     document.body.style = "transform:scale(" + this.heightRate + ");width:" + width + "px !important;height:" + height + "px !important;overflow: hidden;";
      //   }
      //   else {
      //     // let bodyStyle = document.createElement('style')
      //     // bodyStyle.innerHTML = `body{width:1920px; height:937px;}`
      //     // document.documentElement.firstElementChild.appendChild(bodyStyle)
  
      //     let that = this;
      //     that.refreshScale()
  
      //     window.addEventListener("pageshow", function (e) {
      //       if (e.persisted) { // 浏览器后退的时候重新计算
      //         that.refreshScale()
      //       }
      //     }, false);
      //     window.addEventListener("resize", that.refreshScale(), false);
      //   }
      // }
    },
    mounted() {
      if (localStorage.getItem("login") == 1) {
        this.isLogin = 1
        this.isExistLogin = false
      }
      else if (localStorage.getItem("login") == 0) {
        this.isLogin = 0
        this.isExistLogin = true
      }
      if (window.location.href.indexOf("cocorobo.cn") != -1) {
        document.domain = "cocorobo.cn";
      } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
        document.domain = "cocorobo.hk";
      } else if (window.location.href.indexOf("cocorobo.com") != -1) {
        document.domain = "cocorobo.com";
      }
      window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
      touchInit()
    },
    destoryed() {
      window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
    },
    components: {
      Diagram,
      Login
    },
    methods: {
      beforeunloadFn(e) {
        console.log('刷新或关闭', e)
        localStorage.clear()
        destoryApp()
      },
      login(e) {
        this.isLogin = e
        localStorage.setItem("login", e)
        // console.log(e)
      },
      getData(val) {
        this.data = val
      },
      getUserGroup(val){
        //console.log("that.getUserGroup",val)
        this.groupData = val
      },
      getGroup(val) {
        this.group = val
      },
      getTitle(val) {
        this.title = val
      },
      getGroupUsers(val) {
        this.groupUsers = val
      },
      getScale() {
        this.$refs.main.style.setProperty('width', window.innerWidth + "px")
        this.$refs.main.style.setProperty('height', window.innerHeight + "px")
      },
      full(val) {
        this.isFull = val;
      },
      refreshScale() {
        let docWidth = document.documentElement.clientWidth;
        let docHeight = document.documentElement.clientHeight;
        var designWidth = 1920,
          designHeight = 937,
          widthRatio = docWidth / designWidth,
          heightRatio = docHeight / designHeight; // 缩放比例
  
        const scale = docWidth / docHeight < designWidth / designHeight ? widthRatio : heightRatio
        this.heightRate = scale
        if (docWidth / docHeight < designWidth / designHeight) {
          document.body.style = `transform:scale(${scale});height:${docHeight / scale}px;`;
        }
        else {
          document.body.style = `transform:scale(${scale});width:${docWidth / scale}px;`;
        }
        // 应对浏览器 全屏切换前后 窗口因短暂滚动条问题出现未占满情况
        setTimeout(function () {
          var lateWidth = document.documentElement.clientWidth,
            lateHeight = document.documentElement.clientHeight;
          if (lateWidth === docWidth) return;
  
          widthRatio = lateWidth / designWidth
          heightRatio = lateHeight / designHeight
          const scale = lateWidth / lateHeight < designWidth / designHeight ? widthRatio : heightRatio
          this.heightRate = scale
          if (lateWidth / lateHeight < designWidth / designHeight) {
            document.body.style = "transform:scale(" + scale + ");height:" + lateHeight / scale + "px;"
          }
          else {
            document.body.style = "transform:scale(" + scale + ");width:" + lateWidth / scale + "px;"
          }
        }, 0)
      },
    }
  }
</script>
<style scoped>
.container {
  background-color: #F2F2F2;
  height: 100%;
  overflow: hidden;
}
</style>