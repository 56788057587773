var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"runDemo"}},[(!_vm.isShowMulu)?_c('div',{staticClass:"animate__animated animate__zoomIn",staticStyle:{"height":"calc(100%)","display":"flex","flex-direction":"row","background":"#ffffff"}},[(_vm.isShowMulu)?_c('button',{staticClass:"close",staticStyle:{"top":"4px"},attrs:{"type":"button"}},[(_vm.hoverClose)?_c('img',{attrs:{"src":require("../../assets/close_pre.png"),"width":"16","height":"16"},on:{"mouseout":_vm.blurClose,"click":_vm.handleClose}}):_c('img',{attrs:{"src":require("../../assets/close_nor.png"),"width":"16","height":"16"},on:{"mouseover":_vm.focusClose}})]):_vm._e(),_c('div',{staticClass:"left-side",style:(_vm.fullscreen ? '' : ''),attrs:{"id":"leftSide"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center","border-bottom":"1px solid rgb(202, 209, 220)"}},[(_vm.themeName != '')?_c('div',{staticStyle:{"width":"140px","margin-left":"10px","margin-bottom":"5px","color":"var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9))","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","word-break":"break-word","font-family":"SimSun","font-size":"16px","font-style":"normal","font-weight":"600","line-height":"24px"}},[_vm._v(" "+_vm._s(_vm.themeName)+" ")]):_vm._e()]),_c('div',{staticClass:"menuList",style:(_vm.stageDatas.length != 0
            ? 'overflow-y: auto;overflow-x: hidden;width: calc(100%);display: flex;flex-direction: column;align-items: center;height: calc(100% - 35px);'
            : 'overflow-y: auto;overflow-x: hidden;width: calc(100%);display: flex;flex-direction: column;align-items: center;margin-top: 15px;height: calc(100% - 50px);')},[_vm._l((_vm.newnavigationListNew),function(item,i){return [(item.type == 'icon-stage')?_c('div',{key:'menu_' + i,staticStyle:{"width":"100%","cursor":"pointer"}},[_c('div',{staticStyle:{"width":"calc(100% - 16px)","display":"flex","flex-direction":"row","padding":"12px 8px","align-items":"center","font-family":"SimSun","font-size":"14px","font-style":"normal","font-weight":"400","line-height":"22px"},style:(_vm.selectMulu == item.id ? 'background: var(--brand-light, #e0eafb);color: var(--brand-click, #0061ff);' :'color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));')},[(
                    _vm.selectMulu == item.id &&
                    item.isFold &&
                    item.child.length != 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.hideStageNew(i)}}},[_c('img',{style:(item.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):(item.isFold && item.child.length != 0)?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.hideStageNew(i)}}},[_c('img',{style:(item.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_nor.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu == item.id &&
                    !item.isFold && item.child.length != 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showStageNew(i)}}},[_c('img',{style:(item.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):(!item.isFold && item.child.length != 0)?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showStageNew(i)}}},[_c('img',{style:(item.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_nor.png"),"alt":"","width":"16","height":"16"}})]):(item.child.length == 0 || _vm.stageDatas.length != 0)?_c('div',{staticClass:"closeTri"},[_c('img',{staticStyle:{"visibility":"hidden"},attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):_vm._e(),_c('div',{style:(_vm.selectMulu == item.id ?
                  'background: var(--brand-light, #e0eafb);width: calc(100% - 21px);display: flex;flex-direction: row;align-items: center;color: var(--brand-click, #0061ff);font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 400;line-height: 22px;' : 'width: calc(100% - 21px);display: flex;flex-direction: row;align-items: center;color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 400;line-height: 22px;'),on:{"click":function($event){return _vm.navToStage(i)}}},[_c('div',{staticClass:"stageName-1",staticStyle:{"width":"auto"}},[_vm._v(" "+_vm._s(_vm.languageData.stageName)+_vm._s(i + 1)+": ")]),_c('div',{staticStyle:{"margin-left":"10px","width":"auto","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","word-break":"break-word"}},[_vm._v(" "+_vm._s(item.properties.stageName)+" ")])])])]):_vm._e(),_vm._l((item.child),function(itemNew,j){return [(_vm.stageIndex - 1 == i && item.isFold)?_c('a',{key:'menu_' + i + '_' + j,staticStyle:{"text-decoration":"none"},on:{"click":function($event){$event.stopPropagation();return _vm.navTo(itemNew.id)}}},[(itemNew.type == 'icon-task')?_c('div',{class:_vm.selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti',attrs:{"data-id":itemNew.id}},[(
                    _vm.selectMulu == itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.hideTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu != itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.hideTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_nor.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu == itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu != itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_nor.png"),"alt":"","width":"16","height":"16"}})]):(
                    itemNew.child.length == 0 || _vm.stageDatas.length != 0
                  )?_c('div',{staticClass:"closeTri"},[_c('img',{staticStyle:{"visibility":"hidden"},attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):_vm._e(),_c('span',{class:_vm.selectMulu == itemNew.id ? 'text-1 text-select' : 'text-1',staticStyle:{"cursor":"pointer"},attrs:{"data-id":itemNew.id}},[_vm._v(_vm._s(_vm.languageData.taskTip)+" "+_vm._s(itemNew.properties.taskTitle ? itemNew.properties.taskTitle : ""))])]):_vm._e()]):(_vm.stageIndex - 1 != i && item.isFold)?_c('a',{key:'menu_' + i + '_' + j,staticStyle:{"text-decoration":"none"},on:{"click":function($event){$event.stopPropagation();return _vm.navToTask(i, j)}}},[(itemNew.type == 'icon-task')?_c('div',{class:_vm.selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti',attrs:{"data-id":itemNew.id}},[(
                    _vm.selectMulu == itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.hideTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu != itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.hideTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_nor.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu == itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu != itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_nor.png"),"alt":"","width":"16","height":"16"}})]):(
                    itemNew.child.length == 0 || _vm.stageDatas.length != 0
                  )?_c('div',{staticClass:"closeTri"},[_c('img',{staticStyle:{"visibility":"hidden"},attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):_vm._e(),_c('span',{class:_vm.selectMulu == itemNew.id ? 'text-1 text-select' : 'text-1',staticStyle:{"cursor":"pointer"},attrs:{"data-id":itemNew.id}},[_vm._v(_vm._s(_vm.languageData.taskTip)+" "+_vm._s(itemNew.properties.taskTitle ? itemNew.properties.taskTitle : ""))])]):_vm._e()]):_vm._e(),_vm._l((itemNew.child),function(Item,index){return [(
                  _vm.stageIndex - 1 == i &&
                  itemNew.isFold &&
                  item.isFold &&
                  _vm.stageDatas.length == 0
                )?_c('a',{key:'menu_' + i + '_' + j + '_' + index,staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.navTo(Item.id)}}},[(Item.type == 'icon-card')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.title ? Item.properties.title : ""))])]):(Item.type == 'icon-select')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.selectTitle ? Item.properties.selectTitle : ""))])]):(Item.type == 'icon-thinking')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.thinkingTitle ? Item.properties.thinkingTitle : _vm.languageData.thinkingCard))])]):(Item.type == 'icon-AI')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.topic ? Item.properties.topic : ""))])]):(Item.type == 'icon-new-AI')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.topic ? Item.properties.topic : ""))])]):(Item.type == 'icon-whiteboard')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.whiteboardTitle ? Item.properties.whiteboardTitle : _vm.languageData.whiteboard_card))])]):(Item.type == 'icon-map')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(_vm.languageData.map_card))])]):(Item.type == 'icon-camera')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(_vm.languageData.camera_card))])]):_vm._e()]):(
                  _vm.stageIndex - 1 != i &&
                  itemNew.isFold &&
                  item.isFold &&
                  _vm.stageDatas.length == 0
                )?_c('a',{key:'menu_' + i + '_' + j + '_' + index,staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.navToOther(i, j, index)}}},[(Item.type == 'icon-card')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.title ? Item.properties.title : ""))])]):(Item.type == 'icon-select')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.selectTitle ? Item.properties.selectTitle : ""))])]):(Item.type == 'icon-thinking')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.thinkingTitle ? Item.properties.thinkingTitle : _vm.languageData.thinkingCard))])]):(Item.type == 'icon-AI')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.topic ? Item.properties.topic : ""))])]):(Item.type == 'icon-new-AI')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.topic ? Item.properties.topic : ""))])]):(Item.type == 'icon-whiteboard')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.whiteboardTitle ? Item.properties.whiteboardTitle : _vm.languageData.whiteboard_card))])]):(Item.type == 'icon-map')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(_vm.languageData.map_card))])]):(Item.type == 'icon-camera')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(_vm.languageData.camera_card))])]):_vm._e()]):_vm._e()]})]})]})],2)])]):_c('div',{staticStyle:{"height":"calc(100%)","display":"flex","flex-direction":"column"}},[(_vm.isShowMulu)?_c('div',{staticStyle:{"padding":"12px 16px","display":"flex","flex-direction":"row","justify-content":"center","align-items":"center","border-radius":"20px 20px 0px 0px","border-bottom":"1px solid var(--bg3, #E7E7E7)","background":"#FFF"}},[_c('span',{staticStyle:{"color":"var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90))","text-align":"center","font-family":"SimSun","font-size":"16px","font-style":"normal","font-weight":"600","line-height":"24px"}},[_vm._v(" "+_vm._s(_vm.languageData.directory)+" ")])]):_vm._e(),_c('button',{staticClass:"close",attrs:{"type":"button"}},[(_vm.hoverClose)?_c('img',{attrs:{"src":require("../../assets/close_pre.png"),"width":"16","height":"16"},on:{"mouseout":_vm.blurClose,"click":_vm.handleClose}}):_c('img',{attrs:{"src":require("../../assets/close_nor.png"),"width":"16","height":"16"},on:{"mouseover":_vm.focusClose}})]),_c('div',{staticClass:"left-side",style:(_vm.fullscreen ? '' : ''),attrs:{"id":"leftSide"}},[(_vm.themeName != '')?_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[_c('div',{staticStyle:{"width":"calc(100% - 20px)","margin-left":"10px","color":"var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9))","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","word-break":"break-word","font-family":"SimSun","font-size":"16px","font-style":"normal","font-weight":"600","line-height":"24px"},attrs:{"title":_vm.themeName}},[_vm._v(" "+_vm._s(_vm.themeName)+" ")])]):_vm._e(),_c('div',{staticClass:"menuList",style:(_vm.themeName != ''
            ? 'overflow-y: auto;overflow-x: hidden;width: calc(100%);display: flex;flex-direction: column;align-items: center;margin-top: 15px;height: calc(100% - 47px);'
            : 'overflow-y: auto;overflow-x: hidden;width: calc(100%);display: flex;flex-direction: column;align-items: center;height: calc(100% - 8px);')},[_vm._l((_vm.newnavigationListNew),function(item,i){return [(item.type == 'icon-stage')?_c('div',{key:'menu_' + i,staticStyle:{"width":"100%","cursor":"pointer"}},[_c('div',{staticStyle:{"width":"calc(100% - 16px)","display":"flex","flex-direction":"row","padding":"12px 8px","align-items":"center","font-family":"SimSun","font-size":"14px","font-style":"normal","font-weight":"400","line-height":"22px"},style:(_vm.selectMulu == item.id ? 'background: var(--brand-light, #e0eafb);color: var(--brand-click, #0061ff);' :'color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));')},[(
                    _vm.selectMulu == item.id &&
                    item.isFold &&
                    item.child.length != 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer","visibility":"hidden"},on:{"click":function($event){return _vm.hideStageNew(i)}}},[_c('img',{style:(item.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):(item.isFold && item.child.length != 0)?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer","visibility":"hidden"},on:{"click":function($event){return _vm.hideStageNew(i)}}},[_c('img',{style:(item.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_nor.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu == item.id &&
                    !item.isFold && item.child.length != 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer","visibility":"hidden"},on:{"click":function($event){return _vm.showStageNew(i)}}},[_c('img',{style:(item.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):(!item.isFold && item.child.length != 0)?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer","visibility":"hidden"},on:{"click":function($event){return _vm.showStageNew(i)}}},[_c('img',{style:(item.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_nor.png"),"alt":"","width":"16","height":"16"}})]):(item.child.length == 0 || _vm.stageDatas.length != 0)?_c('div',{staticClass:"closeTri"},[_c('img',{staticStyle:{"visibility":"hidden"},attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):_vm._e(),_c('div',{style:(_vm.selectMulu == item.id ?
                  'background: var(--brand-light, #e0eafb);width: calc(100% - 21px);display: flex;flex-direction: row;align-items: center;color: var(--Brand-Normal, #3681FC);font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 600;line-height: 20px;' : 'width: calc(100% - 21px);display: flex;flex-direction: row;align-items: center;color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 600;line-height: 20px;'),on:{"click":function($event){return _vm.navToStage(i)}}},[_c('div',{staticClass:"stageName-1",staticStyle:{"width":"auto"}},[_vm._v(" "+_vm._s(_vm.languageData.stageName)+_vm._s(i + 1)+": ")]),_c('div',{staticStyle:{"margin-left":"10px","width":"auto","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","word-break":"break-word"}},[_vm._v(" "+_vm._s(item.properties.stageName)+" ")])])])]):_vm._e(),_vm._l((item.child),function(itemNew,j){return [(_vm.stageIndex - 1 == i && item.isFold)?_c('a',{key:'menu_' + i + '_' + j,staticStyle:{"text-decoration":"none"},on:{"click":function($event){$event.stopPropagation();return _vm.navTo(itemNew.id)}}},[(itemNew.type == 'icon-task')?_c('div',{class:_vm.selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti',attrs:{"data-id":itemNew.id}},[(
                    _vm.selectMulu == itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer","visibility":"hidden"},on:{"click":function($event){$event.stopPropagation();return _vm.hideTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu != itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer","visibility":"hidden"},on:{"click":function($event){$event.stopPropagation();return _vm.hideTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_nor.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu == itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer","visibility":"hidden"},on:{"click":function($event){$event.stopPropagation();return _vm.showTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu != itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer","visibility":"hidden"},on:{"click":function($event){$event.stopPropagation();return _vm.showTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_nor.png"),"alt":"","width":"16","height":"16"}})]):(
                    itemNew.child.length == 0 || _vm.stageDatas.length != 0
                  )?_c('div',{staticClass:"closeTri"},[_c('img',{staticStyle:{"visibility":"hidden"},attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):_vm._e(),_c('span',{class:_vm.selectMulu == itemNew.id ? 'text-1 text-select' : 'text-1',staticStyle:{"cursor":"pointer"},attrs:{"data-id":itemNew.id}},[_vm._v(_vm._s(_vm.languageData.taskTip)+" "+_vm._s(itemNew.properties.taskTitle ? itemNew.properties.taskTitle : ""))])]):_vm._e()]):(_vm.stageIndex - 1 != i && item.isFold)?_c('a',{key:'menu_' + i + '_' + j,staticStyle:{"text-decoration":"none"},on:{"click":function($event){$event.stopPropagation();return _vm.navToTask(i, j)}}},[(itemNew.type == 'icon-task')?_c('div',{class:_vm.selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti',attrs:{"data-id":itemNew.id}},[(
                    _vm.selectMulu == itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer","visibility":"hidden"},on:{"click":function($event){$event.stopPropagation();return _vm.hideTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu != itemNew.id &&
                    itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer","visibility":"hidden"},on:{"click":function($event){$event.stopPropagation();return _vm.hideTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_nor.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu == itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer","visibility":"hidden"},on:{"click":function($event){$event.stopPropagation();return _vm.showTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):(
                    _vm.selectMulu != itemNew.id &&
                    !itemNew.isFold &&
                    itemNew.child.length != 0 &&
                    _vm.stageDatas.length == 0
                  )?_c('div',{staticClass:"closeTri",staticStyle:{"cursor":"pointer","visibility":"hidden"},on:{"click":function($event){$event.stopPropagation();return _vm.showTaskNew(i, j)}}},[_c('img',{style:(itemNew.isFold ? '' : 'transform: rotate(-90deg);'),attrs:{"src":require("../../assets/chevron-up_nor.png"),"alt":"","width":"16","height":"16"}})]):(
                    itemNew.child.length == 0 || _vm.stageDatas.length != 0
                  )?_c('div',{staticClass:"closeTri"},[_c('img',{staticStyle:{"visibility":"hidden"},attrs:{"src":require("../../assets/chevron-up_pre.png"),"alt":"","width":"16","height":"16"}})]):_vm._e(),_c('span',{class:_vm.selectMulu == itemNew.id ? 'text-1 text-select' : 'text-1',staticStyle:{"cursor":"pointer"},attrs:{"data-id":itemNew.id}},[_vm._v(_vm._s(_vm.languageData.taskTip)+" "+_vm._s(itemNew.properties.taskTitle ? itemNew.properties.taskTitle : ""))])]):_vm._e()]):_vm._e(),_vm._l((itemNew.child),function(Item,index){return [(
                  _vm.stageIndex - 1 == i &&
                  itemNew.isFold &&
                  item.isFold &&
                  _vm.stageDatas.length == 0
                )?_c('a',{key:'menu_' + i + '_' + j + '_' + index,staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.navTo(Item.id)}}},[(Item.type == 'icon-card')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.title ? Item.properties.title : ""))])]):(Item.type == 'icon-select')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.selectTitle ? Item.properties.selectTitle : ""))])]):(Item.type == 'icon-thinking')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.thinkingTitle ? Item.properties.thinkingTitle : _vm.languageData.thinkingCard))])]):(Item.type == 'icon-AI')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.topic ? Item.properties.topic : ""))])]):(Item.type == 'icon-new-AI')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.topic ? Item.properties.topic : ""))])]):(Item.type == 'icon-whiteboard')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.whiteboardTitle ? Item.properties.whiteboardTitle : _vm.languageData.whiteboard_card))])]):(Item.type == 'icon-map')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(_vm.languageData.map_card))])]):(Item.type == 'icon-camera')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(_vm.languageData.camera_card))])]):_vm._e()]):(
                  _vm.stageIndex - 1 != i &&
                  itemNew.isFold &&
                  item.isFold &&
                  _vm.stageDatas.length == 0
                )?_c('a',{key:'menu_' + i + '_' + j + '_' + index,staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.navToOther(i, j, index)}}},[(Item.type == 'icon-card')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.title ? Item.properties.title : ""))])]):(Item.type == 'icon-select')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.selectTitle ? Item.properties.selectTitle : ""))])]):(Item.type == 'icon-thinking')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.thinkingTitle ? Item.properties.thinkingTitle : _vm.languageData.thinkingCard))])]):(Item.type == 'icon-AI')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.topic ? Item.properties.topic : ""))])]):(Item.type == 'icon-new-AI')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.topic ? Item.properties.topic : ""))])]):(Item.type == 'icon-whiteboard')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(Item.properties.whiteboardTitle ? Item.properties.whiteboardTitle : _vm.languageData.whiteboard_card))])]):(Item.type == 'icon-map')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(_vm.languageData.map_card))])]):(Item.type == 'icon-camera')?_c('div',{class:_vm.selectMulu == Item.id ? 'biaoti-select' : 'biaoti',staticStyle:{"padding":"8px 16px 8px 70px","width":"calc(100% - 86px)"},attrs:{"data-id":Item.id}},[_c('span',{class:_vm.selectMulu == Item.id
                        ? 'text-1 text-other text-select'
                        : 'text-1 text-other',attrs:{"data-id":Item.id}},[_vm._v(_vm._s(_vm.languageData.camera_card))])]):_vm._e()]):_vm._e()]})]})]})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }