<template>
  <!-- <svg class="icon" viewBox="0 0 1024 1024" :width="size" :height="size">
    <path d="M170.666667 967.111111H56.888889v-113.777778c0-284.444444 227.555556-512 512-512h341.333333v113.777778h-341.333333c-221.866667 0-398.222222 176.355556-398.222222 398.222222v113.777778z" fill="#0D1733" p-id="1167">
    </path>
    <path d="M614.4 762.311111L529.066667 682.666667l284.444444-284.444445-284.444444-278.755555L614.4 39.822222 972.8 398.222222z" fill="#0D1733" p-id="1168">
    </path>
  </svg> -->

  <svg class="icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" :width="size" :height="size" viewBox="0 0 20 20"
    enable-background="new 0 0 20 20" xml:space="preserve">
    <image id="image0" width="20" height="20" x="0" y="0" href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjMwOyYjMTI5
OyYjMTYyOyYjMjI5OyYjMTY0OyYjMTQxOyI+CjxwYXRoIGlkPSJWZWN0b3IgNDQgKFN0cm9rZSki
IGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOSAyLjYxMzI1TDE0
IDUuNUw5IDguMzg2NzVWNkg1LjVDMy44NDMxNSA2IDIuNSA3LjM0MzE1IDIuNSA5QzIuNSAxMC42
NTY5IDMuODQzMTUgMTIgNS41IDEySDE0QzE0LjI3NjEgMTIgMTQuNSAxMi4yMjM5IDE0LjUgMTIu
NUMxNC41IDEyLjc3NjEgMTQuMjc2MSAxMyAxNCAxM0g1LjVDMy4yOTA4NiAxMyAxLjUgMTEuMjA5
MSAxLjUgOUMxLjUgNi43OTA4NiAzLjI5MDg2IDUgNS41IDVIOVYyLjYxMzI1WiIgZmlsbD0iYmxh
Y2siIGZpbGwtb3BhY2l0eT0iMC45Ii8+CjwvZz4KPC9zdmc+Cg==" />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: '24'
    }
  }
}
</script>
