import EvaluationNode from './EvaluationNode'

// 左上角ICON为消息的节点
class StudentMutualNode extends EvaluationNode.view {
  getImageHref () {
    return require('@/assets/tools/学生互评.png');
  }
}


export default {
  type: 'icon-student-mutual',
  view: StudentMutualNode,
  model: EvaluationNode.model
}