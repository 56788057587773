<template>
    <div class="navigatorContainer">
        <div class="page" v-if="isProjection" style="display: flex;align-items: center;justify-content: center;">
            <div style="display: flex;align-items: center;justify-content: center;margin-right: 15px;"
                v-if="isProjection && isExitPrevious">
                <!-- <el-button size="mini" class="projection" @click="$_previous_projection"
                  :title="languageData.previousPage">{{ languageData.previousPage }}</el-button> -->
                <img src="../../assets/上一页.png" :title="languageData.previousPage" @click="$_previous_projection"
                    style="cursor:pointer;width: 16px;height: 16px;">
            </div>
            <div style="display: flex;align-items: center;justify-content: center;" v-if="isProjection && isExitNext">
                <!-- <el-button size="mini" class="projection" @click="$_next_projection"
                  :title="languageData.nextPage">{{ languageData.nextPage }}</el-button> -->
                <img src="../../assets/下一页.png" :title="languageData.nextPage" @click="$_next_projection"
                    style="cursor:pointer;width: 16px;height: 16px;">
            </div>
        </div>
        <div style="display:flex;flex-direction:row;justify-content: center;margin-left: 10px;margin-right: 10px;" v-if="!isProjection && !isShare">
            <el-switch v-model="value" :active-text="languageData.readOnlyMode" :inactive-text="languageData.editMode"
                @change="changeMode">
            </el-switch>
        </div>
        <div style="display:flex;flex-direction:row;justify-content: center;" v-if="!isProjection">
            <div class="scaleContainer">
                <div class="el-tooltip btn el-icon-minus item" aria-describedby="el-tooltip-5941" tabindex="0"
                    @click="reduce"></div>
                <div class="scaleInfo">{{ transform }}%</div>
                <div class="el-tooltip btn el-icon-plus item" aria-describedby="el-tooltip-1380" tabindex="0"
                    @click="enlarge"></div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import LogicFlow from "@logicflow/core";
export default {
    props: {
        lf: LogicFlow,
        transform: Number,
        isProjection: Boolean,
        isExitPrevious: Boolean,
        isExitNext: Boolean,
        isChangeRate: Boolean,
        isShare: Boolean,
    },
    data() {
        return {
            value: false,
            languageData: {},
        }
    },
    watch: {
        isChangeRate(newVal) {
            if(newVal) {
                this.$emit("getTransformSize", 100)
                this.$emit("changeRate", false)
            }
        }
    },
    mounted() {
        if (window.location.href.indexOf("cocorobo.cn") != -1) {
            this.languageData = this.zhHansLanguage;
        }
        else if (window.location.href.indexOf("cocorobo.hk") != -1) {
            this.languageData = this.zhHantLanguage;
        }else if (window.location.href.indexOf("cocorobo.com") != -1) {
            this.languageData = this.enLanguage;
        }
        else {
            this.languageData = this.zhHansLanguage;
        }
    },
    methods: {
        changeMode(e) {
            if (e) {
                this.$props.lf.updateEditConfig({
                    isSilentMode: e,
                });
                this.$emit("setIsSilentMode", e)
            }
            else {
                this.$props.lf.updateEditConfig({
                    isSilentMode: e,
                });
                this.$emit("setIsSilentMode", e)
            }
        },
        reduce() {
            var _this = this;
            _this.$nextTick(() => {
                var size = parseInt($(".scaleInfo").text().split("%")[0])
                if (size - 4 >= 24) {
                    // $(".scaleInfo").text((size - 10).toString() + "%");
                    _this.$props.lf.zoom((size - 4) / 100)
                    _this.$emit("getTransformSize", (size - 4))
                }
            })
        },
        enlarge() {
            var _this = this;
            _this.$nextTick(() => {
                var size = parseInt($(".scaleInfo").text().split("%")[0])
                if (size + 4 <= 400) {
                    // $(".scaleInfo").text((size + 10).toString() + "%");
                    _this.$props.lf.zoom((size + 4) / 100)
                    _this.$emit("getTransformSize", (size + 4))
                }
            })
        },
        $_previous_projection() {
            this.$parent.$_previous_projection();
        },
        $_next_projection() {
            this.$parent.$_next_projection();
        }
    }
}
</script>

<style>
.navigatorContainer {
    background: #FFFFFF;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
}

.item {
    margin-Right: 10px;
    margin-left: 10px;
}

.scaleContainer {
    display: flex;
    align-items: center;
}

.scaleContainer .btn {
    cursor: pointer;
}

.scaleContainer .scaleInfo {
    width: 40px;
    text-align: center;
    margin: 0 20px;
}
</style>

<style scoped>
.projection {
    /* position: absolute;
  top: 66px;
  left: 58%; */
    z-index: 10;
    background: #FF9800;
    margin-left: 20px;
    box-shadow: 0px 2px 15px 1px rgba(0, 123, 255, 0.16);
    border-color: #FF9800;
    color: white;
}
</style>
