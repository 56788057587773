import { EllipseResize } from '@logicflow/extension'
import { getShapeStyleFuction, getTextStyleFunction } from '../getShapeStyleUtil'

// import { enLanguage } from '../../../lang/en.js'
import { zhHansLanguage } from '../../../lang/zh-Hans.js'
import { zhHantLanguage } from '../../../lang/zh-Hant.js'

// 资源
class StartModel extends EllipseResize.model {
  initNodeData(data) {
    data.text = {
      value: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.start : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.start : zhHansLanguage.start),
      x: data.x,
      y: data.y,
    };
    super.initNodeData(data)
    this.text.editable = false; // 禁止节点文本编辑
    this.rx = 40
    this.ry = 40
  }

  setToBottom() {
    this.zIndex = 0
  }

  getNodeStyle() {
    const style = super.getNodeStyle()
    // style.fill = "#22314F"
    style.stroke = "#007bff"
    const properties = this.getProperties()
    return getShapeStyleFuction(style, properties)
  }

  getTextStyle() {
    const style = super.getTextStyle()
    style.color = "#007bff"
    const properties = this.getProperties()
    return getTextStyleFunction(style, properties)
  }

  // getResizeOutlineStyle(){
  //   const style = super.getResizeOutlineStyle();
  //   style.stroke = "#007BFF";
  //   style.strokeWidth = 2
  //   style.strokeDasharray = "3,3"
  //   style.fill = "transparent"
  //   return style;
  // }
}

export default {
  type: 'icon-start',
  view: EllipseResize.view,
  model: StartModel
}
