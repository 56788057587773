<template>
  <!-- <svg class="icon" viewBox="0 0 1024 1024" :width="size" :height="size">
    <path d="M946.9 897.7L744 695.2c24.4-29.2 44.2-61.7 59.1-97 20.2-47.7 30.4-98.3 30.4-150.5s-10.2-102.8-30.4-150.5c-19.5-46-47.3-87.4-82.8-122.9s-76.8-63.4-122.8-82.8c-47.7-20.2-98.3-30.4-150.4-30.4S344.4 71.3 296.8 91.5c-46 19.5-87.3 47.4-122.8 82.8-35.5 35.5-63.3 76.8-82.8 122.9-20.2 47.7-30.4 98.3-30.4 150.5S71 550.5 91.2 598.2c19.5 46 47.3 87.4 82.8 122.9s76.8 63.4 122.8 82.8c47.7 20.2 98.3 30.4 150.4 30.4s102.7-10.2 150.4-30.4c34.9-14.8 67.1-34.4 96.1-58.5l203 202.6c6.9 6.9 16 10.4 25.1 10.4 9.1 0 18.2-3.5 25.2-10.5 13.8-13.8 13.8-36.3-0.1-50.2zM447.2 763.2c-84.2 0-163.3-32.8-222.8-92.4C164.8 611.2 132 532 132 447.7c0-84.3 32.8-163.5 92.3-223.1 59.5-59.6 138.7-92.4 222.8-92.4s163.3 32.8 222.8 92.4c59.5 59.6 92.3 138.8 92.3 223.1 0 83.9-32.5 162.8-91.6 222.3-0.4 0.4-0.8 0.8-1.2 1.1-0.3 0.3-0.6 0.6-0.8 0.9-59.3 58.9-137.9 91.2-221.4 91.2z" p-id="967">
    </path>
    <path d="M574 416H303.7c-19.7 0-35.6 12.8-35.6 32.5S284 481 303.7 481H574c19.7 0 35.6-12.8 35.6-32.5S593.7 416 574 416z" p-id="968">
    </path>
  </svg> -->

  <svg class="icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" :width="size" :height="size" viewBox="0 0 19 20"
    enable-background="new 0 0 19 20" xml:space="preserve">
    <image id="image0" width="19" height="20" x="0" y="0" href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjMxOyYjMTg4
OyYjMTY5OyYjMjI5OyYjMTc2OyYjMTQzOyI+CjxwYXRoIGlkPSJFbGxpcHNlIDQgKFN0cm9rZSki
IGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy41Njc5NSAzLjAx
MjM1QzUuMDUxOTYgMy4wMTIzNSAzLjAxMjM1IDUuMDUxOTYgMy4wMTIzNSA3LjU2Nzk1QzMuMDEy
MzUgMTAuMDgzOSA1LjA1MTk2IDEyLjEyMzUgNy41Njc5NSAxMi4xMjM1QzguODI2MDggMTIuMTIz
NSA5Ljk2NDI5IDExLjYxNDIgMTAuNzg5MiAxMC43ODkyQzExLjYxNDIgOS45NjQyOSAxMi4xMjM1
IDguODI2MDggMTIuMTIzNSA3LjU2Nzk1QzEyLjEyMzUgNS4wNTE5NiAxMC4wODM5IDMuMDEyMzUg
Ny41Njc5NSAzLjAxMjM1Wk0yIDcuNTY3OTVDMiA0LjQ5Mjg2IDQuNDkyODYgMiA3LjU2Nzk1IDJD
MTAuNjQzIDIgMTMuMTM1OSA0LjQ5Mjg2IDEzLjEzNTkgNy41Njc5NUMxMy4xMzU5IDguOTIzMDQg
MTIuNjUxMyAxMC4xNjU3IDExLjg0NjggMTEuMTMwOUwxMy42NDIxIDEyLjkyNjJDMTMuODM5OCAx
My4xMjM5IDEzLjgzOTggMTMuNDQ0NCAxMy42NDIxIDEzLjY0MjFDMTMuNDQ0NCAxMy44Mzk4IDEz
LjEyMzkgMTMuODM5OCAxMi45MjYyIDEzLjY0MjFMMTEuMTMwOSAxMS44NDY4QzEwLjE2NTcgMTIu
NjUxMyA4LjkyMzA0IDEzLjEzNTkgNy41Njc5NSAxMy4xMzU5QzQuNDkyODYgMTMuMTM1OSAyIDEw
LjY0MyAyIDcuNTY3OTVaIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjkiLz4KPHBhdGgg
aWQ9IlZlY3RvciA0MCAoU3Ryb2tlKSIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2
ZW5vZGQiIGQ9Ik01LjU3ODk1IDcuNUM1LjU3ODk1IDcuMjIzODYgNS43Njc0NiA3IDYgN0g5LjE1
NzlDOS4zOTA0NCA3IDkuNTc4OTUgNy4yMjM4NiA5LjU3ODk1IDcuNUM5LjU3ODk1IDcuNzc2MTQg
OS4zOTA0NCA4IDkuMTU3OSA4SDZDNS43Njc0NiA4IDUuNTc4OTUgNy43NzYxNCA1LjU3ODk1IDcu
NVoiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuOSIvPgo8L2c+Cjwvc3ZnPgo=" />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: '24'
    }
  }
}
</script>
