// import { HtmlNodeModel, HtmlNode } from '@logicflow/core';
import { HtmlResize } from '@logicflow/extension';
import '../../../uml.css'
import { zhHansLanguage } from '../../../lang/zh-Hans.js'
import { zhHantLanguage } from '../../../lang/zh-Hant.js'
import { enLanguage } from '../../../lang/en.js'

class SelectModel extends HtmlResize.model {
  initNodeData(data) {
    super.initNodeData(data)
    this.text.editable = false; // 禁止节点文本编辑
    const width = 250;
    const height = 154;
    this.width = width;
    this.height = height;
    const unable_to_connect_with_oneself = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip43 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip43 : enLanguage.tip43),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor)
        return targetNode.id != sourceNode.id;
      },
    };
    this.sourceRules.push(unable_to_connect_with_oneself);
    const unable_to_connect_with_theme = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.unable_to_connect_with_theme : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.unable_to_connect_with_theme : enLanguage.unable_to_connect_with_theme),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceNode, sourceAnchor, targetAnchor)
        return targetNode.type != "icon-theme";
      },
    }
    this.sourceRules.push(unable_to_connect_with_theme);
    const unable_to_connect_with_other = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.unable_to_connect_with_other : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.unable_to_connect_with_other : enLanguage.unable_to_connect_with_other),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor)
        let edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id)
        let isConnect = false
        for(let i = 0;i < edges.length;i++) {
          if(edges[i].sourceNodeId == sourceNode.id && edges[i].targetNodeId == targetNode.id) {
            isConnect = true
            break
          }
        }
        return isConnect == false;
      },
    }
    this.sourceRules.push(unable_to_connect_with_other);
  }
  // setAttributes() {
  //   this.text.editable = false; // 禁止节点文本编辑
  //   // 设置节点宽高和锚点
  //   const width = 250;
  //   const height = 200;
  //   this.width = width;
  //   this.height = height;
  //   this.anchorsOffset = [
  //     [width / 2, 0],
  //     [0, height / 2],
  //     [-width / 2, 0],
  //     [0, -height / 2],
  //   ]
  // }
  // getResizeOutlineStyle(){
  //   const style = super.getResizeOutlineStyle();
  //   style.stroke = "#007BFF";
  //   style.strokeWidth = 2
  //   style.strokeDasharray = "3,3"
  //   style.fill = "transparent"
  //   return style;
  // }
}
class SelectNode extends HtmlResize.view {
  setHtml(rootEl) {
    const { id, properties } = this.props.model;

    // var r = Math.floor(Math.random() * 256);
    // var g = Math.floor(Math.random() * 256);
    // var b = Math.floor(Math.random() * 256);
    // var color = "rgb(" + r + "," + g + "," + b + ")";
    var color = (properties.selectTitleColor && properties.selectTitleColor != '') ? properties.selectTitleColor : '#F284B2'
    let cardTitle = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.select : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.select : enLanguage.select)
    const el = document.createElement('div');
    el.className = 'uml-wrapper';
    // el.style = "border: 1px solid " + color + ';';
    var html = `
    <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
      <i class="el-icon-s-custom"></i>
      <div class="is_show_user_name">${properties.user_name}</div>
      <i class="el-icon-edit"></i>
      <div class="apostrophe"></div>
    </div>
    <div style="height:${!properties.is_show_user ? '100%' : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 27) + 'px' : 127 + 'px')};">
      <div class="uml-head" style="background-color:${color};">${properties.selectTitle ? properties.selectTitle : cardTitle}</div>
      <i class="el-icon-circle-close closeImg" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
      <div style="height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 122 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 94 + 'px')};display: flex;justify-content: center;align-items: center;flex-direction: column;color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 400;line-height: 22px;padding:8px;">
        <div class="uml-select-body" style="text-align:justify;">
          ${properties.selectContent ? properties.selectContent : ''}
        </div>
        <div class="uml-select-option">`;

    var option = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
      'U', 'V', 'W', 'X', 'Y', 'Z']
    if (properties.selectOption) {
      for (var i = 0; i < properties.selectOption.length; i++) {
        html += `<span style="margin-right:5px;font-size:12px;">` + option[i] + `.` + properties.selectOption[i] + `</span>`;
      }
    }
    html += `</div>
      </div>
    </div>
    `;
    el.innerHTML = html;
    // 需要先把之前渲染的子节点清除掉。
    rootEl.innerHTML = '';
    rootEl.appendChild(el);
  }
}

export default {
  type: 'icon-select',
  view: SelectNode,
  model: SelectModel
}