import ImageNode from './ImageNode'


// 图片-用户节点
class UserNode extends ImageNode.view {
  getImageHref () {
    return 'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240229165621.png';
  }
}


export default {
  type: 'icon-st-4',
  view: UserNode,
  model: ImageNode.model
}