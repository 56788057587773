<template>
  <div v-show="isPromptsList" class="flex_x chat_room_content">
    <div class="promptsList flex_x">
      <div class="flex_y_center" style="flex: 1">
        <div style="text-align: center; font-weight: bolder; line-height: 40px">
          {{ languageData.call_word }}
        </div>
        <div style="width: 100%; overflow-y: scroll; flex: 1">
          <el-collapse v-model="activeName" accordion>
            <template v-for="(item, index) in languageData.promptsList">
              <el-collapse-item :title="item.label" :name="index + 1" v-bind:key="index">
                <template v-for="(items, i) in item.prompt">
                  <div
                    class="prompt"
                    v-bind:key="i"
                    @click="selectProblem(items.label)"
                    style="cursor: pointer"
                  >
                    {{ i + 1 }}.{{ items.label }}
                  </div>
                </template>
              </el-collapse-item>
            </template>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isPromptsList: Boolean,
  },
  data() {
    return {
      languageData: {},
      activeName: "1",
    };
  },
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
  },
  methods: {
    selectProblem(text) {
    //   console.log(text);
      this.$emit("changePrompts",text)
    //   this.$emit("changeisPromptsList",false)
    },
  },
};
</script>

<style scoped>
.chat_room_content {
  height: 40vh;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.promptsList {
  padding: 10px 10px 10px 10px;
  background: #fff;
  width: 210px;
  position: absolute;
  right: 10px;
  z-index: 9999;
  height: calc(40vh - 20px);
  /* border-radius: 5px 0px 0px 5px; */
  /* border-bottom: 1px solid #ccccce;
  border-left: 1px solid #ccccce;
  border-top: 1px solid #ccccce; */
}

.prompt {
  background: #e7f3ff;
  border-radius: 5px;
  color: #000 !important;
  padding: 10px;
  margin-bottom: 10px;
}
</style>
