
import $ from 'jquery'

function touchHandler(event) {
    var touches = event.changedTouches,
        first = touches[0],
        type = "";
    switch (event.type) {
        case "touchstart": type = "mousedown"; break;
        case "touchmove": type = "mousemove"; break;
        case "touchend": type = "mouseup"; break;
        default: return;
    }
    var simulatedEvent = document.createEvent("MouseEvent");
    simulatedEvent.initMouseEvent(type, true, true, window, 1,
        first.screenX, first.screenY,
        first.clientX, first.clientY, false,
        false, false, false, 0 /*left*/, null);
    simulatedEvent.touches = event.touches;
    simulatedEvent.targetTouches = event.targetTouches
    event.stopPropagation();
    first.target.dispatchEvent(simulatedEvent);
}

export function touchInit() {
    document.addEventListener("touchstart", touchHandler, true);
    document.addEventListener("touchmove", touchHandler, true);
    document.addEventListener("touchend", touchHandler, true);
    document.addEventListener("touchcancel", touchHandler, true);

    
    SVGElement.prototype.__defineGetter__("ondblclick", function () {
        return this.doubleTap;
    });

    SVGElement.prototype.__defineSetter__("ondblclick", function (fun) {
        this.doubleTap = fun;
        $(this).on("doubleTap", fun)
    });

    var eventlstener = HTMLElement.prototype.addEventListener;

    SVGElement.prototype.addEventListener = function (type, fun, op) {
        if (type == "click") {
            eventlstener.call(this, type, function (e) {
                if (!this.startTime) {
                    this.startTime = new Date().getTime();
                    fun.apply(this, arguments);
                } else {
                    console.log(this.startTime);
                    console.log(new Date().getTime());
                    const time = new Date().getTime() - this.startTime;
                    this.startTime = new Date().getTime();
                    console.log(time);
                    if (time > 200) {
                        fun.apply(this, arguments);
                    } else {
                        var simulatedEvent = document.createEvent("MouseEvent");
                        simulatedEvent.initMouseEvent("click", true, true, window, 2,
                            e.screenX, e.screenY,
                            e.clientX, e.clientY, false,
                            false, false, false, 0 /*left*/, null);
                        window.event = simulatedEvent;
                        arguments[0] = simulatedEvent;
                        fun.apply(this, arguments);
                    }
                }

            }, op);
        } else {
            eventlstener.apply(this, arguments);
        }
    }
}
