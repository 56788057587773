<template>
    <div id="addIcon">
        <el-dialog :visible.sync="dialogVisible" width="45%" :destroy-on-close="true"
            :before-close="handleClose" :close-on-click-modal="false" :show-close="false">
            <div slot="title" style="width:100%;position: relative;">
                <span class="el-dialog__title">{{ languageData.addIcon }}</span>
                <button type="button" class="close" style="top: 4px;right: 0;">
                    <img v-if="hoverClose" src="../../assets/close_pre.png" width="16" height="16" @mouseout="blurClose"
                    @click="handleClose" />
                    <img v-else src="../../assets/close_nor.png" width="16" height="16" @mouseover="focusClose" />
                </button>
            </div>
            <span>
                <div
                    :style="imageList.length != 0 ? 'width: 100%;height: 260px;display: flex;flex-direction: row;' : 'width: 100%;'">
                    <div style="overflow-y:auto">
                        <div
                            style="display:flex;width:100%;flex-flow: row wrap;justify-content: space-around;margin-top:20px;">
                            <template v-for="(item, index) in imageList">
                                <img :src="item.url" width="80" height="60" v-bind:key="index" style="margin-bottom:20px"
                                    @click="goToAddIcon" />
                            </template>
                        </div>
                    </div>

                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import img_1 from '../../assets/icon/成长.png'
import img_2 from '../../assets/icon/电台.png'
import img_3 from '../../assets/icon/订阅.png'
import img_4 from '../../assets/icon/方法.png'
import img_5 from '../../assets/icon/个人中心.png'
import img_6 from '../../assets/icon/黑板.png'
import img_7 from '../../assets/icon/化学.png'
import img_8 from '../../assets/icon/绘画.png'
import img_9 from '../../assets/icon/计算器.png'
import img_10 from '../../assets/icon/奖牌.png'
import img_11 from '../../assets/icon/课单.png'
import img_12 from '../../assets/icon/闹钟.png'
import img_13 from '../../assets/icon/日历.png'
import img_14 from '../../assets/icon/沙漏.png'
import img_15 from '../../assets/icon/社区.png'
import img_16 from '../../assets/icon/收藏.png'
import img_17 from '../../assets/icon/书包.png'
import img_18 from '../../assets/icon/书法.png'
import img_19 from '../../assets/icon/书籍.png'
import img_20 from '../../assets/icon/网络课程.png'
import img_21 from '../../assets/icon/文件夹.png'
import img_22 from '../../assets/icon/消息.png'
import img_23 from '../../assets/icon/学位.png'
import img_24 from '../../assets/icon/学校.png'
import img_25 from '../../assets/icon/英语.png'
import img_26 from '../../assets/icon/游戏.png'
import img_27 from '../../assets/icon/运动.png'
import img_28 from '../../assets/icon/证件.png'
import img_29 from '../../assets/icon/证书.png'
import img_30 from '../../assets/icon/直播.png'
import $ from 'jquery'
export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        title: String
    },
    data() {
        return {
            input: '',
            languageData: {},
            hoverClose: false,
            imageList: [
                {
                    "height": 500,
                    "url": img_1,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_2,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_3,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_4,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_5,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_6,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_7,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_8,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_9,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_10,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_11,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_12,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_13,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_14,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_15,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_16,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_17,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_18,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_19,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_20,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_21,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_22,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_23,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_24,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_25,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_26,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_27,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_28,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_29,
                    "width": 750
                },
                {
                    "height": 500,
                    "url": img_30,
                    "width": 750
                },
            ]
        };
    },
    created() {
        this.$nextTick(() => {
            $("#addIcon .el-dialog").css("width", "45%");
            $("#addIcon .el-dialog").css({
                "height": "auto"
            })
        })
    },
    mounted() {
        if (window.location.href.indexOf("cocorobo.cn") != -1) {
            this.languageData = this.zhHansLanguage;
        }
        else if (window.location.href.indexOf("cocorobo.hk") != -1) {
            this.languageData = this.zhHantLanguage;
        }else if (window.location.href.indexOf("cocorobo.com") != -1) {
            this.languageData = this.enLanguage;
        }
        else {
            this.languageData = this.zhHansLanguage;
        }
    },
    watch: {
        dialogVisible(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    $(".el-dialog").css("width", "45%");
                    $(".el-dialog").css({
                        "height": "auto"
                    })
                })
            }
        }
    },
    methods: {
        focusClose() {
            this.hoverClose = true;
        },
        blurClose() {
            this.hoverClose = false;
        },
        handleClose() {
            this.$emit("info", false);
            // this.$emit("clearList", []);
        },
        goToAddIcon(e) {
            const iconList = [{ name: '', url: e.target.currentSrc }]
            this.$emit('setIconList', iconList)
            this.$emit('setStyle', {
                iconList: [{ name: '', url: e.target.currentSrc }],
                // radio: "icon",
                iconRadio: true,
                nodeSize: {
                    width: 80,
                    height: 80
                }
            })
            this.$emit("info", false);
        },
    }
};
</script>

<style>
#addIcon .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
}

#addIcon .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-white, #fff);
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, .050980392156862744);
  padding: 8px 24px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#addIcon .el-dialog__body {
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  padding: 0;
  background: var(--font-icon-white,#fff);
  padding: 8px 24px;
  width: calc(100% - 48px);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, .050980392156862744);
  min-height: 74px;
}

#addIcon .close {
  position: absolute;
  top: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}
</style>