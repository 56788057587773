<template>
  <div style="height: 100%" :style="dialogVisible ? 'display:block;': 'display:none;'">
    <div id="result-1" style="height: 100%; overflow-y: hidden;overflow-x: hidden;">
      <div style="height: 100%">
        <!-- 电脑端导航栏 -->
        <div
          style="
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            background: var(--font-icon-white, #fff);
          "
          v-if="!isPhone"
        >
          <div style="display: flex; width: 80%; align-content: center">
            <div
              class="el-tooltip mlImg"
              @click="hideLeftWay"
              style="padding: 6px 8px"
              id="hideLeft"
            >
              <img src="../../assets/收缩.png" alt="" width="16" height="16" />
            </div>
            <div
              class="el-tooltip mlImg_1"
              @click="showLeftWay"
              style="padding: 6px 8px; display: none"
              id="showLeft"
            >
              <img src="../../assets/收缩.png" alt="" width="16" height="16" />
            </div>
            <div
              style="
                border-right: 1px solid var(--bg3, #e7e7e7);
                width: 16px;
                height: 20px;
                margin-right: 16px;
                margin-top: 4px;
                margin-bottom: 4px;
              "
            ></div>
            <div
              style="
                cursor: pointer;
                display: flex;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
                margin-right: 16px;
              "
              @click="export_to"
              :title="languageData.export_to"
            >
              <!-- <img src="../../assets/export.png" alt="" width="16" height="16"> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 7.5H4.5V8.5H3V13H13V8.5H11.5V7.5H14V14H2V7.5Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <path
                  d="M10.875 5L8 2L5.125 5H7.5V12H8.5V5H10.875Z"
                  fill="#3681FC"
                />
              </svg>
              <span
                style="
                  margin-left: 4px;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.export_to }}</span
              >
            </div>

            <div
              style="
                cursor: pointer;
                display: flex;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
                margin-right: 16px;
                display: none;
              "
              @click="createCourse"
              :title="languageData.createCourse"
              v-if="!isHomeWork"
            >
              <!-- <img src="../../assets/export.png" alt="" width="16" height="16"> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6.89844 10.4439C6.99688 10.5424 7.12656 10.5955 7.26406 10.5955C7.40156 10.5955 7.53281 10.5424 7.62969 10.4439L11.4062 6.66895C11.6078 6.46738 11.6078 6.13926 11.4062 5.9377C11.3094 5.83926 11.1781 5.78613 11.0406 5.78613C10.9031 5.78613 10.7719 5.84082 10.675 5.9377L7.26406 9.34707L5.74219 7.8252C5.64375 7.72676 5.51406 7.67363 5.37656 7.67363C5.23906 7.67363 5.10781 7.72676 5.01094 7.8252C4.9125 7.92363 4.85938 8.05332 4.85938 8.19082C4.85938 8.32832 4.91406 8.45957 5.01094 8.55645L6.89844 10.4439Z"
                  fill="#3681FC"
                />
                <path
                  d="M12.4174 13.9955H3.42635C2.5971 13.9955 1.92188 13.3174 1.92188 12.4846V3.50801C1.92188 2.6752 2.5971 1.99707 3.42635 1.99707H5.49714V2.93457H3.42635C3.11207 2.93457 2.85536 3.19238 2.85536 3.50801V12.4846C2.85536 12.8002 3.11207 13.058 3.42635 13.058H12.4174C12.7317 13.058 12.9884 12.8002 12.9884 12.4846V3.50801C12.9884 3.19238 12.7317 2.93457 12.4174 2.93457H5.5V1.99707H12.4174C13.2467 1.99707 13.9219 2.6752 13.9219 3.50801V12.4846C13.9219 13.3189 13.2467 13.9955 12.4174 13.9955Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <path
                  d="M5.54063 2.93457H3.39844C3.13906 2.93457 2.92969 2.7252 2.92969 2.46582C2.92969 2.20645 3.13906 1.99707 3.39844 1.99707H5.54063C5.8 1.99707 6.00938 2.20645 6.00938 2.46582C6.00938 2.7252 5.79844 2.93457 5.54063 2.93457Z"
                  fill="black"
                  fill-opacity="0.9"
                />
              </svg>
              <span
                style="
                  margin-left: 4px;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.createCourse }}</span
              >
            </div>

            <div
              style="
                cursor: pointer;
                display: flex;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
                margin-right: 16px;
                display: none;
              "
              @click="createProject"
              :title="languageData.createProject"
              v-if="!isHomeWork"
            >
              <!-- <img src="../../assets/export.png" alt="" width="16" height="16"> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6.89844 10.4439C6.99688 10.5424 7.12656 10.5955 7.26406 10.5955C7.40156 10.5955 7.53281 10.5424 7.62969 10.4439L11.4062 6.66895C11.6078 6.46738 11.6078 6.13926 11.4062 5.9377C11.3094 5.83926 11.1781 5.78613 11.0406 5.78613C10.9031 5.78613 10.7719 5.84082 10.675 5.9377L7.26406 9.34707L5.74219 7.8252C5.64375 7.72676 5.51406 7.67363 5.37656 7.67363C5.23906 7.67363 5.10781 7.72676 5.01094 7.8252C4.9125 7.92363 4.85938 8.05332 4.85938 8.19082C4.85938 8.32832 4.91406 8.45957 5.01094 8.55645L6.89844 10.4439Z"
                  fill="#3681FC"
                />
                <path
                  d="M12.4174 13.9955H3.42635C2.5971 13.9955 1.92188 13.3174 1.92188 12.4846V3.50801C1.92188 2.6752 2.5971 1.99707 3.42635 1.99707H5.49714V2.93457H3.42635C3.11207 2.93457 2.85536 3.19238 2.85536 3.50801V12.4846C2.85536 12.8002 3.11207 13.058 3.42635 13.058H12.4174C12.7317 13.058 12.9884 12.8002 12.9884 12.4846V3.50801C12.9884 3.19238 12.7317 2.93457 12.4174 2.93457H5.5V1.99707H12.4174C13.2467 1.99707 13.9219 2.6752 13.9219 3.50801V12.4846C13.9219 13.3189 13.2467 13.9955 12.4174 13.9955Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <path
                  d="M5.54063 2.93457H3.39844C3.13906 2.93457 2.92969 2.7252 2.92969 2.46582C2.92969 2.20645 3.13906 1.99707 3.39844 1.99707H5.54063C5.8 1.99707 6.00938 2.20645 6.00938 2.46582C6.00938 2.7252 5.79844 2.93457 5.54063 2.93457Z"
                  fill="black"
                  fill-opacity="0.9"
                />
              </svg>
              <span
                style="
                  margin-left: 4px;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.createProject }}</span
              >
            </div>

            <div
              style="
                cursor: pointer;
                display: flex;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
                margin-right: 16px;
              "
              id="createProjection"
              @click="createProjection"
              :title="languageData.projection"
              v-if="!isHomeWork"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.5 2C3.22386 2 3 2.22386 3 2.5V9.5H2.5C2.22386 9.5 2 9.72386 2 10C2 10.2761 2.22386 10.5 2.5 10.5H3.5H7.50001V13H6.50001C6.22387 13 6.00001 13.2239 6.00001 13.5C6.00001 13.7761 6.22387 14 6.50001 14H8.00001H9.50001C9.77615 14 10 13.7761 10 13.5C10 13.2239 9.77615 13 9.50001 13H8.50001V10.5H12.5H13.5C13.7762 10.5 14 10.2761 14 10C14 9.72386 13.7762 9.5 13.5 9.5H13V2.5C13 2.22386 12.7762 2 12.5 2H3.5ZM12 9.5H8.00001H4V3H12V9.5Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <path
                  d="M7.22361 4.50003C7.06861 4.42253 6.88454 4.43082 6.73713 4.52192C6.58973 4.61302 6.5 4.77396 6.5 4.94724V7.94724C6.5 8.12053 6.58973 8.28147 6.73713 8.37257C6.88454 8.46367 7.06861 8.47195 7.22361 8.39446L9.72361 6.89446C9.893 6.80976 10 6.63663 10 6.44724C10 6.25786 9.893 6.08473 9.72361 6.00003L7.22361 4.50003Z"
                  fill="#3681FC"
                />
              </svg>
              <span
                style="
                  margin-left: 4px;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.projection }}</span
              >
            </div>

            <div
              style="
                cursor: pointer;
                display: flex;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
                margin-right: 16px;
              "
              @click="sumbit_job"
              :title="languageData.sumbit_job"
              v-if="isHomeWork"
            >
              <!-- <img src="../../assets/export.png" alt="" width="16" height="16"> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.85355 7.14645C4.65829 6.95118 4.34171 6.95118 4.14645 7.14645L2.14645 9.14645C1.95118 9.34171 1.95118 9.65829 2.14645 9.85355L4.14645 11.8536C4.34171 12.0488 4.65829 12.0488 4.85355 11.8536C5.04882 11.6583 5.04882 11.3417 4.85355 11.1464L3.70711 10H9.5C9.77614 10 10 9.77614 10 9.5C10 9.22386 9.77614 9 9.5 9H3.70711L4.85355 7.85355C5.04882 7.65829 5.04882 7.34171 4.85355 7.14645Z"
                  fill="#3681FC"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5 1C4.72386 1 4.5 1.22386 4.5 1.5V2H2.5C2.22386 2 2 2.22386 2 2.5V7H3V3H4.5V4C4.5 4.27614 4.72386 4.5 5 4.5H11C11.2761 4.5 11.5 4.27614 11.5 4V3H13V14H3V12H2V14.5C2 14.7761 2.22386 15 2.5 15H13.5C13.7761 15 14 14.7761 14 14.5V2.5C14 2.22386 13.7761 2 13.5 2H11.5V1.5C11.5 1.22386 11.2761 1 11 1H5ZM5.5 2H10.5V3.5H5.5V2Z"
                  fill="black"
                  fill-opacity="0.9"
                />
              </svg>
              <span
                style="
                  margin-left: 4px;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.sumbit_job }}</span
              >
            </div>

            <div
              style="
                display: flex;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
                margin-right: 16px;
              "
              v-if="stageDatas.length != 0"
            >
              <!-- <img style="-webkit-transform: rotate(180deg); transform: rotate(180deg);cursor: pointer;" src="../../assets/qiehuan_normal.png" @click="pre_stage" alt="" width="16" height="16" v-if="stageDatas.length != 0 && stageIndex != 1" :title="languageData.pre_stage">
                  <img src="../../assets/qiehuan_disabled.png" alt="" width="16" height="16" v-else> -->
              <div
                v-if="stageDatas.length != 0 && stageIndex != 1"
                @click="pre_stage"
                style="cursor: pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.64645 8.35352C4.45118 8.15825 4.45118 7.84167 4.64645 7.64641L10.6464 1.64641L11.3536 2.35352L5.70711 7.99996L11.3536 13.6464L10.6464 14.3535L4.64645 8.35352Z"
                    fill="black"
                    fill-opacity="0.9"
                  />
                </svg>
              </div>
              <div v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.64645 8.35352C4.45118 8.15825 4.45118 7.84167 4.64645 7.64641L10.6464 1.64641L11.3536 2.35352L5.70711 7.99996L11.3536 13.6464L10.6464 14.3535L4.64645 8.35352Z"
                    fill="black"
                    fill-opacity="0.4"
                  />
                </svg>
              </div>
              <span
                style="
                  cursor: default;
                  margin-left: 4px;
                  margin-right: 4px;
                  display: flex;
                  padding: 0px 16px;
                  align-items: center;
                  border-radius: 3px;
                  border: 1px solid var(--brand-light, #e0eafb);
                  color: var(--brand-normal, #3681fc);
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.stageName }}{{ stageIndex }}</span
              >
              <!-- <img src="../../assets/qiehuan_normal.png" style="cursor: pointer;" @click="next_stage" alt="" width="16" height="16" v-if="stageDatas.length != 0 && stageIndex != stageDatas.length" :title="languageData.next_page">
                  <img style="-webkit-transform: rotate(180deg); transform: rotate(180deg);" src="../../assets/qiehuan_disabled.png" alt="" width="16" height="16" v-else> -->
              <div
                v-if="stageDatas.length != 0 && stageIndex != stageDatas.length"
                @click="next_stage"
                style="cursor: pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.2071 8.35352C11.4023 8.15825 11.4023 7.84167 11.2071 7.64641L5.20707 1.64641L4.49996 2.35352L10.1464 7.99996L4.49996 13.6464L5.20707 14.3535L11.2071 8.35352Z"
                    fill="black"
                    fill-opacity="0.9"
                  />
                </svg>
              </div>
              <div v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.2071 8.35352C11.4023 8.15825 11.4023 7.84167 11.2071 7.64641L5.20707 1.64641L4.49996 2.35352L10.1464 7.99996L4.49996 13.6464L5.20707 14.3535L11.2071 8.35352Z"
                    fill="black"
                    fill-opacity="0.4"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div
            style="
              display: flex;
              width: 20%;
              align-content: center;
              justify-content: flex-end;
            "
          >
            <div
              style="
                cursor: pointer;
                display: flex;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
              "
              @click="qiehuanImmersive"
              :title="languageData.immersive"
              v-if="!isImmersive"
            >
              <!-- <img src="../../assets/切换.png" alt="" width="16" height="16" /> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M2.5 2.5H13.5V13.5H2.5V2.5Z"
                  fill="#E0EAFB"
                  stroke="black"
                  stroke-linejoin="round"
                />
              </svg>
              <span
                style="
                  margin-left: 4px;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
                >{{ languageData.immersive }}</span
              >
            </div>

            <div
              style="
                cursor: pointer;
                display: flex;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
              "
              @click="qiehuanCard"
              :title="languageData.cards"
              v-else
            >
              <!-- <img src="../../assets/切换.png" alt="" width="16" height="16" /> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 2.5C2 2.22386 2.22386 2 2.5 2H13.5C13.7761 2 14 2.22386 14 2.5V6.5C14 6.77614 13.7761 7 13.5 7H2.5C2.22386 7 2 6.77614 2 6.5V2.5ZM3 3V6H13V3H3Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 8.5C2 8.22386 2.22386 8 2.5 8H6.5C6.77614 8 7 8.22386 7 8.5V13.5C7 13.7761 6.77614 14 6.5 14H2.5C2.22386 14 2 13.7761 2 13.5V8.5ZM3 9V13H6V9H3Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 8.5C8 8.22386 8.22386 8 8.5 8H13.5C13.7761 8 14 8.22386 14 8.5V13.5C14 13.7761 13.7761 14 13.5 14H8.5C8.22386 14 8 13.7761 8 13.5V8.5ZM9 9V13H13V9H9Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <rect x="3" y="3" width="10" height="3" fill="#E0EAFB" />
                <rect x="3" y="9" width="3" height="4" fill="#E0EAFB" />
                <rect x="9" y="9" width="4" height="4" fill="#E0EAFB" />
              </svg>
              <span
                style="
                  margin-left: 4px;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
                >{{ languageData.cards }}</span
              >
            </div>
          </div>
        </div>
        <!-- 移动端导航栏 -->
        <div
          style="
            display: flex;
            flex-direction: column;
            padding: 8px 0;
            justify-content: center;
            align-items: center;
            background: var(--font-icon-white, #fff);
            position: absolute;
            bottom:0;
            width:calc(100%);
            border-top: 1px solid var(--bg3, #E7E7E7);
          "
          v-else
        >
          <div style="display: flex;flex-direction: row;width: 100%; align-content: center;justify-content: space-evenly;">
            <!-- <div
              class="el-tooltip mlImg"
              @click="hideLeftWay"
              style="padding: 6px 8px"
              id="hideLeft"
            >
              <img src="../../assets/收缩.png" alt="" width="16" height="16" />
            </div>
            <div
              class="el-tooltip mlImg_1"
              @click="showLeftWay"
              style="padding: 6px 8px; display: none"
              id="showLeft"
            >
              <img src="../../assets/收缩.png" alt="" width="16" height="16" />
            </div>
            <div
              style="
                border-right: 1px solid var(--bg3, #e7e7e7);
                width: 10px;
                height: 20px;
                margin-right: 10px;
                margin-top: 4px;
                margin-bottom: 4px;
              "
            ></div> -->

            <div
              style="
                cursor: pointer;
                display: flex;
                flex-direction:column;
                justify-content: center;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
              "
              id="createProjection"
              @click="createProjection"
              :title="languageData.projection"
              v-if="!isHomeWork"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.5 2C3.22386 2 3 2.22386 3 2.5V9.5H2.5C2.22386 9.5 2 9.72386 2 10C2 10.2761 2.22386 10.5 2.5 10.5H3.5H7.50001V13H6.50001C6.22387 13 6.00001 13.2239 6.00001 13.5C6.00001 13.7761 6.22387 14 6.50001 14H8.00001H9.50001C9.77615 14 10 13.7761 10 13.5C10 13.2239 9.77615 13 9.50001 13H8.50001V10.5H12.5H13.5C13.7762 10.5 14 10.2761 14 10C14 9.72386 13.7762 9.5 13.5 9.5H13V2.5C13 2.22386 12.7762 2 12.5 2H3.5ZM12 9.5H8.00001H4V3H12V9.5Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <path
                  d="M7.22361 4.50003C7.06861 4.42253 6.88454 4.43082 6.73713 4.52192C6.58973 4.61302 6.5 4.77396 6.5 4.94724V7.94724C6.5 8.12053 6.58973 8.28147 6.73713 8.37257C6.88454 8.46367 7.06861 8.47195 7.22361 8.39446L9.72361 6.89446C9.893 6.80976 10 6.63663 10 6.44724C10 6.25786 9.893 6.08473 9.72361 6.00003L7.22361 4.50003Z"
                  fill="#3681FC"
                />
              </svg>
              <span
                style="
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.projection }}</span
              >
            </div>

            <div
              style="
                cursor: pointer;
                display: flex;
                flex-direction:column;
                justify-content: center;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
              "
              @click="export_to"
              :title="languageData.export_to"
            >
              <!-- <img src="../../assets/export.png" alt="" width="16" height="16"> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 7.5H4.5V8.5H3V13H13V8.5H11.5V7.5H14V14H2V7.5Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <path
                  d="M10.875 5L8 2L5.125 5H7.5V12H8.5V5H10.875Z"
                  fill="#3681FC"
                />
              </svg>
              <span
                style="
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.export_to }}</span
              >
            </div>

            <div
              style="
                cursor: pointer;
                display: flex;
                flex-direction:column;
                justify-content: center;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
              "
              @click="show_mulu"
              :title="languageData.directory"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 11C13 9.89543 13.8547 9 14.9091 9H41.0909C42.1453 9 43 9.89543 43 11C43 12.1046 42.1453 13 41.0909 13H14.9091C13.8547 13 13 12.1046 13 11Z" fill="black" fill-opacity="0.9"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 24C13 22.8954 13.8547 22 14.9091 22H41.0909C42.1453 22 43 22.8954 43 24C43 25.1046 42.1453 26 41.0909 26H14.9091C13.8547 26 13 25.1046 13 24Z" fill="#3681FC"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 37C13 35.8954 13.8547 35 14.9091 35H41.0909C42.1453 35 43 35.8954 43 37C43 38.1046 42.1453 39 41.0909 39H14.9091C13.8547 39 13 38.1046 13 37Z" fill="black" fill-opacity="0.9"/>
                <circle cx="7" cy="11" r="2" fill="black" fill-opacity="0.9"/>
                <circle cx="7" cy="24" r="2" fill="#3681FC"/>
                <circle cx="7" cy="37" r="2" fill="black" fill-opacity="0.9"/>
              </svg>
              <span
                style="
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.directory }}</span
              >
            </div>

            <div
              style="
                cursor: pointer;
                display: flex;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
                margin-right: 16px;
                display: none;
              "
              @click="createCourse"
              :title="languageData.createCourse"
              v-if="!isHomeWork"
            >
              <!-- <img src="../../assets/export.png" alt="" width="16" height="16"> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6.89844 10.4439C6.99688 10.5424 7.12656 10.5955 7.26406 10.5955C7.40156 10.5955 7.53281 10.5424 7.62969 10.4439L11.4062 6.66895C11.6078 6.46738 11.6078 6.13926 11.4062 5.9377C11.3094 5.83926 11.1781 5.78613 11.0406 5.78613C10.9031 5.78613 10.7719 5.84082 10.675 5.9377L7.26406 9.34707L5.74219 7.8252C5.64375 7.72676 5.51406 7.67363 5.37656 7.67363C5.23906 7.67363 5.10781 7.72676 5.01094 7.8252C4.9125 7.92363 4.85938 8.05332 4.85938 8.19082C4.85938 8.32832 4.91406 8.45957 5.01094 8.55645L6.89844 10.4439Z"
                  fill="#3681FC"
                />
                <path
                  d="M12.4174 13.9955H3.42635C2.5971 13.9955 1.92188 13.3174 1.92188 12.4846V3.50801C1.92188 2.6752 2.5971 1.99707 3.42635 1.99707H5.49714V2.93457H3.42635C3.11207 2.93457 2.85536 3.19238 2.85536 3.50801V12.4846C2.85536 12.8002 3.11207 13.058 3.42635 13.058H12.4174C12.7317 13.058 12.9884 12.8002 12.9884 12.4846V3.50801C12.9884 3.19238 12.7317 2.93457 12.4174 2.93457H5.5V1.99707H12.4174C13.2467 1.99707 13.9219 2.6752 13.9219 3.50801V12.4846C13.9219 13.3189 13.2467 13.9955 12.4174 13.9955Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <path
                  d="M5.54063 2.93457H3.39844C3.13906 2.93457 2.92969 2.7252 2.92969 2.46582C2.92969 2.20645 3.13906 1.99707 3.39844 1.99707H5.54063C5.8 1.99707 6.00938 2.20645 6.00938 2.46582C6.00938 2.7252 5.79844 2.93457 5.54063 2.93457Z"
                  fill="black"
                  fill-opacity="0.9"
                />
              </svg>
              <span
                style="
                  margin-left: 4px;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.createCourse }}</span
              >
            </div>

            <div
              style="
                cursor: pointer;
                display: flex;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
                margin-right: 16px;
                display: none;
              "
              @click="createProject"
              :title="languageData.createProject"
              v-if="!isHomeWork"
            >
              <!-- <img src="../../assets/export.png" alt="" width="16" height="16"> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6.89844 10.4439C6.99688 10.5424 7.12656 10.5955 7.26406 10.5955C7.40156 10.5955 7.53281 10.5424 7.62969 10.4439L11.4062 6.66895C11.6078 6.46738 11.6078 6.13926 11.4062 5.9377C11.3094 5.83926 11.1781 5.78613 11.0406 5.78613C10.9031 5.78613 10.7719 5.84082 10.675 5.9377L7.26406 9.34707L5.74219 7.8252C5.64375 7.72676 5.51406 7.67363 5.37656 7.67363C5.23906 7.67363 5.10781 7.72676 5.01094 7.8252C4.9125 7.92363 4.85938 8.05332 4.85938 8.19082C4.85938 8.32832 4.91406 8.45957 5.01094 8.55645L6.89844 10.4439Z"
                  fill="#3681FC"
                />
                <path
                  d="M12.4174 13.9955H3.42635C2.5971 13.9955 1.92188 13.3174 1.92188 12.4846V3.50801C1.92188 2.6752 2.5971 1.99707 3.42635 1.99707H5.49714V2.93457H3.42635C3.11207 2.93457 2.85536 3.19238 2.85536 3.50801V12.4846C2.85536 12.8002 3.11207 13.058 3.42635 13.058H12.4174C12.7317 13.058 12.9884 12.8002 12.9884 12.4846V3.50801C12.9884 3.19238 12.7317 2.93457 12.4174 2.93457H5.5V1.99707H12.4174C13.2467 1.99707 13.9219 2.6752 13.9219 3.50801V12.4846C13.9219 13.3189 13.2467 13.9955 12.4174 13.9955Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <path
                  d="M5.54063 2.93457H3.39844C3.13906 2.93457 2.92969 2.7252 2.92969 2.46582C2.92969 2.20645 3.13906 1.99707 3.39844 1.99707H5.54063C5.8 1.99707 6.00938 2.20645 6.00938 2.46582C6.00938 2.7252 5.79844 2.93457 5.54063 2.93457Z"
                  fill="black"
                  fill-opacity="0.9"
                />
              </svg>
              <span
                style="
                  margin-left: 4px;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.createProject }}</span
              >
            </div>

            <div
              style="
                cursor: pointer;
                display: flex;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
                margin-right: 16px;
              "
              @click="sumbit_job"
              :title="languageData.sumbit_job"
              v-if="isHomeWork"
            >
              <!-- <img src="../../assets/export.png" alt="" width="16" height="16"> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.85355 7.14645C4.65829 6.95118 4.34171 6.95118 4.14645 7.14645L2.14645 9.14645C1.95118 9.34171 1.95118 9.65829 2.14645 9.85355L4.14645 11.8536C4.34171 12.0488 4.65829 12.0488 4.85355 11.8536C5.04882 11.6583 5.04882 11.3417 4.85355 11.1464L3.70711 10H9.5C9.77614 10 10 9.77614 10 9.5C10 9.22386 9.77614 9 9.5 9H3.70711L4.85355 7.85355C5.04882 7.65829 5.04882 7.34171 4.85355 7.14645Z"
                  fill="#3681FC"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5 1C4.72386 1 4.5 1.22386 4.5 1.5V2H2.5C2.22386 2 2 2.22386 2 2.5V7H3V3H4.5V4C4.5 4.27614 4.72386 4.5 5 4.5H11C11.2761 4.5 11.5 4.27614 11.5 4V3H13V14H3V12H2V14.5C2 14.7761 2.22386 15 2.5 15H13.5C13.7761 15 14 14.7761 14 14.5V2.5C14 2.22386 13.7761 2 13.5 2H11.5V1.5C11.5 1.22386 11.2761 1 11 1H5ZM5.5 2H10.5V3.5H5.5V2Z"
                  fill="black"
                  fill-opacity="0.9"
                />
              </svg>
              <span
                style="
                  margin-left: 4px;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.sumbit_job }}</span
              >
            </div>

            <div
              style="
                cursor: pointer;
                display: flex;
                flex-direction:column;
                justify-content: center;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
                display:none;
              "
              @click="qiehuanImmersive"
              :title="languageData.immersive"
              v-if="!isImmersive"
            >
              <!-- <img src="../../assets/切换.png" alt="" width="16" height="16" /> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M2.5 2.5H13.5V13.5H2.5V2.5Z"
                  fill="#E0EAFB"
                  stroke="black"
                  stroke-linejoin="round"
                />
              </svg>
              <span
                style="
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
                >{{ languageData.immersive }}</span
              >
            </div>

            <div
              style="
                cursor: pointer;
                display: flex;
                flex-direction:column;
                justify-content: center;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
                display:none;
              "
              @click="qiehuanCard"
              :title="languageData.cards"
              v-else
            >
              <!-- <img src="../../assets/切换.png" alt="" width="16" height="16" /> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 2.5C2 2.22386 2.22386 2 2.5 2H13.5C13.7761 2 14 2.22386 14 2.5V6.5C14 6.77614 13.7761 7 13.5 7H2.5C2.22386 7 2 6.77614 2 6.5V2.5ZM3 3V6H13V3H3Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 8.5C2 8.22386 2.22386 8 2.5 8H6.5C6.77614 8 7 8.22386 7 8.5V13.5C7 13.7761 6.77614 14 6.5 14H2.5C2.22386 14 2 13.7761 2 13.5V8.5ZM3 9V13H6V9H3Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 8.5C8 8.22386 8.22386 8 8.5 8H13.5C13.7761 8 14 8.22386 14 8.5V13.5C14 13.7761 13.7761 14 13.5 14H8.5C8.22386 14 8 13.7761 8 13.5V8.5ZM9 9V13H13V9H9Z"
                  fill="black"
                  fill-opacity="0.9"
                />
                <rect x="3" y="3" width="10" height="3" fill="#E0EAFB" />
                <rect x="3" y="9" width="3" height="4" fill="#E0EAFB" />
                <rect x="9" y="9" width="4" height="4" fill="#E0EAFB" />
              </svg>
              <span
                style="
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
                >{{ languageData.cards }}</span
              >
            </div>

            <div
              style="
                display: flex;
                padding: 4px 8px;
                align-items: center;
                border-radius: 3px;
                background: var(--font-icon-white, #fff);
                display:none;
              "
              v-if="stageDatas.length != 0"
            >
              <!-- <img style="-webkit-transform: rotate(180deg); transform: rotate(180deg);cursor: pointer;" src="../../assets/qiehuan_normal.png" @click="pre_stage" alt="" width="16" height="16" v-if="stageDatas.length != 0 && stageIndex != 1" :title="languageData.pre_stage">
                  <img src="../../assets/qiehuan_disabled.png" alt="" width="16" height="16" v-else> -->
              <div
                v-if="stageDatas.length != 0 && stageIndex != 1"
                @click="pre_stage"
                style="cursor: pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.64645 8.35352C4.45118 8.15825 4.45118 7.84167 4.64645 7.64641L10.6464 1.64641L11.3536 2.35352L5.70711 7.99996L11.3536 13.6464L10.6464 14.3535L4.64645 8.35352Z"
                    fill="black"
                    fill-opacity="0.9"
                  />
                </svg>
              </div>
              <div v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.64645 8.35352C4.45118 8.15825 4.45118 7.84167 4.64645 7.64641L10.6464 1.64641L11.3536 2.35352L5.70711 7.99996L11.3536 13.6464L10.6464 14.3535L4.64645 8.35352Z"
                    fill="black"
                    fill-opacity="0.4"
                  />
                </svg>
              </div>
              <span
                style="
                  cursor: default;
                  margin-left: 2px;
                  margin-right: 2px;
                  display: flex;
                  padding: 0px 10px;
                  align-items: center;
                  border-radius: 3px;
                  border: 1px solid var(--brand-light, #e0eafb);
                  color: var(--brand-normal, #3681fc);
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                  word-break: keep-all;
                "
                >{{ languageData.stageName }}{{ stageIndex }}</span
              >
              <!-- <img src="../../assets/qiehuan_normal.png" style="cursor: pointer;" @click="next_stage" alt="" width="16" height="16" v-if="stageDatas.length != 0 && stageIndex != stageDatas.length" :title="languageData.next_page">
                  <img style="-webkit-transform: rotate(180deg); transform: rotate(180deg);" src="../../assets/qiehuan_disabled.png" alt="" width="16" height="16" v-else> -->
              <div
                v-if="stageDatas.length != 0 && stageIndex != stageDatas.length"
                @click="next_stage"
                style="cursor: pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.2071 8.35352C11.4023 8.15825 11.4023 7.84167 11.2071 7.64641L5.20707 1.64641L4.49996 2.35352L10.1464 7.99996L4.49996 13.6464L5.20707 14.3535L11.2071 8.35352Z"
                    fill="black"
                    fill-opacity="0.9"
                  />
                </svg>
              </div>
              <div v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.2071 8.35352C11.4023 8.15825 11.4023 7.84167 11.2071 7.64641L5.20707 1.64641L4.49996 2.35352L10.1464 7.99996L4.49996 13.6464L5.20707 14.3535L11.2071 8.35352Z"
                    fill="black"
                    fill-opacity="0.4"
                  />
                </svg>
              </div>
            </div>
          </div>

          <!-- <div
            style="
              display: flex;
              width: 16%;
              align-content: center;
              justify-content: flex-end;
            "
          >
            
          </div> -->
        </div>
        <div v-if="isPhone && isShowMulu" class="v-modal" tabindex="0" style="z-index: 2010;"></div>
        <!-- 沉浸式 -->
        <div
          :style="!isPhone ? 'height: calc(100% - 45px); display: flex; flex-direction: row' : 'height: calc(100% - 55px); display: flex; flex-direction: row'"
          v-if="isImmersive && !isPhone"
        >
          <div class="left-side" id="leftSide" :style="fullscreen ? '' : ''">
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 1px solid rgb(202, 209, 220);
              "
            >
              <div
                style="
                  width: 140px;
                  margin-left: 10px;
                  margin-bottom: 5px;
                  color: black;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-break: break-word;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                "
                v-if="themeName != '' && !isHomeWork"
              >
                {{ themeName }}
              </div>
              <!-- <span class="mulu">{{ languageData.directory }}({{ navigationList.length }})</span> -->
            </div>
            <div
              :style="
                stageDatas.length != 0
                  ? 'overflow-y: auto;overflow-x: hidden;width: 250px;display: flex;flex-direction: column;align-items: center;height: calc(100% - 35px);'
                  : 'overflow-y: auto;overflow-x: hidden;width: 250px;display: flex;flex-direction: column;align-items: center;margin-top: 15px;height: calc(100% - 50px);'
              "
              class="menuList"
            >
              <template v-for="(item, i) in navigationList">
                <div
                  style="width: 100%; cursor: pointer"
                  v-if="item.type == 'icon-stage'"
                  v-bind:key="'menu_' + i"
                >
                  <div
                    style="
                      width: calc(100% - 16px);
                      display: flex;
                      flex-direction: row;
                      padding: 12px 8px;
                      align-items: center;
                      color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                      font-family: SimSun;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px;
                    "
                  >
                    <div
                      class="closeTri"
                      @click="hideStage(i)"
                      v-if="item.isFold && item.child.length != 0"
                    >
                      <img
                        src="../../assets/chevron-up_nor.png"
                        alt=""
                        width="16"
                        height="16"
                        :style="item.isFold ? '' : 'transform: rotate(-90deg);'"
                      />
                    </div>
                    <div
                      class="closeTri"
                      @click="showStage(i)"
                      v-else-if="!item.isFold && item.child.length != 0"
                    >
                      <img
                        src="../../assets/chevron-up_nor.png"
                        alt=""
                        width="16"
                        height="16"
                        :style="item.isFold ? '' : 'transform: rotate(-90deg);'"
                      />
                    </div>
                    <div
                      class="closeTri"
                      v-else-if="
                        item.child.length == 0 || stageDatas.length != 0
                      "
                    >
                      <img
                        src="../../assets/chevron-up_pre.png"
                        alt=""
                        width="16"
                        height="16"
                        style="visibility: hidden"
                      />
                    </div>
                    <div
                      @click="navToStage(i)"
                      style="
                        width: calc(100% - 21px);
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        color: var(
                          --font-icon-gy-1-primary,
                          rgba(0, 0, 0, 0.9)
                        );
                        font-family: SimSun;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                      "
                    >
                      <div style="width: auto">
                        {{ languageData.stageName }}{{ i + 1 }}:
                      </div>
                      <div
                        style="
                          margin-left: 10px;
                          width: auto;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          word-break: break-word;
                        "
                      >
                        {{ item.properties.stageName }}
                      </div>
                    </div>
                  </div>
                </div>
                <template v-for="(itemNew, j) in item.child">
                  <a
                    style="text-decoration: none"
                    @click.stop="navTo(itemNew.id)"
                    v-if="stageIndex - 1 == i && item.isFold"
                    v-bind:key="'menu_' + i + '_' + j"
                  >
                    <div
                      :class="
                        selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti'
                      "
                      :data-id="itemNew.id"
                      v-if="itemNew.type == 'icon-task'"
                    >
                      <div
                        class="closeTri"
                        v-if="
                          selectMulu == itemNew.id &&
                          itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="hideTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu != itemNew.id &&
                          itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="hideTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_nor.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu == itemNew.id &&
                          !itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="showTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu != itemNew.id &&
                          !itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="showTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_nor.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          itemNew.child.length == 0 || stageDatas.length != 0
                        "
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          style="visibility: hidden"
                        />
                      </div>
                      <span
                        :data-id="itemNew.id"
                        :class="
                          selectMulu == itemNew.id ? 'text text-select' : 'text'
                        "
                        style="cursor: pointer"
                        >{{ languageData.taskTip }}
                        {{
                          itemNew.properties.taskTitle
                            ? itemNew.properties.taskTitle
                            : ""
                        }}</span
                      >
                    </div>
                  </a>
                  <a
                    style="text-decoration: none"
                    @click.stop="navToTask(i, j)"
                    v-bind:key="'menu_' + i + '_' + j"
                    v-else-if="stageIndex - 1 != i && item.isFold"
                  >
                    <div
                      :class="
                        selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti'
                      "
                      :data-id="itemNew.id"
                      v-if="itemNew.type == 'icon-task'"
                    >
                      <div
                        class="closeTri"
                        v-if="
                          selectMulu == itemNew.id &&
                          itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="hideTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu != itemNew.id &&
                          itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="hideTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_nor.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu == itemNew.id &&
                          !itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="showTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu != itemNew.id &&
                          !itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="showTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_nor.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          itemNew.child.length == 0 || stageDatas.length != 0
                        "
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          style="visibility: hidden"
                        />
                      </div>
                      <span
                        :data-id="itemNew.id"
                        :class="
                          selectMulu == itemNew.id ? 'text text-select' : 'text'
                        "
                        style="cursor: pointer"
                        >{{ languageData.taskTip }}
                        {{
                          itemNew.properties.taskTitle
                            ? itemNew.properties.taskTitle
                            : ""
                        }}</span
                      >
                    </div>
                  </a>
                  <template v-for="(Item, index) in itemNew.child">
                    <a
                      style="text-decoration: none"
                      @click="navTo(Item.id)"
                      v-if="
                        stageIndex - 1 == i &&
                        itemNew.isFold &&
                        item.isFold &&
                        stageDatas.length == 0
                      "
                      v-bind:key="'menu_' + i + '_' + j + '_' + index"
                    >
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        :data-id="Item.id"
                        v-if="Item.type == 'icon-card'"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.title ? Item.properties.title : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-select'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.selectTitle
                              ? Item.properties.selectTitle
                              : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-thinking'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.thinkingTitle
                              ? Item.properties.thinkingTitle
                              : languageData.thinkingCard
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-AI'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-new-AI'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-whiteboard'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.whiteboardTitle
                              ? Item.properties.whiteboardTitle
                              : languageData.whiteboard_card
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-map'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{ languageData.map_card }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-camera'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{ languageData.camera_card }}</span
                        >
                      </div>
                    </a>

                    <a
                      style="text-decoration: none"
                      @click="navToOther(i, j, index)"
                      v-bind:key="'menu_' + i + '_' + j + '_' + index"
                      v-else-if="
                        stageIndex - 1 != i &&
                        itemNew.isFold &&
                        item.isFold &&
                        stageDatas.length == 0
                      "
                    >
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        :data-id="Item.id"
                        v-if="Item.type == 'icon-card'"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.title ? Item.properties.title : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-select'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.selectTitle
                              ? Item.properties.selectTitle
                              : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-thinking'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.thinkingTitle
                              ? Item.properties.thinkingTitle
                              : languageData.thinkingCard
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-AI'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-new-AI'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-whiteboard'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.whiteboardTitle
                              ? Item.properties.whiteboardTitle
                              : languageData.whiteboard_card
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-map'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{ languageData.map_card }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-camera'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{ languageData.camera_card }}</span
                        >
                      </div>
                    </a>
                  </template>
                </template>
              </template>
            </div>
          </div>

          <div
            class="right-side"
            id="scroll-box"
            :style="fullscreen ? 'height:100%;' : ''"
          >
            <div
              v-if="stageDatas.length != 0"
              style="
                margin-top: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 68px;
                border-bottom: 1px solid #e7e7e7;
              "
            >
              <div class="stageTitle">
                <div class="stageItemTitle">
                  <div>{{ languageData.stageName }}{{ stageIndex }}:</div>
                  <div
                    class="el-tooltip"
                    aria-describedby="el-tooltip-4036"
                    tabindex="0"
                  >
                    {{ stageDatas[stageIndex - 1].properties.stageName }}
                  </div>
                </div>
                <!-- <div style="width: 40%;text-align: right;">
                      <el-button type="text" @click="pre_stage" size="medium" style="color:#0061ff;"
                        v-if="stageDatas.length != 0 && stageIndex != 1" :title="languageData.pre_stage">{{ languageData.pre_stage }}</el-button>
                      <el-button type="text" @click="next_stage" size="medium" style="color:#0061ff;"
                        v-if="stageDatas.length != 0 && stageIndex != stageDatas.length" :title="languageData.next_page">{{ languageData.next_page }}</el-button>
                    </div> -->
              </div>
            </div>
            <div
              v-if="dataList.length != 0"
              id="scroll-box2"
              :style="
                stageDatas.length != 0
                  ? 'margin-bottom: 100px;overflow-y: auto;height: calc(100% - 85px);'
                  : 'margin-bottom: 100px;overflow-y: auto;height: calc(100%);'
              "
            >
              <template v-for="(item, i) in dataList">
                <template v-for="(Item, index) in item.nodes">
                  <div
                    :id="'id_' + Item.id"
                    style="height: 0"
                    v-bind:key="'title' + i + index"
                  ></div>
                  <div v-bind:key="'Title' + i + index">
                    <div
                      class="right_top"
                      :id="'right_top_' + i + '_' + index"
                      v-if="Item.type == 'icon-task'"
                      style="position: relative"
                    >
                      <div
                        style="
                          line-height: 1;
                          width: 100%;
                          margin-bottom: 8px;
                          margin-top: 8px;
                        "
                      >
                        <span
                          class="right_top_title"
                          style="line-height: 27px; font-size: 17px"
                          >{{ languageData.taskTip }}
                          {{
                            Item.properties.taskTitle
                              ? Item.properties.taskTitle
                              : ""
                          }}</span
                        >
                        <!-- <span v-if="Item.children.length > 1 && Item.type != 'icon-task'"
                              style="float: right; margin-top: -10px">
                              <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                :title="languageData.tip19" @click="selectPath(Item.children)">
                                <img src="../../assets/next.png" style="
                                    cursor: pointer;
                                    width: 12px;
                                    height: 12px;
                                  " />
                              </el-button>
                            </span> -->
                      </div>
                      <div
                        v-if="Item.properties.taskContent"
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: column;justify-content: center;height: 90%;'
                            : 'display:flex;flex-direction: column;justify-content: center;height: 95%;'
                        "
                      >
                        <div :id="'card_' + i + '_' + index">
                          <span
                            v-html="Item.properties.taskContent"
                            style="line-height: 1.5"
                          ></span>
                        </div>
                      </div>
                      <div
                        class="handleIframe"
                        draggable="false"
                        @mousedown="handleMousedown"
                      ></div>
                    </div>
                    <div
                      class="right_top"
                      :id="'right_top_' + i + '_' + index"
                      v-if="Item.type == 'icon-card'"
                      style="position: relative"
                    >
                      <div
                        style="line-height: 1; width: 100%; margin-bottom: 8px"
                      >
                        <span class="right_top_title">{{
                          Item.properties.title ? Item.properties.title : ""
                        }}</span>
                        <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                              <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                :title="languageData.tip19" @click="selectPath(Item.children)">
                                <img src="../../assets/next.png" style="
                                    cursor: pointer;
                                    width: 12px;
                                    height: 12px;
                                  " />
                              </el-button>
                            </span> -->
                      </div>
                      <div
                        v-if="Item.properties.describe"
                        style="margin-top: 23px"
                      >
                        <span class="text" style="font-size: 14px">{{
                          Item.properties.describe
                        }}</span>
                      </div>
                      <div
                        v-if="Item.properties.radio == 'content'"
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: column;justify-content: center;height: 90%;'
                            : 'display:flex;flex-direction: column;justify-content: center;height: 95%;'
                        "
                      >
                        <div :id="'card_' + i + '_' + index">
                          <span
                            v-html="Item.properties.content"
                            style="line-height: 1.5"
                          ></span>
                        </div>
                      </div>
                      <div
                        v-else-if="Item.properties.radio == 'iframeUrl'"
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <iframe
                          style="width: 100%; height: 100%; min-height: 300px"
                          :src="
                            Item.properties.iframeUrl.indexOf('https://') !=
                              -1 ||
                            Item.properties.iframeUrl.indexOf('http://') != -1
                              ? Item.properties.iframeUrl
                              : '//' + Item.properties.iframeUrl
                          "
                          frameborder="no"
                          border="0"
                          sandbox="allow-forms allow-scripts allow-same-origin"
                        ></iframe>
                      </div>
                      <div
                        v-else-if="Item.properties.radio == 'embedCode'"
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: row;justify-content: center;height: 85%;'
                            : 'display:flex;flex-direction: row;justify-content: center;height: 95%;'
                        "
                        v-html="Item.properties.embedCode"
                      ></div>
                      <div
                        v-else-if="Item.properties.radio == 'imageList'"
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <img
                          style="
                            width: auto;
                            height: auto;
                            max-width: 95%;
                            max-height: 95%;
                          "
                          :src="
                            Item.properties.imageList &&
                            Item.properties.imageList.length > 0
                              ? Item.properties.imageList[0].url
                              : ''
                          "
                        />
                      </div>
                      <div
                        v-else-if="Item.properties.radio == 'videoList'"
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <video
                          style="
                            width: auto;
                            height: auto;
                            max-width: 95%;
                            max-height: 95%;
                          "
                          controls
                          :src="
                            Item.properties.videoList &&
                            Item.properties.videoList.length > 0
                              ? Item.properties.videoList[0].url
                              : ''
                          "
                        ></video>
                      </div>
                      <div
                        v-else-if="Item.properties.radio == 'audioList'"
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <audio
                          controls
                          :src="
                            Item.properties.audioList &&
                            Item.properties.audioList.length > 0
                              ? Item.properties.audioList[0].url
                              : ''
                          "
                        ></audio>
                      </div>
                      <div
                        v-else-if="
                          Item.properties.radio == 'fileList' &&
                          Item.properties.fileList &&
                          Item.properties.fileList.length != 0 &&
                          (Item.properties.fileList[0].url.indexOf('.pdf') !=
                            -1 ||
                            Item.properties.fileList[0].url.indexOf('.PDF') !=
                              -1)
                        "
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <iframe
                          style="width: 100%; height: 100%; min-height: 800px"
                          frameborder="0"
                          :src="
                            'https://cloud.cocorobo.cn/pdf.js/web/viewer.html?file=' +
                            Item.properties.fileList[0].url
                          "
                        ></iframe>
                      </div>
                      <div
                        v-else-if="
                          Item.properties.radio == 'fileList' &&
                          Item.properties.fileList &&
                          Item.properties.fileList.length != 0 &&
                          Item.properties.fileList[0].url.indexOf('.pdf') ==
                            -1 &&
                          Item.properties.fileList[0].url.indexOf('.PDF') == -1
                        "
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <iframe
                          style="width: 100%; height: 100%; min-height: 800px"
                          frameborder="0"
                          :src="
                            'https://view.officeapps.live.com/op/view.aspx?src=' +
                            Item.properties.fileList[0].url
                          "
                        ></iframe>
                      </div>
                      <div
                        class="handleIframe"
                        draggable="false"
                        @mousedown="handleMousedown"
                      ></div>
                    </div>
                    <div
                      class="right_top"
                      :id="'right_top_' + i + '_' + index"
                      v-if="Item.type == 'icon-thinking'"
                      style="position: relative"
                    >
                      <div
                        style="line-height: 1; width: 100%; margin-bottom: 8px"
                      >
                        <span class="right_top_title">{{
                          Item.properties.thinkingTitle
                            ? Item.properties.thinkingTitle
                            : languageData.thinkingCard
                        }}</span>
                        <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                              <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                :title="languageData.tip19" @click="selectPath(Item.children)">
                                <img src="../../assets/next.png" style="
                                    cursor: pointer;
                                    width: 12px;
                                    height: 12px;
                                  " />
                              </el-button>
                            </span> -->
                      </div>
                      <div
                        v-if="Item.properties.describe"
                        style="margin-top: 10px"
                      >
                        <span class="text" style="font-size: 14px">{{
                          Item.properties.describe
                        }}</span>
                      </div>
                      <div
                        v-if="Item.properties.thinkingContent"
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <img
                          style="
                            width: auto;
                            height: auto;
                            max-width: 95%;
                            max-height: 95%;
                          "
                          :src="Item.properties.thinkingContent"
                        />
                      </div>
                      <div
                        v-else
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <img
                          style="width: 300px; height: 300px"
                          src="https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png"
                        />
                      </div>
                      <div
                        class="handleIframe"
                        draggable="false"
                        @mousedown="handleMousedown"
                      ></div>
                    </div>
                    <div class="right_bottom" v-if="Item.type == 'icon-select'">
                      <span class="right_top_title">{{
                        Item.properties.selectTitle
                          ? Item.properties.selectTitle
                          : ""
                      }}</span>
                      <div
                        v-if="Item.type == 'icon-select'"
                        v-bind:key="'select' + index"
                        style="
                          margin-top: 30px;
                          background: #f5f7fb;
                          border-radius: 10px;
                          padding-top: 20px;
                          padding-left: 20px;
                        "
                      >
                        <div>{{ Item.properties.selectContent }}</div>
                        <div style="margin-top: 10px">
                          <template
                            v-for="(option, index) in Item.properties
                              .selectOption"
                          >
                            <el-radio
                              v-bind:key="'letterList' + index"
                              v-model="Radio"
                              :label="option"
                              style="margin-right: 30px; margin-bottom: 20px"
                              @input="setSelect"
                            >
                              {{ letterList[index] }}.
                              {{ option }}
                            </el-radio>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div
                      class="right_top"
                      :id="'right_top_' + i + '_' + index"
                      v-if="Item.type == 'icon-AI'"
                      style="position: relative; height: auto"
                    >
                      <div
                        style="line-height: 1; width: 100%; margin-bottom: 8px"
                      >
                        <span class="right_top_title">{{
                          Item.properties.topic ? Item.properties.topic : ""
                        }}</span>
                        <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                              <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                :title="languageData.tip19" @click="selectPath(Item.children)">
                                <img src="../../assets/next.png" style="
                                    cursor: pointer;
                                    width: 12px;
                                    height: 12px;
                                  " />
                              </el-button>
                            </span> -->
                      </div>
                      <div
                        v-if="
                          Item.properties.radio == 'description' &&
                          Item.properties.aiContent
                        "
                      >
                        <span
                          style="font-size: 16px; line-height: 1.5"
                          class="span_ai"
                          v-html="Item.properties.aiContent"
                        ></span>
                      </div>
                      <div
                        v-else-if="
                          Item.properties.radio == 'image' &&
                          Item.properties.imgResult != ''
                        "
                        :style="
                          Item.properties.imgResult
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <img
                          style="
                            width: auto;
                            height: auto;
                            max-width: 95%;
                            max-height: 95%;
                          "
                          :src="Item.properties.imgResult"
                        />
                      </div>
                      <div
                        class="handleIframe"
                        draggable="false"
                        @mousedown="handleMousedown"
                      ></div>
                    </div>
                    <div
                      class="right_top"
                      :id="'right_top_' + i + '_' + index"
                      v-if="Item.type == 'icon-new-AI'"
                      style="position: relative; height: auto"
                    >
                      <div
                        style="line-height: 1; width: 100%; margin-bottom: 8px"
                      >
                        <span class="right_top_title">{{
                          Item.properties.topic ? Item.properties.topic : ""
                        }}</span>
                        <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                              <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                :title="languageData.tip19" @click="selectPath(Item.children)">
                                <img src="../../assets/next.png" style="
                                    cursor: pointer;
                                    width: 12px;
                                    height: 12px;
                                  " />
                              </el-button>
                            </span> -->
                      </div>
                      <div
                        v-if="
                          Item.properties.radio == 'description' &&
                          Item.properties.aiContent
                        "
                      >
                        <span
                          style="font-size: 16px; line-height: 1.5"
                          class="span_ai"
                          v-html="Item.properties.aiContent"
                        ></span>
                      </div>
                      <div
                        v-else-if="
                          Item.properties.radio == 'image' &&
                          Item.properties.imgResult != ''
                        "
                        :style="
                          Item.properties.imgResult
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <img
                          style="
                            width: auto;
                            height: auto;
                            max-width: 95%;
                            max-height: 95%;
                          "
                          :src="Item.properties.imgResult"
                        />
                      </div>
                      <div
                        v-else-if="
                          Item.properties.radio == 'audio' &&
                          Item.properties.audioList.length != 0
                        "
                        :style="
                          Item.properties.audioList.length != 0
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <audio
                          style="max-width: 95%; max-height: 95%"
                          :src="Item.properties.audioList[0].url"
                          controls
                        ></audio>
                      </div>
                      <div
                        class="handleIframe"
                        draggable="false"
                        @mousedown="handleMousedown"
                      ></div>
                    </div>
                    <div
                      class="right_top"
                      :id="'right_top_' + i + '_' + index"
                      v-if="Item.type == 'icon-whiteboard'"
                      style="position: relative; height: auto"
                    >
                      <div
                        style="line-height: 1; width: 100%; margin-bottom: 8px"
                      >
                        <span class="right_top_title">{{
                          Item.properties.whiteboardTitle
                            ? Item.properties.whiteboardTitle
                            : languageData.whiteboard_card
                        }}</span>
                        <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                              <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                :title="languageData.tip19" @click="selectPath(Item.children)">
                                <img src="../../assets/next.png" style="
                                    cursor: pointer;
                                    width: 12px;
                                    height: 12px;
                                  " />
                              </el-button>
                            </span> -->
                      </div>
                      <div
                        v-if="Item.properties.whiteboardContent"
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <img
                          style="
                            width: auto;
                            height: auto;
                            max-width: 95%;
                            max-height: 95%;
                          "
                          :src="Item.properties.whiteboardContent"
                        />
                      </div>
                      <div v-else>
                        <span
                          style="font-size: 16px; line-height: 1.5"
                          class="span_ai"
                          >{{ languageData.tip76 }}</span
                        >
                      </div>
                    </div>

                    <div
                      class="right_top"
                      :id="'right_top_' + i + '_' + index"
                      v-if="Item.type == 'icon-map'"
                      style="position: relative; height: auto"
                    >
                      <div
                        style="line-height: 1; width: 100%; margin-bottom: 8px"
                      >
                        <span class="right_top_title">{{
                          languageData.map_card
                        }}</span>
                        <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                              <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                :title="languageData.tip19" @click="selectPath(Item.children)">
                                <img src="../../assets/next.png" style="
                                    cursor: pointer;
                                    width: 12px;
                                    height: 12px;
                                  " />
                              </el-button>
                            </span> -->
                      </div>
                      <div
                        v-if="Item.properties.map_url"
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <img
                          style="
                            width: auto;
                            height: auto;
                            max-width: 95%;
                            max-height: 95%;
                          "
                          :src="Item.properties.map_url"
                        />
                      </div>
                    </div>

                    <div
                      class="right_top"
                      :id="'right_top_' + i + '_' + index"
                      v-if="Item.type == 'icon-camera'"
                      style="position: relative; height: auto"
                    >
                      <div
                        style="line-height: 1; width: 100%; margin-bottom: 8px"
                      >
                        <span class="right_top_title">{{
                          languageData.camera_card
                        }}</span>
                        <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                              <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                :title="languageData.tip19" @click="selectPath(Item.children)">
                                <img src="../../assets/next.png" style="
                                    cursor: pointer;
                                    width: 12px;
                                    height: 12px;
                                  " />
                              </el-button>
                            </span> -->
                      </div>
                      <div
                        v-if="Item.properties.src"
                        :style="
                          Item.properties.describe
                            ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                            : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                        "
                      >
                        <img
                          style="
                            width: auto;
                            height: auto;
                            max-width: 95%;
                            max-height: 95%;
                          "
                          :src="Item.properties.src"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <!-- <div v-if="dataList.length != 0">
                                  <template v-for="(Item, index) in dataList[currentIndex].nodes">
                                      <div v-bind:key="index">
                                          <p :id="Item.id"></p>
                                          
                                      </div>
                                  </template>
  
                              </div> -->
          </div>

          <!-- <div v-if="isAnswer" class="right_answer">
                              <div v-if="answerContent.radio == 'content'" style="margin-top: 10px;">
                                  <span class="text">{{ answerContent.content }}</span>
                              </div>
                              <div v-else-if="answerContent.radio == 'iframeUrl'" style="margin-top: 10px;">
                                  <iframe style="width: 90%;height: 550px;" :src="'//' + answerContent.iframeUrl"
                                      frameborder="no" border="0"></iframe>
                              </div>
                              <div v-else-if="answerContent.radio == 'imageList'"
                                  style="margin-top: 10px;display: flex;justify-content: center;">
                                  <img width="600" height="300" :src="answerContent.imageList[0].url" />
                              </div>
                              <div v-else-if="answerContent.radio == 'videoList'"
                                  style="margin-top: 10px;display: flex;justify-content: center;">
                                  <video width="600" height="300" controls :src="answerContent.videoList[0].url"></video>
                              </div>
                              <div v-else-if="answerContent.radio == 'audioList'">
                                  <audio controls :src="answerContent.audioList[0].url"></audio>
                              </div>
                          </div> -->
        </div>
        <div
          :style="!isPhone ? 'height: calc(100% - 45px); display: flex; flex-direction: row' : 'height: calc(100% - 68px); display: flex; flex-direction: row'"
          v-else-if="isImmersive && isPhone"
        >
          <div class="left-side" id="leftSide" :style="fullscreen ? '' : ''">
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 1px solid rgb(202, 209, 220);
              "
            >
              <div
                style="
                  width: 140px;
                  margin-left: 10px;
                  margin-bottom: 5px;
                  color: black;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-break: break-word;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                "
                v-if="themeName != '' && !isHomeWork"
              >
                {{ themeName }}
              </div>
              <!-- <span class="mulu">{{ languageData.directory }}({{ navigationList.length }})</span> -->
            </div>
            <div
              :style="
                stageDatas.length != 0
                  ? 'overflow-y: auto;overflow-x: hidden;width: 250px;display: flex;flex-direction: column;align-items: center;height: calc(100% - 35px);'
                  : 'overflow-y: auto;overflow-x: hidden;width: 250px;display: flex;flex-direction: column;align-items: center;margin-top: 15px;height: calc(100% - 50px);'
              "
              class="menuList"
            >
              <template v-for="(item, i) in navigationList">
                <div
                  style="width: 100%; cursor: pointer"
                  v-if="item.type == 'icon-stage'"
                  v-bind:key="'menu_' + i"
                >
                  <div
                    style="
                      width: calc(100% - 16px);
                      display: flex;
                      flex-direction: row;
                      padding: 12px 8px;
                      align-items: center;
                      color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                      font-family: SimSun;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px;
                    "
                  >
                    <div
                      class="closeTri"
                      @click="hideStage(i)"
                      v-if="item.isFold && item.child.length != 0"
                    >
                      <img
                        src="../../assets/chevron-up_nor.png"
                        alt=""
                        width="16"
                        height="16"
                        :style="item.isFold ? '' : 'transform: rotate(-90deg);'"
                      />
                    </div>
                    <div
                      class="closeTri"
                      @click="showStage(i)"
                      v-else-if="!item.isFold && item.child.length != 0"
                    >
                      <img
                        src="../../assets/chevron-up_nor.png"
                        alt=""
                        width="16"
                        height="16"
                        :style="item.isFold ? '' : 'transform: rotate(-90deg);'"
                      />
                    </div>
                    <div
                      class="closeTri"
                      v-else-if="
                        item.child.length == 0 || stageDatas.length != 0
                      "
                    >
                      <img
                        src="../../assets/chevron-up_pre.png"
                        alt=""
                        width="16"
                        height="16"
                        style="visibility: hidden"
                      />
                    </div>
                    <div
                      @click="navToStage(i)"
                      style="
                        width: calc(100% - 21px);
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        color: var(
                          --font-icon-gy-1-primary,
                          rgba(0, 0, 0, 0.9)
                        );
                        font-family: SimSun;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                      "
                    >
                      <div style="width: auto">
                        {{ languageData.stageName }}{{ i + 1 }}:
                      </div>
                      <div
                        style="
                          margin-left: 10px;
                          width: auto;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          word-break: break-word;
                        "
                      >
                        {{ item.properties.stageName }}
                      </div>
                    </div>
                  </div>
                </div>
                <template v-for="(itemNew, j) in item.child">
                  <a
                    style="text-decoration: none"
                    @click.stop="navTo(itemNew.id)"
                    v-if="stageIndex - 1 == i && item.isFold"
                    v-bind:key="'menu_' + i + '_' + j"
                  >
                    <div
                      :class="
                        selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti'
                      "
                      :data-id="itemNew.id"
                      v-if="itemNew.type == 'icon-task'"
                    >
                      <div
                        class="closeTri"
                        v-if="
                          selectMulu == itemNew.id &&
                          itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="hideTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu != itemNew.id &&
                          itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="hideTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_nor.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu == itemNew.id &&
                          !itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="showTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu != itemNew.id &&
                          !itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="showTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_nor.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          itemNew.child.length == 0 || stageDatas.length != 0
                        "
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          style="visibility: hidden"
                        />
                      </div>
                      <span
                        :data-id="itemNew.id"
                        :class="
                          selectMulu == itemNew.id ? 'text text-select' : 'text'
                        "
                        style="cursor: pointer"
                        >{{ languageData.taskTip }}
                        {{
                          itemNew.properties.taskTitle
                            ? itemNew.properties.taskTitle
                            : ""
                        }}</span
                      >
                    </div>
                  </a>
                  <a
                    style="text-decoration: none"
                    @click.stop="navToTask(i, j)"
                    v-bind:key="'menu_' + i + '_' + j"
                    v-else-if="stageIndex - 1 != i && item.isFold"
                  >
                    <div
                      :class="
                        selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti'
                      "
                      :data-id="itemNew.id"
                      v-if="itemNew.type == 'icon-task'"
                    >
                      <div
                        class="closeTri"
                        v-if="
                          selectMulu == itemNew.id &&
                          itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="hideTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu != itemNew.id &&
                          itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="hideTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_nor.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu == itemNew.id &&
                          !itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="showTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu != itemNew.id &&
                          !itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="showTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_nor.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          itemNew.child.length == 0 || stageDatas.length != 0
                        "
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          style="visibility: hidden"
                        />
                      </div>
                      <span
                        :data-id="itemNew.id"
                        :class="
                          selectMulu == itemNew.id ? 'text text-select' : 'text'
                        "
                        style="cursor: pointer"
                        >{{ languageData.taskTip }}
                        {{
                          itemNew.properties.taskTitle
                            ? itemNew.properties.taskTitle
                            : ""
                        }}</span
                      >
                    </div>
                  </a>
                  <template v-for="(Item, index) in itemNew.child">
                    <a
                      style="text-decoration: none"
                      @click="navTo(Item.id)"
                      v-if="
                        stageIndex - 1 == i &&
                        itemNew.isFold &&
                        item.isFold &&
                        stageDatas.length == 0
                      "
                      v-bind:key="'menu_' + i + '_' + j + '_' + index"
                    >
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        :data-id="Item.id"
                        v-if="Item.type == 'icon-card'"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.title ? Item.properties.title : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-select'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.selectTitle
                              ? Item.properties.selectTitle
                              : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-thinking'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.thinkingTitle
                              ? Item.properties.thinkingTitle
                              : languageData.thinkingCard
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-AI'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-new-AI'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-whiteboard'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.whiteboardTitle
                              ? Item.properties.whiteboardTitle
                              : languageData.whiteboard_card
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-map'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{ languageData.map_card }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-camera'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{ languageData.camera_card }}</span
                        >
                      </div>
                    </a>

                    <a
                      style="text-decoration: none"
                      @click="navToOther(i, j, index)"
                      v-bind:key="'menu_' + i + '_' + j + '_' + index"
                      v-else-if="
                        stageIndex - 1 != i &&
                        itemNew.isFold &&
                        item.isFold &&
                        stageDatas.length == 0
                      "
                    >
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        :data-id="Item.id"
                        v-if="Item.type == 'icon-card'"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.title ? Item.properties.title : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-select'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.selectTitle
                              ? Item.properties.selectTitle
                              : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-thinking'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.thinkingTitle
                              ? Item.properties.thinkingTitle
                              : languageData.thinkingCard
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-AI'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-new-AI'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-whiteboard'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.whiteboardTitle
                              ? Item.properties.whiteboardTitle
                              : languageData.whiteboard_card
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-map'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{ languageData.map_card }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-camera'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{ languageData.camera_card }}</span
                        >
                      </div>
                    </a>
                  </template>
                </template>
              </template>
            </div>
          </div>

          <div
            class="right-side"
            id="scroll-box"
            :style="fullscreen ? 'height:100%;' : 'height:100%;'"
          >
            <div
              v-if="themeName != ''"
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
              "
            >
              <div class="stageTitle" style="width: calc(100% - 30px);padding:16px 15px 8px 15px;">
                <div :title="themeName" style="margin-left:0;align-items: center;justify-content: center;color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));text-align: center;font-family: SimSun;font-size: 28px;font-style: normal;font-weight: 600;line-height: 38px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 90%;">
                  <!-- <div>{{ languageData.stageName }}{{ i + 1 }}:</div>
                  <div
                    class="el-tooltip"
                    aria-describedby="el-tooltip-4036"
                    tabindex="0"
                  >
                    {{ stageDatas[i].properties.stageName }}
                  </div> -->
                  {{ themeName }}
                </div>
                <!-- <div style="width: 40%;text-align: right;">
                      <el-button type="text" @click="pre_stage" size="medium" style="color:#0061ff;"
                        v-if="stageDatas.length != 0 && stageIndex != 1" :title="languageData.pre_stage">{{ languageData.pre_stage }}</el-button>
                      <el-button type="text" @click="next_stage" size="medium" style="color:#0061ff;"
                        v-if="stageDatas.length != 0 && stageIndex != stageDatas.length" :title="languageData.next_page">{{ languageData.next_page }}</el-button>
                    </div> -->
              </div>
            </div>

            <div
              v-if="dataList_new.length != 0"
              id="scroll-box2"
              :style="
                themeName != ''
                  ? 'margin-bottom: 100px;overflow-y: auto;height: calc(100% - 80px);'
                  : 'margin-bottom: 100px;overflow-y: auto;height: calc(100% - 19px);'
              "
            >
              <template v-for="(itemNew, j) in dataList_new">
                <div
                  :id="'id_' + stageDatas[j].id"
                  style="height: 0"
                  v-bind:key="'title' + j"
                  v-if="stageDatas.length != 0"
                ></div>
                <div
                  v-if="stageDatas.length != 0"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                  v-bind:key="j"
                >
                  <div class="stageTitle" style="padding:8px 15px 0 15px;">
                    <div class="stageItemTitle" style="margin-left:0;">
                      <div>{{ languageData.stageName }}{{ j + 1 }}:</div>
                      <div
                        class="el-tooltip"
                        aria-describedby="el-tooltip-4036"
                        tabindex="0"
                      >
                        {{ stageDatas[j].properties.stageName }}
                      </div>
                    </div>
                    <!-- <div style="width: 40%;text-align: right;">
                          <el-button type="text" @click="pre_stage" size="medium" style="color:#0061ff;"
                            v-if="stageDatas.length != 0 && stageIndex != 1" :title="languageData.pre_stage">{{ languageData.pre_stage }}</el-button>
                          <el-button type="text" @click="next_stage" size="medium" style="color:#0061ff;"
                            v-if="stageDatas.length != 0 && stageIndex != stageDatas.length" :title="languageData.next_page">{{ languageData.next_page }}</el-button>
                        </div> -->
                  </div>
                </div>
                <template v-for="(item, i) in itemNew">
                  <template v-for="(Item, index) in item.nodes">
                    <div
                      :id="'id_' + Item.id"
                      style="height: 0"
                      v-bind:key="'title' + j + i + index"
                    ></div>
                    <div v-bind:key="'Title' + j + i + index">
                      <div
                        class="right_top"
                        :id="'right_top_' + j + '_' + i + '_' + index"
                        v-if="Item.type == 'icon-task'"
                        style="position: relative"
                      >
                        <div
                          style="
                            line-height: 1;
                            width: 100%;
                            margin-bottom: 8px;
                          "
                        >
                          <span
                            class="right_top_title"
                            style="line-height: 27px; font-size: 17px"
                            >{{ languageData.taskTip }}
                            {{
                              Item.properties.taskTitle
                                ? Item.properties.taskTitle
                                : ""
                            }}</span
                          >
                          <!-- <span v-if="Item.children.length > 1 && Item.type != 'icon-task'"
                                style="float: right; margin-top: -10px">
                                <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                  :title="languageData.tip19" @click="selectPath(Item.children)">
                                  <img src="../../assets/next.png" style="
                                      cursor: pointer;
                                      width: 12px;
                                      height: 12px;
                                    " />
                                </el-button>
                              </span> -->
                        </div>
                        <div
                          v-if="Item.properties.taskContent"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: column;justify-content: center;height: 90%;'
                              : 'display:flex;flex-direction: column;justify-content: center;height: 95%;'
                          "
                        >
                          <div :id="'card_' + j + '_' + i + '_' + index">
                            <span
                              v-html="Item.properties.taskContent"
                              style="line-height: 1.5"
                            ></span>
                          </div>
                        </div>
                        <div
                          class="handleIframe"
                          draggable="false"
                          @mousedown="handleMousedown"
                        ></div>
                      </div>
                      <div
                        class="right_top"
                        :id="'right_top_' + j + '_' + i + '_' + index"
                        v-if="Item.type == 'icon-card'"
                        style="position: relative"
                      >
                        <div
                          style="line-height: 1; width: 100%; margin-bottom: 8px"
                        >
                          <span class="right_top_title">{{
                            Item.properties.title ? Item.properties.title : ""
                          }}</span>
                          <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                                <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                  :title="languageData.tip19" @click="selectPath(Item.children)">
                                  <img src="../../assets/next.png" style="
                                      cursor: pointer;
                                      width: 12px;
                                      height: 12px;
                                    " />
                                </el-button>
                              </span> -->
                        </div>
                        <div
                          v-if="Item.properties.describe"
                          style="margin-top: 23px"
                        >
                          <span class="text" style="font-size: 14px">{{
                            Item.properties.describe
                          }}</span>
                        </div>
                        <div
                          v-if="Item.properties.radio == 'content'"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: column;justify-content: center;height: 90%;'
                              : 'display:flex;flex-direction: column;justify-content: center;height: 95%;'
                          "
                        >
                          <div :id="'card_' + j + '_' + i + '_' + index">
                            <span
                              v-html="Item.properties.content"
                              style="line-height: 1.5"
                            ></span>
                          </div>
                        </div>
                        <div
                          v-else-if="Item.properties.radio == 'iframeUrl'"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <iframe
                            style="width: 100%; height: 100%; min-height: 300px"
                            :src="
                              Item.properties.iframeUrl.indexOf('https://') !=
                                -1 ||
                              Item.properties.iframeUrl.indexOf('http://') != -1
                                ? Item.properties.iframeUrl
                                : '//' + Item.properties.iframeUrl
                            "
                            frameborder="no"
                            border="0"
                            sandbox="allow-forms allow-scripts allow-same-origin"
                          ></iframe>
                        </div>
                        <div
                          v-else-if="Item.properties.radio == 'embedCode'"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;height: 85%;'
                              : 'display:flex;flex-direction: row;justify-content: center;height: 95%;'
                          "
                          v-html="Item.properties.embedCode"
                        ></div>
                        <div
                          v-else-if="Item.properties.radio == 'imageList'"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="
                              Item.properties.imageList &&
                              Item.properties.imageList.length > 0
                                ? Item.properties.imageList[0].url
                                : ''
                            "
                            @click="resizeImg"
                          />
                        </div>
                        <div
                          v-else-if="Item.properties.radio == 'videoList'"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <video
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            controls
                            :src="
                              Item.properties.videoList &&
                              Item.properties.videoList.length > 0
                                ? Item.properties.videoList[0].url
                                : ''
                            "
                          ></video>
                        </div>
                        <div
                          v-else-if="Item.properties.radio == 'audioList'"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <audio
                            controls
                            :src="
                              Item.properties.audioList &&
                              Item.properties.audioList.length > 0
                                ? Item.properties.audioList[0].url
                                : ''
                            "
                          ></audio>
                        </div>
                        <div
                          v-else-if="
                            Item.properties.radio == 'fileList' &&
                            Item.properties.fileList &&
                            Item.properties.fileList.length != 0 &&
                            (Item.properties.fileList[0].url.indexOf('.pdf') !=
                              -1 ||
                              Item.properties.fileList[0].url.indexOf('.PDF') !=
                                -1)
                          "
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <iframe
                            style="width: 100%; height: 100%; min-height: 800px"
                            frameborder="0"
                            :src="
                              'https://cloud.cocorobo.cn/pdf.js/web/viewer.html?file=' +
                              Item.properties.fileList[0].url
                            "
                          ></iframe>
                        </div>
                        <div
                          v-else-if="
                            Item.properties.radio == 'fileList' &&
                            Item.properties.fileList &&
                            Item.properties.fileList.length != 0 &&
                            Item.properties.fileList[0].url.indexOf('.pdf') ==
                              -1 &&
                            Item.properties.fileList[0].url.indexOf('.PDF') == -1
                          "
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <iframe
                            style="width: 100%; height: 100%; min-height: 800px"
                            frameborder="0"
                            :src="
                              'https://view.officeapps.live.com/op/view.aspx?src=' +
                              Item.properties.fileList[0].url
                            "
                          ></iframe>
                        </div>
                        <div
                          class="handleIframe"
                          draggable="false"
                          @mousedown="handleMousedown"
                        ></div>
                      </div>
                      <div
                        class="right_top"
                        :id="'right_top_' + j + '_' + i + '_' + index"
                        v-if="Item.type == 'icon-thinking'"
                        style="position: relative"
                      >
                        <div
                          style="line-height: 1; width: 100%; margin-bottom: 8px"
                        >
                          <span class="right_top_title">{{
                            Item.properties.thinkingTitle
                              ? Item.properties.thinkingTitle
                              : languageData.thinkingCard
                          }}</span>
                          <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                                <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                  :title="languageData.tip19" @click="selectPath(Item.children)">
                                  <img src="../../assets/next.png" style="
                                      cursor: pointer;
                                      width: 12px;
                                      height: 12px;
                                    " />
                                </el-button>
                              </span> -->
                        </div>
                        <div
                          v-if="Item.properties.describe"
                          style="margin-top: 10px"
                        >
                          <span class="text" style="font-size: 14px">{{
                            Item.properties.describe
                          }}</span>
                        </div>
                        <div
                          v-if="Item.properties.thinkingContent"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="Item.properties.thinkingContent"
                            @click="resizeImg"
                          />
                        </div>
                        <div
                          v-else
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="width: 300px; height: 300px"
                            src="https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png"
                            @click="resizeImg"
                          />
                        </div>
                        <div
                          class="handleIframe"
                          draggable="false"
                          @mousedown="handleMousedown"
                        ></div>
                      </div>
                      <div class="right_bottom" v-if="Item.type == 'icon-select'">
                        <span class="right_top_title">{{
                          Item.properties.selectTitle
                            ? Item.properties.selectTitle
                            : ""
                        }}</span>
                        <div
                          v-if="Item.type == 'icon-select'"
                          v-bind:key="'select' + index"
                          style="
                            margin-top: 30px;
                            background: #f5f7fb;
                            border-radius: 10px;
                            padding-top: 20px;
                            padding-left: 20px;
                          "
                        >
                          <div>{{ Item.properties.selectContent }}</div>
                          <div style="margin-top: 10px">
                            <template
                              v-for="(option, index) in Item.properties
                                .selectOption"
                            >
                              <el-radio
                                v-bind:key="'letterList' + index"
                                v-model="Radio"
                                :label="option"
                                style="margin-right: 30px; margin-bottom: 20px"
                                @input="setSelect"
                              >
                                {{ letterList[index] }}.
                                {{ option }}
                              </el-radio>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div
                        class="right_top"
                        :id="'right_top_' + j + '_' + i + '_' + index"
                        v-if="Item.type == 'icon-AI'"
                        style="position: relative; height: auto"
                      >
                        <div
                          style="line-height: 1; width: 100%; margin-bottom: 8px"
                        >
                          <span class="right_top_title">{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span>
                          <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                                <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                  :title="languageData.tip19" @click="selectPath(Item.children)">
                                  <img src="../../assets/next.png" style="
                                      cursor: pointer;
                                      width: 12px;
                                      height: 12px;
                                    " />
                                </el-button>
                              </span> -->
                        </div>
                        <div
                          v-if="
                            Item.properties.radio == 'description' &&
                            Item.properties.aiContent
                          "
                        >
                          <span
                            style="font-size: 16px; line-height: 1.5"
                            class="span_ai"
                            v-html="Item.properties.aiContent"
                          ></span>
                        </div>
                        <div
                          v-else-if="
                            Item.properties.radio == 'image' &&
                            Item.properties.imgResult != ''
                          "
                          :style="
                            Item.properties.imgResult
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="Item.properties.imgResult"
                            @click="resizeImg"
                          />
                        </div>
                        <div
                          class="handleIframe"
                          draggable="false"
                          @mousedown="handleMousedown"
                        ></div>
                      </div>
                      <div
                        class="right_top"
                        :id="'right_top_' + j + '_' + i + '_' + index"
                        v-if="Item.type == 'icon-new-AI'"
                        style="position: relative; height: auto"
                      >
                        <div
                          style="line-height: 1; width: 100%; margin-bottom: 8px"
                        >
                          <span class="right_top_title">{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span>
                          <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                                <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                  :title="languageData.tip19" @click="selectPath(Item.children)">
                                  <img src="../../assets/next.png" style="
                                      cursor: pointer;
                                      width: 12px;
                                      height: 12px;
                                    " />
                                </el-button>
                              </span> -->
                        </div>
                        <div
                          v-if="
                            Item.properties.radio == 'description' &&
                            Item.properties.aiContent
                          "
                        >
                          <span
                            style="font-size: 16px; line-height: 1.5"
                            class="span_ai"
                            v-html="Item.properties.aiContent"
                          ></span>
                        </div>
                        <div
                          v-else-if="
                            Item.properties.radio == 'image' &&
                            Item.properties.imgResult != ''
                          "
                          :style="
                            Item.properties.imgResult
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="Item.properties.imgResult"
                            @click="resizeImg"
                          />
                        </div>
                        <div
                          v-else-if="
                            Item.properties.radio == 'audio' &&
                            Item.properties.audioList.length != 0
                          "
                          :style="
                            Item.properties.audioList.length != 0
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <audio
                            style="max-width: 95%; max-height: 95%"
                            :src="Item.properties.audioList[0].url"
                            controls
                          ></audio>
                        </div>
                        <div
                          class="handleIframe"
                          draggable="false"
                          @mousedown="handleMousedown"
                        ></div>
                      </div>
                      <div
                        class="right_top"
                        :id="'right_top_' + j + '_' + i + '_' + index"
                        v-if="Item.type == 'icon-whiteboard'"
                        style="position: relative; height: auto"
                      >
                        <div
                          style="line-height: 1; width: 100%; margin-bottom: 8px"
                        >
                          <span class="right_top_title">{{
                            Item.properties.whiteboardTitle
                              ? Item.properties.whiteboardTitle
                              : languageData.whiteboard_card
                          }}</span>
                          <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                                <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                  :title="languageData.tip19" @click="selectPath(Item.children)">
                                  <img src="../../assets/next.png" style="
                                      cursor: pointer;
                                      width: 12px;
                                      height: 12px;
                                    " />
                                </el-button>
                              </span> -->
                        </div>
                        <div
                          v-if="Item.properties.whiteboardContent"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="Item.properties.whiteboardContent"
                            @click="resizeImg"
                          />
                        </div>
                        <div v-else>
                          <span
                            style="font-size: 16px; line-height: 1.5"
                            class="span_ai"
                            >{{ languageData.tip76 }}</span
                          >
                        </div>
                      </div>

                      <div
                        class="right_top"
                        :id="'right_top_' + j + '_' + i + '_' + index"
                        v-if="Item.type == 'icon-map'"
                        style="position: relative; height: auto"
                      >
                        <div
                          style="line-height: 1; width: 100%; margin-bottom: 8px"
                        >
                          <span class="right_top_title">{{
                            languageData.map_card
                          }}</span>
                          <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                                <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                  :title="languageData.tip19" @click="selectPath(Item.children)">
                                  <img src="../../assets/next.png" style="
                                      cursor: pointer;
                                      width: 12px;
                                      height: 12px;
                                    " />
                                </el-button>
                              </span> -->
                        </div>
                        <div
                          v-if="Item.properties.map_url"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="Item.properties.map_url"
                            @click="resizeImg"
                          />
                        </div>
                      </div>

                      <div
                        class="right_top"
                        :id="'right_top_' + j + '_' + i + '_' + index"
                        v-if="Item.type == 'icon-camera'"
                        style="position: relative; height: auto"
                      >
                        <div
                          style="line-height: 1; width: 100%; margin-bottom: 8px"
                        >
                          <span class="right_top_title">{{
                            languageData.camera_card
                          }}</span>
                          <!-- <span v-if="Item.children.length > 1" style="float: right; margin-top: -10px">
                                <el-button type="warning" circle style="height: 30px; width: 30px; padding: 8px"
                                  :title="languageData.tip19" @click="selectPath(Item.children)">
                                  <img src="../../assets/next.png" style="
                                      cursor: pointer;
                                      width: 12px;
                                      height: 12px;
                                    " />
                                </el-button>
                              </span> -->
                        </div>
                        <div
                          v-if="Item.properties.src"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="Item.properties.src"
                            @click="resizeImg"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </div>
            <!-- <div v-if="dataList.length != 0">
                                  <template v-for="(Item, index) in dataList[currentIndex].nodes">
                                      <div v-bind:key="index">
                                          <p :id="Item.id"></p>
                                          
                                      </div>
                                  </template>
  
                              </div> -->
          </div>

          <!-- <div v-if="isAnswer" class="right_answer">
                              <div v-if="answerContent.radio == 'content'" style="margin-top: 10px;">
                                  <span class="text">{{ answerContent.content }}</span>
                              </div>
                              <div v-else-if="answerContent.radio == 'iframeUrl'" style="margin-top: 10px;">
                                  <iframe style="width: 90%;height: 550px;" :src="'//' + answerContent.iframeUrl"
                                      frameborder="no" border="0"></iframe>
                              </div>
                              <div v-else-if="answerContent.radio == 'imageList'"
                                  style="margin-top: 10px;display: flex;justify-content: center;">
                                  <img width="600" height="300" :src="answerContent.imageList[0].url" />
                              </div>
                              <div v-else-if="answerContent.radio == 'videoList'"
                                  style="margin-top: 10px;display: flex;justify-content: center;">
                                  <video width="600" height="300" controls :src="answerContent.videoList[0].url"></video>
                              </div>
                              <div v-else-if="answerContent.radio == 'audioList'">
                                  <audio controls :src="answerContent.audioList[0].url"></audio>
                              </div>
                          </div> -->
        </div>
        <!-- 卡片式 -->
        <div
          id="aaaa"
          :style="!isPhone ? 'height: calc(100% - 61px);display: flex;flex-direction: row;background: var(--bg, #f0f2f5);padding: 16px 0 0 16px;border-bottom-left-radius: 12px;border-bottom-right-radius: 12px;' : 'height: calc(100% - 55px);display: flex;flex-direction: row;background: var(--bg, #f0f2f5);'
          "
          v-else
        >
          <div
            class="left-side"
            id="leftSide"
            style="
              border-radius: 6px;
              background: var(--font-icon-white, #fff);
              margin-bottom: 16px;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 1px solid rgb(202, 209, 220);
              "
            >
              <div
                style="
                  width: 140px;
                  margin-left: 10px;
                  margin-bottom: 5px;
                  color: black;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-break: break-word;
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                "
                v-if="themeName != '' && !isHomeWork"
              >
                {{ themeName }}
              </div>
              <!-- <span class="mulu">{{ languageData.directory }}({{ navigationList.length }})</span> -->
            </div>
            <!-- <div v-if="stageDatas.length != 0" style="margin-top: 13px;display: flex;flex-direction: column;align-items: center;">
                  <div style="width: 100%;">
                    <div style="display: flex;flex-direction: row;padding: 12px 8px;align-items: center;color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 400;line-height: 22px;">
                      <div class="closeTri" ><img
                        src="../../assets/chevron-up_nor.png" alt=""
                        width="16" height="16"></div>
                      <div>{{ languageData.stageName }}{{ stageIndex }}:</div>
                      <div style="margin-left: 10px;width: 69%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;word-break: break-word;">
                        {{ value }}
                      </div>
                    </div>
                  </div>
                </div> -->

            <div
              :style="
                stageDatas.length != 0
                  ? 'overflow-y: auto;overflow-x: hidden;width: 250px;display: flex;flex-direction: column;align-items: center;height: calc(100% - 15px);'
                  : 'overflow-y: auto;overflow-x: hidden;width: 250px;display: flex;flex-direction: column;align-items: center;margin-top: 15px;height: calc(100% - 30px);'
              "
              class="menuList"
            >
              <template v-for="(item, i) in navigationList">
                <div
                  style="width: 100%; cursor: pointer"
                  v-if="item.type == 'icon-stage'"
                  v-bind:key="'menu_' + i"
                >
                  <div
                    style="
                      width: calc(100% - 16px);
                      display: flex;
                      flex-direction: row;
                      padding: 12px 8px;
                      align-items: center;
                      color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                      font-family: SimSun;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px;
                    "
                  >
                    <div
                      class="closeTri"
                      @click="hideStage(i)"
                      v-if="item.isFold && item.child.length != 0"
                    >
                      <img
                        src="../../assets/chevron-up_nor.png"
                        alt=""
                        width="16"
                        height="16"
                        :style="item.isFold ? '' : 'transform: rotate(-90deg);'"
                      />
                    </div>
                    <div
                      class="closeTri"
                      @click="showStage(i)"
                      v-else-if="!item.isFold && item.child.length != 0"
                    >
                      <img
                        src="../../assets/chevron-up_nor.png"
                        alt=""
                        width="16"
                        height="16"
                        :style="item.isFold ? '' : 'transform: rotate(-90deg);'"
                      />
                    </div>
                    <div
                      class="closeTri"
                      v-else-if="
                        item.child.length == 0 || stageDatas.length != 0
                      "
                    >
                      <img
                        src="../../assets/chevron-up_pre.png"
                        alt=""
                        width="16"
                        height="16"
                        style="visibility: hidden"
                      />
                    </div>
                    <div
                      @click="navToStage(i)"
                      style="
                        width: calc(100% - 21px);
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        color: var(
                          --font-icon-gy-1-primary,
                          rgba(0, 0, 0, 0.9)
                        );
                        font-family: SimSun;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                      "
                    >
                      <div style="width: auto">
                        {{ languageData.stageName }}{{ i + 1 }}:
                      </div>
                      <div
                        style="
                          margin-left: 10px;
                          width: auto;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          word-break: break-word;
                        "
                      >
                        {{ item.properties.stageName }}
                      </div>
                    </div>
                  </div>
                </div>
                <template v-for="(itemNew, j) in item.child">
                  <a
                    style="text-decoration: none"
                    @click.stop="navTo(itemNew.id)"
                    v-if="stageIndex - 1 == i && item.isFold"
                    v-bind:key="'menu_' + i + '_' + j"
                  >
                    <div
                      :class="
                        selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti'
                      "
                      :data-id="itemNew.id"
                      v-if="itemNew.type == 'icon-task'"
                    >
                      <div
                        class="closeTri"
                        v-if="
                          selectMulu == itemNew.id &&
                          itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="hideTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu != itemNew.id &&
                          itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="hideTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_nor.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu == itemNew.id &&
                          !itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="showTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu != itemNew.id &&
                          !itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="showTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_nor.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          itemNew.child.length == 0 || stageDatas.length != 0
                        "
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          style="visibility: hidden"
                        />
                      </div>
                      <span
                        :data-id="itemNew.id"
                        :class="
                          selectMulu == itemNew.id ? 'text text-select' : 'text'
                        "
                        style="cursor: pointer"
                        >{{ languageData.taskTip }}
                        {{
                          itemNew.properties.taskTitle
                            ? itemNew.properties.taskTitle
                            : ""
                        }}</span
                      >
                    </div>
                  </a>
                  <a
                    style="text-decoration: none"
                    @click.stop="navToTask(i, j)"
                    v-bind:key="'menu_' + i + '_' + j"
                    v-else-if="stageIndex - 1 != i && item.isFold"
                  >
                    <div
                      :class="
                        selectMulu == itemNew.id ? 'biaoti-select' : 'biaoti'
                      "
                      :data-id="itemNew.id"
                      v-if="itemNew.type == 'icon-task'"
                    >
                      <div
                        class="closeTri"
                        v-if="
                          selectMulu == itemNew.id &&
                          itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="hideTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu != itemNew.id &&
                          itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="hideTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_nor.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu == itemNew.id &&
                          !itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="showTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          selectMulu != itemNew.id &&
                          !itemNew.isFold &&
                          itemNew.child.length != 0 &&
                          stageDatas.length == 0
                        "
                        @click.stop="showTask(i, j)"
                        style="cursor: pointer"
                      >
                        <img
                          src="../../assets/chevron-up_nor.png"
                          alt=""
                          width="16"
                          height="16"
                          :style="
                            itemNew.isFold ? '' : 'transform: rotate(-90deg);'
                          "
                        />
                      </div>
                      <div
                        class="closeTri"
                        v-else-if="
                          itemNew.child.length == 0 || stageDatas.length != 0
                        "
                      >
                        <img
                          src="../../assets/chevron-up_pre.png"
                          alt=""
                          width="16"
                          height="16"
                          style="visibility: hidden"
                        />
                      </div>
                      <span
                        :data-id="itemNew.id"
                        :class="
                          selectMulu == itemNew.id ? 'text text-select' : 'text'
                        "
                        style="cursor: pointer"
                        >{{ languageData.taskTip }}
                        {{
                          itemNew.properties.taskTitle
                            ? itemNew.properties.taskTitle
                            : ""
                        }}</span
                      >
                    </div>
                  </a>
                  <template v-for="(Item, index) in itemNew.child">
                    <a
                      style="text-decoration: none"
                      @click="navTo(Item.id)"
                      v-if="
                        stageIndex - 1 == i &&
                        itemNew.isFold &&
                        item.isFold &&
                        stageDatas.length == 0
                      "
                      v-bind:key="'menu_' + i + '_' + j + '_' + index"
                    >
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        :data-id="Item.id"
                        v-if="Item.type == 'icon-card'"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.title ? Item.properties.title : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-select'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.selectTitle
                              ? Item.properties.selectTitle
                              : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-thinking'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.thinkingTitle
                              ? Item.properties.thinkingTitle
                              : languageData.thinkingCard
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-AI'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-new-AI'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-whiteboard'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.whiteboardTitle
                              ? Item.properties.whiteboardTitle
                              : languageData.whiteboard_card
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-map'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{ languageData.map_card }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-camera'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{ languageData.camera_card }}</span
                        >
                      </div>
                    </a>

                    <a
                      style="text-decoration: none"
                      @click="navToOther(i, j, index)"
                      v-bind:key="'menu_' + i + '_' + j + '_' + index"
                      v-else-if="
                        stageIndex - 1 != i &&
                        itemNew.isFold &&
                        item.isFold &&
                        stageDatas.length == 0
                      "
                    >
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        :data-id="Item.id"
                        v-if="Item.type == 'icon-card'"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.title ? Item.properties.title : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-select'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.selectTitle
                              ? Item.properties.selectTitle
                              : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-thinking'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.thinkingTitle
                              ? Item.properties.thinkingTitle
                              : languageData.thinkingCard
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-AI'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-new-AI'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-whiteboard'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{
                            Item.properties.whiteboardTitle
                              ? Item.properties.whiteboardTitle
                              : languageData.whiteboard_card
                          }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-map'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{ languageData.map_card }}</span
                        >
                      </div>
                      <div
                        :class="
                          selectMulu == Item.id ? 'biaoti-select' : 'biaoti'
                        "
                        v-else-if="Item.type == 'icon-camera'"
                        :data-id="Item.id"
                        style="padding: 8px 16px 8px 70px; width: 164px"
                      >
                        <!-- <div class="noToolBlueHollow" style="margin-left:50px;"></div> -->
                        <span
                          :data-id="Item.id"
                          :class="
                            selectMulu == Item.id
                              ? 'text text-other text-select'
                              : 'text text-other'
                          "
                          >{{ languageData.camera_card }}</span
                        >
                      </div>
                    </a>
                  </template>
                </template>
              </template>
            </div>
          </div>

          <div
            class="right-side"
            id="scroll-box"
            :style="
              fullscreen ? 'height:100%;background:none;' : 'background:none;'
            "
          >
            <div
              v-if="stageDatas.length != 0"
              style="
                background: var(--font-icon-white, #fff);
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 68px;
                border-bottom: 1px solid #e7e7e7;
              "
            >
              <div class="stageTitle">
                <div class="stageItemTitle">
                  <div>{{ languageData.stageName }}{{ stageIndex }}:</div>
                  <div
                    class="el-tooltip"
                    aria-describedby="el-tooltip-4036"
                    tabindex="0"
                  >
                    {{ stageDatas[stageIndex - 1].properties.stageName }}
                  </div>
                </div>
                <!-- <div style="width: 40%;text-align: right;">
                      <el-button type="text" @click="pre_stage" size="medium" style="color:#0061ff;"
                        v-if="stageDatas.length != 0 && stageIndex != 1" :title="languageData.pre_stage">{{ languageData.pre_stage }}</el-button>
                      <el-button type="text" @click="next_stage" size="medium" style="color:#0061ff;"
                        v-if="stageDatas.length != 0 && stageIndex != stageDatas.length" :title="languageData.next_page">{{ languageData.next_page }}</el-button>
                    </div> -->
              </div>
            </div>
            <div
              v-if="dataList.length != 0"
              id="scroll-box2"
              :style="
                stageDatas.length != 0
                  ? 'margin-top:16px;margin-bottom: 100px;overflow-y: auto;height: calc(100% - 89px);padding:0 16px 0 16px;'
                  : 'margin-bottom: 100px;overflow-y: auto;height: calc(100% - 16px);padding:0 16px 0 16px;'
              "
            >
              <template v-for="(item, i) in newDataList">
                <div
                  v-bind:key="'newDataList' + i"
                  style="
                    border-radius: 6px;
                    background: var(--font-icon-white, #fff);
                    margin-bottom: 16px;
                    padding: 16px 0px;
                  "
                >
                  <template v-for="(Item, index) in item.nodes">
                    <div
                      :id="'id_' + Item.id"
                      style="height: 0"
                      v-bind:key="'title' + i + index"
                    ></div>
                    <div v-bind:key="'Title' + i + index">
                      <div
                        class="right_top"
                        :id="'right_top_' + i + '_' + index"
                        v-if="Item.type == 'icon-task'"
                        style="position: relative"
                      >
                        <div
                          style="
                            line-height: 1;
                            width: 100%;
                            margin-bottom: 8px;
                          "
                        >
                          <span
                            class="right_top_title"
                            style="line-height: 24px; font-size: 16px"
                            >{{ languageData.taskTip }}
                            {{
                              Item.properties.taskTitle
                                ? Item.properties.taskTitle
                                : ""
                            }}</span
                          >
                          <span
                            v-if="
                              Item.children.length > 1 &&
                              Item.type != 'icon-task'
                            "
                            style="float: right; margin-top: -10px"
                          >
                            <el-button
                              type="warning"
                              circle
                              style="height: 30px; width: 30px; padding: 8px"
                              :title="languageData.tip19"
                              @click="selectPath(Item.children)"
                            >
                              <img
                                src="../../assets/next.png"
                                style="
                                  cursor: pointer;
                                  width: 12px;
                                  height: 12px;
                                "
                              />
                            </el-button>
                          </span>
                        </div>
                        <div
                          v-if="Item.properties.taskContent"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: column;justify-content: center;height: 90%;'
                              : 'display:flex;flex-direction: column;justify-content: center;height: 95%;'
                          "
                        >
                          <div :id="'card_' + i + '_' + index">
                            <span
                              v-html="Item.properties.taskContent"
                              style="line-height: 1.5"
                            ></span>
                          </div>
                        </div>
                        <div
                          class="handleIframe"
                          draggable="false"
                          @mousedown="handleMousedown"
                        ></div>
                      </div>
                      <div
                        class="right_top"
                        :id="'right_top_' + i + '_' + index"
                        v-if="Item.type == 'icon-card'"
                        style="position: relative"
                      >
                        <div
                          style="
                            line-height: 1;
                            width: 100%;
                            margin-bottom: 8px;
                          "
                        >
                          <span class="right_top_title">{{
                            Item.properties.title ? Item.properties.title : ""
                          }}</span>
                          <span
                            v-if="Item.children.length > 1"
                            style="float: right; margin-top: -10px"
                          >
                            <el-button
                              type="warning"
                              circle
                              style="height: 30px; width: 30px; padding: 8px"
                              :title="languageData.tip19"
                              @click="selectPath(Item.children)"
                            >
                              <img
                                src="../../assets/next.png"
                                style="
                                  cursor: pointer;
                                  width: 12px;
                                  height: 12px;
                                "
                              />
                            </el-button>
                          </span>
                        </div>
                        <div
                          v-if="Item.properties.describe"
                          style="margin-top: 23px"
                        >
                          <span class="text" style="font-size: 14px">{{
                            Item.properties.describe
                          }}</span>
                        </div>
                        <div
                          v-if="Item.properties.radio == 'content'"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: column;justify-content: center;height: 90%;'
                              : 'display:flex;flex-direction: column;justify-content: center;height: 95%;'
                          "
                        >
                          <div :id="'card_' + i + '_' + index">
                            <span
                              v-html="Item.properties.content"
                              style="line-height: 1.5"
                            ></span>
                          </div>
                        </div>
                        <div
                          v-else-if="Item.properties.radio == 'iframeUrl'"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <iframe
                            style="width: 100%; height: 100%; min-height: 300px"
                            :src="
                              Item.properties.iframeUrl.indexOf('https://') !=
                                -1 ||
                              Item.properties.iframeUrl.indexOf('http://') != -1
                                ? Item.properties.iframeUrl
                                : '//' + Item.properties.iframeUrl
                            "
                            frameborder="no"
                            border="0"
                            sandbox="allow-forms allow-scripts allow-same-origin"
                          ></iframe>
                        </div>
                        <div
                          v-else-if="Item.properties.radio == 'embedCode'"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;height: 85%;'
                              : 'display:flex;flex-direction: row;justify-content: center;height: 95%;'
                          "
                          v-html="Item.properties.embedCode"
                        ></div>
                        <div
                          v-else-if="Item.properties.radio == 'imageList'"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="
                              Item.properties.imageList &&
                              Item.properties.imageList.length > 0
                                ? Item.properties.imageList[0].url
                                : ''
                            "
                          />
                        </div>
                        <div
                          v-else-if="Item.properties.radio == 'videoList'"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <video
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            controls
                            :src="
                              Item.properties.videoList &&
                              Item.properties.videoList.length > 0
                                ? Item.properties.videoList[0].url
                                : ''
                            "
                          ></video>
                        </div>
                        <div
                          v-else-if="Item.properties.radio == 'audioList'"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <audio
                            controls
                            :src="
                              Item.properties.audioList &&
                              Item.properties.audioList.length > 0
                                ? Item.properties.audioList[0].url
                                : ''
                            "
                          ></audio>
                        </div>
                        <div
                          v-else-if="
                            Item.properties.radio == 'fileList' &&
                            Item.properties.fileList &&
                            Item.properties.fileList.length != 0 &&
                            (Item.properties.fileList[0].url.indexOf('.pdf') !=
                              -1 ||
                              Item.properties.fileList[0].url.indexOf('.PDF') !=
                                -1)
                          "
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <iframe
                            style="width: 100%; height: 100%; min-height: 800px"
                            frameborder="0"
                            :src="
                              'https://cloud.cocorobo.cn/pdf.js/web/viewer.html?file=' +
                              Item.properties.fileList[0].url
                            "
                          ></iframe>
                        </div>
                        <div
                          v-else-if="
                            Item.properties.radio == 'fileList' &&
                            Item.properties.fileList &&
                            Item.properties.fileList.length != 0 &&
                            Item.properties.fileList[0].url.indexOf('.pdf') ==
                              -1 &&
                            Item.properties.fileList[0].url.indexOf('.PDF') ==
                              -1
                          "
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <iframe
                            style="width: 100%; height: 100%; min-height: 800px"
                            frameborder="0"
                            :src="
                              'https://view.officeapps.live.com/op/view.aspx?src=' +
                              Item.properties.fileList[0].url
                            "
                          ></iframe>
                        </div>
                        <div
                          class="handleIframe"
                          draggable="false"
                          @mousedown="handleMousedown"
                        ></div>
                      </div>
                      <div
                        class="right_top"
                        :id="'right_top_' + i + '_' + index"
                        v-if="Item.type == 'icon-thinking'"
                        style="position: relative"
                      >
                        <div
                          style="
                            line-height: 1;
                            width: 100%;
                            margin-bottom: 8px;
                          "
                        >
                          <span class="right_top_title">{{
                            Item.properties.thinkingTitle
                              ? Item.properties.thinkingTitle
                              : languageData.thinkingCard
                          }}</span>
                          <span
                            v-if="Item.children.length > 1"
                            style="float: right; margin-top: -10px"
                          >
                            <el-button
                              type="warning"
                              circle
                              style="height: 30px; width: 30px; padding: 8px"
                              :title="languageData.tip19"
                              @click="selectPath(Item.children)"
                            >
                              <img
                                src="../../assets/next.png"
                                style="
                                  cursor: pointer;
                                  width: 12px;
                                  height: 12px;
                                "
                              />
                            </el-button>
                          </span>
                        </div>
                        <div
                          v-if="Item.properties.describe"
                          style="margin-top: 10px"
                        >
                          <span class="text" style="font-size: 14px">{{
                            Item.properties.describe
                          }}</span>
                        </div>
                        <div
                          v-if="Item.properties.thinkingContent"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="Item.properties.thinkingContent"
                          />
                        </div>
                        <div
                          v-else
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="width: 300px; height: 300px"
                            src="https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png"
                          />
                        </div>
                        <div
                          class="handleIframe"
                          draggable="false"
                          @mousedown="handleMousedown"
                        ></div>
                      </div>
                      <div
                        class="right_bottom"
                        v-if="Item.type == 'icon-select'"
                      >
                        <span class="right_top_title">{{
                          Item.properties.selectTitle
                            ? Item.properties.selectTitle
                            : ""
                        }}</span>
                        <div
                          v-if="Item.type == 'icon-select'"
                          v-bind:key="'select' + index"
                          style="
                            margin-top: 30px;
                            background: #f5f7fb;
                            border-radius: 10px;
                            padding-top: 20px;
                            padding-left: 20px;
                          "
                        >
                          <div>{{ Item.properties.selectContent }}</div>
                          <div style="margin-top: 10px">
                            <template
                              v-for="(option, index) in Item.properties
                                .selectOption"
                            >
                              <el-radio
                                v-bind:key="'letterList' + index"
                                v-model="Radio"
                                :label="option"
                                style="margin-right: 30px; margin-bottom: 20px"
                                @input="setSelect"
                              >
                                {{ letterList[index] }}.
                                {{ option }}
                              </el-radio>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div
                        class="right_top"
                        :id="'right_top_' + i + '_' + index"
                        v-if="Item.type == 'icon-AI'"
                        style="position: relative; height: auto"
                      >
                        <div
                          style="
                            line-height: 1;
                            width: 100%;
                            margin-bottom: 8px;
                          "
                        >
                          <span class="right_top_title">{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span>
                          <span
                            v-if="Item.children.length > 1"
                            style="float: right; margin-top: -10px"
                          >
                            <el-button
                              type="warning"
                              circle
                              style="height: 30px; width: 30px; padding: 8px"
                              :title="languageData.tip19"
                              @click="selectPath(Item.children)"
                            >
                              <img
                                src="../../assets/next.png"
                                style="
                                  cursor: pointer;
                                  width: 12px;
                                  height: 12px;
                                "
                              />
                            </el-button>
                          </span>
                        </div>
                        <div
                          v-if="
                            Item.properties.radio == 'description' &&
                            Item.properties.aiContent
                          "
                        >
                          <span
                            style="font-size: 16px; line-height: 1.5"
                            class="span_ai"
                            v-html="Item.properties.aiContent"
                          ></span>
                        </div>
                        <div
                          v-else-if="
                            Item.properties.radio == 'image' &&
                            Item.properties.imgResult != ''
                          "
                          :style="
                            Item.properties.imgResult
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="Item.properties.imgResult"
                          />
                        </div>
                        <div
                          class="handleIframe"
                          draggable="false"
                          @mousedown="handleMousedown"
                        ></div>
                      </div>
                      <div
                        class="right_top"
                        :id="'right_top_' + i + '_' + index"
                        v-if="Item.type == 'icon-new-AI'"
                        style="position: relative; height: auto"
                      >
                        <div
                          style="
                            line-height: 1;
                            width: 100%;
                            margin-bottom: 8px;
                          "
                        >
                          <span class="right_top_title">{{
                            Item.properties.topic ? Item.properties.topic : ""
                          }}</span>
                          <span
                            v-if="Item.children.length > 1"
                            style="float: right; margin-top: -10px"
                          >
                            <el-button
                              type="warning"
                              circle
                              style="height: 30px; width: 30px; padding: 8px"
                              :title="languageData.tip19"
                              @click="selectPath(Item.children)"
                            >
                              <img
                                src="../../assets/next.png"
                                style="
                                  cursor: pointer;
                                  width: 12px;
                                  height: 12px;
                                "
                              />
                            </el-button>
                          </span>
                        </div>
                        <div
                          v-if="
                            Item.properties.radio == 'description' &&
                            Item.properties.aiContent
                          "
                        >
                          <span
                            style="font-size: 16px; line-height: 1.5"
                            class="span_ai"
                            v-html="Item.properties.aiContent"
                          ></span>
                        </div>
                        <div
                          v-else-if="
                            Item.properties.radio == 'image' &&
                            Item.properties.imgResult != ''
                          "
                          :style="
                            Item.properties.imgResult
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="Item.properties.imgResult"
                          />
                        </div>
                        <div
                          v-else-if="
                            Item.properties.radio == 'audio' &&
                            Item.properties.audioList.length != 0
                          "
                          :style="
                            Item.properties.audioList.length != 0
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <audio
                            style="max-width: 95%; max-height: 95%"
                            :src="Item.properties.audioList[0].url"
                            controls
                          ></audio>
                        </div>
                        <div
                          class="handleIframe"
                          draggable="false"
                          @mousedown="handleMousedown"
                        ></div>
                      </div>
                      <div
                        class="right_top"
                        :id="'right_top_' + i + '_' + index"
                        v-if="Item.type == 'icon-whiteboard'"
                        style="position: relative; height: auto"
                      >
                        <div
                          style="
                            line-height: 1;
                            width: 100%;
                            margin-bottom: 8px;
                          "
                        >
                          <span class="right_top_title">{{
                            Item.properties.whiteboardTitle
                              ? Item.properties.whiteboardTitle
                              : languageData.whiteboard_card
                          }}</span>
                          <span
                            v-if="Item.children.length > 1"
                            style="float: right; margin-top: -10px"
                          >
                            <el-button
                              type="warning"
                              circle
                              style="height: 30px; width: 30px; padding: 8px"
                              :title="languageData.tip19"
                              @click="selectPath(Item.children)"
                            >
                              <img
                                src="../../assets/next.png"
                                style="
                                  cursor: pointer;
                                  width: 12px;
                                  height: 12px;
                                "
                              />
                            </el-button>
                          </span>
                        </div>
                        <div
                          v-if="Item.properties.whiteboardContent"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="Item.properties.whiteboardContent"
                          />
                        </div>
                        <div v-else>
                          <span
                            style="font-size: 16px; line-height: 1.5"
                            class="span_ai"
                            >{{ languageData.tip76 }}</span
                          >
                        </div>
                      </div>

                      <div
                        class="right_top"
                        :id="'right_top_' + i + '_' + index"
                        v-if="Item.type == 'icon-map'"
                        style="position: relative; height: auto"
                      >
                        <div
                          style="
                            line-height: 1;
                            width: 100%;
                            margin-bottom: 8px;
                          "
                        >
                          <span class="right_top_title">{{
                            languageData.map_card
                          }}</span>
                          <span
                            v-if="Item.children.length > 1"
                            style="float: right; margin-top: -10px"
                          >
                            <el-button
                              type="warning"
                              circle
                              style="height: 30px; width: 30px; padding: 8px"
                              :title="languageData.tip19"
                              @click="selectPath(Item.children)"
                            >
                              <img
                                src="../../assets/next.png"
                                style="
                                  cursor: pointer;
                                  width: 12px;
                                  height: 12px;
                                "
                              />
                            </el-button>
                          </span>
                        </div>
                        <div
                          v-if="Item.properties.map_url"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="Item.properties.map_url"
                          />
                        </div>
                      </div>
                      <div
                        class="right_top"
                        :id="'right_top_' + i + '_' + index"
                        v-if="Item.type == 'icon-camera'"
                        style="position: relative; height: auto"
                      >
                        <div
                          style="
                            line-height: 1;
                            width: 100%;
                            margin-bottom: 8px;
                          "
                        >
                          <span class="right_top_title">{{
                            languageData.camera_card
                          }}</span>
                          <span
                            v-if="Item.children.length > 1"
                            style="float: right; margin-top: -10px"
                          >
                            <el-button
                              type="warning"
                              circle
                              style="height: 30px; width: 30px; padding: 8px"
                              :title="languageData.tip19"
                              @click="selectPath(Item.children)"
                            >
                              <img
                                src="../../assets/next.png"
                                style="
                                  cursor: pointer;
                                  width: 12px;
                                  height: 12px;
                                "
                              />
                            </el-button>
                          </span>
                        </div>
                        <div
                          v-if="Item.properties.src"
                          :style="
                            Item.properties.describe
                              ? 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 90%;'
                              : 'display:flex;flex-direction: row;justify-content: center;align-items: center;height: 95%;'
                          "
                        >
                          <img
                            style="
                              width: auto;
                              height: auto;
                              max-width: 95%;
                              max-height: 95%;
                            "
                            :src="Item.properties.src"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
            <!-- <div v-if="dataList.length != 0">
                                  <template v-for="(Item, index) in dataList[currentIndex].nodes">
                                      <div v-bind:key="index">
                                          <p :id="Item.id"></p>
                                          
                                      </div>
                                  </template>
  
                              </div> -->
          </div>

          <!-- <div v-if="isAnswer" class="right_answer">
                              <div v-if="answerContent.radio == 'content'" style="margin-top: 10px;">
                                  <span class="text">{{ answerContent.content }}</span>
                              </div>
                              <div v-else-if="answerContent.radio == 'iframeUrl'" style="margin-top: 10px;">
                                  <iframe style="width: 90%;height: 550px;" :src="'//' + answerContent.iframeUrl"
                                      frameborder="no" border="0"></iframe>
                              </div>
                              <div v-else-if="answerContent.radio == 'imageList'"
                                  style="margin-top: 10px;display: flex;justify-content: center;">
                                  <img width="600" height="300" :src="answerContent.imageList[0].url" />
                              </div>
                              <div v-else-if="answerContent.radio == 'videoList'"
                                  style="margin-top: 10px;display: flex;justify-content: center;">
                                  <video width="600" height="300" controls :src="answerContent.videoList[0].url"></video>
                              </div>
                              <div v-else-if="answerContent.radio == 'audioList'">
                                  <audio controls :src="answerContent.audioList[0].url"></audio>
                              </div>
                          </div> -->
        </div>
      </div>
    </div>
    <div id="select" style="display:none;">
      <el-dialog
        :visible.sync="selectDialogVisible"
        width="30%"
        :show-close="false"
        :close-on-press-escape="false"
      >
        <span slot="title">
          <span class="el-dialog__title">{{ languageData.tip19 }}</span>
          <i
            class="el-icon-close"
            @click="handleNextClose"
            style="cursor: pointer; float: right"
          ></i>
        </span>
        <span style="display: flex; flex-wrap: wrap">
          <template v-for="(item, index) in selectList">
            <el-radio
              v-model="selectRadio"
              :label="index"
              v-bind:key="index"
              @input="selectWay"
              style="margin-right: 8px; margin-bottom: 10px"
              :title="
                item.type == 'icon-card'
                  ? item.properties.title
                  : item.type == 'icon-select'
                  ? item.properties.selectTitle
                  : item.type == 'icon-thinking'
                  ? item.properties.thinkingTitle
                  : item.type == 'icon-AI'
                  ? item.properties.topic
                  : item.type == 'icon-new-AI'
                  ? item.properties.topic
                  : ''
              "
            >
              {{
                item.type == "icon-card"
                  ? item.properties.title
                  : item.type == "icon-select"
                  ? item.properties.selectTitle
                  : item.type == "icon-thinking"
                  ? item.properties.thinkingTitle
                  : item.type == "icon-AI"
                  ? item.properties.topic
                  : item.type == "icon-new-AI"
                  ? item.properties.topic
                  : ""
              }}</el-radio
            >
          </template>
        </span>
      </el-dialog>
    </div>
    <div v-if="isShowMulu" class="mulu2">
      <DemonstrateMuluDialog :isShowMulu="isShowMulu" @setIsShowMulu="setIsShowMulu" :navigationList="navigationList" :themeName="themeName" :selectMulu="selectMulu" @setSelectMulu="setSelectMulu"></DemonstrateMuluDialog>
    </div>
    <!-- 弹出层，用于显示放大的图片 -->
    <div id="myModal" class="myModal" v-if="isPhone">
      <span class="myclose" @click="closeModal">&times;</span>
      <div style="display: flex;max-width: 100%;">
        <img class="modal-content" id="img01" :style="{'transform': 'translate('+ posX + 'px,' + posY + 'px) translateZ(0px) scale(' + dis + ')'}">
      </div>
    </div>
  </div>
</template>
  
  <script>
import $ from "jquery";
import DemonstrateMuluDialog from '../dialog/DemonstrateMuluDialog.vue'
import { Loading } from "element-ui";
//import { utils } from "xlsx";
import AlloyFinger from 'alloyfinger';
export default {
  props: {
    list: Array,
    sourceList: Array,
    // selectIndex: Array,
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    isHomeWork: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      letterList: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      isShowMulu: false,
      Radio: "",
      selectRadio: "",
      isAnswer: false,
      selectDialogVisible: false,
      selectList: [],
      selectMulu: "",
      answerContent: {},
      currentIndex: 0,
      dataList: [],
      dataList_new: [],
      nodes: [],
      selectIds: [],
      stageDatas: [],
      taskData: [],
      navigationList: [],
      navigationListNew: [],
      hoverMax: false,
      hoverRecovery: false,
      hoverDownload: false,
      hoverClose: false,
      fullscreen: false,
      // disX: 0,
      disY: 0,
      down: false,
      offsetTop: 0,
      languageData: {},
      value: "",
      stageIndex: 0,
      themeName: "",
      isRun: true,
      isImmersive: true,
      newDataList: [],
      DemoLists: [],
      hide: false,
      isPhone: false,
      screenWidth: null,
      posX: 0,
      posY: 0,
      dis: 1,
    };
  },
  watch: {
    list() {
      if (this.list.length != 0) {
        // console.log(this.list);
        $(".el-dialog").css({
          height: "auto",
          width: "59%",
        });
        var a = [];
        this.nodes = [];
        this.selectIds = [];
        this.stageDatas = [];
        this.taskData = [];
        this.isRun = true;
        this.isImmersive = true;
        this.dataList_new = [];
        this.getSelectIds(this.list[0].children[0].children[0].children);
        this.getStageDatas(this.list[0].children[0].children);
        if (this.stageDatas.length == 0) {
          this.getTaskData(this.list[0].children[0].children);
        }
        if (this.list[0].children.length != 0 && this.stageDatas.length != 0) {
          this.nodes.push(this.list[0].children[0]);
          if (this.list[0].children[0].children.length != 0) {
            this.nodes.push(this.list[0].children[0].children[0]);
            for (
              let i = 0;
              i < this.list[0].children[0].children[0].children.length;
              i++
            ) {
              if (
                this.list[0].children[0].children[0].children[i].type !=
                "icon-stage"
              ) {
                this.nodes.push(
                  this.list[0].children[0].children[0].children[i]
                );
                if (
                  this.list[0].children[0].children[0].children[i].children
                    .length == 1
                ) {
                  this.getSomeStageData(
                    this.list[0].children[0].children[0].children[i].children,
                    this.list[0].children[0].children[0].children[i].id
                  );
                } else if (
                  this.list[0].children[0].children[0].children[i].children
                    .length == 2
                ) {
                  this.getTaskData(
                    this.list[0].children[0].children[0].children[i].children
                  );
                  // console.log(this.taskData)
                  for (
                    let j = 0;
                    j <
                    this.list[0].children[0].children[0].children[i].children
                      .length;
                    j++
                  ) {
                    if (
                      this.list[0].children[0].children[0].children[i].children[
                        j
                      ].type != "icon-task"
                    ) {
                      this.nodes.push(
                        this.list[0].children[0].children[0].children[i]
                          .children[j]
                      );
                      let flag = this.getSomeStageData(
                        this.list[0].children[0].children[0].children[i]
                          .children[j].children,
                        this.list[0].children[0].children[0].children[i]
                          .children[j].id
                      );
                      if (flag) {
                        this.isRun = false;
                      }
                      let taskFlag = this.getSomeTaskData(
                        this.list[0].children[0].children[0].children[i]
                          .children[j].children,
                        this.list[0].children[0].children[0].children[i]
                          .children[j].id
                      );
                      if (taskFlag) {
                        this.isRun = false;
                      }
                      break;
                    }
                  }
                }
                break;
              }
            }
            if (this.taskData.length != 0) {
              for (let i = 0; i < this.taskData.length; i++) {
                this.nodes.push(this.taskData[i]);
                for (let j = 0; j < this.taskData[i].children.length; j++) {
                  if (this.taskData[i].children[j].type != "icon-task") {
                    this.nodes.push(this.taskData[i].children[j]);
                    let flag = this.getSomeStageData(
                      this.taskData[i].children[j].children,
                      this.taskData[i].children[j].id
                    );
                    if (flag) {
                      this.isRun = false;
                      break;
                    }
                    let taskFlag = this.getSomeTaskData(
                      this.taskData[i].children[j].children,
                      this.taskData[i].children[j].id
                    );
                    if (taskFlag) {
                      this.isRun = false;
                      break;
                    }
                  }
                }
              }
            }
          }
        } else if (
          this.list[0].children.length != 0 &&
          this.stageDatas.length == 0
        ) {
          this.nodes.push(this.list[0].children[0]);
          if (this.list[0].children[0].children.length != 0) {
            this.nodes.push(this.list[0].children[0].children[0]);
            if (this.list[0].children[0].children[0].children.length == 1) {
              this.getSomeStageData(
                this.list[0].children[0].children[0].children,
                this.list[0].children[0].children[0].id
              );
            } else if (
              this.list[0].children[0].children[0].children.length == 2
            ) {
              for (
                let i = 0;
                i < this.list[0].children[0].children[0].children.length;
                i++
              ) {
                if (
                  this.list[0].children[0].children[0].children[i].type !=
                  "icon-task"
                ) {
                  this.nodes.push(
                    this.list[0].children[0].children[0].children[i]
                  );
                  let taskFlag = this.getSomeTaskData(
                    this.list[0].children[0].children[0].children[i].children,
                    this.list[0].children[0].children[0].children[i].id
                  );
                  if (taskFlag) {
                    this.isRun = false;
                  }
                  break;
                }
              }
              if (this.taskData.length != 0) {
                for (let i = 1; i < this.taskData.length; i++) {
                  this.nodes.push(this.taskData[i]);
                  for (let j = 0; j < this.taskData[i].children.length; j++) {
                    if (this.taskData[i].children[j].type != "icon-task") {
                      this.nodes.push(this.taskData[i].children[j]);
                      let taskFlag = this.getSomeTaskData(
                        this.taskData[i].children[j].children,
                        this.taskData[i].children[j].id
                      );
                      if (taskFlag) {
                        this.isRun = false;
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if (this.isRun) {
          //console.log(this.nodes)
          let navigationList = [];
          navigationList[0] = [];
          this.dataList = [];
          for (let i = 0; i < this.nodes.length; i++) {
            if (
              this.nodes[i] != undefined &&
              this.nodes[i].type &&
              this.nodes[i].type == "icon-select"
            ) {
              a.push(this.nodes[i]);
              navigationList[0].push(this.nodes[i]);
              this.dataList.push({ nodes: a });
              break;
            } else if (
              this.nodes[i] != undefined &&
              i == this.nodes.length - 1 &&
              this.nodes[i].type != "icon-select"
            ) {
              a.push(this.nodes[i]);
              navigationList[0].push(this.nodes[i]);
              this.dataList.push({ nodes: a });
            } else if (
              this.nodes[i] == undefined &&
              i == this.nodes.length - 1
            ) {
              this.dataList.push({ nodes: a });
            } else if (
              this.nodes[i].type != "icon-start" &&
              this.nodes[i].type != "icon-theme" &&
              this.nodes[i].type != "icon-stage"
            ) {
              a.push(this.nodes[i]);
              navigationList[0].push(this.nodes[i]);
            } else if (this.nodes[i].type == "icon-stage") {
              navigationList[0].push(this.nodes[i]);
            }
          }
          this.currentIndex = this.dataList[0].nodes.length - 1;
          if (this.dataList.length != 0 && !this.isPhone) {
            this.selectMulu = this.dataList[0].nodes[0].id;
          }
          if(this.isPhone) {
            if(this.stageDatas.length != 0) {
              this.selectMulu = this.stageDatas[0].id;
            }
            else {
              // console.log(navigationList)
              this.selectMulu = navigationList[0][0].id;
            }
          }
          this.selectList =
            this.dataList[this.dataList.length - 1].nodes[0].children;
          if (this.stageDatas.length != 0) {
            this.value = this.stageDatas[0].properties.stageName;
            this.stageIndex = 1;
          }
          if (this.nodes[0].type == "icon-theme") {
            this.themeName = this.nodes[0].properties.topic;
          }
          this.dataList_new.push(this.dataList)
          // document.getElementById(this.dataList[0].nodes[0].id).scrollIntoView(true);
          // console.log(this.dataList);
          // console.log(this.navigationList)
          this.DemoLists = navigationList[0];
          if (this.stageDatas.length != 0) {
            for (let i = 1; i < this.stageDatas.length; i++) {
              this.exportProcessData(i);
              navigationList[i] = this.navigationListNew;
              this.DemoLists = this.DemoLists.concat(this.navigationListNew);
              if(this.isPhone) {
                this.stageIndex = this.stageIndex + 1;
                this.processData();
              }
            }
            let navigationListNew = [];
            let taskData = [];
            let otherData = [];
            let taskIndex = 0;
            for (let i = 0; i < navigationList.length; i++) {
              for (let j = 0; j < navigationList[i].length; j++) {
                if (navigationList[i][j].type == "icon-stage") {
                  navigationList[i][j].child = [];
                  navigationList[i][j].isFold = true;
                  navigationListNew[i] = navigationList[i][j];
                }
                if (j == 1 && navigationList[i][j].type == "icon-task") {
                  taskData = [];
                  otherData = [];
                  taskIndex = 0;
                  navigationList[i][j].isFold = true;
                  taskData.push(navigationList[i][j]);
                  if (j == navigationList[i].length - 1) {
                    taskData[taskIndex].child = otherData;
                    navigationListNew[i].child = taskData;
                  }
                }
                if (j != 1 && navigationList[i][j].type == "icon-task") {
                  taskData[taskIndex].child = otherData;
                  navigationList[i][j].isFold = true;
                  taskData.push(navigationList[i][j]);
                  taskIndex = taskIndex + 1;
                  otherData = [];
                  if (j == navigationList[i].length - 1) {
                    taskData[taskIndex].child = otherData;
                    navigationListNew[i].child = taskData;
                  }
                } else if (
                  navigationList[i][j].type != "icon-task" &&
                  navigationList[i][j].type != "icon-stage"
                ) {
                  otherData.push(navigationList[i][j]);
                  if (j == navigationList[i].length - 1) {
                    taskData[taskIndex].child = otherData;
                    navigationListNew[i].child = taskData;
                  }
                }
              }
            }
            this.navigationList = navigationListNew;
          } else {
            let navigationListNew = [];
            let taskData = [];
            let otherData = [];
            let taskIndex = 0;
            navigationListNew[0] = {
              child: [],
              isFold: true,
            };
            if (navigationList[0][0].type == "icon-task") {
              for (let i = 0; i < navigationList.length; i++) {
                for (let j = 0; j < navigationList[i].length; j++) {
                  if (j == 0 && navigationList[i][j].type == "icon-task") {
                    taskData = [];
                    otherData = [];
                    taskIndex = 0;
                    navigationList[i][j].isFold = true;
                    taskData.push(navigationList[i][j]);
                    if (j == navigationList[i].length - 1) {
                      taskData[taskIndex].child = otherData;
                      navigationListNew[i].child = taskData;
                    }
                  }
                  if (j != 0 && navigationList[i][j].type == "icon-task") {
                    taskData[taskIndex].child = otherData;
                    navigationList[i][j].isFold = true;
                    taskData.push(navigationList[i][j]);
                    taskIndex = taskIndex + 1;
                    otherData = [];
                    if (j == navigationList[i].length - 1) {
                      taskData[taskIndex].child = otherData;
                      navigationListNew[i].child = taskData;
                    }
                  } else if (navigationList[i][j].type != "icon-task") {
                    otherData.push(navigationList[i][j]);
                    if (j == navigationList[i].length - 1) {
                      taskData[taskIndex].child = otherData;
                      navigationListNew[i].child = taskData;
                    }
                  }
                }
                this.navigationList = navigationListNew;
              }
            } else {
              otherData = [];
              navigationListNew[0].child[0] = {
                child: [],
                isFold: true,
              };
              for (let i = 0; i < navigationList.length; i++) {
                for (let j = 0; j < navigationList[i].length; j++) {
                  if (navigationList[i][j].type != "icon-task") {
                    otherData.push(navigationList[i][j]);
                    if (j == navigationList[i].length - 1) {
                      navigationListNew[0].child[0].child = otherData;
                    }
                  }
                }
                this.navigationList = navigationListNew;
              }
            }
          }
        } else {
          this.handleClose();
          this.$message.error(this.languageData.runTip2);
        }
      }
    },
    dataList() {
      if (this.dataList.length != 0) {
        // console.log(this.dataList)
        // console.log(this.navigationList);
        this.newDataList = [];
        let nodes = [];
        for (let i = 0; i < this.dataList.length; i++) {
          for (let j = 0; j < this.dataList[i].nodes.length; j++) {
            if (
              this.dataList[i].nodes[j].type == "icon-task" &&
              j != 0 &&
              j != this.dataList[i].nodes.length - 1
            ) {
              this.newDataList.push({ nodes: nodes });
              nodes = [];
              nodes.push(this.dataList[i].nodes[j]);
            } else if (
              this.dataList[i].nodes[j].type == "icon-task" &&
              j == 0
            ) {
              nodes.push(this.dataList[i].nodes[j]);
            } else if (
              this.dataList[i].nodes[j].type == "icon-task" &&
              j == this.dataList[i].nodes.length - 1
            ) {
              this.newDataList.push({ nodes: nodes });
              nodes = [];
              nodes.push(this.dataList[i].nodes[j]);
              this.newDataList.push({ nodes: nodes });
            } else if (
              this.dataList[i].nodes[j].type != "icon-task" &&
              j != this.dataList[i].nodes.length - 1
            ) {
              nodes.push(this.dataList[i].nodes[j]);
            } else if (
              this.dataList[i].nodes[j].type != "icon-task" &&
              j == this.dataList[i].nodes.length - 1
            ) {
              nodes.push(this.dataList[i].nodes[j]);
              this.newDataList.push({ nodes: nodes });
            }
          }
        }
        // console.log(this.newDataList)
        this.$nextTick(() => {
          // $("#leftSide").css({
          //     "height": parseInt(window.innerHeight * 0.64).toString() + "px"
          // })
          // $("#scroll-box").css({
          //     "height": parseInt(window.innerHeight * 0.68).toString() + "px"
          // })
          // let fontsize = '';
          for (let i = 0; i < this.dataList.length; i++) {
            for (let j = 0; j < this.dataList[i].nodes.length; j++) {
              // if (this.dataList[i].nodes[j].type == "icon-card") {
              //     if (this.dataList[i].nodes[j].properties.radio == 'content') {
              //         let len = this.dataList[i].nodes[j].properties.contentLength;
              //         if (0 < len && len == 1) {
              //             fontsize = "160px";
              //         }
              //         else if (1 < len && len <= 3) {
              //             fontsize = "80px";
              //         }
              //         else if (3 < len && len <= 5) {
              //             fontsize = "40px";
              //         }
              //         else if (5 < len && len <= 7) {
              //             fontsize = "20px";
              //         }
              //         else if (7 < len && len <= 9) {
              //             fontsize = "12px";
              //         }
              //         $("#card_" + i + "_" + j + " p").css("font-size", fontsize)
              //         if (fontsize == "12px") {
              //             $("#card_" + i + "_" + j + " p").css("-webkit-transform", "scale(0.83)")
              //         }
              //     }
              // }
              $("#right_top_" + i + "_" + j).on("mouseup", () => {
                this.down = false;
                document.onmousemove = null;
                document.onmousedown = null;
                document.onmouseup = null;
              });
              $("#right_top_" + i + "_" + j).on("mousemove", (e) => {
                e.preventDefault();
                // 获取鼠标的实时位置
                const curY = e.clientY;
                // 获取拖拽的长度
                const increaseY = curY - this.disY;
                // 按下开始拖拽改变目标元素大小
                if (this.down) {
                  const height =
                    e.currentTarget.children[
                      e.currentTarget.children.length - 1
                    ].offsetHeight + increaseY;
                  $(e.currentTarget).css({
                    height: height < 400 ? "400px" : height + "px",
                  });
                }
              });
            }
          }
        });
      }
    },
    screenWidth(newVal) {
      if(newVal>960){
        this.isPhone=false;
      }else{
          // this.crossFun(document.getElementById("DemoResult"));
        $("#leftSide").css("display", "none");
        $("#scroll-box").css("width","calc(100%)");
        $("#scroll-box").css("border-bottom-left-radius", "12px");
        this.isPhone=true;
      }
    }
  },
  mounted() {
    //console.log(this.navigationList);
    //console.log(this.list);
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
    this.screenWidth = window.screen.width
    window.onresize = () => {
      return (() => {
          this.screenWidth = window.screen.width
      })()
    }
    if (this.dataList.length != 0) {
      this.$nextTick(() => {
        $(".el-dialog").css({
          width: "59%",
        });
        // $("#leftSide").css({
        //     "height": parseInt(window.innerHeight * 0.64).toString() + "px"
        // })
        // $("#scroll-box").css({
        //     "height": parseInt(window.innerHeight * 0.68).toString() + "px"
        // })
        // let fontsize = '';
        for (let i = 0; i < this.dataList.length; i++) {
          for (let j = 0; j < this.dataList[i].nodes.length; j++) {
            // if (this.dataList[i].nodes[j].type == "icon-card") {
            //     if (this.dataList[i].nodes[j].properties.radio == 'content') {
            //         let len = this.dataList[i].nodes[j].properties.contentLength;
            //         if (0 < len && len == 1) {
            //             fontsize = "160px";
            //         }
            //         else if (1 < len && len <= 3) {
            //             fontsize = "80px";
            //         }
            //         else if (3 < len && len <= 5) {
            //             fontsize = "40px";
            //         }
            //         else if (5 < len && len <= 7) {
            //             fontsize = "20px";
            //         }
            //         else if (7 < len && len <= 9) {
            //             fontsize = "12px";
            //         }
            //         $("#card_" + i + "_" + j + " p").css("font-size", fontsize)
            //         if (fontsize == "12px") {
            //             $("#card_" + i + "_" + j + " p").css("-webkit-transform", "scale(0.83)")
            //         }
            //     }
            // }
            $("#right_top_" + i + "_" + j).on("mouseup", () => {
              this.down = false;
              document.onmousemove = null;
              document.onmousedown = null;
              document.onmouseup = null;
            });
            $("#right_top_" + i + "_" + j).on("mousemove", (e) => {
              e.preventDefault();
              // 获取鼠标的实时位置
              const curY = e.clientY;
              // 获取拖拽的长度
              const increaseY = curY - this.disY;
              // 按下开始拖拽改变目标元素大小
              if (this.down) {
                const height =
                  e.currentTarget.children[e.currentTarget.children.length - 1]
                    .offsetHeight + increaseY;
                $(e.currentTarget).css({
                  height: height < 400 ? "400px" : height + "px",
                });
              }
            });
          }
        }
      });
    }
  },
  methods: {
    getData(el) {
      let that = this;
      let pic= el;
        this.af = new AlloyFinger(pic, {
        pinch: function (evt) {
          console.log("实现缩放");
          that.dis = evt.zoom;
        },
        pressMove: function (evt) {
          console.log("实现移动");
          that.posX += evt.deltaX;
          that.posY += evt.deltaY;
        },
        // tap: function () {
        //   console.log("单击");
        //   //点按触发
        //   that.dis = 1
        // },
        // doubleTap: function () {
        //   console.log("双击");
        //   //双击屏幕触发
        //   that.dis = 2
        // },
      });
    },
    resizeImg(e) {
      // 获取图片和弹出层元素
      var modal = document.getElementById("myModal");
      var el = document.getElementById("img01");
      modal.style.display = "flex";
      el.src = e.target.src;
      // 创建 AlloyFinger 实例并绑定到元素上
      this.getData(el);
    },
    closeModal() {
      var modal = document.getElementById("myModal");
      modal.style.display = "none";
    },
    setIsShowMulu(val) {
      this.isShowMulu = val
    },
    show_mulu() {
      this.isShowMulu = true
    },
    qiehuanCard() {
      this.isImmersive = false;
      this.$nextTick(() => {
        if(!this.isPhone) {
          if (this.hide && !this.isImmersive) {
            $("#aaaa").css("padding", "16px 0 0 0");
          } else {
            $("#aaaa").css("padding", "16px 0 0 16px");
          }
        }
      });
    },
    qiehuanImmersive() {
      this.isImmersive = true;
    },
    // 生成课程
    createCourse() {
      this.$emit("createCourse", "");
    },
    // 生成项目
    createProject() {
      this.$emit("createProject", "");
    },
    createProjection() {
      this.$emit("getThemeName", this.themeName);
      this.$emit("getNavigationList", this.navigationList);
      this.$emit("createDemoList", this.DemoLists);
    },
    // 提交作业
    sumbit_job() {
      let that = this;
      // console.log(this.nodes)
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: that.languageData.jobTip,
      });
      let data = {
        createDate: new Date(),
        createUser:
          window.location.href.indexOf("localhost") != -1 ||
          window.location.href.indexOf("192.168") != -1
            ? "潘炜桐"
            : window.parent.US.userInfo.username,
        nodes: that.nodes,
      };
      if (
        localStorage.getItem("network") == "true" ||
        localStorage.getItem("network") == true
      ) {
        let newData = {
          type: "sumbitJob",
          data: data,
        };
        let params =
          window.location.href.indexOf("localhost") != -1 ||
          window.location.href.indexOf("192.168") != -1
            ? "mode=" +
              [
                "1cf9dc4b-d95f-11ea-af4c-52540005ab01",
                localStorage.getItem("roomId"),
                "cscl",
                encodeURIComponent(encodeURIComponent(JSON.stringify(newData))),
              ].join(",")
            : "mode=" +
              [
                window.parent.US.userInfo.userid,
                localStorage.getItem("roomId"),
                "cscl",
                encodeURIComponent(encodeURIComponent(JSON.stringify(newData))),
              ].join(",");
        that.$ajax
          .post("https://pbl.cocorobo.cn/api/pbl/add_OperationCSCL", params)
          .then((res) => {
            console.log(res);
            that.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close();
            });
            that.$message({
              message: that.languageData.tip44,
              type: "success",
            });
          })
          .catch((err) => {
            console.error(err);
            that.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close();
            });
            that.$message({
              message: err,
              type: "error",
            });
          });
      }
    },
    //导出
    export_to() {
      //txt
      //要保存的字符串
      //console.log(this.navigationList)
      let stringData = "";
      if (this.stageDatas.length != 0) {
        stringData = this.themeName + "\n";
        for (let i = 0; i < this.stageDatas.length; i++) {
          this.exportProcessData(i);
          let taskNum = 0;
          this.navigationListNew.forEach((item) => {
            switch (item.type) {
              case "icon-stage":
                stringData +=
                  "\n阶段" + (i + 1) + " " + item.properties.stageName + "\n";
                break;
              case "icon-task":
                taskNum = taskNum + 1;
                // console.log(item.properties.taskContent);
                if (item.properties.taskContent) {
                  stringData +=
                    "任务" +
                    taskNum +
                    " " +
                    item.properties.taskTitle +
                    "\n" +
                    item.properties.taskContent.replace(/<[^>]*>/g, "") +
                    "\n";
                } else {
                  stringData +=
                    "任务" + taskNum + " " + item.properties.taskTitle + "\n";
                }
                break;
              case "icon-card":
                if (item.properties.content) {
                  stringData +=
                    item.properties.title +
                    "\n" +
                    item.properties.content.replace(/<[^>]*>/g, "") +
                    "\n";
                } else {
                  stringData += item.properties.title + "\n";
                }
                break;
              case "icon-AI":
                stringData +=
                  item.properties.topic +
                  "\n" +
                  item.properties.aiContent +
                  "\n";
                break;
              case "icon-new-AI":
                stringData +=
                  item.properties.topic +
                  "\n" +
                  item.properties.aiContent +
                  "\n";
                break;
              default:
                stringData += "";
                break;
            }
          });
        }
      } else {
        if (!this.isHomeWork) {
          stringData = this.themeName + "\n";
        }
        let taskNum = 0;
        if (this.navigationList[0].child[0].type) {
          this.navigationList[0].child.forEach((item) => {
            switch (item.type) {
              case "icon-stage":
                stringData += item.properties.stageName + "\n";
                break;
              case "icon-task":
                taskNum = taskNum + 1;
                // console.log(item.properties.taskContent);
                if (item.properties.taskContent) {
                  stringData +=
                    "任务" +
                    taskNum +
                    " " +
                    item.properties.taskTitle +
                    "\n" +
                    item.properties.taskContent.replace(/<[^>]*>/g, "") +
                    "\n";
                } else {
                  stringData +=
                    "任务" + taskNum + " " + item.properties.taskTitle + "\n";
                }
                break;
              case "icon-card":
                if (item.properties.content) {
                  stringData +=
                    item.properties.title +
                    "\n" +
                    item.properties.content.replace(/<[^>]*>/g, "") +
                    "\n";
                } else {
                  stringData += item.properties.title + "\n";
                }
                break;
              case "icon-AI":
                stringData +=
                  item.properties.topic +
                  "\n" +
                  item.properties.aiContent +
                  "\n";
                break;
              case "icon-new-AI":
                stringData +=
                  item.properties.topic +
                  "\n" +
                  item.properties.aiContent +
                  "\n";
                break;
              default:
                stringData += "";
                break;
            }
            item.child.forEach((item) => {
              switch (item.type) {
                case "icon-stage":
                  stringData += item.properties.stageName + "\n";
                  break;
                case "icon-task":
                  taskNum = taskNum + 1;
                  // console.log(item.properties.taskContent);
                  if (item.properties.taskContent) {
                    stringData +=
                      "任务" +
                      taskNum +
                      " " +
                      item.properties.taskTitle +
                      "\n" +
                      item.properties.taskContent.replace(/<[^>]*>/g, "") +
                      "\n";
                  } else {
                    stringData +=
                      "任务" + taskNum + " " + item.properties.taskTitle + "\n";
                  }
                  break;
                case "icon-card":
                  if (item.properties.content) {
                    stringData +=
                      item.properties.title +
                      "\n" +
                      item.properties.content.replace(/<[^>]*>/g, "") +
                      "\n\n";
                  } else {
                    stringData += item.properties.title + "\n\n";
                  }
                  break;
                case "icon-AI":
                  stringData +=
                    item.properties.topic +
                    "\n" +
                    item.properties.aiContent +
                    "\n";
                  break;
                case "icon-new-AI":
                  stringData +=
                    item.properties.topic +
                    "\n" +
                    item.properties.aiContent +
                    "\n";
                  break;
                default:
                  stringData += "";
                  break;
              }
            });
          });
        } else {
          this.navigationList[0].child[0].child.forEach((item) => {
            switch (item.type) {
              case "icon-stage":
                stringData += item.properties.stageName + "\n";
                break;
              case "icon-task":
                taskNum = taskNum + 1;
                // console.log(item.properties.taskContent);
                if (item.properties.taskContent) {
                  stringData +=
                    "任务" +
                    taskNum +
                    " " +
                    item.properties.taskTitle +
                    "\n" +
                    item.properties.taskContent.replace(/<[^>]*>/g, "") +
                    "\n";
                } else {
                  stringData +=
                    "任务" + taskNum + " " + item.properties.taskTitle + "\n";
                }
                break;
              case "icon-card":
                if (item.properties.content) {
                  stringData +=
                    item.properties.title +
                    "\n" +
                    item.properties.content.replace(/<[^>]*>/g, "") +
                    "\n\n";
                } else {
                  stringData += item.properties.title + "\n\n";
                }
                break;
              case "icon-AI":
                stringData +=
                  item.properties.topic +
                  "\n" +
                  item.properties.aiContent +
                  "\n";
                break;
              case "icon-new-AI":
                stringData +=
                  item.properties.topic +
                  "\n" +
                  item.properties.aiContent +
                  "\n";
                break;
              default:
                stringData += "";
                break;
            }
          });
        }
      }
      // dada 表示要转换的字符串数据，type 表示要转换的数据格式
      const blob = new Blob([stringData], {
        type: "text/plain;charset=utf-8",
      }); // 根据 blob生成 url链接
      const objectURL = URL.createObjectURL(blob); // 创建一个 a 标签Tag
      const aTag = document.createElement("a"); // 设置文件的下载地址
      aTag.href = objectURL; // 设置保存后的文件名称
      aTag.download = this.themeName + ".txt"; // 给 a 标签添加点击事件
      aTag.click(); // 释放一个之前已经存在的、通过调用 URL.createObjectURL() 创建的 URL 对象。 // 当你结束使用某个 URL 对象之后，应该通过调用这个方法来让浏览器知道不用在内存中继续保留对这个文件的引用了。
      URL.revokeObjectURL(objectURL);
      // let properties = [];
      // this.navigationList.forEach((item) => {
      //   delete item.properties.type;
      //   if(item.properties?.taskTitleColor)delete item.properties.taskTitleColor;
      //   if(item.properties?.id)delete item.properties.id;
      //   if(item.properties?.imgResultList)delete item.properties.imgResultList;
      //   if(item.properties?.isLoading)delete item.properties.isLoading;
      //   if(item.properties?.isShowDelete)delete item.properties.isShowDelete;
      //   if(item.properties?.questionList)delete item.properties.questionList;
      //   properties.push(item.properties);
      // });
      // const ws = utils.json_to_sheet(properties)
      // const txt = utils.sheet_to_txt(ws);
      // // 创建一个Blob对象
      // var blob = new Blob([txt], { type:"text/plain" });
      // // 创建一个下载链接
      // var link = document.createElement("a");
      // link.href = window.URL.createObjectURL(blob);
      // link.download ="CocoroboAiChat.txt";
      // // 点击链接下载文件
      // link.click();
    },
    navToStage(i) {
      this.stageIndex = i + 1;
      this.processData();
    },
    showStage(i) {
      let navigationList = this.navigationList;
      this.navigationList = [];
      navigationList[i].isFold = true;
      this.navigationList = navigationList;
    },
    hideStage(i) {
      let navigationList = this.navigationList;
      this.navigationList = [];
      navigationList[i].isFold = false;
      this.navigationList = navigationList;
    },
    showTask(i, j) {
      let navigationList = this.navigationList;
      this.navigationList = [];
      navigationList[i].child[j].isFold = true;
      this.navigationList = navigationList;
    },
    hideTask(i, j) {
      let navigationList = this.navigationList;
      this.navigationList = [];
      navigationList[i].child[j].isFold = false;
      this.navigationList = navigationList;
    },
    navToTask(i, j) {
      if (this.stageDatas.length != 0) {
        this.stageIndex = i + 1;
        this.processData();
        this.navTo(this.navigationList[i].child[j].id);
      } else {
        this.navTo(this.navigationList[i].child[j].id);
      }
    },
    navToOther(i, j, index) {
      if (this.stageDatas.length != 0) {
        this.stageIndex = i + 1;
        this.processData();
        this.navTo(this.navigationList[i].child[j].child[index].id);
      } else {
        this.navTo(this.navigationList[i].child[j].child[index].id);
      }
    },
    pre_stage() {
      this.stageIndex = this.stageIndex - 1;
      this.processData();
    },
    next_stage() {
      this.stageIndex = this.stageIndex + 1;
      this.processData();
    },
    processData() {
      this.nodes = [];
      // this.navigationList = []
      this.dataList = [];
      var a = [];
      this.taskData = [];
      this.isRun = true;
      this.nodes.push(this.stageDatas[this.stageIndex - 1]);
      if (this.stageDatas[this.stageIndex - 1].children.length != 0) {
        for (
          let i = 0;
          i < this.stageDatas[this.stageIndex - 1].children.length;
          i++
        ) {
          if (
            this.stageDatas[this.stageIndex - 1].children[i].type !=
            "icon-stage"
          ) {
            this.nodes.push(this.stageDatas[this.stageIndex - 1].children[i]);
            if (
              this.stageDatas[this.stageIndex - 1].children[i].children
                .length == 1
            ) {
              this.getSomeStageData(
                this.stageDatas[this.stageIndex - 1].children[i].children,
                this.stageDatas[this.stageIndex - 1].children[i].id
              );
            }
            if (
              this.stageDatas[this.stageIndex - 1].children[i].children
                .length == 2
            ) {
              this.getTaskData(
                this.stageDatas[this.stageIndex - 1].children[i].children
              );
              for (
                let j = 0;
                j <
                this.stageDatas[this.stageIndex - 1].children[i].children
                  .length;
                j++
              ) {
                if (
                  this.stageDatas[this.stageIndex - 1].children[i].children[j]
                    .type != "icon-task"
                ) {
                  this.nodes.push(
                    this.stageDatas[this.stageIndex - 1].children[i].children[j]
                  );
                  let flag = this.getSomeStageData(
                    this.stageDatas[this.stageIndex - 1].children[i].children[j]
                      .children,
                    this.stageDatas[this.stageIndex - 1].children[i].children[j]
                      .id
                  );
                  if (flag) {
                    this.isRun = false;
                  }
                  let taskFlag = this.getSomeTaskData(
                    this.stageDatas[this.stageIndex - 1].children[i].children[j]
                      .children,
                    this.stageDatas[this.stageIndex - 1].children[i].children[j]
                      .id
                  );
                  if (taskFlag) {
                    this.isRun = false;
                  }
                  break;
                }
              }
            }
            break;
          }
        }
        if (this.taskData.length != 0) {
          for (let i = 0; i < this.taskData.length; i++) {
            this.nodes.push(this.taskData[i]);
            for (let j = 0; j < this.taskData[i].children.length; j++) {
              if (this.taskData[i].children[j].type != "icon-task") {
                this.nodes.push(this.taskData[i].children[j]);
                let flag = this.getSomeStageData(
                  this.taskData[i].children[j].children,
                  this.taskData[i].children[j].id
                );
                if (flag) {
                  this.isRun = false;
                  break;
                }
                let taskFlag = this.getSomeTaskData(
                  this.taskData[i].children[j].children,
                  this.taskData[i].children[j].id
                );
                if (taskFlag) {
                  this.isRun = false;
                  break;
                }
              }
            }
          }
        }
      }
      if (this.isRun) {
        // console.log(this.nodes)
        for (let i = 0; i < this.nodes.length; i++) {
          if (
            this.nodes[i] != undefined &&
            this.nodes[i].type &&
            this.nodes[i].type == "icon-select"
          ) {
            a.push(this.nodes[i]);
            // this.navigationList.push(this.nodes[i]);
            this.dataList.push({ nodes: a });
            break;
          } else if (
            this.nodes[i] != undefined &&
            i == this.nodes.length - 1 &&
            this.nodes[i].type != "icon-select" &&
            this.nodes[i].type != "icon-stage"
          ) {
            a.push(this.nodes[i]);
            // this.navigationList.push(this.nodes[i]);
            this.dataList.push({ nodes: a });
          } else if (this.nodes[i] == undefined && i == this.nodes.length - 1) {
            this.dataList.push({ nodes: a });
          } else if (
            this.nodes[i].type != "icon-start" &&
            this.nodes[i].type != "icon-theme" &&
            this.nodes[i].type != "icon-stage"
          ) {
            a.push(this.nodes[i]);
            // this.navigationList.push(this.nodes[i]);
          }
        }
        if (this.dataList.length != 0) {
          if(this.isPhone) {
            this.dataList_new.push(this.dataList)
          }
          this.currentIndex = this.dataList[0].nodes.length - 1;
          if(!this.isPhone) {
            this.selectMulu = this.dataList[0].nodes[0].id;
          }
          this.selectList =
            this.dataList[this.dataList.length - 1].nodes[0].children;
        } else {
          this.selectMulu = "";
        }
        this.value = this.stageDatas[this.stageIndex - 1].properties.stageName;
        // let navigationListNew = this.navigationList
        // navigationListNew[this.stageIndex - 1].isFold = true
        // if(navigationListNew[this.stageIndex - 1].child.length != 0) {
        //   for(let i = 0;i < navigationListNew[this.stageIndex - 1].child.length;i++) {
        //     navigationListNew[this.stageIndex - 1].child[i].isFold = true
        //   }
        // }
        // this.navigationList = navigationListNew
      } else {
        this.handleClose();
        this.$message.error(this.languageData.runTip2);
      }
    },
    exportProcessData(index) {
      this.navigationListNew = [];
      this.nodes = [];
      this.taskData = [];
      this.isRun = true;
      this.nodes.push(this.stageDatas[index]);
      if (this.stageDatas[index].children.length != 0) {
        for (let i = 0; i < this.stageDatas[index].children.length; i++) {
          if (this.stageDatas[index].children[i].type != "icon-stage") {
            this.nodes.push(this.stageDatas[index].children[i]);
            if (this.stageDatas[index].children[i].children.length == 1) {
              this.getSomeStageData(
                this.stageDatas[index].children[i].children,
                this.stageDatas[index].children[i].id
              );
            }
            if (this.stageDatas[index].children[i].children.length == 2) {
              this.getTaskData(this.stageDatas[index].children[i].children);
              for (
                let j = 0;
                j < this.stageDatas[index].children[i].children.length;
                j++
              ) {
                if (
                  this.stageDatas[index].children[i].children[j].type !=
                  "icon-task"
                ) {
                  this.nodes.push(
                    this.stageDatas[index].children[i].children[j]
                  );
                  let flag = this.getSomeStageData(
                    this.stageDatas[index].children[i].children[j].children,
                    this.stageDatas[index].children[i].children[j].id
                  );
                  if (flag) {
                    this.isRun = false;
                  }
                  let taskFlag = this.getSomeTaskData(
                    this.stageDatas[index].children[i].children[j].children,
                    this.stageDatas[index].children[i].children[j].id
                  );
                  if (taskFlag) {
                    this.isRun = false;
                  }
                  break;
                }
              }
            }
            break;
          }
        }
        if (this.taskData.length != 0) {
          for (let i = 0; i < this.taskData.length; i++) {
            this.nodes.push(this.taskData[i]);
            for (let j = 0; j < this.taskData[i].children.length; j++) {
              if (this.taskData[i].children[j].type != "icon-task") {
                this.nodes.push(this.taskData[i].children[j]);
                let flag = this.getSomeStageData(
                  this.taskData[i].children[j].children,
                  this.taskData[i].children[j].id
                );
                if (flag) {
                  this.isRun = false;
                  break;
                }
                let taskFlag = this.getSomeTaskData(
                  this.taskData[i].children[j].children,
                  this.taskData[i].children[j].id
                );
                if (taskFlag) {
                  this.isRun = false;
                  break;
                }
              }
            }
          }
        }
      }
      if (this.isRun) {
        // console.log(this.nodes)
        for (let i = 0; i < this.nodes.length; i++) {
          if (
            this.nodes[i] != undefined &&
            this.nodes[i].type &&
            this.nodes[i].type == "icon-select"
          ) {
            this.navigationListNew.push(this.nodes[i]);
            break;
          } else if (
            this.nodes[i] != undefined &&
            i == this.nodes.length - 1 &&
            this.nodes[i].type != "icon-select"
          ) {
            this.navigationListNew.push(this.nodes[i]);
          } else if (
            this.nodes[i].type != "icon-start" &&
            this.nodes[i].type != "icon-theme"
          ) {
            this.navigationListNew.push(this.nodes[i]);
          }
        }
      } else {
        this.handleClose();
        this.$message.error(this.languageData.runTip2);
      }
    },
    handleNextClose() {
      this.selectDialogVisible = false;
    },
    selectPath(data) {
      this.selectDialogVisible = true;
      this.selectList = data;
    },
    selectWay(e) {
      this.nodes = [];
      let index = this.dataList.length;
      for (let i = 0; i < this.dataList.length; i++) {
        for (let j = 0; j < this.dataList[i].nodes.length; j++) {
          for (let k = 0; k < this.selectList.length; k++) {
            if (this.selectList[k].id == this.dataList[i].nodes[j].id) {
              index = i;
              break;
            }
          }
        }
      }
      this.dataList.splice(index, this.dataList.length - index);
      for (let j = 0; j < this.navigationList.length; j++) {
        for (let k = 0; k < this.selectList.length; k++) {
          if (this.navigationList[j].id == this.selectList[k].id) {
            index = j;
            break;
          }
        }
      }
      this.navigationList.splice(index, this.navigationList.length - index);
      index = this.dataList.length;
      this.nodes.push(this.selectList[e]);
      this.getWayData([this.selectList[e]]);
      for (let i = 0; i < this.nodes.length; i++) {
        let a = [];
        a.push(this.nodes[i]);
        this.navigationList.push(this.nodes[i]);
        this.dataList.push({ nodes: a });
      }
      this.selectDialogVisible = false;
      this.selectRadio = "";
      this.selectList =
        this.dataList[this.dataList.length - 1].nodes[0].children;
      this.$nextTick(() => {
        // let fontsize = '';
        for (let i = 0; i < this.dataList.length; i++) {
          for (let j = 0; j < this.dataList[i].nodes.length; j++) {
            // if (this.dataList[i].nodes[j].type == "icon-card") {
            //     if (this.dataList[i].nodes[j].properties.radio == 'content') {
            //         let len = this.dataList[i].nodes[j].properties.contentLength;
            //         if (0 < len && len == 1) {
            //             fontsize = "160px";
            //         }
            //         else if (1 < len && len <= 3) {
            //             fontsize = "80px";
            //         }
            //         else if (3 < len && len <= 5) {
            //             fontsize = "40px";
            //         }
            //         else if (5 < len && len <= 7) {
            //             fontsize = "20px";
            //         }
            //         else if (7 < len && len <= 9) {
            //             fontsize = "12px";
            //         }
            //         $("#card_" + i + "_" + j + " p").css("font-size", fontsize)
            //         if (fontsize == "12px") {
            //             $("#card_" + i + "_" + j + " p").css("-webkit-transform", "scale(0.83)")
            //         }
            //     }
            // }
            $("#right_top_" + i + "_" + j).on("mouseup", () => {
              this.down = false;
              document.onmousemove = null;
              document.onmousedown = null;
              document.onmouseup = null;
            });
            $("#right_top_" + i + "_" + j).on("mousemove", (e) => {
              e.preventDefault();
              // 获取鼠标的实时位置
              const curY = e.clientY;
              // 获取拖拽的长度
              const increaseY = curY - this.disY;
              // 按下开始拖拽改变目标元素大小
              if (this.down) {
                const height =
                  e.currentTarget.children[e.currentTarget.children.length - 1]
                    .offsetHeight + increaseY;
                $(e.currentTarget).css({
                  height: height < 400 ? "400px" : height + "px",
                });
              }
            });
          }
        }
        this.navTo(this.dataList[index].nodes[0].id);
      });
    },
    getTargetData(data, id) {
      let Data = Object.values(data);
      return Data.filter((item) => {
        if (item.id != id && item.type != "icon-stage") {
          this.nodes.push(item);
        }
        if (item.children.length != 0) {
          this.getTargetData(item.children, id);
        }
        return item.id != id && item.type != "icon-stage";
      });
    },
    getSomeStageData(data, id) {
      let Data = Object.values(data);
      return Data.some((item) => {
        if (item.id != id && item.type != "icon-stage") {
          let ids = [];
          this.nodes.forEach((it) => {
            ids.push(it.id);
          });
          if (ids.indexOf(item.id) == -1) {
            this.nodes.push(item);
          }
          if (item.children.length != 0) {
            this.getSomeStageData(item.children, id);
          }
        }
        return item.type == "icon-stage";
      });
    },
    getSomeTaskData(data, id) {
      let Data = Object.values(data);
      return Data.some((item) => {
        if (item.id != id && item.type != "icon-task") {
          let ids = [];
          this.nodes.forEach((it) => {
            ids.push(it.id);
          });
          if (ids.indexOf(item.id) == -1) {
            this.nodes.push(item);
          }
          if (item.children.length != 0) {
            this.getSomeTaskData(item.children, id);
          }
        }
        return item.type == "icon-task";
      });
    },
    getSelectIds(data) {
      let Data = Object.values(data);
      return Data.filter((item) => {
        if (item.type == "icon-select") {
          this.selectIds.push(item.id);
        }
        if (item.children.length != 0) {
          this.getSelectIds(item.children);
        }
        return item.type == "icon-select";
      });
    },
    getStageDatas(data) {
      let Data = Object.values(data);
      return Data.filter((item) => {
        if (item.type == "icon-stage") {
          this.stageDatas.push(item);
        }
        if (item.children.length != 0) {
          this.getStageDatas(item.children);
        }
        return item.type == "icon-stage";
      });
    },
    getWayData(data) {
      let Data = Object.values(data);
      return Data.filter((item) => {
        if (item.children.length == 1) {
          this.nodes.push(item.children[0]);
          this.getWayData(item.children);
        }
        return item.children.length == 1;
      });
    },
    getTaskData(data) {
      let Data = Object.values(data);
      return Data.filter((item) => {
        if (item.type == "icon-task") {
          this.taskData.push(item);
        }
        if (item.children.length != 0) {
          this.getTaskData(item.children);
        }
        return item.type == "icon-task";
      });
    },
    handleClose() {
      this.selectList = [];
      this.dataList = [];
      this.navigationList = [];
      this.Radio = "";
      this.fullscreen = false;
      $("#result-1 .el-dialog__body").css({
        height: "69%",
      });
      this.$emit("info", false);
      // this.$emit("clearList", []);
    },
    // down置为true，记录鼠标点下的位置
    handleMousedown(e) {
      this.down = true;
      this.disY = e.clientY - e.target.offsetTop;
      console.log(e)
    },
    focusMax() {
      this.hoverMax = true;
      this.hoverRecovery = false;
      this.hoverDownload = false;
      this.hoverClose = false;
    },
    blurMax() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverDownload = false;
      this.hoverClose = false;
    },
    focusRecovery() {
      this.hoverMax = false;
      this.hoverRecovery = true;
      this.hoverDownload = false;
      this.hoverClose = false;
    },
    blurRecovery() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverDownload = false;
      this.hoverClose = false;
    },
    focusClose() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverDownload = false;
      this.hoverClose = true;
    },
    blurClose() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverDownload = false;
      this.hoverClose = false;
    },
    focusDownload() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverDownload = true;
      this.hoverClose = false;
    },
    blurDownload() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverDownload = false;
      this.hoverClose = false;
    },
    setSelectMulu(val) {
      this.selectMulu = val
      this.navTo(val);
      // for(let i = 0;i < this.navigationList.length;i++) {
      //   if(this.navigationList[i].id == val) {
      //     this.navToStage(i)
      //     break
      //   }
      //   for(let j = 0;j < this.navigationList[i].child.length;j++) {
      //     if(this.navigationList[i].child[j].id == val) {
      //       this.navToTask(i,j)
      //       break
      //     }
      //     for(let k = 0;k < this.navigationList[i].child[j].child.length;k++) {
      //       if(this.navigationList[i].child[j].child[k].id == val) {
      //         this.navToOther(i,j,k)
      //         break
      //       }
      //     }
      //   }
      // }
      this.isShowMulu = false
    },
    clickMax() {
      this.fullscreen = true;
      $("#result-1 .el-dialog__body").css({
        height: "calc(100% - 41px)",
      });
    },
    clickRecovery() {
      this.fullscreen = false;
      $("#result-1 .el-dialog__body").css({
        height: "69%",
      });
    },
    clickDownload() {
      console.log($("#scroll-box").html());
      this.generate($("#scroll-box").html());
    },
    generate(html) {
      // 将html文件中需要用到的数据挂载到store上
      const content = `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>知识建构</title>
            <style>
              body {
                  background: #F0F7FD;
              }
  
              .left-side {
                  width: 280px;
                  height: 600px;
                  background: #FFFFFF;
                  box-shadow: 4px 0px 12px 0px rgba(0, 47, 130, 0.08);
                  border-radius: 0px 0px 0px 10px;
                  padding: 20px;
              }
  
              .right-side {
                  width: 100%;
                  height: 100%;
                  overflow-y: auto;
              }
  
              .right_top {
                  /* width: 100%; */
                  height: 360px;
                  background: #FFFFFF;
                  box-shadow: 0px 4px 12px 0px rgba(0, 47, 130, 0.08);
                  border-radius: 10px;
                  margin-left: 25px;
                  /* margin-right: 25px; */
                  margin-bottom: 25px;
                  padding: 20px;
              }
  
              .right_top_title {
                  font-size: 24px;
                  font-family: SimSun;
                  font-weight: bold;
                  color: #333333;
                  line-height: 16px;
              }
  
              .right_bottom {
                  /* width: 100%; */
                  height: 175px;
                  background: #FFFFFF;
                  box-shadow: 0px 4px 12px 0px rgba(0, 47, 130, 0.08);
                  border-radius: 10px;
                  margin-left: 25px;
                  /* margin-right: 25px; */
                  margin-bottom: 25px;
                  padding: 20px;
              }
  
              .right_answer {
                  /* width: 100%; */
                  height: 600px;
                  background: #FFFFFF;
                  box-shadow: 0px 4px 12px 0px rgba(0, 47, 130, 0.08);
                  border-radius: 10px;
                  margin-left: 25px;
                  padding: 20px;
              }
  
              .mulu {
                  width: 35px;
                  height: 17px;
                  font-size: 18px;
                  font-family: SimSun;
                  font-weight: bold;
                  color: #205CC6;
                  line-height: 16px;
                  margin-bottom: 15px;
              }
  
              .menuList::-webkit-scrollbar,
              .right-side::-webkit-scrollbar {
                  width: 4px;
              }
  
              .menuList::-webkit-scrollbar-thumb,
              .right-side::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                  opacity: 0.2;
                  background: fade(#606266, 60%);
              }
  
              .menuList::-webkit-scrollbar-track,
              .right-side::-webkit-scrollbar-track {
                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                  border-radius: 0;
                  background: fade(#606266, 30%);
              }
  
              .biaoti {
                  width: 221px;
                  height: 52px;
                  background: #E7F4FF;
                  border-radius: 0px 0px 6px 6px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: 15px;
                  cursor: pointer;
              }
  
              .biaoti-select {
                  width: 221px;
                  height: 52px;
                  background: #007BFF;
                  border-radius: 0px 0px 6px 6px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: 15px;
                  cursor: pointer;
              }
  
              .text {
                  font-size: 16px;
                  font-family: SimSun;
                  font-weight: 400;
                  color: #666666;
                  line-height: 16px;
              }
  
              .text-select {
                  font-size: 16px;
                  font-family: SimSun;
                  font-weight: 400;
                  color: #FFFFFF;
                  line-height: 16px;
              }
  
              .el-dialog {
                  margin-top: 5vh !important;
              }
  
              .el-dialog__body {
                  background: #F0F7FD;
              }
  
              .handleIframe {
                  width: 100%;
                  height: 20px;
                  /* background-color: red; */
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  -webkit-user-select: none;
                  -moz-user-select: none;
                  -o-user-select: none;
                  user-select: none;
                  cursor: n-resize;
              }
  
              .download {
                  position: absolute;
                  top: 20px;
                  right: 62px;
                  padding: 0;
                  background: 0 0;
                  border: none;
                  outline: 0;
                  cursor: pointer;
                  font-size: 16px;
              }
          </style>
        </head>
        <body>
          <div>
              ${html}
          </div>
        </body>
        </html>`;
      // debugger
      // 生成报告
      const link = document.createElement("a");
      let dname = "测试.html";
      // link.download = "报告.html"; // 文件名
      link.download = dname; // 文件名
      link.style.display = "none";
      // 创建文件流
      // 创建bolb实例时，内容一定要放在[]中
      const blob = new Blob([content], {
        type: "text/plain;charset='utf-8'",
      });
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // saveAs(
      //   htmlDocx.asBlob(content, {
      //     orientation: "landscape", //跨域设置
      //   }),
      //   //文件名
      //   "报告.doc"
      // );
    },
    navTo(id) {
      // let scroll_box = document.getElementById("scroll-box");
      // scroll_box.querySelector(`#id_${id}`).scrollIntoView(true);
      // this.selectMulu = id;
      this.$nextTick(() => {
        let scrollItem = document.getElementById("id_" + id);
        // 锚点对应的模块与最近的一个具有定位的祖宗元素顶部的距离
        this.offsetTop = scrollItem.offsetTop;
        this.selectMulu = id;
        document.getElementById("id_" + id).scrollIntoView(true);
        return false;
      });
    },
    scroll() {
      // this.dataList[0].nodes.forEach((item)=>{
      //     const box = document.getElementById(`id_${item.id}`);
      //     if(box.getBoundingClientRect().top<180 && box.getBoundingClientRect().top>130){
      //         this.selectMulu=item.id;
      //     }
      // })
      // const box2 = document.getElementById("scroll-box");
      // if(box2.clientHeight + box2.scrollTop === box2.scrollHeight){
      //     this.selectMulu=this.dataList[0].nodes.at(-1).id;
      // }
      //console.log(this.dataList[0].nodes)
      //炜桐
      this.$nextTick(() => {
        const box = document.getElementById("scroll-box2");
        // 若当前设置的滚动高度大于实际滚动的高度，即为锚点跳转，不再设置选中的锚点
        if (this.offsetTop > box.scrollTop) {
          this.offsetTop = 0;
          return;
        }
        this.navigationList.some((item, index) => {
          let scrollItem = document.getElementById("id_" + item.id); // 锚点对应的模块
          let totalH = scrollItem.offsetTop;
          let judge =
            box.scrollTop <=
            totalH + scrollItem.nextElementSibling.clientHeight - 25;
          if (judge) {
            if (index != 0) {
              // let previousDom = document.getElementById(this.navigationList[index - 1].id)
              // if (box.scrollTop >= previousDom.offsetTop + previousDom.nextElementSibling.clientHeight && box.scrollTop + 640 != box.scrollHeight) {
              //     // console.log(item.properties.title != undefined ? item.properties.title : '选择题')
              //     this.selectMulu = item.id;
              //     return true
              // }
              // else if (box.scrollTop >= previousDom.offsetTop + previousDom.nextElementSibling.clientHeight && box.scrollTop + 640 == box.scrollHeight) {
              //     this.selectMulu = this.navigationList[index + 1].id;
              //     return true
              // }
              if (box.scrollTop + 640 >= box.scrollHeight) {
                if (index + 1 <= this.navigationList.length - 1) {
                  this.selectMulu = this.navigationList[index + 1].id;
                }
                return true;
              } else {
                this.selectMulu = item.id;
                return true;
              }
            } else {
              if (box.scrollTop + 640 >= box.scrollHeight) {
                if (index + 1 <= this.navigationList.length - 1) {
                  this.selectMulu = this.navigationList[index + 1].id;
                }
                return true;
              } else {
                this.selectMulu = item.id;
                return true;
              }
            }
          }
        });
      });
    },
    setSelect(e) {
      // var rightAnswer = "";
      let nowSelectId = "";
      this.nodes = [];
      let index = this.dataList.length;
      for (let j = 0; j < this.sourceList[0].nodes.length; j++) {
        if (
          this.sourceList[0].nodes[j].type == "icon-select" &&
          this.sourceList[0].nodes[j].properties.selectOption.includes(e) ==
            true
        ) {
          nowSelectId = this.sourceList[0].nodes[j].id;
          break;
        }
      }
      for (let i = 0; i < this.dataList.length; i++) {
        for (let j = 0; j < this.dataList[i].nodes.length; j++) {
          if (nowSelectId == this.dataList[i].nodes[j].id) {
            index = i + 1;
            break;
          }
        }
      }
      this.dataList.splice(index, this.dataList.length - index);
      this.selectList =
        this.dataList[this.dataList.length - 1].nodes[0].children;
      for (let k = 0; k < this.selectList.length; k++) {
        if (this.selectList[k].properties.title == e) {
          this.nodes.push(this.selectList[k]);
          this.getWayData([this.selectList[k]]);
          break;
        }
      }
      for (let j = 0; j < this.navigationList.length; j++) {
        if (this.navigationList[j].id == nowSelectId) {
          index = j + 1;
          break;
        }
      }
      this.navigationList.splice(index, this.navigationList.length - index);
      index = this.dataList.length;
      for (let i = 0; i < this.nodes.length; i++) {
        let a = [];
        a.push(this.nodes[i]);
        this.navigationList.push(this.nodes[i]);
        this.dataList.push({ nodes: a });
      }
      this.selectList =
        this.dataList[this.dataList.length - 1].nodes[0].children;
      this.$nextTick(() => {
        // let fontsize = '';
        for (let i = 0; i < this.dataList.length; i++) {
          for (let j = 0; j < this.dataList[i].nodes.length; j++) {
            // if (this.dataList[i].nodes[j].type == "icon-card") {
            //     if (this.dataList[i].nodes[j].properties.radio == 'content') {
            //         let len = this.dataList[i].nodes[j].properties.contentLength;
            //         if (0 < len && len == 1) {
            //             fontsize = "160px";
            //         }
            //         else if (1 < len && len <= 3) {
            //             fontsize = "80px";
            //         }
            //         else if (3 < len && len <= 5) {
            //             fontsize = "40px";
            //         }
            //         else if (5 < len && len <= 7) {
            //             fontsize = "20px";
            //         }
            //         else if (7 < len && len <= 9) {
            //             fontsize = "12px";
            //         }
            //         $("#card_" + i + "_" + j + " p").css("font-size", fontsize)
            //         if (fontsize == "12px") {
            //             $("#card_" + i + "_" + j + " p").css("-webkit-transform", "scale(0.83)")
            //         }
            //     }
            // }
            $("#right_top_" + i + "_" + j).on("mouseup", () => {
              this.down = false;
              document.onmousemove = null;
              document.onmousedown = null;
              document.onmouseup = null;
            });
            $("#right_top_" + i + "_" + j).on("mousemove", (e) => {
              e.preventDefault();
              // 获取鼠标的实时位置
              const curY = e.clientY;
              // 获取拖拽的长度
              const increaseY = curY - this.disY;
              // 按下开始拖拽改变目标元素大小
              if (this.down) {
                const height =
                  e.currentTarget.children[e.currentTarget.children.length - 1]
                    .offsetHeight + increaseY;
                $(e.currentTarget).css({
                  height: height < 400 ? "400px" : height + "px",
                });
              }
            });
          }
        }
        this.navTo(this.dataList[index].nodes[0].id);
      });
      // for (let l = 0; l < this.navigationList.length; l++) {
      //     if (this.navigationList[l].id == nowSelectId) {
      //         this.currentIndex = l;
      //     }
      // }
      // var targetNodeId = "";
      // if (e == rightAnswer) {
      //     for (let i = 0; i < this.sourceList[0].edges.length; i++) {
      //         if (this.sourceList[0].edges[i].properties.route && this.sourceList[0].edges[i].properties.route == rightAnswer && this.sourceList[0].edges[i].sourceNodeId == nowSelectId) {
      //             targetNodeId = this.sourceList[0].edges[i].targetNodeId;
      //         }
      //     }
      //     let a = [];
      //     a.push(targetNodeId)
      //     let nextTargetNodeId = targetNodeId;
      //     for (let g = 0; g < this.sourceList[0].edges.length; g++) {
      //         if (this.sourceList[0].edges[g].sourceNodeId == nextTargetNodeId && this.selectIds.includes(this.sourceList[0].edges[g].sourceNodeId) == false) {
      //             a.push(this.sourceList[0].edges[g].targetNodeId);
      //             nextTargetNodeId = this.sourceList[0].edges[g].targetNodeId;
      //         }
      //     }
      //     let b = [];
      //     let list = [];
      //     for (let l = 0; l < this.navigationList.length; l++) {
      //         if (l <= this.currentIndex) {
      //             list.push(this.navigationList[l])
      //         }
      //     }
      //     for (let k = 0; k < a.length; k++) {
      //         for (let j = 0; j < this.sourceList[0].nodes.length; j++) {
      //             if (this.sourceList[0].nodes[j].id == a[k]) {
      //                 b.push(this.sourceList[0].nodes[j])
      //                 list.push(this.sourceList[0].nodes[j])
      //             }
      //         }
      //     }
      //     this.navigationList = list;
      //     this.dataList.splice(index + 1, this.dataList.length - index - 1)
      //     this.dataList[index] = { "nodes": b };
      //     this.selectMulu = this.dataList[index].nodes[0].id;
      //     this.$nextTick(() => {
      //         // $(".right_top").on('mouseup', () => {
      //         //     this.down = false
      //         //     $(".right_top").onmousemove = null
      //         //     $(".right_top").onmousedown = null
      //         //     $(".right_top").onmouseup = null
      //         // })
      //         // $(".right_top").on('mousemove', (e) => {
      //         //     e.preventDefault();
      //         //     // 获取鼠标的实时位置
      //         //     const curY = e.clientY
      //         //     // 获取拖拽的长度
      //         //     const increaseY = curY - this.disY
      //         //     // 按下开始拖拽改变目标元素大小
      //         //     if (this.down) {
      //         //         const height = e.currentTarget.children[e.currentTarget.children.length - 1].offsetHeight + increaseY
      //         //         $(e.currentTarget).css({
      //         //             "height": height < 400 ? '400px' : height + 'px'
      //         //         })
      //         //     }
      //         // })
      //         let fontsize = '';
      //         for (let i = 0; i < this.dataList.length; i++) {
      //             for (let j = 0; j < this.dataList[i].nodes.length; j++) {
      //                 if (this.dataList[i].nodes[j].type == "icon-card") {
      //                     if (this.dataList[i].nodes[j].properties.radio == 'content') {
      //                         let len = this.dataList[i].nodes[j].properties.contentLength;
      //                         if (0 < len && len == 1) {
      //                             fontsize = "160px";
      //                         }
      //                         else if (1 < len && len <= 3) {
      //                             fontsize = "80px";
      //                         }
      //                         else if (3 < len && len <= 5) {
      //                             fontsize = "40px";
      //                         }
      //                         else if (5 < len && len <= 7) {
      //                             fontsize = "20px";
      //                         }
      //                         else if (7 < len && len <= 9) {
      //                             fontsize = "12px";
      //                         }
      //                         $("#card_" + i + "_" + j + " p").css("font-size", fontsize)
      //                         if (fontsize == "12px") {
      //                             $("#card_" + i + "_" + j + " p").css("-webkit-transform", "scale(0.83)")
      //                         }
      //                     }
      //                 }
      //                 $("#right_top_" + i + "_" + j).on('mouseup', () => {
      //                     this.down = false
      //                     document.onmousemove = null
      //                     document.onmousedown = null
      //                     document.onmouseup = null
      //                 })
      //                 $("#right_top_" + i + "_" + j).on('mousemove', (e) => {
      //                     e.preventDefault();
      //                     // 获取鼠标的实时位置
      //                     const curY = e.clientY
      //                     // 获取拖拽的长度
      //                     const increaseY = curY - this.disY
      //                     // 按下开始拖拽改变目标元素大小
      //                     if (this.down) {
      //                         const height = e.currentTarget.children[e.currentTarget.children.length - 1].offsetHeight + increaseY
      //                         $(e.currentTarget).css({
      //                             "height": height < 400 ? '400px' : height + 'px'
      //                         })
      //                     }
      //                 })
      //             }
      //         }
      //         document.getElementById(this.dataList[index].nodes[0].id).scrollIntoView(true);
      //         // return false;
      //     })
      // }
      // else {
      //     for (let i = 0; i < this.sourceList[0].edges.length; i++) {
      //         if (this.sourceList[0].edges[i].properties.route && this.sourceList[0].edges[i].properties.route == e && this.sourceList[0].edges[i].sourceNodeId == nowSelectId) {
      //             targetNodeId = this.sourceList[0].edges[i].targetNodeId;
      //         }
      //     }
      //     let a = [];
      //     a.push(targetNodeId)
      //     let nextTargetNodeId = targetNodeId;
      //     for (let g = 0; g < this.sourceList[0].edges.length; g++) {
      //         if (this.sourceList[0].edges[g].sourceNodeId == nextTargetNodeId && this.selectIds.includes(this.sourceList[0].edges[g].sourceNodeId) == false) {
      //             a.push(this.sourceList[0].edges[g].targetNodeId);
      //             nextTargetNodeId = this.sourceList[0].edges[g].targetNodeId;
      //         }
      //     }
      //     let b = [];
      //     let list = [];
      //     for (let l = 0; l < this.navigationList.length; l++) {
      //         if (l <= this.currentIndex) {
      //             list.push(this.navigationList[l])
      //         }
      //     }
      //     for (let k = 0; k < a.length; k++) {
      //         for (let j = 0; j < this.sourceList[0].nodes.length; j++) {
      //             if (this.sourceList[0].nodes[j].id == a[k]) {
      //                 b.push(this.sourceList[0].nodes[j])
      //                 list.push(this.sourceList[0].nodes[j])
      //             }
      //         }
      //     }
      //     this.navigationList = list;
      //     this.dataList.splice(index + 1, this.dataList.length - index - 1)
      //     this.dataList[index] = { "nodes": b };
      //     this.selectMulu = this.dataList[index].nodes[0].id;
      //     this.$nextTick(() => {
      //         // $(".right_top").on('mouseup', () => {
      //         //     this.down = false
      //         //     $(".right_top").onmousemove = null
      //         //     $(".right_top").onmousedown = null
      //         //     $(".right_top").onmouseup = null
      //         // })
      //         // $(".right_top").on('mousemove', (e) => {
      //         //     e.preventDefault();
      //         //     // 获取鼠标的实时位置
      //         //     const curY = e.clientY
      //         //     // 获取拖拽的长度
      //         //     const increaseY = curY - this.disY
      //         //     // 按下开始拖拽改变目标元素大小
      //         //     if (this.down) {
      //         //         const height = e.currentTarget.children[e.currentTarget.children.length - 1].offsetHeight + increaseY
      //         //         $(e.currentTarget).css({
      //         //             "height": height < 400 ? '400px' : height + 'px'
      //         //         })
      //         //     }
      //         // })
      //         let fontsize = '';
      //         for (let i = 0; i < this.dataList.length; i++) {
      //             for (let j = 0; j < this.dataList[i].nodes.length; j++) {
      //                 if (this.dataList[i].nodes[j].type == "icon-card") {
      //                     if (this.dataList[i].nodes[j].properties.radio == 'content') {
      //                         let len = this.dataList[i].nodes[j].properties.contentLength;
      //                         if (0 < len && len == 1) {
      //                             fontsize = "160px";
      //                         }
      //                         else if (1 < len && len <= 3) {
      //                             fontsize = "80px";
      //                         }
      //                         else if (3 < len && len <= 5) {
      //                             fontsize = "40px";
      //                         }
      //                         else if (5 < len && len <= 7) {
      //                             fontsize = "20px";
      //                         }
      //                         else if (7 < len && len <= 9) {
      //                             fontsize = "12px";
      //                         }
      //                         $("#card_" + i + "_" + j + " p").css("font-size", fontsize)
      //                         if (fontsize == "12px") {
      //                             $("#card_" + i + "_" + j + " p").css("-webkit-transform", "scale(0.83)")
      //                         }
      //                     }
      //                 }
      //                 $("#right_top_" + i + "_" + j).on('mouseup', () => {
      //                     this.down = false
      //                     document.onmousemove = null
      //                     document.onmousedown = null
      //                     document.onmouseup = null
      //                 })
      //                 $("#right_top_" + i + "_" + j).on('mousemove', (e) => {
      //                     e.preventDefault();
      //                     // 获取鼠标的实时位置
      //                     const curY = e.clientY
      //                     // 获取拖拽的长度
      //                     const increaseY = curY - this.disY
      //                     // 按下开始拖拽改变目标元素大小
      //                     if (this.down) {
      //                         const height = e.currentTarget.children[e.currentTarget.children.length - 1].offsetHeight + increaseY
      //                         $(e.currentTarget).css({
      //                             "height": height < 400 ? '400px' : height + 'px'
      //                         })
      //                     }
      //                 })
      //             }
      //         }
      //         document.getElementById(this.dataList[index].nodes[0].id).scrollIntoView(true);
      //         return false;
      //     })
      // }
    },
    showLeftWay() {
      this.hide = false;
      $("#leftSide").css("display", "block");
      $("#showLeft").css("display", "none");
      $("#hideLeft").css("display", "block");
      $("#scroll-box").css("width","calc(100% - 260px)");
      $("#scroll-box").css("border-bottom-left-radius", "none");
      if (!this.isImmersive) {
        $("#aaaa").css("padding", "16px 0 0 16px");
      }
    },
    hideLeftWay() {
      this.hide = true;
      $("#leftSide").css("display", "none");
      $("#scroll-box").css("width","calc(100%)");
      $("#scroll-box").css("border-bottom-left-radius", "12px");
      $("#showLeft").css("display", "block");
      $("#hideLeft").css("display", "none");
      if (!this.isImmersive) {
        $("#aaaa").css("padding", "16px 0 0 0");
      }
    },
  },
  components: {
    DemonstrateMuluDialog
  }
};
</script>
  
  <style>
#result-1 .left-side {
  /* width: 280px; */
  /* height: 95.2%; */
  background: #ffffff;
  /* box-shadow: 4px 0px 12px 0px rgba(0, 47, 130, 0.08);
    border-radius: 10px; */
  padding: 24px 16px;
  border-right: 1px solid var(--bg3, #e7e7e7);
  /* border-top: 1px solid var(--bg3, #E7E7E7); */
  background: var(--font-icon-wh-2, rgba(255, 255, 255, 0.55));
  border-bottom-left-radius: 12px;
  width: 260px;
}

#result-1 .right-side {
  width: calc(100% - 260px);
  /* height: 640px; */
  /* overflow-y: auto; */
  border-bottom-right-radius: 12px;
  background: var(--font-icon-white, #fff);
}

.right_top {
  /* width: 100%; */
  height: auto;
  line-height: 40px;
  background: #ffffff;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  border-radius: 12px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
  padding: 20px;
}

#result-1 .right_top {
  /* width: 100%; */
  height: auto;
  line-height: 1;
  /* background: #ffffff; */
  box-shadow: none;
  border-radius: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 8px 24px;
}

#result-1 .right_top p {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6)) !important;
  /* body/small */
  font-family: SimSun;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important;
}

#result-1 .right_top p span {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6)) !important;
  /* body/small */
  font-family: SimSun;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important;
}

#result-1 .right_top .span_ai {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6)) !important;
  /* body/small */
  font-family: SimSun;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important;
}

#result-1 .right_top_title {
  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
  /* Title/mediun */
  font-family: SimSun;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
}

.right_bottom {
  /* width: 100%; */
  height: 175px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(0, 47, 130, 0.08);
  border-radius: 10px;
  margin-left: 25px;
  /* margin-right: 25px; */
  margin-bottom: 25px;
  padding: 20px;
}

.right_answer {
  /* width: 100%; */
  height: 600px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(0, 47, 130, 0.08);
  border-radius: 10px;
  margin-left: 25px;
  padding: 20px;
}

.mulu {
  width: 70px;
  height: 17px;
  font-size: 18px;
  font-family: SimSun;
  font-weight: bold;
  color: #205cc6;
  line-height: 16px;
  margin-bottom: 15px;
}

.menuList::-webkit-scrollbar,
.right-side::-webkit-scrollbar {
  width: 4px;
}

.menuList::-webkit-scrollbar-thumb,
.right-side::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: fade(#606266, 60%);
}

.menuList::-webkit-scrollbar-track,
.right-side::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: fade(#606266, 30%);
}

#result-1 .biaoti {
  width: 218px;
  /* min-height: 48px; */
  background: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 15px; */
  cursor: pointer;
  border-radius: 3px;
  padding: 8px 8px 8px 24px;
}

.biaoti-select {
  width: 218px;
  /* min-height: 48px; */
  background: var(--brand-light, #e0eafb);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 15px; */
  cursor: pointer;
  border-radius: 3px;
  padding: 8px 8px 8px 24px;
}

#result-1 .text {
  overflow: hidden;
  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: SimSun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

#result-1 .text-other {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
  font-size: 12px;
  line-height: 20px;
}

#result-1 .text-select {
  /* overflow: hidden; */
  color: var(--brand-click, #0061ff);
  /* text-overflow: ellipsis;
    white-space: nowrap;
    font-family: SimSun;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; */
}

.el-dialog {
  margin-top: 5vh !important;
}

.el-dialog__body {
  background: #f0f7fd;
}

.handleIframe {
  width: 100%;
  height: 20px;
  /* background-color: red; */
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: n-resize;
  display: none;
}

.download {
  position: absolute;
  top: 20px;
  right: 62px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#select .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  width: 40% !important;
  box-shadow: none;
}

#result-1 .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
}

#select .el-dialog__header {
  width: 100%;
  background: #ffffff;
}

#result-1 .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-white, #fff);
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 8px 10px -5px #00000014;
  box-shadow: 0px 16px 24px 2px #0000000a;
  box-shadow: 0px 6px 30px 5px #0000000d;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
}

#select .el-dialog__body {
  width: 100%;
  height: 69%;
}

#result-1 .el-dialog__body {
  width: 100%;
  height: 69%;
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  /* border-bottom: 1px solid var(--bg3, #E7E7E7); */
  box-shadow: 0px 8px 10px -5px #00000014;
  box-shadow: 0px 16px 24px 2px #0000000a;
  box-shadow: 0px 6px 30px 5px #0000000d;
  /* background: var(--font-icon-white, #FFF); */
  padding: 0px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

#result-1 .el-dialog__footer {
  box-sizing: initial;
  padding: 16px 8px;
  width: 100%;
  height: 31px;
  background: #ffffff;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 8px 10px -5px #00000014;
  box-shadow: 0px 16px 24px 2px #0000000a;
  box-shadow: 0px 6px 30px 5px #0000000d;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#result-1 .dialog-footer {
  margin-right: 16px;
}

#select .el-dialog__headerbtn,
#result-1 .el-dialog__headerbtn {
  top: none;
}

#result-1 .createCourse {
  background: #ffffff;
}

#result-1 .createCourse:hover,
#result-1 .createCourse:focus {
  background: #007bff;
}

#select i:hover,
#result-1 i:hover {
  color: #5cb6ff;
}

#result-1 .export_to {
  position: absolute;
  bottom: 0;
  right: 60px;
}

#result-1 .create_course {
  position: absolute;
  bottom: 0;
  right: 160px;
}

/* #result-1 .preStage {
    position: absolute;
    bottom: 0;
    right: 250px;
  }
  
  #result-1 .nextStage {
    position: absolute;
    bottom: 0;
    right: 160px;
  } */

#result-1 .max {
  position: absolute;
  top: 0;
  right: 25px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#result-1 .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#result-1 .el-dialog.is-fullscreen {
  overflow: hidden;
}

.noToolBlue {
  min-width: 10px;
  width: 10px;
  height: 10px;
  background: #0061ff;
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 11px;
}

.noToolBlueHollow {
  min-width: 8px;
  width: 8px;
  height: 8px;
  border: 1px solid #0061ff;
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 11px;
}

.stageBoxCss,
.stageBoxIsCss {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #e2f5fc;
  cursor: pointer;
  width: 100%;
}

.stageBoxIsCss {
  background: #0061ff;
  color: #fff;
  border-radius: 8px;
}

.closeTri {
  min-width: 16px;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.stageItemTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  width: calc(100% - 50px);
}

.stageItemTitle > div:first-child {
  font-size: 16px;
  font-weight: 700;
  min-width: 55px;
}

.stageItemTitle > div:last-child {
  font-size: 15px;
  font-weight: 600;
  max-width: 242px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  width: calc(100% - 55px);
}

.mlImg {
  width: 16px !important;
  min-width: 16px;
  cursor: pointer;
  height: 16px;
  /* -webkit-transform: rotate(180deg); */
  /* transform: rotate(180deg); */
  /* margin-bottom: 10px; */
}

.mlImg_1 {
  width: 16px !important;
  min-width: 16px;
  cursor: pointer;
  height: 16px;
  /* margin-bottom: 10px; */
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  /* text-align: center; */
}

#result-1 .el-tooltip {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

#result-1 .stageTitle {
  padding: 16px;
  /* border-bottom: 1px solid #E7E7E7; */
  width: 96%;
  display: flex;
}

#result-1 .stageTitle .stageItemTitle > div:first-child {
  min-width: 55px;
  margin-right: 15px;
  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
  font-family: SimSun;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

#result-1 .stageTitle .stageItemTitle > div:last-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
  font-family: SimSun;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.stageTitle .stageItemTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  /* width: 60%; */
}

@media screen and (max-width: 960px){ 
  #result-1 .stageTitle{
    width: 100%;
  }

  #result-1 .stageTitle .stageItemTitle {
    width: 100%;
  }

  .mulu2 #runDemo {
    position: absolute;
    z-index: 2011;
    left: 0%;
    bottom: 0;
    max-height: 100%;
    height: 50%;
    border-radius: 20px 20px 0px 0px;
    background: #FFF;
    width: 100%;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08), 0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 6px 30px 5px rgba(0, 0, 0, 0.05);
  }

  .mulu2 #runDemo .left-side {
    border-radius: 20px 20px 0px 0px;
    padding: 8px 16px;
    height: calc(100% - 49px);
    overflow: hidden;
  }

  .mulu2 #runDemo .close{
    top: 15px;
    right: 20px;
  }

  .mulu2 #runDemo .text-1 {
    line-height: 20px;
  }

  .mulu2 #runDemo .text-other {
    color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
    line-height: 20px;
    font-size: 14px;
  }

  .mulu2 #runDemo .text-select {
    color: var(--Brand-Normal, #3681FC);
  }

  #result-1 #scroll-box2 {
    padding: 0 15px 16px 15px;
  }

  #result-1 .right_top p {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6)) !important;
    /* body/small */
    font-family: SimSun;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 24px !important;
  }

  #result-1 .right_top p span {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6)) !important;
    /* body/small */
    font-family: SimSun;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 24px !important;
  }

  #result-1 .right_top .span_ai {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6)) !important;
    /* body/small */
    font-family: SimSun;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 24px !important;
  }

  #result-1 .right_top_title {
    color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
    font-family: SimSun;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  #result-1 .stageTitle .stageItemTitle>div:first-child {
    min-width: 55px;
    margin-right: 15px;
    color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
    font-family: SimSun;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  #result-1 .stageTitle .stageItemTitle>div:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
    font-family: SimSun;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  #result-1 .right_top {
    /* width: 100%; */
    height: auto;
    line-height: 1;
    /* background: #ffffff; */
    box-shadow: none;
    border-radius: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 8px 0px 0px 0px;
  }

  .myModal {
    display: none; /* 默认隐藏 */
    position: fixed; /* 固定定位 */
    z-index: 1; /* 置于顶层 */
    left: 0;
    top: 0;
    width: 100%; /* 宽度100% */
    height: 100%; /* 高度100% */
    overflow: auto; /* 如果需要滚动条则显示 */
    background-color: rgb(0,0,0); /* 背景颜色 */
    background-color: rgba(0,0,0,0.9); /* 背景颜色，带有透明度 */
    max-width: 100%;
  }

  .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    /* max-width: 700px; */
  }

  .modal-content, #caption {
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
  }

  .myclose {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .myclose:hover,
  .myclose:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
}
</style>