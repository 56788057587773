<template>
  <div
    style="height:93%;display: flex;flex-direction: row;justify-content: center;align-items: center;">
    <codemirror ref="mycode" v-model="curCodeCopy" :options="cmOptions" class="code">
    </codemirror>
    <div :style="'width: ' + width + '%; height:' + height + '%;background: #ffffff;border:1px solid rgb(209 213 219);'">
      <svg ref="svg" id="markmap" style="width:100%;height:100%;background:#fff;" viewBox="0 50 680 800"></svg>
    </div>
  </div>
</template>

<script>
import { Transformer } from 'markmap-lib';
import { codemirror } from 'vue-codemirror'
import "codemirror/theme/monokai.css"; // 这里引入的是主题样式，根据设置的theme的主题引入，一定要引入！！
require("codemirror/mode/javascript/javascript"); // 这里引入的模式的js，根据设置的mode引入，一定要引入！！
export default {
  name: "MarkDown",
  props: {
    width: Number,
    height: Number,
    curCode: String
  },
  data() {
    return {
      Markmap: null,
      cmOptions: {
        // autorefresh: true,
        // tabSize: 4,
        // mode: 'text/x-properties',
        // theme: 'ayu-mirage',
        // line: true,
        // viewportMargin: Infinity, //处理高度自适应时搭配使用
        // highlightDifferences: true,
        // autofocus: false,
        // indentUnit: 2,
        // smartIndent: true,
        // readOnly: true, // 只读
        // showCursorWhenSelecting: true,
        // firstLineNumber: 1
        lineNumbers: true, // 显示行号
        mode: 'text/x-yaml', // 语法model
        gutters: ['CodeMirror-lint-markers'], // 语法检查器
        theme: "ambiance",
        lint: true, // 开启语法检查
      },
      curCodeCopy:this.curCode
    }
  },
  components: {
    codemirror
  },
  watch: {
    curCode(newVal){
      this.curCodeCopy=newVal
    },
    curCodeCopy(newVal) {
      // console.log(newVal)
      document.getElementById("markmap").innerHTML = ''
      const transformer = new Transformer();
      const { root } = transformer.transform(newVal);
      this.Markmap.create('#markmap', null, root);
      this.$emit("getCode", newVal);
    }
  },
  mounted() {
    document.getElementById("markmap").innerHTML = ''
    const transformer = new Transformer();

    // 1. transform markdown
    // let markdown = this.curCode.repeat(/\n/g, ' ')
    // console.log(markdown)
    
    const { root } = transformer.transform(this.curCodeCopy);

    // 2. get assets
    // either get assets required by used features
    //const { styles, scripts } = transformer.getUsedAssets(features);
    // or get all possible assets that could be used later
    const { styles, scripts } = transformer.getAssets();
    const { Markmap, loadCSS, loadJS } = window.markmap;
    
    // 1. load assets
    if (styles) loadCSS(styles);
    if (scripts) loadJS(scripts, { getMarkmap: () => window.markmap });

    this.Markmap = Markmap

    // 2. create markmap

    Markmap.create('#markmap', null, root);

    // or pass an SVG element directly
    // const svgEl = document.querySelector('#markmap');
    // Markmap.create(svgEl, null, this.markdown);
  },
  methods:{
    handle_download(){
      const down = document.querySelector('#markmap');
      const g = document.querySelector('g');
      console.log(g.getter)
      //g.transform="translate(1.814679541311989,263.6376913996352) scale(0.3772495241545533)";
      const xmlDom = new XMLSerializer().serializeToString(down); //将设置好颜色的div转为xml

      const xml = `<?xml version="1.0" standalone="no"?>\r\n${xmlDom}`; //生成xml文件

      const src = `data:image/svg+xml;chartset=utf-8,${encodeURIComponent(
        xml
      )}`; //生成src
      const img = new Image();
      img.src = src;
      img.style = "display:none;";
      document.body.appendChild(img);
      const canvas = document.createElement("canvas");
      const canWidth = window.innerWidth,canHeight = window.innerHeight;
      canvas.width = canWidth;
      canvas.height = canHeight;

      const context = canvas.getContext("2d");

      img.onload = () => {
        context.drawImage(img, 0, 0, canWidth, canHeight);
        const a = document.createElement("a");
        a.download = `markmap.jpg`;
        a.href = canvas.toDataURL("image/jpeg", 1);
        a.click();
      };      
    }
  }
}
</script>

<style></style>