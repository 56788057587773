<template>
  <div class="MessageDialog2" :style="bg_color?`background:${bg_color}`:''" @click="skip_card">
    <i class="el-icon-circle-close xx" @click="close"></i>
    <div v-if="font">
      {{ font }}
    </div>
    <div v-else>
      {{ data.name }}{{ languageData.tip29 }}{{data.length}}{{ languageData.tip30 }}
    </div>
  </div>
</template>

<script>
import LogicFlow from "@logicflow/core";
export default {
  name: "MessageDialog",
  props: {
    data:Object,
    lf:LogicFlow,
    bg_color:String,
    font:String
  },
  data() {
    return {
      languageData: {},
    }
  },
  mounted() {
    //console.log(this.font)
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    }
    else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    }else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    }
    else {
      this.languageData = this.zhHansLanguage;
    }
  },
  methods: {
    skip_card(){
      this.lf.focusOn({
        id:this.data.id
      })
    },
    close(){
      this.$emit("close",false)
    }
  }
};
</script>
<style scoped>
.MessageDialog2{
  width: 300px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  border-radius: 5px;
  border: #d8ed61;
  background: #f16d9e;
  text-align: center;
  box-shadow: 3px 4px 5px 2px;
}
.xx{
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
</style>