<template>
  <div>
    <div id="aiAgent">
      <el-dialog
        :visible.sync="dialogVisible"
        width="59%"
        :destroy-on-close="true"
        :fullscreen="fullscreen"
        :show-close="false"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <div slot="title" style="width: 100%; position: relative">
          <span class="el-dialog__title">{{
            id == "" ? languageData.tip112 : languageData.tip113
          }}</span>
          <button type="button" class="max" v-if="!fullscreen" style="top: 4px">
            <img
              v-if="hoverMax"
              src="../../assets/max_pre.png"
              width="16"
              height="16"
              @mouseout="blurMax"
              @click="clickMax"
            />
            <img
              v-else
              src="../../assets/max_nor.png"
              width="16"
              height="16"
              @mouseover="focusMax"
            />
          </button>
          <button type="button" class="max" v-else-if="fullscreen" style="top: 4px">
            <img
              v-if="hoverRecovery"
              src="../../assets/recovery_pre.png"
              width="16"
              height="16"
              @mouseout="blurRecovery"
              @click="clickRecovery"
            />
            <img
              v-else
              src="../../assets/recovery_nor.png"
              width="16"
              height="16"
              @mouseover="focusRecovery"
            />
          </button>

          <button type="button" class="close" style="top: 4px">
            <img
              v-if="hoverClose"
              src="../../assets/close_pre.png"
              width="16"
              height="16"
              @mouseout="blurClose"
              @click="handleClose"
            />
            <img
              v-else
              src="../../assets/close_nor.png"
              width="16"
              height="16"
              @mouseover="focusClose"
            />
          </button>
        </div>
        <div style="height: 100%">
          <div
            style="
              display: flex;
              padding: 16px;
              justify-content: center;
              align-items: center;
              background: var(--font-icon-white, #fff);
              flex-direction: column;
            "
          >
            <div
              style="
                display: flex;
                width: 100%;
                align-content: center;
                justify-content: center;
              "
              @click="changeHeadUrl"
            >
              <el-avatar :size="80" :src="headUrl"></el-avatar>
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-content: center;
                justify-content: center;
                margin-top: 15px;
              "
            > 
              <span style="color:red;width: 10px;display: flex;align-items: center;">*  </span>
              <span class="name" v-if="!isEdit" :style="assistantName != '' ? 'color:rgba(0, 0, 0, 0.9);' : ''">{{
                assistantName == "" ? "编辑名称" : assistantName
              }}</span>
              <el-input type="text" :value="assistantName" @input="getAssistantName" @change="getAssistantName" @blur="isEdit = false"
              :placeholder="languageData.themeDesc" style="width:20%;" v-else></el-input>
              <div
                style="
                  line-height: 26px;
                  letter-spacing: 0em;
                  width: 16px;
                  margin-left: 5px;
                  margin-top: 2px;
                "
                @click="isEdit = true"
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.93 0.138C7.02477 0.0477096 7.15116 -0.00181864 7.28204 5.10757e-05C7.41292 0.00192079 7.53785 0.0550394 7.63 0.148L10.355 2.8965C10.4031 2.94497 10.4408 3.00279 10.4657 3.06638C10.4906 3.12998 10.5023 3.19801 10.4999 3.26627C10.4975 3.33453 10.4811 3.40158 10.4518 3.46327C10.4225 3.52497 10.3809 3.58001 10.3295 3.625L3.7635 9.376C3.66963 9.45834 3.54834 9.50257 3.4235 9.5L0.9895 9.448C0.85872 9.44525 0.734224 9.39136 0.642715 9.29789C0.551206 9.20442 0.499971 9.07881 0.5 8.948V6.476C0.499987 6.40831 0.513719 6.34132 0.540362 6.2791C0.567005 6.21687 0.606006 6.16071 0.655 6.114L6.93 0.138ZM7.5935 8.177L11.341 8.25C11.4067 8.25128 11.4714 8.26548 11.5316 8.29179C11.5918 8.3181 11.6462 8.35601 11.6917 8.40334C11.7372 8.45068 11.773 8.50651 11.7969 8.56767C11.8209 8.62882 11.8325 8.69409 11.8313 8.75975C11.83 8.82541 11.8158 8.89018 11.7895 8.95035C11.7631 9.01052 11.7252 9.06492 11.6779 9.11045C11.6306 9.15597 11.5747 9.19173 11.5136 9.21567C11.4524 9.23961 11.3872 9.25128 11.3215 9.25L7.574 9.177C7.50834 9.17569 7.44358 9.16145 7.38342 9.13511C7.32326 9.10877 7.26888 9.07084 7.22338 9.02348C7.17787 8.97612 7.14215 8.92027 7.11823 8.8591C7.09432 8.79794 7.08269 8.73266 7.084 8.667C7.08531 8.60134 7.09955 8.53658 7.12589 8.47642C7.15223 8.41626 7.19016 8.36188 7.23752 8.31637C7.28488 8.27087 7.34073 8.23515 7.4019 8.21123C7.46306 8.18732 7.52834 8.17569 7.594 8.177H7.5935ZM7.265 1.2L1.5 6.69V8.4585L3.25 8.496L9.2705 3.223L7.265 1.2ZM11.3165 10.677C11.4491 10.676 11.5767 10.7277 11.6712 10.8208C11.7656 10.9139 11.8193 11.0406 11.8202 11.1732C11.8212 11.3059 11.7695 11.4334 11.6765 11.5279C11.5834 11.6224 11.4566 11.676 11.324 11.677L1.504 11.75C1.37139 11.751 1.24382 11.6993 1.14935 11.6062C1.05488 11.5131 1.00124 11.3864 1.00025 11.2537C0.999256 11.1211 1.05098 10.9936 1.14404 10.8991C1.23711 10.8046 1.36389 10.751 1.4965 10.75L11.3165 10.677Z"
                    fill="black"
                    fill-opacity="0.6"
                  />
                </svg>
              </div>
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-content: center;
                justify-content: center;
                margin-top: 15px;
              "
            >
              <div style="width: 60%; display: flex">
                <span class="describe" style="margin-right: 20px"><span style="color:red;">* </span>{{
                  languageData.describe
                }}</span>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4 }"
                  :value="desc"
                  @input="getDescTextareaValue"
                  @change="$_changeDesc"
                  :placeholder="languageData.searchImagesDesc"
                />
              </div>
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-content: center;
                justify-content: center;
                margin-top: 15px;
              "
            >
              <div style="width: 60%; display: flex">
                <span class="describe" style="margin-right: 20px"><span style="color:red;">* </span>prompt</span>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4 }"
                  :value="instructions"
                  @input="getInstructionsTextareaValue"
                  @change="getInstructionsTextareaValue"
                  :placeholder="languageData.searchImagesDesc"
                />
              </div>
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-content: center;
                justify-content: center;
                margin-top: 15px;
              "
            >
              <div style="width: 60%; display: flex">
                <span class="describe" style="margin-right: 20px">{{
                  languageData.knowledge_base
                }}</span>
                <el-upload
                  class="upload-demo"
                  action="#"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :limit="20"
                  :file-list="fileList"
                  :auto-upload="false" accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx"
                  :on-change="uploadFile"
                >
                  <el-button size="small" type="primary">{{
                    languageData.upload_file
                  }}</el-button>
                  <div slot="tip" class="el-upload__tip">
                    {{ languageData.upload_file_desc }}
                  </div>
                </el-upload>
              </div>
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-content: center;
                justify-content: center;
                margin-top: 15px;
              "
            >
              <div style="width: 60%; display: flex">
                <span class="describe" style="margin-right: 20px"><span style="color:red;">* </span>{{
                  languageData.prologue
                }}</span>
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 4 }"
                  :value="prologue"
                  @input="getPrologueTextareaValue"
                  @change="getPrologueTextareaValue"
                  :placeholder="languageData.searchImagesDesc"
                />
              </div>
            </div>
            <div
              style="
                display: flex;
                width: 100%;
                align-content: center;
                justify-content: center;
                margin-top: 15px;
              "
            >
              <div style="width: 60%; display: flex" class="isPublish">
                <span class="describe" style="margin-right: 20px">{{
                  languageData.isPublish
                }}</span>
                <el-select v-model="isPublish" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveAIAgent" style="margin-right:20px;">{{ languageData.store }}</el-button>
        </span>
      </el-dialog>
    </div>
    <HeadUrlDialog :dialogVisible="HeadUrlDialogVisible" @info="getInfo" @setHeadUrl="setHeadUrl" @setNetwork="setNetwork"></HeadUrlDialog>
    <NetworkImageDialog :dialogVisible="networkImageDialogVisible" @info="getNetworkInfo" @setHeadUrl="setHeadUrl" :assistantName="assistantName"></NetworkImageDialog>
  </div>
</template>

<script>
import $ from "jquery";
import HeadUrlDialog from '../dialog/HeadUrlDialog.vue';
import NetworkImageDialog from '../dialog/NetworkImageDialog.vue';
export default {
  props: {
    id: String,
    // selectIndex: Array,
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hoverMax: false,
      hoverRecovery: false,
      hoverClose: false,
      fullscreen: false,
      languageData: {},
      headUrl: "https://beta.cloud.cocorobo.cn/aigpt/img/ai_agent_header.88be856f.png",
      assistantName: "",
      desc: "",
      instructions: "",
      prologue: "",
      fileList: [],
      fileIds: [],
      fileNames: [],
      options: [
        {
          value: 0,
          label: "private",
        },
        {
          value: 1,
          label: "public",
        },
      ],
      isPublish: 1,
      assistantId: '',
      isEdit: false,
      HeadUrlDialogVisible: false,
      networkImageDialogVisible: false,
    };
  },
  watch: {
    id(newVal) {
      if (newVal != "") {
        console.log(newVal);
        this.getAIAgent(newVal)
      }
      else {
        this.prologue = ""
        this.headUrl = "https://beta.cloud.cocorobo.cn/aigpt/img/ai_agent_header.88be856f.png"
        this.desc = ""
        this.fileIds = []
        this.fileNames = []
        this.fileList = []
        this.instructions = ""
        this.assistantName = ""
        this.isPublish = 1
        this.assistantId = ""
      }
    },
  },
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://sdk.amazonaws.com/js/aws-sdk-2.235.1.min.js";
    script.async = "async";
    script.onload = function () {
      var credentials = {
        accessKeyId: "AKIATLPEDU37QV5CHLMH",
        secretAccessKey: "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",
      }; //秘钥形式的登录上传
      window.AWS.config.update(credentials);
      window.AWS.config.region = "cn-northwest-1"; //设置区域
    };
    document.body.appendChild(script);
  },
  methods: {
    uploadFile(nfile, fileList) {
      var file = nfile.raw;
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      _this.fileList = fileList;
      if (file) {
        let fileType2 = file.name.split(".");
        let fileType = fileType2[fileType2.length - 1];
        let timestamp = new Date().getTime();
        var params = {
          Key: file.name.split(".")[0] + timestamp + "." + fileType,
          ContentType: file.type,
          Body: file,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket
          .upload(params, options)
          .on("httpUploadProgress", function (e) {
            console.log(e)
            // _this.isFileProgress = true;
            // _this.fileProgressPercent = Number(
            //   ((e.loaded / e.total) * 100).toFixed(2)
            // );
            // if (_this.fileProgressPercent == 100) {
            //   _this.isFileProgress = false;
            // }
          })
          .send(function (err, data) {
            if (err) {
              console.log(err);
            } else {
              // console.log(data.Location);
              console.log(data);
              _this.uploadFileAI(data.Key,data.Location)
            }
          });
      }
    },
    uploadFileAI(name,url) {
      let that = this;
      var config = {
        method: "PUT",
        url: "https://gpt4.cocorobo.cn/upload_file_knowledge",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          url: url,
        },
      };
      this.$ajax(config)
        .then((res) => {
          that.fileNames.push(name)
          that.fileIds.push(res.data.FunctionResponse.result.id)
          that.fileList.at(-1).name=name;
          console.log(that.fileNames,that.fileIds,that.fileList)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveAIAgent() {
      let that = this;
      if(that.prologue != "" && that.desc != "" && that.instructions != "" && that.assistantName != "") {
        var config = {
          method: "POST",
          url: that.id !='' ? "https://gpt4.cocorobo.cn/update_ai_agent_assistants" : "https://gpt4.cocorobo.cn/create_ai_agent_assistants",
          headers: {
            "Content-Type": "application/json",
          },
          data: that.id !='' ? {
            id: that.id,
            prologue: that.prologue,
            headUrl: that.headUrl,
            desc: that.desc,
            fileIds: that.fileIds,
            fileNames: that.fileNames,
            userId: window.location.href.indexOf("localhost") != -1 || window.location.href.indexOf("192.168") != -1 ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01" : window.parent.US.userInfo.userid,
            instructions: that.instructions,
            assistantName: that.assistantName,
            isRetrieval: 1,
            isCode: 1,
            isGoogle: 0,
            isDalleImage: 0,
            functionNames: "",
            functionContents: "",
            tools: [
              {
                type: "file_search" 
              },  
              {
                type: "code_interpreter"
              }
            ],
            isPublish: that.isPublish,
            assistantId: that.assistantId
          } : {
            prologue: that.prologue,
            headUrl: that.headUrl,
            desc: that.desc,
            fileIds: that.fileIds,
            fileNames: that.fileNames,
            userId: window.location.href.indexOf("localhost") != -1 || window.location.href.indexOf("192.168") != -1 ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01" : window.parent.US.userInfo.userid,
            username: window.location.href.indexOf("localhost") != -1 || window.location.href.indexOf("192.168") != -1 ? '潘炜桐' : window.parent.US.userInfo.username,
            instructions: that.instructions,
            assistantName: that.assistantName,
            isRetrieval: 1,
            isCode: 1,
            isGoogle: 0,
            isDalleImage: 0,
            functionNames: "",
            functionContents: "",
            tools: [
              {
                type: "file_search" 
              },  
              {
                type: "code_interpreter"
              }
            ],
            isPublish: that.isPublish
          },
        };
        that.$ajax(config)
          .then((res) => {
            console.log(res)
            that.$emit("saveAIAgent", true);
            that.prologue = ""
            that.headUrl = "https://s3-alpha-sig.figma.com/img/cf0a/9d6d/e2c3e4f8f7c45c79e9955a1fe8c21ef6?Expires=1707696000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CwSh6-WSSiW4X7tgHNh3iKYVTIcI~5qt58TB656r7bsdYjOc3s7Xel-zQnNa48I5gwNu~toKw9BFcqbFpEGGTAqccwp8ri3fKz5by1QgwCRRFUbA8zYK7n45311WfwBKYeE7ZfBFdTfoyVWfub7VHrXEc8cQKpaTFg8Su8z5icFBMrskQ86hmAiyLo56351fPLRQRaZQuQV0mSzgImBYiSU0c43NWxFYmaHdygReGja6q4PTFK~-hpIGUUYRD0PLEuJOc2olo6p~-x9HkNWYJ2J--E-veZmp6xipQksbidj5cR4h-6Py9AFgkgx5XB89z0YMSuyvw5ClpY-9W3-HUw__"
            that.desc = ""
            that.fileIds = []
            that.fileNames = []
            that.fileList = []
            that.instructions = ""
            that.assistantName = ""
            that.isPublish = 1
            that.assistantId = ""
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else {
        that.$message({
          message: that.languageData.sumbit_error_message,
          type: "warning",
        });
      }
    },
    getAIAgent(id) {
      // let that = this;
      var config = {
        method: "POST",
        url: "https://gpt4.cocorobo.cn/get_ai_agent_assistant_id",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: id
        },
      };
      this.$ajax(config)
        .then((res) => {
          console.log(res)
          let result = JSON.parse(res.data.FunctionResponse.result)
          // this.$emit("saveAIAgent", true);
          this.prologue = result[0].prologue
          this.headUrl = result[0].headUrl
          this.desc = result[0].description
          this.fileIds = result[0].file_ids != null && result[0].file_ids != '' ? result[0].file_ids.split(",") : []
          this.fileNames = result[0].file_names != null && result[0].file_names != '' ? result[0].file_names.split(",") : []
          this.fileList = []
          for(let i = 0;i < this.fileNames.length;i++) {
            this.fileList.push({ name: this.fileNames[i], url: "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/" + this.fileNames[i] })
          }
          this.instructions = result[0].instructions
          this.assistantName = result[0].assistantName
          this.isPublish = result[0].isPublish
          this.assistantId = result[0].assistant_id
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      let index = 0;
      for(let i = 0;i < this.fileNames.length;i++) {
        if(this.fileNames[i] == file.name) {
          index = i;
          break;
        }
      }
      this.fileNames.splice(index,1);
      this.fileIds.splice(index,1);
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeRemove(file, fileList) {
      console.log(fileList);
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    getDescTextareaValue(e) {
      this.desc = e;
    },
    $_changeDesc(e) {
      this.desc = e;
    },
    getInstructionsTextareaValue(e) {
      this.instructions = e;
    },
    getPrologueTextareaValue(e) {
      this.prologue = e;
    },
    getAssistantName(e) {
      this.assistantName = e
    },
    focusMax() {
      this.hoverMax = true;
      this.hoverRecovery = false;
      this.hoverClose = false;
    },
    blurMax() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverClose = false;
    },
    focusRecovery() {
      this.hoverMax = false;
      this.hoverRecovery = true;
      this.hoverClose = false;
    },
    blurRecovery() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverClose = false;
    },
    focusClose() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverClose = true;
    },
    blurClose() {
      this.hoverMax = false;
      this.hoverRecovery = false;
      this.hoverClose = false;
    },
    handleClose() {
      this.fullscreen = false;
      $("#aiAgent .el-dialog__body").css({
        height: "69%",
      });
      this.$emit("info", false);
      // this.$emit("clearList", []);
    },
    clickMax() {
      this.fullscreen = true;
      $("#aiAgent .el-dialog__body").css({
        height: "calc(100% - 41px)",
      });
    },
    clickRecovery() {
      this.fullscreen = false;
      $("#aiAgent .el-dialog__body").css({
        height: "69%",
      });
    },
    changeHeadUrl() {
      this.$emit("info", false);
      this.HeadUrlDialogVisible = true
    },
    getInfo(value) {
      this.$emit("info", true);
      this.HeadUrlDialogVisible = value
    },
    setHeadUrl(value) {
      this.headUrl = value
    },
    setNetwork(value) {
      this.$emit("info", false);
      this.networkImageDialogVisible = value
    },
    getNetworkInfo(value) {
      this.$emit("info", true);
      this.networkImageDialogVisible = value
    }
  },
  components: {
    HeadUrlDialog,
    NetworkImageDialog
  }
};
</script>

<style scoped>
.name {
  font-family: SimSun;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
}

.describe {
  font-family: SimSun;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 0.9);
  min-width: 80px;
}
</style>

<style>
.isPublish .el-input__inner {
  height: 40px;
  line-height: 40px;
}

#aiAgent .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
}

#aiAgent .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-white, #fff);
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 8px 10px -5px #00000014;
  box-shadow: 0px 16px 24px 2px #0000000a;
  box-shadow: 0px 6px 30px 5px #0000000d;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
}

#aiAgent .el-dialog__body {
  width: 100%;
  height: 69%;
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  /* border-bottom: 1px solid var(--bg3, #E7E7E7); */
  /* box-shadow: 0px 8px 10px -5px #00000014;
  box-shadow: 0px 16px 24px 2px #0000000a;
  box-shadow: 0px 6px 30px 5px #0000000d; */
  /* background: var(--font-icon-white, #FFF); */
  padding: 0px;
  /* border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px; */
  background: #ffffff;
  overflow-y:auto;
  overflow-x:hidden;
}

#aiAgent .el-dialog__footer {
  box-sizing: initial;
  padding: 16px 0px;
  width: 100%;
  height: 31px;
  background: #ffffff;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 8px 10px -5px #00000014;
  box-shadow: 0px 16px 24px 2px #0000000a;
  box-shadow: 0px 6px 30px 5px #0000000d;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* #aiAgent .dialog-footer {
  margin-right: 16px;
} */

#aiAgent .el-dialog__headerbtn {
  top: none;
}

#aiAgent i:hover {
  color: #5cb6ff;
}

#aiAgent .max {
  position: absolute;
  top: 0;
  right: 25px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#aiAgent .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#aiAgent .el-dialog.is-fullscreen {
  overflow: hidden;
}

.el-avatar > img {
  width: 100%;
}
</style>
