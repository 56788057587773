<template>
    <div class="demonstrate" id="DemoResult" :style="dialogVisible ? 'display:block;': 'display:none;'" style="width:100%;height:100%;">
        <!-- <el-dialog :visible.sync="dialogVisible" :destroy-on-close="true" :close-on-click-modal="false" :close-on-press-escape="false"
            :show-close="false" width="100%" style="width:100%;"> -->
            <div style="height:100%;background:#fff;">
                <div style="height:100%;background:#fff;position: relative;">
                    <template v-for="(item, index) in list">
                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-theme' && Show" v-bind:key="index" id="icon-theme"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height: 100%;">
                                <div class="left-top-1" style="background: var(--brand-normal, #3681FC);"></div>
                                <div class="left-top-2" style="background: var(--brand-normal, #3681FC);position: absolute;top: 0;"></div>
                                <div id="content" class="themeContent" style="position: absolute;display:flex;" >
                                    <div style="width:100%;display: flex;justify-content: center;align-items: center;flex-direction: column;">
                                        <div class="themeDiv" style="background: var(--brand-normal, #3681FC);text-align: center;">
                                            <span class="text">{{ languageData.topic }}</span>
                                        </div>
                                        <div class="themeText">{{ item.properties.topic }}</div>
                                    </div>
                                </div>
                                <div class="left-top-3" style="background: var(--brand-normal, #3681FC);position: absolute;right:0;"></div>
                                <div class="left-top-2" style="background: var(--brand-normal, #3681FC);position: absolute;bottom: 0;right:0;"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-stage' && Show" v-bind:key="index" id="icon-stage"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height: 100%;">
                                <div class="left-top-1" :style="item.properties.stageTitleColor ? 'background:' + item.properties.stageTitleColor +';' : 'background: #1D51B6;'"></div>
                                <div class="left-top-2" :style="item.properties.stageTitleColor ? 'position: absolute;top: 0;background:' + item.properties.stageTitleColor +';' : 'background: #1D51B6;position: absolute;top: 0;'"></div>
                                <div id="content" class="stageContent_1" style="position: absolute;display:flex;" v-if="item.properties.stageContent && item.properties.stageContent != '<p></p>'">
                                    <div style="width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <div class="themeDiv" :style="item.properties.stageTitleColor ? 'text-align: center;background:' + item.properties.stageTitleColor +';' : 'background: #1D51B6;text-align: center;'">
                                            <span class="text">{{ languageData.stageName }}</span>
                                        </div>
                                        <div v-if="item.properties.stageName && item.properties.stageName !=''" class="stageName">{{ item.properties.stageName }}</div>
                                        <div v-if="!isPhone" :style="item.properties.stageName && item.properties.stageName !='' ? 'width:100%;height:calc(100% - 248px);display:flex;justify-content: center;' : 'margin-top:24px;width:100%;height:calc(100% - 80px);display:flex;justify-content: center;'">
                                            <span v-html="item.properties.stageContent" style="overflow-y: auto;height: 100%;width: 100%;" id="content1"></span>
                                        </div>
                                        <div v-else :style="item.properties.stageName && item.properties.stageName !='' ? 'width:100%;height:calc(100% - 98.5px);display:flex;justify-content: center;' : 'margin-top:12.5px;width:100%;height:calc(100% - 48px);display:flex;justify-content: center;'">
                                            <span v-html="item.properties.stageContent" style="overflow-y: auto;height: 100%;width: 100%;" id="content1"></span>
                                        </div>
                                    </div>
                                </div>
                                <div id="content" class="stageContent_2" style="position: absolute;display:flex;" v-else>
                                    <div style="width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <div class="themeDiv" :style="item.properties.stageTitleColor ? 'text-align: center;background:' + item.properties.stageTitleColor +';' : 'background: #1D51B6;text-align: center;'">
                                            <span class="text">{{ languageData.stageName }}</span>
                                        </div>
                                        <div class="themeText">{{ item.properties.stageName }}</div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.stageTitleColor ? 'position: absolute;right:0;background:' + item.properties.stageTitleColor +';' : 'background: #1D51B6;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.stageTitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.stageTitleColor +';' : 'background: #1D51B6;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-task' && Show" v-bind:key="index" id="icon-task"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height: 100%;">
                                <div class="left-top-1" :style="item.properties.taskTitleColor ? 'background:' + item.properties.taskTitleColor +';' : 'background: #6E44C9;'"></div>
                                <div class="left-top-2" :style="item.properties.taskTitleColor ? 'position: absolute;top: 0;background:' + item.properties.taskTitleColor +';' : 'background: #6E44C9;position: absolute;top: 0;'"></div>
                                <div id="content" class="stageContent_1" style="position: absolute;display:flex;" v-if="item.properties.taskContent && item.properties.taskContent != '<p></p>'">
                                    <div style="width: 100%;">
                                        <div class="themeDiv" :style="item.properties.taskTitleColor ? 'text-align: center;background:' + item.properties.taskTitleColor +';' : 'background: #6E44C9;text-align: center;'">
                                            <span class="taskText">{{ languageData.taskName }}</span>
                                        </div>
                                        <div class="taskName" v-if="item.properties.taskTitle && item.properties.taskTitle != ''">{{ item.properties.taskTitle }}</div>
                                        <div v-if="!isPhone" :style="item.properties.taskTitle && item.properties.taskTitle != '' ? 'width:100%;height:calc(100% - 248px);display:flex;justify-content: center;' : 'margin-top:24px;width:100%;height:calc(100% - 80px);display:flex;justify-content: center;'">
                                            <span v-html="item.properties.taskContent" style="overflow-y: auto;height: 100%;width: 100%;" id="content2"></span>
                                        </div>
                                        <div v-else :style="item.properties.taskTitle && item.properties.taskTitle != '' ? 'width:100%;height:calc(100% - 98.5px);display:flex;justify-content: center;' : 'margin-top:12.5px;width:100%;height:calc(100% - 48px);display:flex;justify-content: center;'">
                                            <span v-html="item.properties.taskContent" style="overflow-y: auto;height: 100%;width: 100%;" id="content2"></span>
                                        </div>
                                    </div>
                                </div>
                                <div id="content" class="stageContent_2" style="position: absolute;display:flex;" v-else>
                                    <div style="width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <div class="themeDiv" :style="item.properties.taskTitleColor ? 'text-align: center;background:' + item.properties.taskTitleColor +';' : 'background: #6E44C9;text-align: center;'">
                                            <span class="text">{{ languageData.taskName }}</span>
                                        </div>
                                        <div class="themeText">{{ item.properties.taskTitle }}</div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.taskTitleColor ? 'position: absolute;right:0;background:' + item.properties.taskTitleColor +';' : 'background: #6E44C9;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.taskTitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.taskTitleColor +';' : 'background: #6E44C9;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-card' && Show" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div v-if="!item.properties.radio" style="height: 100%;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                
                                <div id="content" class="themeContent" style="position: absolute;display:flex;" >
                                    <div style="width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="themeText">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'content')" style="height: 100%;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                
                                <div id="content" class="stageContent_1" style="position: absolute;display:flex;" v-if="item.properties.content && item.properties.content !='<p></p>'">
                                    <div style="width:100%;">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                        <div v-if="!isPhone" style="width:100%;height:calc(100% - 144px);display:flex;justify-content: center;">
                                            <span v-html="item.properties.content" style="overflow-y: auto;height: 100%;width: 100%;" id="content1"></span>
                                        </div>
                                        <div v-else style="width:100%;height:calc(100% - 50.5px);display:flex;justify-content: center;">
                                            <span v-html="item.properties.content" style="overflow-y: auto;height: 100%;width: 100%;" id="content1"></span>
                                        </div>
                                    </div>
                                </div>
                                <div id="content" class="themeContent" style="position: absolute;display:flex;" v-else>
                                    <div style="width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="themeText">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'iframeUrl' && item.properties.iframeUrl != '')" style="height:100%;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:600px;margin-right:30px;" v-if="!isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width:300px;margin-right:30px;" v-else-if="isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width: 100%;background: var(--brand-light, #E0EAFB);justify-content: center;display: flex;align-items: center;">
                                        <iframe style="width: 100%;height: 90%;"
                                            :src="item.properties.iframeUrl.indexOf('https://') != -1 || item.properties.iframeUrl.indexOf('http://') != -1 ? item.properties.iframeUrl : '//' + item.properties.iframeUrl"
                                            frameborder="no" border="0" sandbox="allow-forms allow-scripts allow-same-origin"></iframe>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'embedCode')" style="height:100%;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:600px;margin-right:30px;" v-if="!isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width:300px;margin-right:30px;" v-else-if="isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div class="embedCode" style="width: 100%;background: var(--brand-light, #E0EAFB);justify-content: center;display: flex;align-items: center;" v-html="item.properties.embedCode">
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'imageList' && item.properties.imageList && item.properties.imageList.length != 0 && item.properties.imageList[0].url)" style="height:100%;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:600px;margin-right:30px;" v-if="!isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width:300px;margin-right:30px;" v-else-if="isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width: 100%;background: var(--brand-light, #E0EAFB);justify-content: center;display: flex;align-items: center;">
                                        <img style="width: auto;height: auto;max-width: 100%;max-height: 100%;"
                                            :src="item.properties.imageList[0].url" />
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'videoList' && item.properties.videoList && item.properties.videoList.length != 0 && item.properties.videoList[0].url)" style="height:100%;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:600px;margin-right:30px;" v-if="!isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width:300px;margin-right:30px;" v-else-if="isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width: 100%;background: var(--brand-light, #E0EAFB);justify-content: center;display: flex;align-items: center;">
                                        <video controls :src="item.properties.videoList[0].url"
                                            style="width: auto;height: auto;max-width: 100%;max-height: 100%;"></video>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'audioList' && item.properties.audioList && item.properties.audioList.length != 0 && item.properties.audioList[0].url)" style="height: 100%;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:100%;justify-content: center;display: flex;align-items: center;flex-direction:column;">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                        <audio controls :src="item.properties.audioList[0].url" style="width:450px;" v-if="!isPhone"></audio>
                                        <audio controls :src="item.properties.audioList[0].url" style="width:300px;" v-else></audio>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'fileList' && item.properties.fileList && item.properties.fileList.length != 0 && (item.properties.fileList[0].url.indexOf('.pdf') != -1 || item.properties.fileList[0].url.indexOf('.PDF') != -1))" style="height:100%;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:600px;margin-right:30px;" v-if="!isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width:300px;margin-right:30px;" v-else-if="isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width: 100%;background: var(--brand-light, #E0EAFB);justify-content: center;display: flex;align-items: center;">
                                        <iframe :style="isPhone ? 'position: absolute;width: 62%;height: 90%;top: 5%;' : 'position: absolute;width: 72%;height: 90%;top: 5%;'"
                                            :src="'https://cloud.cocorobo.cn/pdf.js/web/viewer.html?file=' + item.properties.fileList[0].url" frameborder="no" border="0" ></iframe>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'fileList' && item.properties.fileList && item.properties.fileList.length != 0 && item.properties.fileList[0].url.indexOf('.pdf') == -1 && item.properties.fileList[0].url.indexOf('.PDF') == -1)" style="height:100%;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:600px;margin-right:30px;" v-if="!isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width:300px;margin-right:30px;" v-else-if="isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width: 100%;background: var(--brand-light, #E0EAFB);justify-content: center;display: flex;align-items: center;">
                                        <iframe :style="isPhone ? 'position: absolute;width: 62%;height: 90%;top: 5%;' : 'position: absolute;width: 72%;height: 90%;top: 5%;'"
                                            :src="'https://view.officeapps.live.com/op/view.aspx?src=' + item.properties.fileList[0].url" frameborder="no" border="0" ></iframe>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else style="height: 100%;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                
                                <div id="content" class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="themeText">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div>

                        <!-- <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-select' && Show" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height:100%;">
                                <div class="left-top-1" :style="item.properties.selectTitleColor ? 'background:' + item.properties.selectTitleColor +';' : 'background: #F284B2;'"></div>
                                <div class="left-top-2" :style="item.properties.selectTitleColor ? 'position: absolute;top: 0;background:' + item.properties.selectTitleColor +';' : 'background: #F284B2;position: absolute;top: 0;'"></div>
                                <div class="themeContent" style="position: absolute;display:flex;" >
                                    <div style="width:100%;">
                                        <div style="color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 64px;font-style: normal;font-weight: 400;line-height: 112px;letter-spacing: 7.68px;">{{ item.properties.selectTitle }}</div>
                                        <div style="color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));font-family: SimSun;font-size: 32px;font-style: normal;font-weight: 400;line-height: 48px;">{{ item.properties.selectContent }}</div>
                                        <div style="margin-top:10px;display:flex;align-items: center;flex-wrap: wrap;">
                                            <template v-for="(option, index) in item.properties.selectOption">
                                                <el-radio v-bind:key="'letterItemList' + index" v-model="Radio" :label="option"
                                                    style="width: calc(50%);color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 32px;font-style: normal;font-weight: 400;" @input="setSelect">
                                                    {{ letterList[index] }}.
                                                    {{ option }}
                                                </el-radio>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.selectTitleColor ? 'position: absolute;right:0;background:' + item.properties.selectTitleColor +';' : 'background: #F284B2;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.selectTitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.selectTitleColor +';' : 'background: #F284B2;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div> -->

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-thinking' && Show" v-bind:key="index" style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height:100%;">
                                <div class="left-top-1" style="background: #6292BC;"></div>
                                <div class="left-top-2" style="background: #6292BC;position: absolute;top: 0;"></div>
                                <div class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div style="width: 280px;height: 80px;background: #6292BC;text-align: center;">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.thinkingCard }}</span>
                                        </div> -->
                                        <div style="width:100%;height:calc(100%);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: calc(100%);max-height: calc(100%);"
                                                :src="item.properties.thinkingContent ? item.properties.thinkingContent : 'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png'" />
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" style="background: #6292BC;position: absolute;right:0;"></div>
                                <div class="left-top-2" style="background: #6292BC;position: absolute;bottom: 0;right:0;"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-AI' && Show" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height: 100%;"
                                v-if="item.properties.radio == 'description'">
                                <div class="left-top-1" :style="item.properties.AITitleColor ? 'background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;top: 0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;top: 0;'"></div>
                                <div class="stageContent_1" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div :style="item.properties.AITitleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.AITitleColor +';' : 'width: 280px;height: 80px;background: #80BFB0;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.aiCard }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.topic }}</div>
                                        <div v-if="!isPhone" style="width:100%;height:calc(100% - 144px);display:flex;justify-content: center;">
                                            <span style="overflow-y: auto;" id="content1">{{ item.properties.aiContent }}</span>
                                        </div>
                                        <div v-else style="width:100%;height:calc(100% - 50.5px);display:flex;justify-content: center;">
                                            <span style="overflow-y: auto;" id="content1">{{ item.properties.aiContent }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.AITitleColor ? 'position: absolute;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'image' && item.properties.imgResult != '')"
                                style="height:100%;">
                                <div class="left-top-1" :style="item.properties.AITitleColor ? 'background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;top: 0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;top: 0;'"></div>
                                <div class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div :style="item.properties.AITitleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.AITitleColor +';' : 'width: 280px;height: 80px;background: #80BFB0;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.aiCard }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.topic }}</div>
                                        <div v-if="!isPhone" style="width:100%;height:calc(100% - 144px);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: 100%;max-height: 100%;"
                                                :src="item.properties.imgResult" />
                                        </div>
                                        <div v-else style="width:100%;height:calc(100% - 50.5px);display:flex;justify-content: center;">
                                            <img style="width: auto;height: auto;max-width: 100%;max-height: 100%;"
                                                :src="item.properties.imgResult" />
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.AITitleColor ? 'position: absolute;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-new-AI' && Show" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height: 100%;"
                                v-if="item.properties.radio == 'description'">
                                <div class="left-top-1" :style="item.properties.AITitleColor ? 'background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;top: 0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;top: 0;'"></div>
                                <div class="stageContent_1" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div :style="item.properties.AITitleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.AITitleColor +';' : 'width: 280px;height: 80px;background: #80BFB0;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.aiCard }}</span>
                                        </div> -->
                                        <div class="taskName" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;" :title="item.properties.topic">{{ item.properties.topic }}</div>
                                        <div v-if="!isPhone" style="width:100%;height:calc(100% - 144px);display:flex;justify-content: center;">
                                            <span style="overflow-y: auto;" id="content1">{{ item.properties.aiContent }}</span>
                                        </div>
                                        <div v-else style="width:100%;height:calc(100% - 50.5px);display:flex;justify-content: center;">
                                            <span style="overflow-y: auto;" id="content1">{{ item.properties.aiContent }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.AITitleColor ? 'position: absolute;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'image' && item.properties.imgResult != '')"
                                style="height:100%;">
                                <div class="left-top-1" :style="item.properties.AITitleColor ? 'background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;top: 0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;top: 0;'"></div>
                                <div class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div :style="item.properties.AITitleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.AITitleColor +';' : 'width: 280px;height: 80px;background: #80BFB0;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.aiCard }}</span>
                                        </div> -->
                                        <div class="taskName" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;" :title="item.properties.topic">{{ item.properties.topic }}</div>
                                        <div v-if="!isPhone" style="width:100%;height:calc(100% - 144px);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: 100%;max-height: 100%;"
                                                :src="item.properties.imgResult" />
                                        </div>
                                        <div v-else style="width:100%;height:calc(100% - 50.5px);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: 100%;max-height: 100%;"
                                                :src="item.properties.imgResult" />
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.AITitleColor ? 'position: absolute;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'audio' && item.properties.audioList.length != 0)"
                                style="height:100%;">
                                <div class="left-top-1" :style="item.properties.AITitleColor ? 'background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;top: 0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;top: 0;'"></div>
                                <div class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div :style="item.properties.AITitleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.AITitleColor +';' : 'width: 280px;height: 80px;background: #80BFB0;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.aiCard }}</span>
                                        </div> -->
                                        <div class="taskName" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;" :title="item.properties.topic">{{ item.properties.topic }}</div>
                                        <div v-if="!isPhone" style="width:100%;height:calc(100% - 144px);display:flex;justify-content: center;align-items: center;">
                                            <audio style="
                                                max-width: 100%;
                                                max-height: 100%;
                                                " :src="item.properties.audioList[0].url" controls></audio>
                                        </div>
                                        <div v-else style="width:100%;height:calc(100% - 50.5px);display:flex;justify-content: center;align-items: center;">
                                            <audio style="
                                                max-width: 100%;
                                                max-height: 100%;
                                                " :src="item.properties.audioList[0].url" controls></audio>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.AITitleColor ? 'position: absolute;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-whiteboard' && Show" v-bind:key="index" style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height:100%;">
                                <div class="left-top-1" style="background: #6292BC;"></div>
                                <div class="left-top-2" style="background: #6292BC;position: absolute;top: 0;"></div>
                                <div class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div style="width: 280px;height: 80px;background: #6292BC;text-align: center;">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.whiteboard_card }}</span>
                                        </div> -->
                                        <div style="width:100%;height:calc(100%);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: calc(100%);max-height: calc(100%);"
                                                :src="item.properties.whiteboardContent" v-if="item.properties.whiteboardContent && item.properties.whiteboardContent != ''"/>
                                            <p class="stageName" v-else>{{ languageData.tip76 }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" style="background: #6292BC;position: absolute;right:0;"></div>
                                <div class="left-top-2" style="background: #6292BC;position: absolute;bottom: 0;right:0;"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-map' && Show" v-bind:key="index" style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height:100%;">
                                <div class="left-top-1" style="background: #3B51C6;"></div>
                                <div class="left-top-2" style="background: #3B51C6;position: absolute;top: 0;"></div>
                                <div class="themeContent"  style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div style="width: 280px;height: 80px;background: #3B51C6;text-align: center;">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.map_card }}</span>
                                        </div> -->
                                        <div style="width:100%;height:calc(100%);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: calc(100%);max-height: calc(100%);"
                                                :src="item.properties.map_url" v-if="item.properties.lat && item.properties.lng && item.properties.map_url"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" style="background: #3B51C6;position: absolute;right:0;"></div>
                                <div class="left-top-2" style="background: #3B51C6;position: absolute;bottom: 0;right:0;"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-camera' && Show" v-bind:key="index" style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height:100%;">
                                <div class="left-top-1" style="background: #F5A9A9;"></div>
                                <div class="left-top-2" style="background: #F5A9A9;position: absolute;top: 0;"></div>
                                <div class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div style="width: 280px;height: 80px;background: #F5A9A9;text-align: center;">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.camera_card }}</span>
                                        </div> -->
                                        <div style="width:100%;height:calc(100%);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: calc(100%);max-height: calc(100%);"
                                                :src="item.properties.src" v-if="item.properties.src"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" style="background: #F5A9A9;position: absolute;right:0;"></div>
                                <div class="left-top-2" style="background: #F5A9A9;position: absolute;bottom: 0;right:0;"></div>
                            </div>
                        </div>
                    </template>
                    
                </div>
                <div class="animate__animated animate__zoomIn" style="width: 470px;height: 72px;border-top-right-radius: 36px;border-bottom-right-radius: 36px;background: rgba(238, 238, 238, 0.60);position: absolute;left:0;bottom:24px;" v-if="!isHiddenTools && !isPhone">
                    <div style="display:flex;height: 100%;align-items: center;">
                        <div class="exit" style="margin-left: 8px;width: 210px;height: 56px;border-radius: 100px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FFF;display: flex;justify-content: center;align-items: center;cursor:pointer;" @click="handleClose"  v-if="isExitHover">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M13.9996 25C12.6502 25 11.3398 24.7323 10.1072 24.2015C8.91576 23.6913 7.84732 22.9599 6.92907 22.028C6.01082 21.096 5.29022 20.01 4.78596 18.8016C4.26458 17.5506 4 16.2213 4 14.851C4 13.2114 4.39609 11.5836 5.1447 10.1414C5.86841 8.7482 6.91973 7.52479 8.18583 6.60388C8.53757 6.34798 9.02626 6.43091 9.27762 6.78711C9.52897 7.14331 9.44882 7.63852 9.09708 7.89284C8.02708 8.67316 7.13918 9.70465 6.52908 10.8807C5.88864 12.1136 5.56414 13.4491 5.56414 14.851C5.56414 17.1383 6.44193 19.2889 8.03564 20.9057C9.62857 22.5232 11.7468 23.4141 13.9996 23.4141C16.2517 23.4141 18.3706 22.5232 19.9644 20.9057C21.5581 19.2889 22.4351 17.1383 22.4351 14.851C22.4351 13.4507 22.1114 12.1128 21.4701 10.8807C20.86 9.70544 19.9714 8.67316 18.9014 7.89442C18.5504 7.6401 18.4687 7.14489 18.7208 6.78869C18.973 6.43249 19.4609 6.34877 19.8118 6.60546C21.0787 7.52637 22.1316 8.74977 22.8545 10.143C23.6039 11.5852 24 13.213 24 14.8526C24 16.2229 23.7354 17.5521 23.2133 18.804C22.7098 20.0124 21.9884 21.0984 21.0702 22.0303C20.1519 22.9623 19.0835 23.6929 17.8921 24.2055C16.6587 24.733 15.349 25 13.9996 25ZM14.2346 13.987C13.8027 13.987 13.4526 13.6331 13.4526 13.194V3.79375C13.4526 3.3562 13.8027 3 14.2346 3C14.6665 3 15.0175 3.3562 15.0175 3.79375V13.194C15.0175 13.6331 14.6665 13.987 14.2346 13.987Z" fill="#FE7777"/>
                            </svg>
                            <span style="color: var(--error-hover, #FE7777);font-family: SimSun;font-size: 18px;font-style: normal;font-weight: 400;line-height: 26px;">{{ languageData.exitProjection }}</span>
                        </div>
                        <div class="exit" style="margin-left: 8px;width: 210px;height: 56px;border-radius: 100px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FFF;display: flex;justify-content: center;align-items: center;cursor:pointer;" @click="handleClose"  v-else-if="!isExitHover">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M13.9996 25C12.6502 25 11.3398 24.7323 10.1072 24.2015C8.91576 23.6913 7.84732 22.9599 6.92907 22.028C6.01082 21.096 5.29022 20.01 4.78596 18.8016C4.26458 17.5506 4 16.2213 4 14.851C4 13.2114 4.39609 11.5836 5.1447 10.1414C5.86841 8.7482 6.91973 7.52479 8.18583 6.60388C8.53757 6.34798 9.02626 6.43091 9.27762 6.78711C9.52897 7.14331 9.44882 7.63852 9.09708 7.89284C8.02708 8.67316 7.13918 9.70465 6.52908 10.8807C5.88864 12.1136 5.56414 13.4491 5.56414 14.851C5.56414 17.1383 6.44193 19.2889 8.03564 20.9057C9.62857 22.5232 11.7468 23.4141 13.9996 23.4141C16.2517 23.4141 18.3706 22.5232 19.9644 20.9057C21.5581 19.2889 22.4351 17.1383 22.4351 14.851C22.4351 13.4507 22.1114 12.1128 21.4701 10.8807C20.86 9.70544 19.9714 8.67316 18.9014 7.89442C18.5504 7.6401 18.4687 7.14489 18.7208 6.78869C18.973 6.43249 19.4609 6.34877 19.8118 6.60546C21.0787 7.52637 22.1316 8.74977 22.8545 10.143C23.6039 11.5852 24 13.213 24 14.8526C24 16.2229 23.7354 17.5521 23.2133 18.804C22.7098 20.0124 21.9884 21.0984 21.0702 22.0303C20.1519 22.9623 19.0835 23.6929 17.8921 24.2055C16.6587 24.733 15.349 25 13.9996 25ZM14.2346 13.987C13.8027 13.987 13.4526 13.6331 13.4526 13.194V3.79375C13.4526 3.3562 13.8027 3 14.2346 3C14.6665 3 15.0175 3.3562 15.0175 3.79375V13.194C15.0175 13.6331 14.6665 13.987 14.2346 13.987Z" fill="black" fill-opacity="0.6"/>
                            </svg>
                            <span style="color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));font-family: SimSun;font-size: 18px;font-style: normal;font-weight: 400;line-height: 26px;">{{ languageData.exitProjection }}</span>
                        </div>
                        <div class="prev" style="width: 56px;height: 56px;border-radius:28px;background: #FFF;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-if="isProjection && isExitPrevious && isPrevHover && !isPrevVisited" @click="$_previous_projection">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28" fill="none">
                                <path d="M0.872872 14.7559C0.41241 14.3572 0.412409 13.6428 0.872871 13.2441L13.3453 2.44259C13.993 1.88172 15 2.34177 15 3.19852L15 24.8015C15 25.6582 13.993 26.1183 13.3453 25.5574L0.872872 14.7559Z" fill="#5E9AFC"/>
                            </svg>
                        </div>
                        <div class="prev" style="width: 56px;height: 56px;border-radius:28px;background: #FFF;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isProjection && isExitPrevious && !isPrevVisited && !isPrevHover" @click="$_previous_projection">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28" fill="none">
                                <path d="M0.872872 14.7559C0.41241 14.3572 0.412409 13.6428 0.872871 13.2441L13.3453 2.44259C13.993 1.88172 15 2.34177 15 3.19852L15 24.8015C15 25.6582 13.993 26.1183 13.3453 25.5574L0.872872 14.7559Z" fill="black" fill-opacity="0.6"/>
                            </svg>
                        </div>
                        <div class="prev" style="width: 56px;height: 56px;border-radius:28px;background: #FFF;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isProjection && isExitPrevious && !isPrevHover && isPrevVisited" @click="$_previous_projection">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28" fill="none">
                                <path d="M0.872872 14.7559C0.41241 14.3572 0.412409 13.6428 0.872871 13.2441L13.3453 2.44259C13.993 1.88172 15 2.34177 15 3.19852L15 24.8015C15 25.6582 13.993 26.1183 13.3453 25.5574L0.872872 14.7559Z" fill="#0061FF"/>
                            </svg>
                        </div>
                        <div class="prev" style="width: 56px;height: 56px;border-radius:28px;background: #FFF;margin-left:8px;display: flex;justify-content: center;align-items: center;" v-else-if="isProjection && !isExitPrevious">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28" fill="none">
                                <path d="M0.872872 14.7559C0.41241 14.3572 0.412409 13.6428 0.872871 13.2441L13.3453 2.44259C13.993 1.88172 15 2.34177 15 3.19852L15 24.8015C15 25.6582 13.993 26.1183 13.3453 25.5574L0.872872 14.7559Z" fill="#D9D9D9"/>
                            </svg>
                        </div>
                        <div class="next" style="width: 56px;height: 56px;border-radius:28px;background: #FFF;margin-left:8px;display: flex;justify-content: center;align-items: center;" v-if="isProjection && !isExitNext">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28" fill="none">
                                <path d="M19.1271 14.7559C19.5876 14.3572 19.5876 13.6428 19.1271 13.2441L6.65465 2.44259C6.00701 1.88172 5 2.34177 5 3.19852L5 24.8015C5 25.6582 6.00701 26.1183 6.65465 25.5574L19.1271 14.7559Z" fill="#D9D9D9"/>
                            </svg>
                        </div>
                        <div class="next" style="width: 56px;height: 56px;border-radius:28px;background: #FFF;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isProjection && isExitNext && isNextHover && !isNextVisited" @click="$_next_projection">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28" fill="none">
                                <path d="M19.1271 14.7559C19.5876 14.3572 19.5876 13.6428 19.1271 13.2441L6.65465 2.44259C6.00701 1.88172 5 2.34177 5 3.19852L5 24.8015C5 25.6582 6.00701 26.1183 6.65465 25.5574L19.1271 14.7559Z" fill="#5E9AFC"/>
                            </svg>
                        </div>
                        <div class="next" style="width: 56px;height: 56px;border-radius:28px;background: #FFF;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isProjection && isExitNext && !isNextHover && isNextVisited" @click="$_next_projection">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28" fill="none">
                                <path d="M19.1271 14.7559C19.5876 14.3572 19.5876 13.6428 19.1271 13.2441L6.65465 2.44259C6.00701 1.88172 5 2.34177 5 3.19852L5 24.8015C5 25.6582 6.00701 26.1183 6.65465 25.5574L19.1271 14.7559Z" fill="#0061FF"/>
                            </svg>
                        </div>
                        <div class="next" style="width: 56px;height: 56px;border-radius:28px;background: #FFF;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isProjection && isExitNext && !isNextHover && !isNextVisited" @click="$_next_projection">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28" fill="none">
                                <path d="M19.1271 14.7559C19.5876 14.3572 19.5876 13.6428 19.1271 13.2441L6.65465 2.44259C6.00701 1.88172 5 2.34177 5 3.19852L5 24.8015C5 25.6582 6.00701 26.1183 6.65465 25.5574L19.1271 14.7559Z" fill="black" fill-opacity="0.6"/>
                            </svg>
                        </div>

                        <div class="mulu_1" style="width: 56px;height: 56px;border-radius:28px;background: #FFF;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-if="!isMuluHover && !isMuluVisited && navigationList.length != 0" @click="showMulu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M24.2761 5H8.89297C7.94603 5 7.16905 5.78027 7.16905 6.73121C7.16905 7.68216 7.94603 8.46243 8.89297 8.46243H24.2761C25.223 8.46243 26 7.68216 26 6.73121C26 5.78027 25.223 5 24.2761 5ZM24.2761 12.7214H8.89297C7.94603 12.7214 7.16905 13.5016 7.16905 14.4526C7.16905 15.4035 7.94603 16.1838 8.89297 16.1838H24.2761C25.223 16.1838 26 15.4035 26 14.4526C26 13.5016 25.223 12.7214 24.2761 12.7214ZM24.2761 20.5105H8.89297C7.94603 20.5105 7.16905 21.2908 7.16905 22.2417C7.16905 23.1927 7.94603 23.9729 8.89297 23.9729H24.2761C25.223 23.9729 26 23.1927 26 22.2417C26 21.2908 25.223 20.5105 24.2761 20.5105ZM3.69964 5.0271C2.7635 5.0271 2 5.79652 2 6.73393V6.7827C2 7.7228 2.76618 8.48953 3.69964 8.48953C4.63578 8.48953 5.39928 7.72008 5.39928 6.7827V6.73393C5.39928 5.79652 4.63578 5.0271 3.69964 5.0271ZM3.69964 12.7485C2.7635 12.7485 2 13.5179 2 14.4553V14.5041C2 15.4442 2.76618 16.2109 3.69964 16.2109C4.63578 16.2109 5.39928 15.4415 5.39928 14.5041V14.4553C5.39928 13.5179 4.63578 12.7485 3.69964 12.7485ZM3.69964 20.5376C2.7635 20.5376 2 21.307 2 22.2444V22.2932C2 23.2333 2.76618 24 3.69964 24C4.63578 24 5.39928 23.2306 5.39928 22.2932V22.2444C5.39928 21.307 4.63578 20.5376 3.69964 20.5376Z" fill="black" fill-opacity="0.6"/>
                            </svg>
                        </div>
                        <div class="mulu_1" style="width: 56px;height: 56px;border-radius:28px;background: #FFF;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isMuluHover && !isMuluVisited && navigationList.length != 0" @click="showMulu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M24.2761 5H8.89297C7.94603 5 7.16905 5.78027 7.16905 6.73121C7.16905 7.68216 7.94603 8.46243 8.89297 8.46243H24.2761C25.223 8.46243 26 7.68216 26 6.73121C26 5.78027 25.223 5 24.2761 5ZM24.2761 12.7214H8.89297C7.94603 12.7214 7.16905 13.5016 7.16905 14.4526C7.16905 15.4035 7.94603 16.1838 8.89297 16.1838H24.2761C25.223 16.1838 26 15.4035 26 14.4526C26 13.5016 25.223 12.7214 24.2761 12.7214ZM24.2761 20.5105H8.89297C7.94603 20.5105 7.16905 21.2908 7.16905 22.2417C7.16905 23.1927 7.94603 23.9729 8.89297 23.9729H24.2761C25.223 23.9729 26 23.1927 26 22.2417C26 21.2908 25.223 20.5105 24.2761 20.5105ZM3.69964 5.0271C2.7635 5.0271 2 5.79652 2 6.73393V6.7827C2 7.7228 2.76618 8.48953 3.69964 8.48953C4.63578 8.48953 5.39928 7.72008 5.39928 6.7827V6.73393C5.39928 5.79652 4.63578 5.0271 3.69964 5.0271ZM3.69964 12.7485C2.7635 12.7485 2 13.5179 2 14.4553V14.5041C2 15.4442 2.76618 16.2109 3.69964 16.2109C4.63578 16.2109 5.39928 15.4415 5.39928 14.5041V14.4553C5.39928 13.5179 4.63578 12.7485 3.69964 12.7485ZM3.69964 20.5376C2.7635 20.5376 2 21.307 2 22.2444V22.2932C2 23.2333 2.76618 24 3.69964 24C4.63578 24 5.39928 23.2306 5.39928 22.2932V22.2444C5.39928 21.307 4.63578 20.5376 3.69964 20.5376Z" fill="#5E9AFC"/>
                            </svg>
                        </div>
                        <div class="mulu_1" style="width: 56px;height: 56px;border-radius:28px;background: #FFF;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isMuluVisited && navigationList.length != 0" @click="showMulu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M24.2761 5H8.89297C7.94603 5 7.16905 5.78027 7.16905 6.73121C7.16905 7.68216 7.94603 8.46243 8.89297 8.46243H24.2761C25.223 8.46243 26 7.68216 26 6.73121C26 5.78027 25.223 5 24.2761 5ZM24.2761 12.7214H8.89297C7.94603 12.7214 7.16905 13.5016 7.16905 14.4526C7.16905 15.4035 7.94603 16.1838 8.89297 16.1838H24.2761C25.223 16.1838 26 15.4035 26 14.4526C26 13.5016 25.223 12.7214 24.2761 12.7214ZM24.2761 20.5105H8.89297C7.94603 20.5105 7.16905 21.2908 7.16905 22.2417C7.16905 23.1927 7.94603 23.9729 8.89297 23.9729H24.2761C25.223 23.9729 26 23.1927 26 22.2417C26 21.2908 25.223 20.5105 24.2761 20.5105ZM3.69964 5.0271C2.7635 5.0271 2 5.79652 2 6.73393V6.7827C2 7.7228 2.76618 8.48953 3.69964 8.48953C4.63578 8.48953 5.39928 7.72008 5.39928 6.7827V6.73393C5.39928 5.79652 4.63578 5.0271 3.69964 5.0271ZM3.69964 12.7485C2.7635 12.7485 2 13.5179 2 14.4553V14.5041C2 15.4442 2.76618 16.2109 3.69964 16.2109C4.63578 16.2109 5.39928 15.4415 5.39928 14.5041V14.4553C5.39928 13.5179 4.63578 12.7485 3.69964 12.7485ZM3.69964 20.5376C2.7635 20.5376 2 21.307 2 22.2444V22.2932C2 23.2333 2.76618 24 3.69964 24C4.63578 24 5.39928 23.2306 5.39928 22.2932V22.2444C5.39928 21.307 4.63578 20.5376 3.69964 20.5376Z" fill="#0061FF"/>
                            </svg>
                        </div>

                        <div class="mulu_1" style="width: 56px;height: 56px;border-radius:28px;background: #FFF;margin-left:8px;display: flex;justify-content: center;align-items: center;" v-else-if="navigationList.length == 0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M24.2761 5H8.89297C7.94603 5 7.16905 5.78027 7.16905 6.73121C7.16905 7.68216 7.94603 8.46243 8.89297 8.46243H24.2761C25.223 8.46243 26 7.68216 26 6.73121C26 5.78027 25.223 5 24.2761 5ZM24.2761 12.7214H8.89297C7.94603 12.7214 7.16905 13.5016 7.16905 14.4526C7.16905 15.4035 7.94603 16.1838 8.89297 16.1838H24.2761C25.223 16.1838 26 15.4035 26 14.4526C26 13.5016 25.223 12.7214 24.2761 12.7214ZM24.2761 20.5105H8.89297C7.94603 20.5105 7.16905 21.2908 7.16905 22.2417C7.16905 23.1927 7.94603 23.9729 8.89297 23.9729H24.2761C25.223 23.9729 26 23.1927 26 22.2417C26 21.2908 25.223 20.5105 24.2761 20.5105ZM3.69964 5.0271C2.7635 5.0271 2 5.79652 2 6.73393V6.7827C2 7.7228 2.76618 8.48953 3.69964 8.48953C4.63578 8.48953 5.39928 7.72008 5.39928 6.7827V6.73393C5.39928 5.79652 4.63578 5.0271 3.69964 5.0271ZM3.69964 12.7485C2.7635 12.7485 2 13.5179 2 14.4553V14.5041C2 15.4442 2.76618 16.2109 3.69964 16.2109C4.63578 16.2109 5.39928 15.4415 5.39928 14.5041V14.4553C5.39928 13.5179 4.63578 12.7485 3.69964 12.7485ZM3.69964 20.5376C2.7635 20.5376 2 21.307 2 22.2444V22.2932C2 23.2333 2.76618 24 3.69964 24C4.63578 24 5.39928 23.2306 5.39928 22.2932V22.2444C5.39928 21.307 4.63578 20.5376 3.69964 20.5376Z" fill="#D9D9D9"/>
                            </svg>
                        </div>

                        <div class="visiable" style="margin-right:8px;width: 56px;height: 56px;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-if="!isVisiable" @click="$_hidden_tools">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25628 14.6191C3.91457 14.2774 3.91457 13.7234 4.25628 13.3817L14.7563 2.8817L15.9937 4.11914L6.11244 14.0004L15.9937 23.8817L14.7563 25.1191L4.25628 14.6191Z" fill="black" fill-opacity="0.6"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1249 14.6191C12.7832 14.2774 12.7832 13.7234 13.1249 13.3817L23.6249 2.8817L24.8624 4.11914L14.9811 14.0004L24.8624 23.8817L23.6249 25.1191L13.1249 14.6191Z" fill="black" fill-opacity="0.6"/>
                            </svg>
                        </div>
                        <div class="visiable" style="margin-right:8px;width: 56px;height: 56px;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else @click="$_hidden_tools">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25628 14.6191C3.91457 14.2774 3.91457 13.7234 4.25628 13.3817L14.7563 2.8817L15.9937 4.11914L6.11244 14.0004L15.9937 23.8817L14.7563 25.1191L4.25628 14.6191Z" fill="#5E9AFC" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1249 14.6191C12.7832 14.2774 12.7832 13.7234 13.1249 13.3817L23.6249 2.8817L24.8624 4.11914L14.9811 14.0004L24.8624 23.8817L23.6249 25.1191L13.1249 14.6191Z" fill="#5E9AFC" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="animate__animated animate__zoomIn" v-else-if="!isHiddenTools && isPhone" style="position: absolute;bottom:24px;width:100%;display: flex;align-items: center;justify-content: center;">
                    <div style="width: 211px;height: 33px;border-top-left-radius: 36px;border-bottom-left-radius: 36px;border-top-right-radius: 36px;border-bottom-right-radius: 36px;background: rgba(238, 238, 238, 0.60);">
                        <div style="display:flex;height: 100%;align-items: center;">
                            <div class="exit" style="margin-left: 3px;width: 95px;height: 24px;border-radius: 100px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FFF;display: flex;justify-content: center;align-items: center;cursor:pointer;" @click="handleClose"  v-if="isExitHover">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                                    <path d="M13.9996 25C12.6502 25 11.3398 24.7323 10.1072 24.2015C8.91576 23.6913 7.84732 22.9599 6.92907 22.028C6.01082 21.096 5.29022 20.01 4.78596 18.8016C4.26458 17.5506 4 16.2213 4 14.851C4 13.2114 4.39609 11.5836 5.1447 10.1414C5.86841 8.7482 6.91973 7.52479 8.18583 6.60388C8.53757 6.34798 9.02626 6.43091 9.27762 6.78711C9.52897 7.14331 9.44882 7.63852 9.09708 7.89284C8.02708 8.67316 7.13918 9.70465 6.52908 10.8807C5.88864 12.1136 5.56414 13.4491 5.56414 14.851C5.56414 17.1383 6.44193 19.2889 8.03564 20.9057C9.62857 22.5232 11.7468 23.4141 13.9996 23.4141C16.2517 23.4141 18.3706 22.5232 19.9644 20.9057C21.5581 19.2889 22.4351 17.1383 22.4351 14.851C22.4351 13.4507 22.1114 12.1128 21.4701 10.8807C20.86 9.70544 19.9714 8.67316 18.9014 7.89442C18.5504 7.6401 18.4687 7.14489 18.7208 6.78869C18.973 6.43249 19.4609 6.34877 19.8118 6.60546C21.0787 7.52637 22.1316 8.74977 22.8545 10.143C23.6039 11.5852 24 13.213 24 14.8526C24 16.2229 23.7354 17.5521 23.2133 18.804C22.7098 20.0124 21.9884 21.0984 21.0702 22.0303C20.1519 22.9623 19.0835 23.6929 17.8921 24.2055C16.6587 24.733 15.349 25 13.9996 25ZM14.2346 13.987C13.8027 13.987 13.4526 13.6331 13.4526 13.194V3.79375C13.4526 3.3562 13.8027 3 14.2346 3C14.6665 3 15.0175 3.3562 15.0175 3.79375V13.194C15.0175 13.6331 14.6665 13.987 14.2346 13.987Z" fill="#FE7777"/>
                                </svg>
                                <span style="color: var(--error-hover, #FE7777);font-family: SimSun;font-size: 12px;font-style: normal;font-weight: 400;line-height: 16px;">{{ languageData.exitProjection }}</span>
                            </div>
                            <div class="exit" style="margin-left: 3px;width: 95px;height: 24px;border-radius: 100px;border: 1px solid rgba(0, 0, 0, 0.10);background: #FFF;display: flex;justify-content: center;align-items: center;cursor:pointer;" @click="handleClose"  v-else-if="!isExitHover">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                                    <path d="M13.9996 25C12.6502 25 11.3398 24.7323 10.1072 24.2015C8.91576 23.6913 7.84732 22.9599 6.92907 22.028C6.01082 21.096 5.29022 20.01 4.78596 18.8016C4.26458 17.5506 4 16.2213 4 14.851C4 13.2114 4.39609 11.5836 5.1447 10.1414C5.86841 8.7482 6.91973 7.52479 8.18583 6.60388C8.53757 6.34798 9.02626 6.43091 9.27762 6.78711C9.52897 7.14331 9.44882 7.63852 9.09708 7.89284C8.02708 8.67316 7.13918 9.70465 6.52908 10.8807C5.88864 12.1136 5.56414 13.4491 5.56414 14.851C5.56414 17.1383 6.44193 19.2889 8.03564 20.9057C9.62857 22.5232 11.7468 23.4141 13.9996 23.4141C16.2517 23.4141 18.3706 22.5232 19.9644 20.9057C21.5581 19.2889 22.4351 17.1383 22.4351 14.851C22.4351 13.4507 22.1114 12.1128 21.4701 10.8807C20.86 9.70544 19.9714 8.67316 18.9014 7.89442C18.5504 7.6401 18.4687 7.14489 18.7208 6.78869C18.973 6.43249 19.4609 6.34877 19.8118 6.60546C21.0787 7.52637 22.1316 8.74977 22.8545 10.143C23.6039 11.5852 24 13.213 24 14.8526C24 16.2229 23.7354 17.5521 23.2133 18.804C22.7098 20.0124 21.9884 21.0984 21.0702 22.0303C20.1519 22.9623 19.0835 23.6929 17.8921 24.2055C16.6587 24.733 15.349 25 13.9996 25ZM14.2346 13.987C13.8027 13.987 13.4526 13.6331 13.4526 13.194V3.79375C13.4526 3.3562 13.8027 3 14.2346 3C14.6665 3 15.0175 3.3562 15.0175 3.79375V13.194C15.0175 13.6331 14.6665 13.987 14.2346 13.987Z" fill="black" fill-opacity="0.6"/>
                                </svg>
                                <span style="color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));font-family: SimSun;font-size: 12px;font-style: normal;font-weight: 400;line-height: 16px;">{{ languageData.exitProjection }}</span>
                            </div>
                            <div class="prev" style="width: 24px;height: 24px;border-radius:12px;background: #FFF;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-if="isProjection && isExitPrevious && isPrevHover && !isPrevVisited" @click="$_previous_projection">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 20 28" fill="none">
                                    <path d="M0.872872 14.7559C0.41241 14.3572 0.412409 13.6428 0.872871 13.2441L13.3453 2.44259C13.993 1.88172 15 2.34177 15 3.19852L15 24.8015C15 25.6582 13.993 26.1183 13.3453 25.5574L0.872872 14.7559Z" fill="#5E9AFC"/>
                                </svg>
                            </div>
                            <div class="prev" style="width: 24px;height: 24px;border-radius:12px;background: #FFF;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isProjection && isExitPrevious && !isPrevVisited && !isPrevHover" @click="$_previous_projection">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 20 28" fill="none">
                                    <path d="M0.872872 14.7559C0.41241 14.3572 0.412409 13.6428 0.872871 13.2441L13.3453 2.44259C13.993 1.88172 15 2.34177 15 3.19852L15 24.8015C15 25.6582 13.993 26.1183 13.3453 25.5574L0.872872 14.7559Z" fill="black" fill-opacity="0.6"/>
                                </svg>
                            </div>
                            <div class="prev" style="width: 24px;height: 24px;border-radius:12px;background: #FFF;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isProjection && isExitPrevious && !isPrevHover && isPrevVisited" @click="$_previous_projection">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 20 28" fill="none">
                                    <path d="M0.872872 14.7559C0.41241 14.3572 0.412409 13.6428 0.872871 13.2441L13.3453 2.44259C13.993 1.88172 15 2.34177 15 3.19852L15 24.8015C15 25.6582 13.993 26.1183 13.3453 25.5574L0.872872 14.7559Z" fill="#0061FF"/>
                                </svg>
                            </div>
                            <div class="prev" style="width: 24px;height: 24px;border-radius:12px;background: #FFF;margin-left:3px;display: flex;justify-content: center;align-items: center;" v-else-if="isProjection && !isExitPrevious">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 20 28" fill="none">
                                    <path d="M0.872872 14.7559C0.41241 14.3572 0.412409 13.6428 0.872871 13.2441L13.3453 2.44259C13.993 1.88172 15 2.34177 15 3.19852L15 24.8015C15 25.6582 13.993 26.1183 13.3453 25.5574L0.872872 14.7559Z" fill="#D9D9D9"/>
                                </svg>
                            </div>
                            <div class="next" style="width: 24px;height: 24px;border-radius:12px;background: #FFF;margin-left:3px;display: flex;justify-content: center;align-items: center;" v-if="isProjection && !isExitNext">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 20 28" fill="none">
                                    <path d="M19.1271 14.7559C19.5876 14.3572 19.5876 13.6428 19.1271 13.2441L6.65465 2.44259C6.00701 1.88172 5 2.34177 5 3.19852L5 24.8015C5 25.6582 6.00701 26.1183 6.65465 25.5574L19.1271 14.7559Z" fill="#D9D9D9"/>
                                </svg>
                            </div>
                            <div class="next" style="width: 24px;height: 24px;border-radius:12px;background: #FFF;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isProjection && isExitNext && isNextHover && !isNextVisited" @click="$_next_projection">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 20 28" fill="none">
                                    <path d="M19.1271 14.7559C19.5876 14.3572 19.5876 13.6428 19.1271 13.2441L6.65465 2.44259C6.00701 1.88172 5 2.34177 5 3.19852L5 24.8015C5 25.6582 6.00701 26.1183 6.65465 25.5574L19.1271 14.7559Z" fill="#5E9AFC"/>
                                </svg>
                            </div>
                            <div class="next" style="width: 24px;height: 24px;border-radius:12px;background: #FFF;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isProjection && isExitNext && !isNextHover && isNextVisited" @click="$_next_projection">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 20 28" fill="none">
                                    <path d="M19.1271 14.7559C19.5876 14.3572 19.5876 13.6428 19.1271 13.2441L6.65465 2.44259C6.00701 1.88172 5 2.34177 5 3.19852L5 24.8015C5 25.6582 6.00701 26.1183 6.65465 25.5574L19.1271 14.7559Z" fill="#0061FF"/>
                                </svg>
                            </div>
                            <div class="next" style="width: 24px;height: 24px;border-radius:12px;background: #FFF;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isProjection && isExitNext && !isNextHover && !isNextVisited" @click="$_next_projection">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 20 28" fill="none">
                                    <path d="M19.1271 14.7559C19.5876 14.3572 19.5876 13.6428 19.1271 13.2441L6.65465 2.44259C6.00701 1.88172 5 2.34177 5 3.19852L5 24.8015C5 25.6582 6.00701 26.1183 6.65465 25.5574L19.1271 14.7559Z" fill="black" fill-opacity="0.6"/>
                                </svg>
                            </div>

                            <div class="mulu_1" style="width: 24px;height: 24px;border-radius:12px;background: #FFF;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-if="!isMuluHover && !isMuluVisited && navigationList.length != 0" @click="showMulu">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                                    <path d="M24.2761 5H8.89297C7.94603 5 7.16905 5.78027 7.16905 6.73121C7.16905 7.68216 7.94603 8.46243 8.89297 8.46243H24.2761C25.223 8.46243 26 7.68216 26 6.73121C26 5.78027 25.223 5 24.2761 5ZM24.2761 12.7214H8.89297C7.94603 12.7214 7.16905 13.5016 7.16905 14.4526C7.16905 15.4035 7.94603 16.1838 8.89297 16.1838H24.2761C25.223 16.1838 26 15.4035 26 14.4526C26 13.5016 25.223 12.7214 24.2761 12.7214ZM24.2761 20.5105H8.89297C7.94603 20.5105 7.16905 21.2908 7.16905 22.2417C7.16905 23.1927 7.94603 23.9729 8.89297 23.9729H24.2761C25.223 23.9729 26 23.1927 26 22.2417C26 21.2908 25.223 20.5105 24.2761 20.5105ZM3.69964 5.0271C2.7635 5.0271 2 5.79652 2 6.73393V6.7827C2 7.7228 2.76618 8.48953 3.69964 8.48953C4.63578 8.48953 5.39928 7.72008 5.39928 6.7827V6.73393C5.39928 5.79652 4.63578 5.0271 3.69964 5.0271ZM3.69964 12.7485C2.7635 12.7485 2 13.5179 2 14.4553V14.5041C2 15.4442 2.76618 16.2109 3.69964 16.2109C4.63578 16.2109 5.39928 15.4415 5.39928 14.5041V14.4553C5.39928 13.5179 4.63578 12.7485 3.69964 12.7485ZM3.69964 20.5376C2.7635 20.5376 2 21.307 2 22.2444V22.2932C2 23.2333 2.76618 24 3.69964 24C4.63578 24 5.39928 23.2306 5.39928 22.2932V22.2444C5.39928 21.307 4.63578 20.5376 3.69964 20.5376Z" fill="black" fill-opacity="0.6"/>
                                </svg>
                            </div>
                            <div class="mulu_1" style="width: 24px;height: 24px;border-radius:12px;background: #FFF;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isMuluHover && !isMuluVisited && navigationList.length != 0" @click="showMulu">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                                    <path d="M24.2761 5H8.89297C7.94603 5 7.16905 5.78027 7.16905 6.73121C7.16905 7.68216 7.94603 8.46243 8.89297 8.46243H24.2761C25.223 8.46243 26 7.68216 26 6.73121C26 5.78027 25.223 5 24.2761 5ZM24.2761 12.7214H8.89297C7.94603 12.7214 7.16905 13.5016 7.16905 14.4526C7.16905 15.4035 7.94603 16.1838 8.89297 16.1838H24.2761C25.223 16.1838 26 15.4035 26 14.4526C26 13.5016 25.223 12.7214 24.2761 12.7214ZM24.2761 20.5105H8.89297C7.94603 20.5105 7.16905 21.2908 7.16905 22.2417C7.16905 23.1927 7.94603 23.9729 8.89297 23.9729H24.2761C25.223 23.9729 26 23.1927 26 22.2417C26 21.2908 25.223 20.5105 24.2761 20.5105ZM3.69964 5.0271C2.7635 5.0271 2 5.79652 2 6.73393V6.7827C2 7.7228 2.76618 8.48953 3.69964 8.48953C4.63578 8.48953 5.39928 7.72008 5.39928 6.7827V6.73393C5.39928 5.79652 4.63578 5.0271 3.69964 5.0271ZM3.69964 12.7485C2.7635 12.7485 2 13.5179 2 14.4553V14.5041C2 15.4442 2.76618 16.2109 3.69964 16.2109C4.63578 16.2109 5.39928 15.4415 5.39928 14.5041V14.4553C5.39928 13.5179 4.63578 12.7485 3.69964 12.7485ZM3.69964 20.5376C2.7635 20.5376 2 21.307 2 22.2444V22.2932C2 23.2333 2.76618 24 3.69964 24C4.63578 24 5.39928 23.2306 5.39928 22.2932V22.2444C5.39928 21.307 4.63578 20.5376 3.69964 20.5376Z" fill="#5E9AFC"/>
                                </svg>
                            </div>
                            <div class="mulu_1" style="width: 24px;height: 24px;border-radius:12px;background: #FFF;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else-if="isMuluVisited && navigationList.length != 0" @click="showMulu">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                                    <path d="M24.2761 5H8.89297C7.94603 5 7.16905 5.78027 7.16905 6.73121C7.16905 7.68216 7.94603 8.46243 8.89297 8.46243H24.2761C25.223 8.46243 26 7.68216 26 6.73121C26 5.78027 25.223 5 24.2761 5ZM24.2761 12.7214H8.89297C7.94603 12.7214 7.16905 13.5016 7.16905 14.4526C7.16905 15.4035 7.94603 16.1838 8.89297 16.1838H24.2761C25.223 16.1838 26 15.4035 26 14.4526C26 13.5016 25.223 12.7214 24.2761 12.7214ZM24.2761 20.5105H8.89297C7.94603 20.5105 7.16905 21.2908 7.16905 22.2417C7.16905 23.1927 7.94603 23.9729 8.89297 23.9729H24.2761C25.223 23.9729 26 23.1927 26 22.2417C26 21.2908 25.223 20.5105 24.2761 20.5105ZM3.69964 5.0271C2.7635 5.0271 2 5.79652 2 6.73393V6.7827C2 7.7228 2.76618 8.48953 3.69964 8.48953C4.63578 8.48953 5.39928 7.72008 5.39928 6.7827V6.73393C5.39928 5.79652 4.63578 5.0271 3.69964 5.0271ZM3.69964 12.7485C2.7635 12.7485 2 13.5179 2 14.4553V14.5041C2 15.4442 2.76618 16.2109 3.69964 16.2109C4.63578 16.2109 5.39928 15.4415 5.39928 14.5041V14.4553C5.39928 13.5179 4.63578 12.7485 3.69964 12.7485ZM3.69964 20.5376C2.7635 20.5376 2 21.307 2 22.2444V22.2932C2 23.2333 2.76618 24 3.69964 24C4.63578 24 5.39928 23.2306 5.39928 22.2932V22.2444C5.39928 21.307 4.63578 20.5376 3.69964 20.5376Z" fill="#0061FF"/>
                                </svg>
                            </div>

                            <div class="mulu_1" style="width: 24px;height: 24px;border-radius:12px;background: #FFF;margin-left:3px;display: flex;justify-content: center;align-items: center;" v-else-if="navigationList.length == 0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                                    <path d="M24.2761 5H8.89297C7.94603 5 7.16905 5.78027 7.16905 6.73121C7.16905 7.68216 7.94603 8.46243 8.89297 8.46243H24.2761C25.223 8.46243 26 7.68216 26 6.73121C26 5.78027 25.223 5 24.2761 5ZM24.2761 12.7214H8.89297C7.94603 12.7214 7.16905 13.5016 7.16905 14.4526C7.16905 15.4035 7.94603 16.1838 8.89297 16.1838H24.2761C25.223 16.1838 26 15.4035 26 14.4526C26 13.5016 25.223 12.7214 24.2761 12.7214ZM24.2761 20.5105H8.89297C7.94603 20.5105 7.16905 21.2908 7.16905 22.2417C7.16905 23.1927 7.94603 23.9729 8.89297 23.9729H24.2761C25.223 23.9729 26 23.1927 26 22.2417C26 21.2908 25.223 20.5105 24.2761 20.5105ZM3.69964 5.0271C2.7635 5.0271 2 5.79652 2 6.73393V6.7827C2 7.7228 2.76618 8.48953 3.69964 8.48953C4.63578 8.48953 5.39928 7.72008 5.39928 6.7827V6.73393C5.39928 5.79652 4.63578 5.0271 3.69964 5.0271ZM3.69964 12.7485C2.7635 12.7485 2 13.5179 2 14.4553V14.5041C2 15.4442 2.76618 16.2109 3.69964 16.2109C4.63578 16.2109 5.39928 15.4415 5.39928 14.5041V14.4553C5.39928 13.5179 4.63578 12.7485 3.69964 12.7485ZM3.69964 20.5376C2.7635 20.5376 2 21.307 2 22.2444V22.2932C2 23.2333 2.76618 24 3.69964 24C4.63578 24 5.39928 23.2306 5.39928 22.2932V22.2444C5.39928 21.307 4.63578 20.5376 3.69964 20.5376Z" fill="#D9D9D9"/>
                                </svg>
                            </div>

                            <div class="visiable" style="margin-right:3px;width: 24px;height: 24px;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-if="!isVisiable" @click="$_hidden_tools">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25628 14.6191C3.91457 14.2774 3.91457 13.7234 4.25628 13.3817L14.7563 2.8817L15.9937 4.11914L6.11244 14.0004L15.9937 23.8817L14.7563 25.1191L4.25628 14.6191Z" fill="black" fill-opacity="0.6"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1249 14.6191C12.7832 14.2774 12.7832 13.7234 13.1249 13.3817L23.6249 2.8817L24.8624 4.11914L14.9811 14.0004L24.8624 23.8817L23.6249 25.1191L13.1249 14.6191Z" fill="black" fill-opacity="0.6"/>
                                </svg>
                            </div>
                            <div class="visiable" style="margin-right:3px;width: 24px;height: 24px;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else @click="$_hidden_tools">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25628 14.6191C3.91457 14.2774 3.91457 13.7234 4.25628 13.3817L14.7563 2.8817L15.9937 4.11914L6.11244 14.0004L15.9937 23.8817L14.7563 25.1191L4.25628 14.6191Z" fill="#5E9AFC" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1249 14.6191C12.7832 14.2774 12.7832 13.7234 13.1249 13.3817L23.6249 2.8817L24.8624 4.11914L14.9811 14.0004L24.8624 23.8817L23.6249 25.1191L13.1249 14.6191Z" fill="#5E9AFC" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="animate__animated animate__zoomIn" style="width: 56px;height: 72px;border-top-right-radius: 36px;border-bottom-right-radius: 36px;background: rgba(238, 238, 238, 0.60);position: absolute;left:0;bottom:24px;" v-else-if="isHiddenTools && !isPhone">
                    <div style="display:flex;height: 100%;align-items: center;">
                        <div class="hidden" style="margin-right:8px;width: 56px;height: 56px;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-if="!isHidden" @click="$_show_tools">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.606 14.6191C24.9477 14.2774 24.9477 13.7234 24.606 13.3817L14.106 2.8817L12.8686 4.11914L22.7499 14.0004L12.8686 23.8817L14.106 25.1191L24.606 14.6191Z" fill="black" fill-opacity="0.6"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7374 14.6191C16.0791 14.2774 16.0791 13.7234 15.7374 13.3817L5.23737 2.8817L3.99994 4.11914L13.8812 14.0004L3.99993 23.8817L5.23737 25.1191L15.7374 14.6191Z" fill="black" fill-opacity="0.6"/>
                            </svg>
                        </div>
                        <div class="hidden" style="margin-right:8px;width: 56px;height: 56px;margin-left:8px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else @click="$_show_tools">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.606 14.6191C24.9477 14.2774 24.9477 13.7234 24.606 13.3817L14.106 2.8817L12.8686 4.11914L22.7499 14.0004L12.8686 23.8817L14.106 25.1191L24.606 14.6191Z" fill="#5E9AFC"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7374 14.6191C16.0791 14.2774 16.0791 13.7234 15.7374 13.3817L5.23737 2.8817L3.99994 4.11914L13.8812 14.0004L3.99993 23.8817L5.23737 25.1191L15.7374 14.6191Z" fill="#5E9AFC"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="animate__animated animate__zoomIn" style="width: 30px;height: 33px;border-top-right-radius: 36px;border-bottom-right-radius: 36px;background: rgba(238, 238, 238, 0.60);position: absolute;left:0;bottom:24px;" v-else>
                    <div style="display:flex;height: 100%;align-items: center;">
                        <div class="hidden" style="margin-right:3px;width: 24px;height: 24px;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-if="!isHidden" @click="$_show_tools">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.606 14.6191C24.9477 14.2774 24.9477 13.7234 24.606 13.3817L14.106 2.8817L12.8686 4.11914L22.7499 14.0004L12.8686 23.8817L14.106 25.1191L24.606 14.6191Z" fill="black" fill-opacity="0.6"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7374 14.6191C16.0791 14.2774 16.0791 13.7234 15.7374 13.3817L5.23737 2.8817L3.99994 4.11914L13.8812 14.0004L3.99993 23.8817L5.23737 25.1191L15.7374 14.6191Z" fill="black" fill-opacity="0.6"/>
                            </svg>
                        </div>
                        <div class="hidden" style="margin-right:3px;width: 24px;height: 24px;margin-left:3px;display: flex;justify-content: center;align-items: center;cursor:pointer;" v-else @click="$_show_tools">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.606 14.6191C24.9477 14.2774 24.9477 13.7234 24.606 13.3817L14.106 2.8817L12.8686 4.11914L22.7499 14.0004L12.8686 23.8817L14.106 25.1191L24.606 14.6191Z" fill="#5E9AFC"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7374 14.6191C16.0791 14.2774 16.0791 13.7234 15.7374 13.3817L5.23737 2.8817L3.99994 4.11914L13.8812 14.0004L3.99993 23.8817L5.23737 25.1191L15.7374 14.6191Z" fill="#5E9AFC"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <!-- <div id="handle" ref="handle" draggable="false" @mousedown="handleMousedown" v-if="!fullscreen" /> -->
                <DemonstrateMuluDialog :isShowMulu="false" :navigationList="navigationList" :themeName="themeName" :selectMulu="selectMulu" @setSelectMulu="setSelectMulu" v-if="isShowMulu"></DemonstrateMuluDialog>
            </div>
        <!-- </el-dialog> -->
    </div>
</template>

<script>
import $ from 'jquery'
import 'animate.css'
import DemonstrateMuluDialog from '../dialog/DemonstrateMuluDialog.vue'
export default {
    props: {
        list: Array,
        navigationList: Array,
        themeName: String,
        dialogVisible: {
            type: Boolean,
            default: false
        },
        isProjection: Boolean,
        isExitPrevious: Boolean,
        isExitNext: Boolean,
        Show: Boolean
    },
    watch: {
        list() {
            let that = this;
            if (that.list.length != 0) {
                that.selectMulu = that.list[0].id
                that.$nextTick(() => {
                    $("#DemoResult .prev").on("mouseover",function() {
                        if(that.isPrevVisited) {
                            that.isPrevVisited = false
                        }
                        that.isPrevHover = true
                    })
                    $("#DemoResult .prev").on("mouseout",function() {
                        that.isPrevHover = false
                    })
                    $("#DemoResult .next").on("mouseover",function() {
                        if(that.isNextVisited) {
                            that.isNextVisited = false
                        }
                        that.isNextHover = true
                    })
                    $("#DemoResult .next").on("mouseout",function() {
                        that.isNextHover = false
                    })
                    $("#DemoResult .exit").on("mouseover",function() {
                        that.isExitHover = true
                    })
                    $("#DemoResult .exit").on("mouseout",function() {
                        that.isExitHover = false
                    })

                    $("#DemoResult .mulu_1").on("mouseover",function() {
                        that.isMuluHover = true
                    })
                    $("#DemoResult .mulu_1").on("mouseout",function() {
                        that.isMuluHover = false
                    })

                    $("#DemoResult .visiable").on("mouseover",function() {
                        that.isVisiable = true
                    })
                    $("#DemoResult .visiable").on("mouseout",function() {
                        that.isVisiable = false
                    })
                    // if(that.list[0].properties.radio == 'embedCode') {
                    //     $("iframe").css({
                    //         "width": "100%",
                    //         "height": "90%"
                    //     })
                    // }
                    // let fontsize = '';
                    // for (let i = 0; i < this.list.length; i++) {
                    //     if (this.list[i].type == "icon-card") {
                    //         if (this.list[i].properties.radio == 'content') {
                    //             let len = this.list[i].properties.contentLength;
                    //             if (0 < len && len <= 100) {
                    //                 fontsize = "120px";
                    //             }
                    //             else if (100 < len && len <= 200) {
                    //                 fontsize = "78px";
                    //             }
                    //             else if (200 < len && len <= 300) {
                    //                 fontsize = "71px";
                    //             }
                    //             else if (300 < len && len <= 400) {
                    //                 fontsize = "64px";
                    //             }
                    //             else if (400 < len && len <= 500) {
                    //                 fontsize = "57px";
                    //             }
                    //             else if (len > 500) {
                    //                 fontsize = "50px";
                    //             }
                    //             $("#content1 p").css("font-size", fontsize)
                    //             $("#content1 p").css("line-height", (parseInt(fontsize.split("px")[0]) + 2).toString() + "px")
                    //         }
                    //     }
                    //     if (this.list[i].type == "icon-stage" || this.list[i].type == "icon-task") {
                    //         let len = this.list[i].properties.contentLength;
                    //         console.log(len)
                    //         if (0 < len && len <= 100) {
                    //             fontsize = "120px";
                    //         }
                    //         else if (100 < len && len <= 200) {
                    //             fontsize = "78px";
                    //         }
                    //         else if (200 < len && len <= 300) {
                    //             fontsize = "71px";
                    //         }
                    //         else if (300 < len && len <= 400) {
                    //             fontsize = "64px";
                    //         }
                    //         else if (400 < len && len <= 500) {
                    //             fontsize = "57px";
                    //         }
                    //         else if (len > 500) {
                    //             fontsize = "50px";
                    //         }
                    //         $("#content1 p").css("font-size", fontsize)
                    //         $("#content1 p").css("line-height", (parseInt(fontsize.split("px")[0]) + 2).toString() + "px")
                    //     }
                    //     if (this.list[i].type == "icon-AI") {
                    //         let len = this.list[i].properties.aiContent.length;
                    //         if (0 < len && len <= 100) {
                    //             fontsize = "92px";
                    //         }
                    //         else if (100 < len && len <= 200) {
                    //             fontsize = "65px";
                    //         }
                    //         else if (200 < len && len <= 300) {
                    //             fontsize = "52px";
                    //         }
                    //         else if (300 < len && len <= 400) {
                    //             fontsize = "48px";
                    //         }
                    //         else if (400 < len && len <= 500) {
                    //             fontsize = "44px";
                    //         }
                    //         else if (len > 500) {
                    //             fontsize = "40px";
                    //         }
                    //         $("#content1").css("font-size", fontsize)
                    //         $("#content1").css("line-height", (parseInt(fontsize.split("px")[0]) + 2).toString() + "px")
                    //     }
                    // }
                })
            }
        },
        dialogVisible(newVal) {
            if(!newVal) {
                this.isShowMulu = false
                this.isMuluVisited = false
            }
        },
        screenWidth(newVal) {
            if(newVal>960){
                this.isPhone=false;
            }else{
                // this.crossFun(document.getElementById("DemoResult"));
                this.isPhone=true;
            }
        }
    },
    data() {
        return {
            hoverMax: false,
            hoverRecovery: false,
            fullscreen: false,
            // disX: 0,
            disY: 0,
            down: false,
            letterList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
                'U', 'V', 'W', 'X', 'Y', 'Z'],
            Radio: '',
            showClose: false,
            isDrag: true,
            languageData: {},
            isPrevHover: false,
            isPrevVisited: false,
            isNextHover: false,
            isNextVisited: false,
            isExitHover: false,
            isVisiable: false,
            isHidden: false,
            isHiddenTools: false,
            isMuluHover: false,
            isMuluVisited: false,
            isShowMulu: false,
            selectMulu: '',
            isPhone: false,
            screenWidth: null
        };
    },
    mounted() {
        if (window.location.href.indexOf("cocorobo.cn") != -1) {
            this.languageData = this.zhHansLanguage;
        } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
            this.languageData = this.zhHantLanguage;
        } else if (window.location.href.indexOf("cocorobo.com") != -1) {
            this.languageData = this.enLanguage;
        }else {
            this.languageData = this.zhHansLanguage;
        }
        this.screenWidth = window.screen.width
        window.onresize = () => {
            return (() => {
                this.screenWidth = window.screen.width
            })()
        }
        this.isShowMulu = false
        this.isMuluVisited = false
    },
    methods: {
        crossFun(ele) {
            var width = document.documentElement.clientWidth;
            var height = document.documentElement.clientHeight;
            //第一次进来  判断是不是横屏
            if (width < height) {
                ele.style.width = height + 'px';
                ele.style.height = width + 'px';
                ele.style.top = (height - width) / 2 + 'px';
                ele.style.left = 0 - (height - width) / 2 + 'px';
                ele.style.transform = 'rotate(90deg)';
                ele.style.transformOrign = '50% 50%';
                ele.style.position = 'relative';
            }
            //设备旋转或者窗口变化时候进行  横竖屏处理     在来回切换的时候回出现BUG  使用定时器解决
            var evt = "onorientationchange" in window ? "orientationchange" : "resize";

            window.addEventListener(evt, function () {
                setTimeout(function () {
                var width = document.documentElement.clientWidth;
                var height = document.documentElement.clientHeight;
                if (width > height) {
                    ele.style.width = width + 'px';
                    ele.style.height = height + 'px';
                    ele.style.top = 0;
                    ele.style.left = 0;
                    ele.style.transform = 'none';
                    ele.style.transformOrign = '50% 50%';
                    ele.style.position = 'relative';
                } else {
                    ele.style.width = height + 'px';
                    ele.style.height = width + 'px';
                    ele.style.top = (height - width) / 2 + 'px';
                    ele.style.left = 0 - (height - width) / 2 + 'px';
                    ele.style.transform = 'rotate(90deg)';
                    ele.style.transformOrign = '50% 50%';
                    ele.style.position = 'relative';
                }

                }, 100);

            }, false);
        },
        setSelectMulu(val) {
            this.selectMulu = val
            this.$emit("setSelectMulu",val)
        },
        handleClose() {
            this.isExitHover = false
            this.isShowMulu = false
            this.isMuluVisited = false
            this.$parent.$_fullScreen()
            this.Radio = '';
            this.$emit("info", false);
        },
        setSelect(e) {
            this.Radio = e
        },
        $_previous_projection() {
            let that = this;
            that.isPrevHover = false
            that.isPrevVisited = true
            that.$parent.$_previous_projection();
            setTimeout(function() {
                // that.isPrevHover = false
                that.isPrevVisited = false
            },10000)
        },
        $_next_projection() {
            let that = this;
            that.isNextHover = false
            that.isNextVisited = true
            that.$parent.$_next_projection();
            setTimeout(function() {
                // that.isNextHover = false
                that.isNextVisited = false
            },10000)
        },
        showMulu() {
            let that = this;
            that.isMuluHover = false
            that.isMuluVisited = !that.isMuluVisited
            that.isShowMulu = !that.isShowMulu
        },
        $_hidden_tools() {
            let that = this
            that.isVisiable = false
            that.isHiddenTools = true
            that.$nextTick(() => {
                $("#DemoResult .hidden").on("mouseover",function() {
                    that.isHidden = true
                })
                $("#DemoResult .hidden").on("mouseout",function() {
                    that.isHidden = false
                })
            })
        },
        $_show_tools() {
            let that = this
            that.isHidden = false
            that.isHiddenTools = false
            that.$nextTick(() => {
                    $("#DemoResult .prev").on("mouseover",function() {
                        if(that.isPrevVisited) {
                            that.isPrevVisited = false
                        }
                        that.isPrevHover = true
                    })
                    $("#DemoResult .prev").on("mouseout",function() {
                        that.isPrevHover = false
                    })
                    $("#DemoResult .next").on("mouseover",function() {
                        if(that.isNextVisited) {
                            that.isNextVisited = false
                        }
                        that.isNextHover = true
                    })
                    $("#DemoResult .next").on("mouseout",function() {
                        that.isNextHover = false
                    })
                    $("#DemoResult .exit").on("mouseover",function() {
                        that.isExitHover = true
                    })
                    $("#DemoResult .exit").on("mouseout",function() {
                        that.isExitHover = false
                    })
                    $("#DemoResult .mulu_1").on("mouseover",function() {
                        that.isMuluHover = true
                    })
                    $("#DemoResult .mulu_1").on("mouseout",function() {
                        that.isMuluHover = false
                    })
                    $("#DemoResult .visiable").on("mouseover",function() {
                        that.isVisiable = true
                    })
                    $("#DemoResult .visiable").on("mouseout",function() {
                        that.isVisiable = false
                    })
                })
        }
    },
    components: {
        DemonstrateMuluDialog
    }
};
</script>

<style scoped>
.embedCode >>> iframe {
    width: 100%;
    height: 90%;
}

#DemoResult #content1 {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
    font-family: SimSun;
    font-style: normal;
    font-weight: 400;
    line-height: 48px !important;
    font-size: 32px !important;
}

#DemoResult #content1 p {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
    font-family: SimSun;
    font-style: normal;
    font-weight: 400;
    line-height: 48px !important;
    font-size: 32px !important;
    margin-top: 0;
    margin-bottom: 0;
}

#DemoResult #content1 p span {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
    font-family: SimSun;
    font-style: normal;
    font-weight: 400;
    line-height: 48px !important;
    font-size: 32px !important;
}

#DemoResult #content2 {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
    font-family: SimSun;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 64px !important;
}

#DemoResult #content2 p {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
    font-family: SimSun;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 64px !important;
    margin-top: 0;
    margin-bottom: 0;
}

#DemoResult #content2 p span {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
    font-family: SimSun;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 64px !important;
}

#DemoResult .text {
    color: #FFF;text-align: center;font-family: SimSun;font-size: 64px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 7.68px;
}

.left-top-1{
    width: 30px;
    height: 315px;
}

.left-top-2{
    width: 400px;
    height: 30px;
}

.left-top-3 {
    width: 30px;
    height: 315px;
    bottom: 30px;
}

.themeContent {
    top: 140px;
    left: 240px;
    height:calc(100% - 280px);
    width:calc(100% - 480px);
}

.themeText {
    color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
    text-align: center;
    font-family: SimSun;
    font-size: 128px;
    font-style: normal;
    font-weight: 600;
    line-height: 148px;
    letter-spacing: 15.36px;
}

.themeDiv {
    width: 280px;
    height: 80px;
    margin-bottom:48px;
}

.stageContent_1 {
    top: 200px;
    left: 240px;
    height:calc(100% - 400px);
    width:calc(100% - 480px);
}

.stageContent_2 {
    top: 328px;
    left: 240px;
    height:calc(100% - 656px);
    width:calc(100% - 480px);
}

.stageName {
    margin-bottom:32px;
    color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
    text-align: center;
    font-family: SimSun;
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: 112px;
    letter-spacing: 9.6px;
}

.taskName {
    margin-bottom:32px;color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 64px;font-style: normal;font-weight: 400;line-height: 112px;letter-spacing: 7.68px;
}

.taskText {
    color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;
}

.cardContent {
    top: 140px;
    left: 128px;
    height:calc(100% - 280px);
    width:calc(100% - 256px);
}

@media screen and (max-width: 960px){
    .left-top-1{
        width: 25px;
        height: 175px;
    }

    .left-top-2{
        width: 175px;
        height: 25px;
    }

    .left-top-3 {
        width: 25px;
        height: 175px;
        bottom: 25px;
    }


    .themeText {
        color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
        text-align: center;
        font-family: SimSun;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px; /* 128.571% */
        letter-spacing: 3.36px;
    }

    .themeContent {
        top: 71px;
        left: 50px;
        height: calc(100% - 142px);
        width: calc(100% - 100px);
    }

    .themeDiv {
        width: 110px;
        height: 33px;
        margin-bottom:15px;
    }

    .stageContent_1 {
        top: 71px;
        left: 50px;
        height: calc(100% - 142px);
        width: calc(100% - 100px);
    }

    .stageContent_2 {
        top: 71px;
        left: 50px;
        height: calc(100% - 142px);
        width: calc(100% - 100px);
    }

    #DemoResult .text {
        color: #FFF;text-align: center;font-family: SimSun;font-size: 24px;font-style: normal;font-weight: 700;line-height: 32px;letter-spacing: 2.88px;
    }

    .stageName {
        margin-bottom:12.5px;
        color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
        text-align: center;
        font-family: SimSun;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 3.36px;
    }

    #DemoResult #content1 {
        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
        font-family: SimSun;
        font-style: normal;
        font-weight: 400;
        line-height: 20px !important;
        font-size: 14px !important;
    }

    #DemoResult #content1 p {
        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
        font-family: SimSun;
        font-style: normal;
        font-weight: 400;
        line-height: 20px !important;
        font-size: 14px !important;
        margin-top: 0;
        margin-bottom: 0;
    }

    #DemoResult #content1 p span {
        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
        font-family: SimSun;
        font-style: normal;
        font-weight: 400;
        line-height: 20px !important;
        font-size: 14px !important;
    }

    .taskName {
        margin-bottom:12.5px;
        color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
        font-family: SimSun;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 3.36px;
    }

    .taskText {
        color: #FFF;text-align: center;font-family: SimSun;font-size: 24px;font-style: normal;font-weight: 700;line-height: 32px;letter-spacing: 2.88px;
    }

    #DemoResult #content2 {
        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
        font-family: SimSun;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 20px !important;
    }

    #DemoResult #content2 p {
        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
        font-family: SimSun;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 20px !important;
        margin-top: 0;
        margin-bottom: 0;
    }

    #DemoResult #content2 p span {
        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
        font-family: SimSun;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 20px !important;
    }

    .cardContent {
        top: 54px;
        left: 77px;
        height:calc(100% - 130px);
        width:calc(100% - 154px);
    }
}
</style>

<style>
#DemoResult .el-dialog__wrapper{
    display: flex;
    align-items: center;
    z-index: 99999999!important;
}
#DemoResult .el-dialog__header {
    display: none;
    width: 96.5%;
}

#DemoResult .el-dialog__body {
    padding: 0;
    background: none;
    height: 100%;
    width: 100%;
    position: relative;
}

#DemoResult .el-dialog {
    background: none;
    box-shadow: none;
    height: 100% !important;
    /* margin-top: 0 !important; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    width: 100% !important;
}

#DemoResult .el-dialog__body i:hover {
    color: #FE7777;
}

/* #DemoResult .text {
    color: #FFF;text-align: center;font-family: SimSun;font-size: 64px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 7.68px;
} */
</style>