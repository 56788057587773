import { EllipseResize } from '@logicflow/extension'
import { getShapeStyleFuction, getTextStyleFunction } from '../getShapeStyleUtil'

// 学生
class StudentModel extends EllipseResize.model {
  initNodeData(data) {
    super.initNodeData(data)
    this.rx = 50
    this.ry = 50
  }

  setToBottom() {
    this.zIndex = 0
  }

  getNodeStyle() {
    const style = super.getNodeStyle()
    style.fill = "#60416A"
    style.stroke = "#60416A"
    const properties = this.getProperties()
    return getShapeStyleFuction(style, properties)
  }

  getTextStyle() {
    const style = super.getTextStyle()
    style.color = "#FFFFFF"
    const properties = this.getProperties()
    return getTextStyleFunction(style, properties)
  }
}

export default {
  type: 'pro-student-circle',
  view: EllipseResize.view,
  model: StudentModel
}
