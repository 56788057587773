import InteractiveNode from './InteractiveNode'

// 左上角ICON为消息的节点
class CountDownNode extends InteractiveNode.view {
  getImageHref () {
    return require('@/assets/tools/暂无-倒计时.png');
  }
}


export default {
  type: 'icon-count-down',
  view: CountDownNode,
  model: InteractiveNode.model
}