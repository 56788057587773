<template>
  <div>
    <div style="width:100%;">
      <el-button type="primary" style="float: right;margin-bottom: 10px;" size="mini" round @click="handleSave">{{ languageData.save }}</el-button>
    </div>
    <div style="width: 100%;" class="flex_y_center"
    v-loading="camera_loading"
        :element-loading-text="languageData.loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.2)"
    >
      <video v-show="!src" id="camera_content" ref="video" width="300" height="300"
      ></video>
      <canvas v-show="img_flag" id="canvas" ref="canvas" width="300" height="300"></canvas>
    </div>
  </div>
</template>
  
<script>
import { sendUpdate, add_Operation } from "../../tools/send.js";
import { Loading } from 'element-ui';
import { v4 as uuidv4 } from "uuid";
import LogicFlow from "@logicflow/core";
export default {
  props: {
    list: Array,
    lf: LogicFlow,
  },
  data() {
    return {
      src: "",
      img_flag: false,
      mediaStreamTrack :null,
      camera_loading:true,
      languageData:{}
    };
  },
  watch: {},
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      document.domain = "cocorobo.cn";
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      document.domain = "cocorobo.hk";
      this.languageData = this.zhHantLanguage;
    }else if (window.location.href.indexOf("cocorobo.com") != -1) {
      document.domain = "cocorobo.com";
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
    this.init_camera();
  },
  methods: {
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //2,再将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date();  // 文件最后的修改日期
      theBlob.name = fileName;                // 文件名
      return new File([theBlob], fileName, { type: theBlob.type, lastModified: Date.now() });
    },
    uploadImage(fileParams, loadingInstance) {
      // console.log(fileParams.file)
      var bucket = new window.AWS.S3({ params: { Bucket: 'ccrb' } });  //选择桶
      var _this = this;
      let list = _this.list
      if (fileParams) {
        var params = { Key: fileParams.name, ContentType: fileParams.type, Body: fileParams, 'Access-Control-Allow-Credentials': '*', 'ACL': 'public-read' }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = { partSize: 2048 * 1024 * 1024, queueSize: 2, leavePartsOnError: true };
        bucket.upload(params, options).send(function (err, data) {
          if (err) {
            console.log(err)
          } else {
            list[0].properties.src = data.Location
            _this.$props.lf.getNodeModelById(list[0].id).setProperties({
              src: list[0].properties.src
            });
            sendUpdate(list, [], "update");
            let newData = {
              "nodes": list,
              "edges": [],
              "backgroundUrl": localStorage.getItem("background")
            }
            let video = document.querySelector("#camera_content");
            video.srcObject = null;
            _this.mediaStreamTrack.getVideoTracks().forEach(
              track=>track.stop()
            )
            add_Operation(newData, "updateNode")
            _this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close();
            });
            _this.$emit("info", false);
          }
        });
      }
    },
    handleSave() {
      this.img_flag = true;
      let video = document.getElementById("camera_content");
      let canvas = document.getElementById("canvas");
      let ctx = canvas.getContext('2d')
      // 把当前视频帧内容渲染到canvas上
      ctx.drawImage(video, 0, 0, 300, 300)
      let img = canvas.toDataURL();
      this.src = img;
      let loadingInstance = Loading.service({ fullscreen: true });
      let blob = this.dataURLtoBlob(this.src);
      // 拿到文件名
      let fileName = uuidv4() + ".png";
      // 2，在转为 file类型
      let file1 = this.blobToFile(blob, fileName);
      this.uploadImage(file1, loadingInstance)
      this.img_flag = false;
      this.src = ""
    },
    init_camera() {
      let that =this;
      let video = document.getElementById("camera_content");
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
      navigator.getUserMedia({ video: true, audio: false }, success, error);

      function success(stream) {
        that.mediaStreamTrack = stream;
        console.log(that.mediaStreamTrack);
        video.srcObject = stream;
        video.play();
        that.camera_loading = false;
      }

      function error(err) {
        alert("video error: " + err);
      }
    },
  },
};
</script>
  
<style>
#camera .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
  width: 40%;
}

#camera .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-white, #fff);
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, .050980392156862744);
  padding: 8px 24px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#camera .el-dialog__body {
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  padding: 0;
  /* background: var(--font-icon-white,#fff); */
  padding: 8px 24px;
  width: calc(100% - 48px);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, .050980392156862744);
}

#camera .close {
  position: absolute;
  top: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}
</style>