import ThinkingNode from './ThinkingNode'

// 左上角ICON为消息的节点
class ThinkingGridNode extends ThinkingNode.view {
  getImageHref () {
    return require('@/assets/tools/14思维网格.png');
  }
}


export default {
  type: 'icon-thinking-grid',
  view: ThinkingGridNode,
  model: ThinkingNode.model
}