import { EllipseResize } from '@logicflow/extension'
import { getShapeStyleFuction, getTextStyleFunction } from '../getShapeStyleUtil'

// 参与任务
class ParticipateModel extends EllipseResize.model {
  initNodeData(data) {
    super.initNodeData(data)
    this.rx = 40
    this.ry = 40
  }

  setToBottom() {
    this.zIndex = 0
  }

  getNodeStyle() {
    const style = super.getNodeStyle()
    style.fill = "#AED6F3"
    style.stroke = "#AED6F3"
    const properties = this.getProperties()
    return getShapeStyleFuction(style, properties)
  }

  getTextStyle() {
    const style = super.getTextStyle()
    style.color = "#FFFFFF"
    const properties = this.getProperties()
    return getTextStyleFunction(style, properties)
  }
}

export default {
  type: 'pro-participate-circle',
  view: EllipseResize.view,
  model: ParticipateModel
}
