<template>
    <svg class="icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" :width="size" :height="size" viewBox="0 0 20 20" enable-background="new 0 0 20 20"
        xml:space="preserve">
        <image id="image0" width="20" height="20" x="0" y="0" href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0iI2E2
Y2VlYiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjMwOyYj
MTYxOyYjMTM0OyYjMjMzOyYjMTI4OyYjMTM3OyI+CjxnIGlkPSJHcm91cCA4OTMiPgo8cGF0aCBp
ZD0iVW5pb24iIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMS40
MTA4NSAzQzEuMTgzOTUgMyAxIDMuMTc5MDkgMSAzLjRWNC4wNjY2N0MxIDQuMjg3NTggMS4xODM5
NSA0LjQ2NjY3IDEuNDEwODUgNC40NjY2N0MxLjYzNzc2IDQuNDY2NjcgMS44MjE3MSA0LjI4NzU4
IDEuODIxNzEgNC4wNjY2N1YzLjhIMi4xODEyMUMyLjQwODExIDMuOCAyLjU5MjA2IDMuNjIwOTEg
Mi41OTIwNiAzLjRDMi41OTIwNiAzLjE3OTA5IDIuNDA4MTEgMyAyLjE4MTIxIDNIMS40MTA4NVpN
My43MjE5MSAzQzMuNDk1IDMgMy4zMTEwNSAzLjE3OTA5IDMuMzExMDUgMy40QzMuMzExMDUgMy42
MjA5MSAzLjQ5NSAzLjggMy43MjE5MSAzLjhINS4yNjI2MUM1LjQ4OTUyIDMuOCA1LjY3MzQ2IDMu
NjIwOTEgNS42NzM0NiAzLjRDNS42NzM0NiAzLjE3OTA5IDUuNDg5NTIgMyA1LjI2MjYxIDNIMy43
MjE5MVpNNi44MDMzMSAzQzYuNTc2NCAzIDYuMzkyNDYgMy4xNzkwOSA2LjM5MjQ2IDMuNEM2LjM5
MjQ2IDMuNjIwOTEgNi41NzY0IDMuOCA2LjgwMzMxIDMuOEg4LjM0NDAxQzguNTcwOTIgMy44IDgu
NzU0ODcgMy42MjA5MSA4Ljc1NDg3IDMuNEM4Ljc1NDg3IDMuMTc5MDkgOC41NzA5MiAzIDguMzQ0
MDEgM0g2LjgwMzMxWk05Ljg4NDcyIDNDOS42NTc4MSAzIDkuNDczODYgMy4xNzkwOSA5LjQ3Mzg2
IDMuNEM5LjQ3Mzg2IDMuNjIwOTEgOS42NTc4MSAzLjggOS44ODQ3MiAzLjhIMTEuNDI1NEMxMS42
NTIzIDMuOCAxMS44MzYzIDMuNjIwOTEgMTEuODM2MyAzLjRDMTEuODM2MyAzLjE3OTA5IDExLjY1
MjMgMyAxMS40MjU0IDNIOS44ODQ3MlpNMTIuOTY2MSAzQzEyLjczOTIgMyAxMi41NTUzIDMuMTc5
MDkgMTIuNTU1MyAzLjRDMTIuNTU1MyAzLjYyMDkxIDEyLjczOTIgMy44IDEyLjk2NjEgMy44SDEz
LjMyNTZWNC40QzEzLjMyNTYgNC42MjA5MSAxMy41MDk2IDQuOCAxMy43MzY1IDQuOEMxMy45NjM0
IDQuOCAxNC4xNDczIDQuNjIwOTEgMTQuMTQ3MyA0LjRWMy40QzE0LjE0NzMgMy4xNzkwOSAxMy45
NjM0IDMgMTMuNzM2NSAzSDEyLjk2NjFaTTEuODIxNzEgNS40QzEuODIxNzEgNS4xNzkwOSAxLjYz
Nzc2IDUgMS40MTA4NSA1QzEuMTgzOTUgNSAxIDUuMTc5MDkgMSA1LjRWNi43MzMzM0MxIDYuOTU0
MjUgMS4xODM5NSA3LjEzMzMzIDEuNDEwODUgNy4xMzMzM0MxLjYzNzc2IDcuMTMzMzMgMS44MjE3
MSA2Ljk1NDI1IDEuODIxNzEgNi43MzMzM1Y1LjRaTTE0LjE0NzMgNi40QzE0LjE0NzMgNi4xNzkw
OSAxMy45NjM0IDYgMTMuNzM2NSA2QzEzLjUwOTYgNiAxMy4zMjU2IDYuMTc5MDkgMTMuMzI1NiA2
LjRWNy40QzEzLjMyNTYgNy42MjA5MSAxMy41MDk2IDcuOCAxMy43MzY1IDcuOEMxMy45NjM0IDcu
OCAxNC4xNDczIDcuNjIwOTEgMTQuMTQ3MyA3LjRWNi40Wk0xLjgyMTcxIDguMDY2NjdDMS44MjE3
MSA3Ljg0NTc1IDEuNjM3NzYgNy42NjY2NyAxLjQxMDg1IDcuNjY2NjdDMS4xODM5NSA3LjY2NjY3
IDEgNy44NDU3NSAxIDguMDY2NjdWOS40QzEgOS42MjA5MSAxLjE4Mzk1IDkuOCAxLjQxMDg1IDku
OEMxLjYzNzc2IDkuOCAxLjgyMTcxIDkuNjIwOTEgMS44MjE3MSA5LjRWOC4wNjY2N1pNMS44MjE3
MSAxMC43MzMzQzEuODIxNzEgMTAuNTEyNCAxLjYzNzc2IDEwLjMzMzMgMS40MTA4NSAxMC4zMzMz
QzEuMTgzOTUgMTAuMzMzMyAxIDEwLjUxMjQgMSAxMC43MzMzVjExLjRDMSAxMS42MjA5IDEuMTgz
OTUgMTEuOCAxLjQxMDg1IDExLjhIMi4xODEyMUMyLjQwODExIDExLjggMi41OTIwNiAxMS42MjA5
IDIuNTkyMDYgMTEuNEMyLjU5MjA2IDExLjE3OTEgMi40MDgxMSAxMSAyLjE4MTIxIDExSDEuODIx
NzFWMTAuNzMzM1pNMy43MjE5MSAxMUMzLjQ5NSAxMSAzLjMxMTA1IDExLjE3OTEgMy4zMTEwNSAx
MS40QzMuMzExMDUgMTEuNjIwOSAzLjQ5NSAxMS44IDMuNzIxOTEgMTEuOEg1LjI2MjYxQzUuNDg5
NTIgMTEuOCA1LjY3MzQ2IDExLjYyMDkgNS42NzM0NiAxMS40QzUuNjczNDYgMTEuMTc5MSA1LjQ4
OTUyIDExIDUuMjYyNjEgMTFIMy43MjE5MVpNNi44MDMzMSAxMUM2LjU3NjQgMTEgNi4zOTI0NiAx
MS4xNzkxIDYuMzkyNDYgMTEuNEM2LjM5MjQ2IDExLjYyMDkgNi41NzY0IDExLjggNi44MDMzMSAx
MS44SDcuNTczNjZDNy44MDA1NyAxMS44IDcuOTg0NTIgMTEuNjIwOSA3Ljk4NDUyIDExLjRDNy45
ODQ1MiAxMS4xNzkxIDcuODAwNTcgMTEgNy41NzM2NiAxMUg2LjgwMzMxWk05LjUzMzMzIDUuODMy
MTVDOS40MDE1MyA1LjczNDExIDkuMjIyMTEgNS43MjI4NSA5LjA3ODQ3IDUuODAzNTlDOC45MzQ4
MyA1Ljg4NDMzIDguODU1MTQgNi4wNDEyNCA4Ljg3NjQ1IDYuMjAxMzhMOS43MzQwNCAxMi42NDc1
QzkuNzU3NTYgMTIuODI0MyA5Ljg5ODM3IDEyLjk2NDYgMTAuMDc5MyAxMi45OTE1QzEwLjI2MDMg
MTMuMDE4NSAxMC40Mzc4IDEyLjkyNTYgMTAuNTE0NSAxMi43NjM4TDExLjcxMDUgMTAuMjQxOUwx
NC41NTE4IDEwLjQ5NDVDMTQuNzM0MSAxMC41MTA3IDE0LjkwNTQgMTAuNDA3NSAxNC45NzE5IDEw
LjI0MTRDMTUuMDM4NCAxMC4wNzU0IDE0Ljk4NCA5Ljg4NjUxIDE0LjgzODUgOS43NzgzTDkuNTMz
MzMgNS44MzIxNVpNMTEuMDg2IDkuNjUwMkwxMC4zNjU5IDExLjE2ODdMOS44MTgwMSA3LjA1MDY3
TDEzLjIwNzIgOS41NzE2M0wxMS40OTY0IDkuNDE5NTNDMTEuMzIzMiA5LjQwNDE0IDExLjE1ODkg
OS40OTY1MiAxMS4wODYgOS42NTAyWiIgZmlsbD0iIzEwODhmZCIgLz4KPC9nPgo8L2c+Cjwvc3Zn
Pgo=" />
    </svg>
</template>
  
<script>
export default {
    props: {
        size: {
            default: '24'
        }
    }
}
</script>