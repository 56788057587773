<template>
    <div id="export">
        <el-dialog :visible.sync="dialogVisible" width="20%" :destroy-on-close="true" :before-close="handleClose"
            :close-on-click-modal="false" :show-close="false">
            <div slot="title" style="width: 100%; position: relative">
                <span class="el-dialog__title">{{ languageData.export }}</span>
                <button type="button" class="close" style="top: 4px;right: 0;">
                <img v-if="hoverClose" src="../../assets/close_pre.png" width="16" height="16" @mouseout="blurClose"
                    @click="handleClose" />
                <img v-else src="../../assets/close_nor.png" width="16" height="16" @mouseover="focusClose" />
                </button>
            </div>
            <span style="display:flex;height: 100%;flex-wrap:wrap;flex-direction: row;justify-content: center;align-items: center;">
                <div style="display:flex;flex-direction: row;align-items: center;justify-content: space-evenly;">
                    <el-radio v-model="radio" label="1" style="margin-bottom: 0;margin-right: 10px;">{{ languageData.pictureFiles }}</el-radio>
                    <!-- <el-radio v-model="radio" label="2" style="margin-bottom: 0;">Xml文件</el-radio> -->
                    <el-radio v-model="radio" label="3" style="margin-bottom: 0;">{{ languageData.jsonFiles }}</el-radio>
                </div>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">{{ languageData.close }}</el-button>
                <el-button type="primary" @click="confirmExport">{{ languageData.confirm }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            radio: '',
            languageData: {},
            hoverClose: false
        };
    },
    created() {
        this.$nextTick(() => {
            $(".el-dialog").css("width", "20%");
            $(".el-dialog").css({
                "height": "auto"
            })
        })
    },
    mounted() {
        if (window.location.href.indexOf("cocorobo.cn") != -1) {
            this.languageData = this.zhHansLanguage;
        }
        else if (window.location.href.indexOf("cocorobo.hk") != -1) {
            this.languageData = this.zhHantLanguage;
        }else if (window.location.href.indexOf("cocorobo.com") != -1) {
            this.languageData = this.enLanguage;
        }
        else {
            this.languageData = this.zhHansLanguage;
        }
    },
    watch: {
        dialogVisible(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    $(".el-dialog").css("width", "20%");
                    $(".el-dialog").css({
                        "height": "auto"
                    })
                })
            }
        }
    },
    methods: {
        focusClose() {
        this.hoverClose = true;
        },
        blurClose() {
        this.hoverClose = false;
        },
        handleClose() {
            this.$emit("info", false);
        },
        confirmExport() {
            if (this.radio != '') {
                this.$emit("saveGraph", this.radio);
                this.radio = '';
                this.$emit("info", false);
            }
            else {
                this.$message({
                    message: this.languageData.tip,
                    offset: 400
                });
            }
        },
    }
};
</script>

<style>
#export .el-dialog {
  margin-top: 0!important;
  height: 100%!important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
}

#export .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3,#e7e7e7);
  background: var(--font-icon-white,#fff);
  border-left: 1px solid var(--bg3,#e7e7e7);
  border-right: 1px solid var(--bg3,#e7e7e7);
  border-top: 1px solid var(--bg3,#e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0,0,0,.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0,0,0,.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0,0,0,.050980392156862744);
  padding: 8px 24px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#export .el-dialog__body {
  border-left: 1px solid var(--bg3,#e7e7e7);
  border-right: 1px solid var(--bg3,#e7e7e7);
  padding: 0;
  background: var(--font-icon-white,#fff);
  padding: 8px 24px;
  width: calc(100% - 48px);
  box-shadow: 0 8px 10px -5px rgba(0,0,0,.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0,0,0,.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0,0,0,.050980392156862744);
  height: 5%;
}

#export .el-dialog__footer {
  border-left: 1px solid var(--bg3,#e7e7e7);
  border-right: 1px solid var(--bg3,#e7e7e7);
  border-top: 1px solid var(--bg3,#e7e7e7);
  border-bottom: 1px solid var(--bg3,#e7e7e7);
  padding: 8px 24px;
  background: var(--font-icon-white,#fff);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  width: calc(100% - 48px);
  box-shadow: 0 8px 10px -5px rgba(0,0,0,.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0,0,0,.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0,0,0,.050980392156862744);
  box-sizing: unset;
}

#export .close {
  position: absolute;
  top: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}
</style>