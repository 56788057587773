<template>
    <div
        class="showResult"
        id="showResult" v-if="list.length != 0 && dialogVisible">
        <el-dialog :visible.sync="dialogVisible" :destroy-on-close="true" :fullscreen="fullscreen"
            :before-close="handleClose"  :show-close="showClose" v-dialogDrag>
            <!-- <div slot="title">
                <span class="el-dialog__title">预览</span>
                <button type="button" class="max" v-if="!fullscreen">
                    <img v-if="hoverMax" src="../../assets/max_pre.png" width="16" height="16" @mouseout="blurMax"
                        @click="clickMax" />
                    <img v-else src="../../assets/max_nor.png" width="16" height="16" @mouseover="focusMax" />
                </button>
                <button type="button" class="max" v-else-if="fullscreen">
                    <img v-if="hoverRecovery" src="../../assets/recovery_pre.png" width="16" height="16"
                        @mouseout="blurRecovery" @click="clickRecovery" />
                    <img v-else src="../../assets/recovery_nor.png" width="16" height="16" @mouseover="focusRecovery" />
                </button>
            </div> -->
            <span style="height:100%;">
                <div style="height:100%;position: relative;">
                    <!-- <div :id="list.length != 0 ? 'aaa_' + list[0].id : ''" @click="handleClose"
                        style="font-size: 20px;position: absolute;top: 3px;right: 3px;cursor: pointer;z-index: 1023;width:40px;height:40px;">
                    </div> -->
                    <!-- <i v-if="Show" class="el-icon-circle-close CloseImgTheme animate__animated animate__zoomIn" :id="list.length != 0 ? 'result_' + list[0].id : ''"
                        style="z-index: 1024;" @click="handleClose"></i> -->
                    <!-- <template v-for="(item, index) in list">
                        <div class="right_top" v-if="item.type == 'icon-theme'" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;min-height: 435px;max-height: 600px;">
                            <div style="height: 100%;min-height: 425px;max-height: 600px;overflow-y: auto;display: flex;justify-content: center;align-items: center;">
                                <span style="color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 28px;font-style: normal;font-weight: 700;line-height: 38px;letter-spacing: 3.36px;">{{ item.properties.topic }}</span>
                            </div>
                        </div>
                        <div class="right_top" v-if="item.type == 'icon-stage'" v-bind:key="index" id="icon-stage"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;min-height: 435px;max-height: 600px;">
                            <div style="height: 100%;min-height: 425px;max-height: 600px;overflow-y: auto;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                                <span style="color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 28px;font-style: normal;font-weight: 700;line-height: 38px;letter-spacing: 3.36px;">{{ item.properties.stageName }}</span>
                                <span v-html="item.properties.stageContent"></span>
                            </div>
                        </div>
                        <div class="right_top" v-if="item.type == 'icon-task'" v-bind:key="index" id="icon-task"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;min-height: 435px;max-height: 600px;">
                            <div style="height: 100%;min-height: 425px;max-height: 600px;overflow-y: auto;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                                <span style="color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 28px;font-style: normal;font-weight: 700;line-height: 38px;letter-spacing: 3.36px;">{{ item.properties.taskTitle }}</span>
                                <span v-html="item.properties.taskContent"></span>
                            </div>
                        </div>
                        <div class="right_top" v-if="item.type == 'icon-card'" v-bind:key="index"
                            :style="item.properties.radio == 'imageList' || item.properties.radio == 'videoList' ? 'margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;background:none;padding:0;box-shadow: none;' : 'margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0 10px;'">
                            <div v-if="(item.properties.radio == 'content')"
                                style="margin-left: 0;height: 100%;min-height: 300px;max-height: 600px;">
                                <div id="content" style="color:black;padding-top:10px;overflow-y: auto;height:97%;" v-html="item.properties.content">
                                </div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'iframeUrl')"
                                style="display: flex;justify-content: center;align-items: center;height:96%;padding: 10px 0px;">
                                <iframe style="width: 100%;height: 100%;min-height: 600px;"
                                    :src="item.properties.iframeUrl.indexOf('https://') != -1 || item.properties.iframeUrl.indexOf('http://') != -1 ? item.properties.iframeUrl : '//' + item.properties.iframeUrl"
                                    frameborder="no" border="0" sandbox="allow-forms allow-scripts allow-same-origin"></iframe>
                            </div>
                            <div v-else-if="(item.properties.radio == 'embedCode')"
                                style="display: flex;flex-direction: row;justify-content: center;height:96%;padding: 10px 0px;min-height: 600px;"
                                v-html="item.properties.embedCode">
                            </div>
                            <div v-else-if="(item.properties.radio == 'imageList')"
                                style="display: flex;justify-content: center;align-items: center;height:96%;padding: 10px 0px;">
                                <img style="width: auto;height: auto;max-width:100%;max-height: 600px;"
                                    :src="item.properties.imageList[0].url" />
                            </div>
                            <div v-else-if="(item.properties.radio == 'videoList')"
                                style="display: flex;justify-content: center;align-items: center;height:100%;">
                                <video controls :src="item.properties.videoList[0].url"
                                    style="width: auto;height: auto;max-width: 100%;max-height: 600px;"></video>
                            </div>
                            <div v-else-if="(item.properties.radio == 'audioList')"
                                style="display:flex;flex-direction: row;justify-content: center;align-items: center;height: 100%;min-height: 300px;">
                                <audio controls :src="item.properties.audioList[0].url"></audio>
                            </div>
                            <div v-else-if="(item.properties.radio == 'fileList' && item.properties.fileList && item.properties.fileList.length != 0 && (item.properties.fileList[0].url.indexOf('.pdf') != -1 || item.properties.fileList[0].url.indexOf('.PDF') != -1))"
                                style="display: flex;justify-content: center;align-items: center;padding: 10px 0px;width: 100%;min-height: 670px;">
                                <iframe style="position: absolute;width: 100%;height: 100%;top: 0px;left: 0;"
                                    :src="'https://cloud.cocorobo.cn/pdf.js/web/viewer.html?file=' + item.properties.fileList[0].url" frameborder="no" border="0" ></iframe>
                            </div>
                            <div v-else-if="(item.properties.radio == 'fileList' && item.properties.fileList && item.properties.fileList.length != 0 && item.properties.fileList[0].url.indexOf('.pdf') == -1 && item.properties.fileList[0].url.indexOf('.PDF') == -1)"
                                style="display: flex;justify-content: center;align-items: center;padding: 10px 0px;width: 100%;min-height: 670px;">
                                <iframe style="position: absolute;width: 100%;height: 100%;top: 0px;left: 0;"
                                    :src="'https://view.officeapps.live.com/op/view.aspx?src=' + item.properties.fileList[0].url" frameborder="no" border="0" ></iframe>
                            </div>
                        </div>

                        <div class="right_top" v-if="item.type == 'icon-select'" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;padding:0 20px;min-height: 435px;">
                            <div
                                style="display:flex;flex-direction: column;justify-content: center;height: 100%;min-height: 425px;">
                                <div>{{ item.properties.selectContent }}</div>
                                <div style="margin-top:10px;">
                                    <template v-for="(option, index) in item.properties.selectOption">
                                        <el-radio v-bind:key="'letterItemList' + index" v-model="Radio" :label="option"
                                            style="margin-right: 30px;margin-bottom: 20px;" @input="setSelect">
                                            {{ letterList[index] }}.
                                            {{ option }}
                                        </el-radio>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="right_top" v-if="item.type == 'icon-thinking'" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;background:none;padding:0;box-shadow: none;">
                            <div style="display: flex;justify-content: center;align-items: center;height:96%;padding: 10px 0px;">
                                <img style="width: auto;height: auto;max-width: 100%;max-height: 600px;"
                                    :src="item.properties.thinkingContent" />
                            </div>
                        </div>

                        <div class="right_top" v-if="item.type == 'icon-whiteboard'" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;background:none;padding:0;box-shadow: none;">
                            <div style="display: flex;justify-content: center;align-items: center;height:96%;padding: 10px 0px;">
                                <img style="width: auto;height: auto;max-width: 100%;max-height: 600px;"
                                    :src="item.properties.whiteboardContent" />
                            </div>
                        </div>

                        <div class="right_top" v-if="item.type == 'icon-camera'" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;background:none;padding:0;box-shadow: none;">
                            <div style="display: flex;justify-content: center;align-items: center;height:96%;padding: 10px 0px;">
                                <img style="width: auto;height: auto;max-width: 100%;max-height: 600px;"
                                    :src="item.properties.src" />
                            </div>
                        </div>

                        <div class="right_top" v-if="item.type == 'icon-AI'" v-bind:key="index"
                            :style="item.properties.radio == 'image' && item.properties.imgResult != '' ? 'margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;background:none;padding:0;box-shadow: none;' : 'margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0 10px;'">
                            <div style="display:flex;flex-direction: column;justify-content: center;height: 100%;min-height: 300px;"
                                v-if="item.properties.radio == 'description'">
                                <div style="margin-left: 0;height: 100%;min-height: 300px;max-height: 600px;">
                                    <div id="content" style="color:black;padding-top:10px;overflow-y: auto;height:97%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <span style="color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 28px;font-style: normal;font-weight: 700;line-height: 38px;letter-spacing: 3.36px;">{{ item.properties.topic }}</span>
                                        <span class="text" style="font-size:18px;">{{ item.properties.aiContent }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'image' && item.properties.imgResult != '')"
                                style="display: flex;justify-content: center;align-items: center;height:100%;">
                                <img style="width: auto;height: auto;max-width: 100%;max-height: 100%;"
                                    :src="item.properties.imgResult" />
                            </div>
                        </div>

                        <div class="right_top" v-if="item.type == 'icon-new-AI'" v-bind:key="index"
                            :style="item.properties.radio == 'image' && item.properties.imgResult != '' ? 'margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;background:none;padding:0;box-shadow: none;' : 'margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0 10px;'">
                            <div style="display:flex;flex-direction: column;justify-content: center;height: 100%;min-height: 300px;"
                                v-if="item.properties.radio == 'description'">
                                <div style="margin-left: 0;height: 100%;min-height: 300px;max-height: 600px;">
                                    <div id="content" style="color:black;padding-top:10px;overflow-y: auto;height:97%;">
                                        <span class="text" style="font-size:18px;">{{ item.properties.aiContent }}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'audio' && item.properties.audioList.length != 0)"
                                style="display: flex;justify-content: center;align-items: center;height:100%;">
                                <audio style="max-width: 100%;max-height: 100%;"
                                    :src="item.properties.audioList[0].url" controls></audio>
                            </div>
                        </div>
                    </template> -->
                    <template v-for="(item, index) in list">
                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-theme' && Show" v-bind:key="index" id="icon-theme"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height: 100%;position: relative;">
                                <div class="left-top-1" style="background: var(--brand-normal, #3681FC);"></div>
                                <div class="left-top-2" style="background: var(--brand-normal, #3681FC);position: absolute;top: 0;"></div>
                                <div id="content" class="themeContent" style="position: absolute;display:flex;" >
                                    <div style="width:100%;display: flex;justify-content: center;align-items: center;flex-direction: column;">
                                        <div class="themeDiv" style="background: var(--brand-normal, #3681FC);text-align: center;">
                                            <span class="text">{{ languageData.topic }}</span>
                                        </div>
                                        <div class="themeText">{{ item.properties.topic }}</div>
                                    </div>
                                </div>
                                <div class="left-top-3" style="background: var(--brand-normal, #3681FC);position: absolute;right:0;"></div>
                                <div class="left-top-2" style="background: var(--brand-normal, #3681FC);position: absolute;bottom: 0;right:0;"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-stage' && Show" v-bind:key="index" id="icon-stage"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height: 100%;position: relative;">
                                <div class="left-top-1" :style="item.properties.stageTitleColor ? 'background:' + item.properties.stageTitleColor +';' : 'background: #1D51B6;'"></div>
                                <div class="left-top-2" :style="item.properties.stageTitleColor ? 'position: absolute;top: 0;background:' + item.properties.stageTitleColor +';' : 'background: #1D51B6;position: absolute;top: 0;'"></div>
                                <div id="content" class="stageContent_1" style="position: absolute;display:flex;" v-if="item.properties.stageContent && item.properties.stageContent != '<p></p>'">
                                    <div style="width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <div class="themeDiv" :style="item.properties.stageTitleColor ? 'text-align: center;background:' + item.properties.stageTitleColor +';' : 'background: #1D51B6;text-align: center;'">
                                            <span class="text">{{ languageData.stageName }}</span>
                                        </div>
                                        <div v-if="item.properties.stageName && item.properties.stageName !=''" class="stageName">{{ item.properties.stageName }}</div>
                                        <div v-if="!isPhone" :style="item.properties.stageName && item.properties.stageName !='' ? 'width:100%;height:calc(100% - 112px);display:flex;justify-content: center;' : 'margin-top:24px;width:100%;height:calc(100% - 64px);display:flex;justify-content: center;'">
                                            <span v-html="item.properties.stageContent" style="overflow-y: auto;height: 100%;width: 100%;" id="content1"></span>
                                        </div>
                                        <div v-else :style="item.properties.stageName && item.properties.stageName !='' ? 'width:100%;height:calc(100% - 98.5px);display:flex;justify-content: center;' : 'margin-top:12.5px;width:100%;height:calc(100% - 48px);display:flex;justify-content: center;'">
                                            <span v-html="item.properties.stageContent" style="overflow-y: auto;height: 100%;width: 100%;" id="content1"></span>
                                        </div>
                                    </div>
                                </div>
                                <div id="content" class="stageContent_2" style="position: absolute;display:flex;" v-else>
                                    <div style="width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <div class="themeDiv" :style="item.properties.stageTitleColor ? 'text-align: center;background:' + item.properties.stageTitleColor +';' : 'background: #1D51B6;text-align: center;'">
                                            <span class="text">{{ languageData.stageName }}</span>
                                        </div>
                                        <div class="themeText">{{ item.properties.stageName }}</div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.stageTitleColor ? 'position: absolute;right:0;background:' + item.properties.stageTitleColor +';' : 'background: #1D51B6;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.stageTitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.stageTitleColor +';' : 'background: #1D51B6;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-task' && Show" v-bind:key="index" id="icon-task"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height: 100%;position: relative;">
                                <div class="left-top-1" :style="item.properties.taskTitleColor ? 'background:' + item.properties.taskTitleColor +';' : 'background: #6E44C9;'"></div>
                                <div class="left-top-2" :style="item.properties.taskTitleColor ? 'position: absolute;top: 0;background:' + item.properties.taskTitleColor +';' : 'background: #6E44C9;position: absolute;top: 0;'"></div>
                                <div id="content" class="stageContent_1" style="position: absolute;display:flex;" v-if="item.properties.taskContent && item.properties.taskContent != '<p></p>'">
                                    <div style="width: 100%;">
                                        <div class="themeDiv" :style="item.properties.taskTitleColor ? 'text-align: center;background:' + item.properties.taskTitleColor +';' : 'background: #6E44C9;text-align: center;'">
                                            <span class="taskText">{{ languageData.taskName }}</span>
                                        </div>
                                        <div class="taskName" v-if="item.properties.taskTitle && item.properties.taskTitle != ''">{{ item.properties.taskTitle }}</div>
                                        <div v-if="!isPhone" :style="item.properties.taskTitle && item.properties.taskTitle != '' ? 'width:100%;height:calc(100% - 112px);display:flex;justify-content: center;' : 'margin-top:24px;width:100%;height:calc(100% - 64px);display:flex;justify-content: center;'">
                                            <span v-html="item.properties.taskContent" style="overflow-y: auto;height: 100%;width: 100%;" id="content2"></span>
                                        </div>
                                        <div v-else :style="item.properties.taskTitle && item.properties.taskTitle != '' ? 'width:100%;height:calc(100% - 98.5px);display:flex;justify-content: center;' : 'margin-top:12.5px;width:100%;height:calc(100% - 48px);display:flex;justify-content: center;'">
                                            <span v-html="item.properties.taskContent" style="overflow-y: auto;height: 100%;width: 100%;" id="content2"></span>
                                        </div>
                                    </div>
                                </div>
                                <div id="content" class="stageContent_2" style="position: absolute;display:flex;" v-else>
                                    <div style="width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <div class="themeDiv" :style="item.properties.taskTitleColor ? 'text-align: center;background:' + item.properties.taskTitleColor +';' : 'background: #6E44C9;text-align: center;'">
                                            <span class="text">{{ languageData.taskName }}</span>
                                        </div>
                                        <div class="themeText">{{ item.properties.taskTitle }}</div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.taskTitleColor ? 'position: absolute;right:0;background:' + item.properties.taskTitleColor +';' : 'background: #6E44C9;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.taskTitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.taskTitleColor +';' : 'background: #6E44C9;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-card' && Show" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div v-if="!item.properties.radio" style="height: 100%;position: relative;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                
                                <div id="content" class="themeContent" style="position: absolute;display:flex;" >
                                    <div style="width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="themeText">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'content')" style="height: 100%;position: relative;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                
                                <div id="content" class="stageContent_1" style="position: absolute;display:flex;" v-if="item.properties.content && item.properties.content !='<p></p>'">
                                    <div style="width:100%;">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                        <div v-if="!isPhone" style="width:100%;height:calc(100% - 48px);display:flex;justify-content: center;">
                                            <span v-html="item.properties.content" style="overflow-y: auto;height: 100%;width: 100%;" id="content1"></span>
                                        </div>
                                        <div v-else style="width:100%;height:calc(100% - 50.5px);display:flex;justify-content: center;">
                                            <span v-html="item.properties.content" style="overflow-y: auto;height: 100%;width: 100%;" id="content1"></span>
                                        </div>
                                    </div>
                                </div>
                                <div id="content" class="themeContent" style="position: absolute;display:flex;" v-else>
                                    <div style="width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="themeText">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'iframeUrl' && item.properties.iframeUrl != '')" style="height:100%;position: relative;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:200px;margin-right:30px;" v-if="!isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width:300px;margin-right:30px;" v-else-if="isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width: 100%;background: var(--brand-light, #E0EAFB);justify-content: center;display: flex;align-items: center;">
                                        <iframe style="position: absolute;transform: scale(.5, .5) translate(-47%, -50%);width: 142%;height: 199%;top: 0px;left: 177px;"
                                            :src="item.properties.iframeUrl.indexOf('https://') != -1 || item.properties.iframeUrl.indexOf('http://') != -1 ? item.properties.iframeUrl : '//' + item.properties.iframeUrl"
                                            frameborder="no" border="0" sandbox="allow-forms allow-scripts allow-same-origin"></iframe>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'embedCode')" style="height:100%;position: relative;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:200px;margin-right:30px;" v-if="!isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width:300px;margin-right:30px;" v-else-if="isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div class="embedCode" style="width: 100%;background: var(--brand-light, #E0EAFB);justify-content: center;display: flex;align-items: center;" v-html="item.properties.embedCode">
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'imageList' && item.properties.imageList && item.properties.imageList.length != 0 && item.properties.imageList[0].url)" style="height:100%;position: relative;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:200px;margin-right:30px;" v-if="!isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width:300px;margin-right:30px;" v-else-if="isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width: 100%;background: var(--brand-light, #E0EAFB);justify-content: center;display: flex;align-items: center;">
                                        <img style="width: auto;height: auto;max-width: 100%;max-height: 100%;"
                                            :src="item.properties.imageList[0].url" />
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'videoList' && item.properties.videoList && item.properties.videoList.length != '' && item.properties.videoList[0].url)" style="height:100%;position: relative;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:200px;margin-right:30px;" v-if="!isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width:300px;margin-right:30px;" v-else-if="isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width: 100%;background: var(--brand-light, #E0EAFB);justify-content: center;display: flex;align-items: center;">
                                        <video controls :src="item.properties.videoList[0].url"
                                            style="width: auto;height: auto;max-width: 100%;max-height: 100%;"></video>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'audioList' && item.properties.audioList && item.properties.audioList.length != '' && item.properties.audioList[0].url)" style="height: 100%;position: relative;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:100%;justify-content: center;display: flex;align-items: center;flex-direction:column;">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                        <audio controls :src="item.properties.audioList[0].url" style="width:450px;" v-if="!isPhone"></audio>
                                        <audio controls :src="item.properties.audioList[0].url" style="width:300px;" v-else></audio>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'fileList' && item.properties.fileList && item.properties.fileList.length != 0 && (item.properties.fileList[0].url.indexOf('.pdf') != -1 || item.properties.fileList[0].url.indexOf('.PDF') != -1))" style="height:100%;position: relative;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:200px;margin-right:30px;" v-if="!isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width:300px;margin-right:30px;" v-else-if="isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width: 100%;background: var(--brand-light, #E0EAFB);justify-content: center;display: flex;align-items: center;">
                                        <iframe style="position: absolute;width: 78%;height: 90%;top: 5%;"
                                            :src="'https://cloud.cocorobo.cn/pdf.js/web/viewer.html?file=' + item.properties.fileList[0].url" frameborder="no" border="0" ></iframe>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'fileList' && item.properties.fileList && item.properties.fileList.length != 0 && item.properties.fileList[0].url.indexOf('.pdf') == -1 && item.properties.fileList[0].url.indexOf('.PDF') == -1)" style="height:100%;position: relative;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                <div class="cardContent" style="position: absolute;display:flex;">
                                    <div style="width:200px;margin-right:30px;" v-if="!isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width:300px;margin-right:30px;" v-else-if="isPhone && item.properties.title && item.properties.title != ''">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                    <div style="width: 100%;background: var(--brand-light, #E0EAFB);justify-content: center;display: flex;align-items: center;">
                                        <iframe style="position: absolute;width: 78%;height: 90%;top: 5%;"
                                            :src="'https://view.officeapps.live.com/op/view.aspx?src=' + item.properties.fileList[0].url" frameborder="no" border="0" ></iframe>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else style="height: 100%;position: relative;">
                                <div class="left-top-1" :style="item.properties.titleColor ? 'background:' + item.properties.titleColor +';' : 'background: #6266BC;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;top: 0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;top: 0;'"></div>
                                
                                <div id="content" class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width:100%;display:flex;flex-direction: column;justify-content: center;align-items: center;">
                                        <!-- <div :style="item.properties.titleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.titleColor +';' : 'width: 280px;height: 80px;background: #6266BC;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.card }}</span>
                                        </div> -->
                                        <div class="themeText">{{ item.properties.title && item.properties.title != '' ? item.properties.title : "" }}</div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.titleColor ? 'position: absolute;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.titleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.titleColor +';' : 'background: #6266BC;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div>

                        <!-- <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-select' && Show" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height:100%;">
                                <div class="left-top-1" :style="item.properties.selectTitleColor ? 'background:' + item.properties.selectTitleColor +';' : 'background: #F284B2;'"></div>
                                <div class="left-top-2" :style="item.properties.selectTitleColor ? 'position: absolute;top: 0;background:' + item.properties.selectTitleColor +';' : 'background: #F284B2;position: absolute;top: 0;'"></div>
                                <div class="themeContent" style="position: absolute;display:flex;" >
                                    <div style="width:100%;">
                                        <div style="color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 64px;font-style: normal;font-weight: 400;line-height: 112px;letter-spacing: 7.68px;">{{ item.properties.selectTitle }}</div>
                                        <div style="color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));font-family: SimSun;font-size: 32px;font-style: normal;font-weight: 400;line-height: 48px;">{{ item.properties.selectContent }}</div>
                                        <div style="margin-top:10px;display:flex;align-items: center;flex-wrap: wrap;">
                                            <template v-for="(option, index) in item.properties.selectOption">
                                                <el-radio v-bind:key="'letterItemList' + index" v-model="Radio" :label="option"
                                                    style="width: calc(50%);color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 32px;font-style: normal;font-weight: 400;" @input="setSelect">
                                                    {{ letterList[index] }}.
                                                    {{ option }}
                                                </el-radio>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.selectTitleColor ? 'position: absolute;right:0;background:' + item.properties.selectTitleColor +';' : 'background: #F284B2;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.selectTitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.selectTitleColor +';' : 'background: #F284B2;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div> -->

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-thinking' && Show" v-bind:key="index" style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height:100%;position: relative;">
                                <div class="left-top-1" style="background: #6292BC;"></div>
                                <div class="left-top-2" style="background: #6292BC;position: absolute;top: 0;"></div>
                                <div class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div style="width: 280px;height: 80px;background: #6292BC;text-align: center;">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.thinkingCard }}</span>
                                        </div> -->
                                        <div style="width:100%;height:calc(100%);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: calc(100%);max-height: calc(100%);"
                                                :src="item.properties.thinkingContent ? item.properties.thinkingContent : 'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png'" />
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" style="background: #6292BC;position: absolute;right:0;"></div>
                                <div class="left-top-2" style="background: #6292BC;position: absolute;bottom: 0;right:0;"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-AI' && Show" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height: 100%;position: relative;"
                                v-if="item.properties.radio == 'description'">
                                <div class="left-top-1" :style="item.properties.AITitleColor ? 'background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;top: 0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;top: 0;'"></div>
                                <div class="stageContent_1" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div :style="item.properties.AITitleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.AITitleColor +';' : 'width: 280px;height: 80px;background: #80BFB0;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.aiCard }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.topic }}</div>
                                        <div v-if="!isPhone" style="width:100%;height:calc(100% - 48px);display:flex;justify-content: center;">
                                            <span style="overflow-y: auto;" id="content1">{{ item.properties.aiContent }}</span>
                                        </div>
                                        <div v-else style="width:100%;height:calc(100% - 50.5px);display:flex;justify-content: center;">
                                            <span style="overflow-y: auto;" id="content1">{{ item.properties.aiContent }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.AITitleColor ? 'position: absolute;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'image' && item.properties.imgResult != '')"
                                style="height:100%;">
                                <div class="left-top-1" :style="item.properties.AITitleColor ? 'background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;top: 0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;top: 0;'"></div>
                                <div class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div :style="item.properties.AITitleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.AITitleColor +';' : 'width: 280px;height: 80px;background: #80BFB0;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.aiCard }}</span>
                                        </div> -->
                                        <div class="taskName">{{ item.properties.topic }}</div>
                                        <div v-if="!isPhone" style="width:100%;height:calc(100% - 48px);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: 100%;max-height: 100%;"
                                                :src="item.properties.imgResult" />
                                        </div>
                                        <div v-else style="width:100%;height:calc(100% - 50.5px);display:flex;justify-content: center;">
                                            <img style="width: auto;height: auto;max-width: 100%;max-height: 100%;"
                                                :src="item.properties.imgResult" />
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.AITitleColor ? 'position: absolute;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-new-AI' && Show" v-bind:key="index"
                            style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height: 100%;position: relative;"
                                v-if="item.properties.radio == 'description'">
                                <div class="left-top-1" :style="item.properties.AITitleColor ? 'background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;top: 0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;top: 0;'"></div>
                                <div class="stageContent_1" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div :style="item.properties.AITitleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.AITitleColor +';' : 'width: 280px;height: 80px;background: #80BFB0;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.aiCard }}</span>
                                        </div> -->
                                        <div class="taskName" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;" :title="item.properties.topic">{{ item.properties.topic }}</div>
                                        <div v-if="!isPhone" style="width:100%;height:calc(100% - 48px);display:flex;justify-content: center;">
                                            <span style="overflow-y: auto;" id="content1">{{ item.properties.aiContent }}</span>
                                        </div>
                                        <div v-else style="width:100%;height:calc(100% - 50.5px);display:flex;justify-content: center;">
                                            <span style="overflow-y: auto;" id="content1">{{ item.properties.aiContent }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.AITitleColor ? 'position: absolute;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'image' && item.properties.imgResult != '')"
                                style="height:100%;">
                                <div class="left-top-1" :style="item.properties.AITitleColor ? 'background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;top: 0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;top: 0;'"></div>
                                <div class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div :style="item.properties.AITitleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.AITitleColor +';' : 'width: 280px;height: 80px;background: #80BFB0;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.aiCard }}</span>
                                        </div> -->
                                        <div class="taskName" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;" :title="item.properties.topic">{{ item.properties.topic }}</div>
                                        <div v-if="!isPhone" style="width:100%;height:calc(100% - 48px);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: 100%;max-height: 100%;"
                                                :src="item.properties.imgResult" />
                                        </div>
                                        <div v-else style="width:100%;height:calc(100% - 50.5px);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: 100%;max-height: 100%;"
                                                :src="item.properties.imgResult" />
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.AITitleColor ? 'position: absolute;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                            <div v-else-if="(item.properties.radio == 'audio' && item.properties.audioList.length != 0)"
                                style="height:100%;">
                                <div class="left-top-1" :style="item.properties.AITitleColor ? 'background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;top: 0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;top: 0;'"></div>
                                <div class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div :style="item.properties.AITitleColor ? 'width: 280px;height: 80px;text-align: center;background:' + item.properties.AITitleColor +';' : 'width: 280px;height: 80px;background: #80BFB0;text-align: center;'">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.aiCard }}</span>
                                        </div> -->
                                        <div class="taskName" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;" :title="item.properties.topic">{{ item.properties.topic }}</div>
                                        <div v-if="!isPhone" style="width:100%;height:calc(100% - 48px);display:flex;justify-content: center;align-items: center;">
                                            <audio style="
                                                max-width: 100%;
                                                max-height: 100%;
                                                " :src="item.properties.audioList[0].url" controls></audio>
                                        </div>
                                        <div v-else style="width:100%;height:calc(100% - 50.5px);display:flex;justify-content: center;align-items: center;">
                                            <audio style="
                                                max-width: 100%;
                                                max-height: 100%;
                                                " :src="item.properties.audioList[0].url" controls></audio>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" :style="item.properties.AITitleColor ? 'position: absolute;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;right:0;'"></div>
                                <div class="left-top-2" :style="item.properties.AITitleColor ? 'position: absolute;bottom: 0;right:0;background:' + item.properties.AITitleColor +';' : 'background: #80BFB0;position: absolute;bottom: 0;right:0;'"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-whiteboard' && Show" v-bind:key="index" style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height:100%;position: relative;">
                                <div class="left-top-1" style="background: #6292BC;"></div>
                                <div class="left-top-2" style="background: #6292BC;position: absolute;top: 0;"></div>
                                <div class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div style="width: 280px;height: 80px;background: #6292BC;text-align: center;">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.whiteboard_card }}</span>
                                        </div> -->
                                        <div style="width:100%;height:calc(100%);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: calc(100%);max-height: calc(100%);"
                                                :src="item.properties.whiteboardContent" v-if="item.properties.whiteboardContent && item.properties.whiteboardContent != ''"/>
                                            <p class="stageName" v-else>{{ languageData.tip76 }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" style="background: #6292BC;position: absolute;right:0;"></div>
                                <div class="left-top-2" style="background: #6292BC;position: absolute;bottom: 0;right:0;"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-map' && Show" v-bind:key="index" style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height:100%;position: relative;">
                                <div class="left-top-1" style="background: #3B51C6;"></div>
                                <div class="left-top-2" style="background: #3B51C6;position: absolute;top: 0;"></div>
                                <div class="themeContent"  style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div style="width: 280px;height: 80px;background: #3B51C6;text-align: center;">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.map_card }}</span>
                                        </div> -->
                                        <div style="width:100%;height:calc(100%);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: calc(100%);max-height: calc(100%);"
                                                :src="item.properties.map_url" v-if="item.properties.lat && item.properties.lng && item.properties.map_url"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" style="background: #3B51C6;position: absolute;right:0;"></div>
                                <div class="left-top-2" style="background: #3B51C6;position: absolute;bottom: 0;right:0;"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-camera' && Show" v-bind:key="index" style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;">
                            <div style="height:100%;position: relative;">
                                <div class="left-top-1" style="background: #F5A9A9;"></div>
                                <div class="left-top-2" style="background: #F5A9A9;position: absolute;top: 0;"></div>
                                <div class="themeContent" style="position: absolute;display:flex;">
                                    <div style="width: 100%;">
                                        <!-- <div style="width: 280px;height: 80px;background: #F5A9A9;text-align: center;">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.camera_card }}</span>
                                        </div> -->
                                        <div style="width:100%;height:calc(100%);display:flex;justify-content: center;align-items: center;">
                                            <img style="width: auto;height: auto;max-width: calc(100%);max-height: calc(100%);"
                                                :src="item.properties.src" v-if="item.properties.src"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="left-top-3" style="background: #F5A9A9;position: absolute;right:0;"></div>
                                <div class="left-top-2" style="background: #F5A9A9;position: absolute;bottom: 0;right:0;"></div>
                            </div>
                        </div>

                        <div class="right_top animate__animated animate__zoomIn" v-if="item.type == 'icon-ai-npc' && Show" v-bind:key="index" style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;background: none;border: none;box-shadow: none;">
                            <div style="height:100%;position: relative;">
                                <!-- <div class="left-top-1" style="background: #F5A9A9;"></div>
                                <div class="left-top-2" style="background: #F5A9A9;position: absolute;top: 0;"></div> -->
                                <div :style="isShare ? 'position: absolute;display:flex;height: 294px;width: 480px;bottom: 24px; left: 10px;' : 'position: absolute;display:flex;height: 294px;width: 480px;bottom: 24px; left: 99.5px;'">
                                    <div style="width: 100%;">
                                        <!-- <div style="width: 280px;height: 80px;background: #F5A9A9;text-align: center;">
                                            <span style="color: #FFF;text-align: center;font-family: SimSun;font-size: 48px;font-style: normal;font-weight: 600;line-height: 76px;letter-spacing: 5.76px;">{{ languageData.camera_card }}</span>
                                        </div> -->
                                        <img
                                        v-if="hoverClose"
                                            src="../../assets/close_pre.png"
                                            width="16"
                                            height="16"
                                            @mouseout="blurClose"
                                            @click="handleClose"
                                            style="position: absolute;right: 10px;top: 5px;z-index: 1029;cursor:pointer;"
                                        />
                                        <img
                                            v-else
                                            src="../../assets/关闭.png"
                                            width="16"
                                            height="16"
                                            @mouseover="focusClose"
                                            style="position: absolute;right: 10px;top: 5px;z-index: 1029;cursor:pointer;"
                                        />
                                        <div style="width:100%;height:calc(100%);display:flex;flex-direction:column;">
                                            <div class="content" id="content" ref="content" style="width:calc(100% - 32px);padding:16px;border-radius:6px;background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 69, 108, 0.6) 100%);box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08);box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.04);box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05);">
                                                <template v-for="(itemNew, index) of chat_data">
                                                    <div v-if="itemNew.ai" :key="index" style="display:flex;flex-direction:row;line-height: 20px;margin-bottom:8px;">
                                                        <!-- <el-avatar :size="32" :src="item.properties.url"></el-avatar> -->
                                                        <div style="background: rgba(230, 127, 54, 1);border: 1px solid rgba(230, 127, 54, 1);padding:0 8px;border-radius:6px;color: rgba(255, 255, 255, 0.9);font-size: 16px;height: 22px;max-width: calc(20% - 26px);white-space: nowrap;overflow: hidden;text-overflow: ellipsis;cursor: default;" :title="itemNew.aiName">{{ itemNew.aiName }}:</div>
                                                        <div :id="itemNew.uid" class="item_content item_content_other" :style="loading && index == chat_data.length - 1 ? 'margin-left:10px;padding:0;background: none;border: none;color: rgba(255, 255, 255, 0.55);font-size: 16px;max-width: 80%;' : 'margin-left:10px;padding:0;background: none;border: none;color: rgba(255, 215, 187, 1);font-size: 16px;max-width: 80%;'" v-html="itemNew.content"></div>
                                                    </div>
                                                    <div v-else :key="index" style="display:flex;flex-direction:column;line-height: 20px;margin-bottom:8px;">
                                                        <div style="display:flex;flex-direction:row;">
                                                            <div style="background: rgba(23, 196, 105, 1);border: 1px solid rgba(23, 196, 105, 1);padding:0 8px;border-radius:6px;color: rgba(255, 255, 255, 0.9);font-size: 16px;height: 22px;max-width: calc(20% - 26px);white-space: nowrap;overflow: hidden;text-overflow: ellipsis;cursor: default;" :title="itemNew.aiName">{{ itemNew.aiName }}:</div>
                                                            <div class="item_content item_content_other item-myself" style="margin-left:10px;padding:0;background: none;border: none;font-size: 16px;color: rgba(168, 237, 201, 1);max-width: 80%;">{{ itemNew.content }}</div>
                                                            <!-- <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_658_545)">
                                                                <path d="M16.0001 0C7.16822 0 0.00744629 7.16077 0.00744629 15.9926C0.00744629 21.4325 2.72456 26.2374 6.87499 29.1264C7.68386 26.3098 9.72772 24.188 12.509 23.2191C12.6248 23.1787 12.742 23.1404 12.8604 23.1041C12.8791 23.0984 12.8976 23.0921 12.9165 23.0865C13.1951 23.0029 13.4773 22.9313 13.7621 22.8719C13.7912 22.8658 13.8206 22.8603 13.8498 22.8545C14.0025 22.8239 14.1559 22.7966 14.3099 22.7726C14.4183 22.7558 14.5269 22.7407 14.6357 22.7272C14.6749 22.7222 14.7138 22.7169 14.7531 22.7125C14.9004 22.6954 15.048 22.6812 15.1959 22.67H16.8117C16.9603 22.6811 17.1071 22.6957 17.253 22.7122C17.2943 22.717 17.3352 22.7226 17.3762 22.7278C17.5265 22.7465 17.6765 22.7684 17.8259 22.7934C17.9327 22.8114 18.0386 22.8309 18.1436 22.8519C18.1796 22.859 18.216 22.8657 18.2517 22.8732C18.5332 22.9324 18.8087 23.0023 19.0781 23.0829C19.1114 23.0928 19.1442 23.1036 19.1774 23.1138C19.2781 23.1451 19.3782 23.1778 19.4771 23.212C22.2673 24.177 24.3179 26.3007 25.1303 29.1228C29.2778 26.2332 31.9929 21.4301 31.9929 15.9924C31.9926 7.16064 24.832 0 16.0001 0ZM16.0001 22.6663C11.9259 22.6663 8.60974 19.43 8.47686 15.3882C8.4723 15.3854 8.46775 15.383 8.46319 15.3802C8.30715 12.4629 9.83236 10.2219 11.7631 8.91231C11.7743 8.90463 11.7851 8.89669 11.7963 8.88914C11.87 8.83969 11.9447 8.79283 12.0194 8.74611C12.0487 8.72776 12.0773 8.70863 12.1068 8.69079C13.2956 7.96873 14.6036 7.59143 15.7542 7.6133C15.8361 7.61056 15.9177 7.60705 16.0002 7.60705C16.0786 7.60705 16.1558 7.61056 16.2336 7.61291C17.116 7.59768 18.09 7.81711 19.0324 8.24452C19.7343 8.55408 20.3848 8.96905 20.9614 9.47507C22.5309 10.8216 23.6673 12.8453 23.5297 15.3802L23.5235 15.3838C23.3927 19.4275 20.0757 22.6663 16.0001 22.6663Z" fill="#E0EAFB"/>
                                                                <path d="M11.7631 8.91366C11.7741 8.90611 11.7851 8.89791 11.7962 8.89049C11.7851 8.89804 11.7742 8.90611 11.7631 8.91366ZM16.2334 7.61426C16.1556 7.61191 16.0784 7.6084 16 7.6084C15.9175 7.6084 15.8359 7.61191 15.7541 7.61465C15.8329 7.61621 15.9117 7.6179 15.989 7.62311C16.0704 7.61812 16.1519 7.61516 16.2334 7.61426ZM14.7588 12.0865C13.3117 14.6252 10.86 16.8742 8.47681 15.3896C8.60969 19.4313 11.9259 22.6676 16 22.6676C20.0756 22.6676 23.3927 19.4289 23.5234 15.3852C21.532 16.6246 16.3104 14.8266 14.7588 12.0865ZM12.0193 8.74746C12.0486 8.72924 12.0774 8.70998 12.1068 8.69215C12.0771 8.71011 12.0486 8.72924 12.0193 8.74746ZM19.0322 8.24587C19.73 8.56312 20.3794 8.9774 20.9612 9.47642C20.3846 8.97044 19.7341 8.55548 19.0322 8.24587Z" fill="#FFF3EA"/>
                                                                <path d="M13.762 22.8726C13.7911 22.8665 13.8205 22.861 13.8497 22.8551C13.8204 22.861 13.791 22.8665 13.762 22.8726ZM19.477 23.2128C19.4999 23.2207 19.5226 23.2288 19.5452 23.2369C19.4602 23.4778 19.3507 23.7093 19.2184 23.9278C19.7541 24.7527 19.6197 28.3494 18.8147 27.784L17.4055 26.7916L15.9999 25.8029L16.0923 25.7379C16.0615 25.7387 16.0308 25.7403 15.9999 25.7403C15.9676 25.7403 15.9357 25.7386 15.9037 25.7378L15.9962 25.8029L14.5906 26.7916L13.1814 27.784C12.372 28.3504 12.2379 24.7401 12.7789 23.9235C12.6481 23.7068 12.5396 23.4774 12.4553 23.2387C12.4731 23.2323 12.4908 23.2259 12.5088 23.2198C9.72748 24.1885 7.68363 26.3105 6.87476 29.1271C9.46289 30.9288 12.6078 31.986 15.9999 31.986C19.3941 31.986 22.541 30.9273 25.1299 29.1237C24.3179 26.3016 22.2673 24.1778 19.477 23.2128ZM18.1437 22.8527C18.1796 22.8598 18.216 22.8665 18.2517 22.874C18.216 22.8665 18.1797 22.8597 18.1437 22.8527ZM14.6356 22.728C14.6748 22.7232 14.7137 22.7177 14.753 22.7133C14.7137 22.7177 14.6748 22.723 14.6356 22.728ZM17.2529 22.7129C17.2942 22.7176 17.3351 22.7233 17.376 22.7285C17.3351 22.7234 17.2943 22.7178 17.2529 22.7129Z" fill="#FFC399"/>
                                                                <path d="M12.779 23.9225C12.8865 23.7603 13.0205 23.7078 13.1813 23.8209L14.5905 24.8131L15.9034 25.7367C15.9356 25.7375 15.9673 25.7392 15.9996 25.7392C16.0306 25.7392 16.0613 25.7376 16.092 25.7369L17.4051 24.8131L18.8142 23.8209C18.9758 23.7067 19.1103 23.7611 19.2179 23.9268C19.3502 23.7083 19.4597 23.4768 19.5447 23.2359L19.4765 23.2118C19.3771 23.1775 19.2772 23.1448 19.1768 23.1137C19.1436 23.1034 19.1108 23.0926 19.0775 23.0827C18.805 23.0014 18.5293 22.9314 18.2511 22.8731C18.2154 22.8655 18.179 22.8589 18.1431 22.8517C17.8889 22.8008 17.6329 22.7594 17.3757 22.7277C17.3347 22.7225 17.2938 22.7168 17.2526 22.7121C17.1057 22.6952 16.9586 22.6811 16.8113 22.6699H15.1956C15.0478 22.6811 14.9002 22.6952 14.7529 22.7123C14.7136 22.7168 14.6747 22.7222 14.6355 22.7271C14.372 22.7596 14.1099 22.802 13.8496 22.8543C13.8203 22.8602 13.7909 22.8656 13.7619 22.8718C13.4771 22.9313 13.1951 23.0029 12.9164 23.0864C12.8975 23.092 12.879 23.0982 12.8603 23.1039C12.7419 23.1401 12.6248 23.1785 12.5089 23.219C12.4911 23.2251 12.4733 23.2315 12.4554 23.2378C12.5398 23.4764 12.6482 23.7058 12.779 23.9225Z" fill="#FEFEFE"/>
                                                                <path d="M18.8142 23.8211L17.4051 24.8134L16.092 25.7372L15.9996 25.8021L17.4051 26.7907L18.8142 27.783C19.6191 28.3483 19.7536 24.752 19.2179 23.9272C19.1104 23.7614 18.976 23.707 18.8142 23.8211ZM13.1814 23.8211C13.0205 23.7081 12.8865 23.7606 12.779 23.9228C12.238 24.7393 12.3721 28.3493 13.1814 27.7829L14.5905 26.7906L15.996 25.802L15.9035 25.7369L14.5905 24.8133L13.1814 23.8211Z" fill="#3681FC"/>
                                                                <path d="M14.7587 12.0849C16.3102 14.8247 21.5313 16.6226 23.5225 15.3833L23.5287 15.3796C23.6663 12.8449 22.5299 10.8214 20.9606 9.47505C20.3788 8.97615 19.7295 8.56191 19.0318 8.2446C18.0895 7.81723 17.1156 7.59782 16.2332 7.61305C16.1511 7.61448 16.0693 7.61643 15.9889 7.6219C16.2494 8.54431 15.7305 10.3799 14.7587 12.0849Z" fill="black"/>
                                                                <path d="M14.7581 12.0847C15.73 10.3795 16.249 8.54407 15.9883 7.62166C15.9101 7.61687 15.8318 7.61405 15.7534 7.6132C14.6029 7.59134 13.295 7.96861 12.1063 8.69061C12.0769 8.70844 12.0482 8.7277 12.0189 8.74592C11.9442 8.79264 11.8695 8.83948 11.7958 8.88894C11.7846 8.89648 11.7738 8.90455 11.7626 8.9121C9.83206 10.2215 8.30699 12.4624 8.46302 15.3794C8.46757 15.3822 8.47213 15.3846 8.47668 15.3875C10.8596 16.8719 13.3111 14.6232 14.7581 12.0847Z" fill="#1E1D1D"/>
                                                                </g>
                                                                <rect x="0.25" y="0.25" width="31.5" height="31.5" rx="15.75" stroke="#3681FC" stroke-width="0.5"/>
                                                                <defs>
                                                                <clipPath id="clip0_658_545">
                                                                <rect width="32" height="32" rx="16" fill="white"/>
                                                                </clipPath>
                                                                </defs>
                                                            </svg> -->
                                                        </div>
                                                        <!-- <div
                                                            v-if="loading && index == chat_data.length - 1"
                                                            v-loading="loading"
                                                            :element-loading-text="languageData.tip84"
                                                            element-loading-spinner="el-icon-loading"
                                                            class="loading_div"
                                                            style="display:flex;flex-direction:row;background: none;margin-top:8px;"
                                                        ></div> -->
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="input flex_x" style="position: relative;margin-top:4px;" id="input">
                                                <el-input
                                                    type="textarea"
                                                    :autosize="{ minRows: 1, maxRows: 10 }"
                                                    :placeholder="languageData.searchImagesDesc"
                                                    ref="chat_input_new"
                                                    :clearable="true"
                                                    :autofocus="false"
                                                    :disabled="disabled"
                                                    @input="handle_chat_content_change"
                                                    v-model="chat_content"
                                                    @keyup.enter.native="send_message"
                                                    @focus="handle_focus"
                                                    @blur="handle_blur"
                                                    >
                                                </el-input>
                                                <!-- <img
                                                slot="suffix"
                                                src="../../assets/chat/send.png"
                                                class="send_icon"
                                                @click="send_message"
                                                /> -->
                                                <el-button :disabled="disabled" class="send_icon" @click="send_message">{{  languageData.sendButton }}</el-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="left-top-3" style="background: #F5A9A9;position: absolute;right:0;"></div>
                                <div class="left-top-2" style="background: #F5A9A9;position: absolute;bottom: 0;right:0;"></div> -->
                            </div>
                        </div>
                    </template>
                </div>
                <!-- <div id="handle" ref="handle" draggable="false" @mousedown="handleMousedown" v-if="!fullscreen" /> -->
            </span>
        </el-dialog>
    </div>
    <!-- <div class="showResult" id="showResult" v-else-if="list.length != 0 && list[0].type == 'icon-ai-npc' && dialogVisible">
        <div class="right_top animate__animated animate__zoomIn" v-if="list[0].type == 'icon-ai-npc' && Show" style="margin-left: 0;margin-right: 0;height:100%;position: relative;margin-bottom: 0;padding:0;border-radius: 0;background: none;border: none;box-shadow: none;">
            <div style="height:100%;position: relative;">
                <div style="position: absolute;display:flex;height: 294px;width: 480px;bottom: 24px; left: 99.5px;">
                    <div style="width: 100%;">
                        <div style="width:100%;height:calc(100%);display:flex;flex-direction:column;">
                            <div class="content" ref="content" style="width:calc(100% - 32px);padding:16px;border-radius:6px;background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 69, 108, 0.6) 100%);box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08);box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.04);box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05);">
                                <template v-for="(itemNew, index) of chat_data">
                                    <div v-if="itemNew.ai" :key="index" style="display:flex;flex-direction:row;line-height: 20px;margin-bottom:8px;">
                                        <div style="background: rgba(230, 127, 54, 1);border: 1px solid rgba(230, 127, 54, 1);padding:0 8px;border-radius:6px;color: rgba(255, 255, 255, 0.9);font-size: 16px;height: 22px;">{{ itemNew.aiName }}:</div>
                                        <div class="item_content item_content_other" :style="loading && index == chat_data.length - 1 ? 'margin-left:10px;padding:0;background: none;border: none;color: rgba(255, 255, 255, 0.55);font-size: 16px;max-width: 85%;' : 'margin-left:10px;padding:0;background: none;border: none;color: rgba(255, 215, 187, 1);font-size: 16px;max-width: 85%;'">{{ itemNew.content }}</div>
                                    </div>
                                    <div v-else :key="index" style="display:flex;flex-direction:column;line-height: 20px;margin-bottom:8px;">
                                        <div style="display:flex;flex-direction:row;">
                                            <div style="background: rgba(23, 196, 105, 1);border: 1px solid rgba(23, 196, 105, 1);padding:0 8px;border-radius:6px;color: rgba(255, 255, 255, 0.9);font-size: 16px;height: 22px;">{{ itemNew.aiName }}:</div>
                                            <div class="item_content item_content_other item-myself" style="margin-left:10px;padding:0;background: none;border: none;font-size: 16px;color: rgba(168, 237, 201, 1);max-width: 85%;">{{ itemNew.content }}</div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="input flex_x" style="position: relative;margin-top:4px;" id="input">
                                <el-input
                                    type="textarea"
                                    :autosize="{ minRows: 1, maxRows: 10 }"
                                    :placeholder="languageData.searchImagesDesc"
                                    ref="chat_input_new"
                                    :clearable="true"
                                    :autofocus="false"
                                    :disabled="disabled"
                                    @input="handle_chat_content_change"
                                    v-model="chat_content"
                                    @keyup.enter.native="send_message"
                                    @focus="handle_focus"
                                    @blur="handle_blur"
                                    >
                                </el-input>
                                <el-button :disabled="disabled" class="send_icon" @click="send_message">{{  languageData.sendButton }}</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
import $ from 'jquery'
import 'animate.css'
import { v4 as uuidv4 } from "uuid";
import { formatDateTime } from "../../tools/utils.js";
import MarkdownIt from "markdown-it";
import { showUserMessage, showAIMessage, showTipMessage, destoryTipMessage, setIsTip, showUserTipMessage, destoryUserTipMessage } from '../../tools/spriteUtilities'
export default {
    props: {
        list: Array,
        dialogVisible: {
            type: Boolean,
            default: false
        },
        Show: Boolean,
        isShare: {
            type: Boolean,
            default: true
        },
        userId: {
            type: String,
            default: ""
        }
    },
    watch: {
        list() {
            // let that = this;
            console.log(this.list)
            if (this.list.length != 0) {
                $(".el-dialog").css("width", "");
                // let fontsize = '';
                this.isDrag = true
                localStorage.setItem("isDrag", this.isDrag)
                for (let i = 0; i < this.list.length; i++) {
                    // if (this.list[i].properties.radio == 'content') {
                    //     let len = this.list[i].properties.contentLength;
                    //     // console.log(len)
                    //     if (0 < len && len == 1) {
                    //         fontsize = "160px";
                    //     }
                    //     else if (1 < len && len <= 3) {
                    //         fontsize = "80px";
                    //     }
                    //     else if (3 < len && len <= 5) {
                    //         fontsize = "40px";
                    //     }
                    //     else if (5 < len && len <= 7) {
                    //         fontsize = "20px";
                    //     }
                    //     else if (7 < len && len <= 9) {
                    //         fontsize = "12px";
                    //     }
                    // }
                    if (this.list[i].properties.radio == 'videoList') {
                        $(".el-dialog").css({
                            "height": "425px"
                        })
                        this.isDrag = false
                    }
                    else if (this.list[i].properties.radio == 'imageList') {
                        this.isDrag = false
                    }
                    else if(this.list[i].properties.prologue) {
                        this.chat_data = []
                        this.chat_data.push({
                            userName: "",
                            userId: "",
                            content: this.list[i].properties.prologue,
                            time: formatDateTime(new Date().getTime()),
                            ai: true,
                            is_mind_map: false,
                            index: this.chat_data.length,
                            aiName:this.list[i].properties.name
                        })
                        this.$nextTick(() => {
                            $("#showResult .el-dialog").css("width","100%")
                            $("#showResult .el-dialog__body").css("height","100%")
                        })
                    }
                    localStorage.setItem("isDrag", this.isDrag)
                    document.addEventListener('keydown', this.handleKeyDown);
                }
                this.$nextTick(() => {
                    // $("#content p").css("font-size", fontsize)
                    // if (fontsize == "12px") {
                    //     $("#content p").css("-webkit-transform", "scale(0.83)")
                    // }
                    // if (document.getElementById("aaa_" + that.list[0].id)) {
                    //     document.getElementById("aaa_" + that.list[0].id).addEventListener("mouseover", function () {
                    //         $("#result_" + that.list[0].id).css({
                    //             "display": "block",
                    //             "color": "#FE7777"
                    //         })
                    //     });
                    //     document.getElementById("aaa_" + that.list[0].id).addEventListener("mouseout", function () {
                    //         $("#result_" + that.list[0].id).css({
                    //             "display": "none"
                    //         })
                    //     });
                    // }
                    $(".el-dialog").on('mouseup', () => {
                        this.down = false
                        document.onmousemove = null
                        document.onmousedown = null
                        document.onmouseup = null
                    })
                    $(".el-dialog").on('mousemove', (e) => {
                        // 获取鼠标的实时位置
                        // const curX = e.clientX
                        const curY = e.clientY
                        // 获取拖拽的长度
                        // const increaseX = curX - this.disX
                        const increaseY = curY - this.disY
                        // 按下开始拖拽改变目标元素大小
                        if (this.down) {
                            // const width = this.$refs.handle.offsetWidth + increaseX
                            const height = this.$refs.handle.offsetHeight + increaseY
                            $(e.currentTarget).css({
                                // "width": width < parseInt(parseInt($(".diagram-main").width()) * 0.52) ? '52%' : width + 'px',
                                "height": height < 425 ? '425px' : (425 <= height && height / parseInt($(".diagram-main").height()) * 100 <= 80 ? height + 'px' : '80%')
                            })
                        }
                    })
                    $(".el-dialog__header").on('mouseover', () => {
                        this.showClose = true;
                    })
                    $(".el-dialog__header").on('mouseleave', () => {
                        this.showClose = false;
                    })
                })
            }
        },
        screenWidth(newVal) {
            if(newVal>960){
                this.isPhone=false;
            }else{
                // this.crossFun(document.getElementById("DemoResult"));
                $("#leftSide").css("display", "none");
                $("#scroll-box").css("width","calc(100%)");
                $("#scroll-box").css("border-bottom-left-radius", "12px");
                this.isPhone=true;
            }
        }
    },
    data() {
        return {
            chat_data: [],
            chat_content: "",
            hoverMax: false,
            hoverRecovery: false,
            fullscreen: false,
            hoverClose: false,
            // disX: 0,
            disY: 0,
            down: false,
            letterList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
                'U', 'V', 'W', 'X', 'Y', 'Z'],
            Radio: '',
            showClose: false,
            isDrag: true,
            isPhone: false,
            screenWidth: null,
            loading: false,
            time: null,
            disabled: false
        };
    },
    mounted() {
        if (window.location.href.indexOf("cocorobo.cn") != -1) {
            this.languageData = this.zhHansLanguage;
        } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
            this.languageData = this.zhHantLanguage;
        } else if (window.location.href.indexOf("cocorobo.com") != -1) {
            this.languageData = this.enLanguage;
        } else {
            this.languageData = this.zhHansLanguage;
        }
        this.screenWidth = window.screen.width
        window.onresize = () => {
            return (() => {
                this.screenWidth = window.screen.width
            })()
        }
        this.$nextTick(() => {
            $(".el-dialog").on('mouseup', () => {
                this.down = false
                document.onmousemove = null
                document.onmousedown = null
                document.onmouseup = null
            })
            $(".el-dialog").on('mousemove', (e) => {
                // console.log($(".diagram-main").width())
                e.preventDefault();
                // 获取鼠标的实时位置
                // const curX = e.clientX
                const curY = e.clientY
                // 获取拖拽的长度
                // const increaseX = curX - this.disX
                const increaseY = curY - this.disY
                // 按下开始拖拽改变目标元素大小
                if (this.down) {
                    // const width = this.$refs.handle.offsetWidth + increaseX
                    const height = this.$refs.handle.offsetHeight + increaseY
                    $(e.currentTarget).css({
                        // "width": width < parseInt(parseInt($(".diagram-main").width()) * 0.52) ? '52%' : width + 'px',
                        "height": height < 425 ? '425px' : (425 <= height && height / parseInt($(".diagram-main").height()) * 100 <= 80 ? height + 'px' : '80%')
                    })
                }
            })
        })
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeyDown);
    },
    methods: {
        focusClose() {
            this.hoverClose = true;
        },
        blurClose() {
            this.hoverClose = false;
        },
        handleKeyDown(event) {
            if (event.key === 'Escape') { // 检查按下的是否是Esc键
                // 处理Esc键按下的逻辑
                this.handleClose()
            }
        },
        handle_chat_content_change(value) {
            if(this.$refs.chat_input_new.$el) {
                const scrollElement = this.$refs.chat_input_new.$el.querySelector(
                    ".el-textarea__inner"
                );
                scrollElement.scrollTop = scrollElement.scrollHeight;
            }
            console.log(value)
        },
        handle_focus() {
            let that = this;
            if(that.chat_data.at(-1).ai == false) {
                that.chat_data.at(-1).content = that.languageData.tip115
                that.$nextTick(() => {
                    document.getElementById("content").scrollTop = document.getElementById("content").scrollHeight;
                });
                if (!that.isPhone && that.isShare) {
                    showUserTipMessage(that.chat_data.at(-1).content)
                }
                let temp = 0
                that.time = setInterval(function() {
                    that.$nextTick(() => {
                        document.getElementById("content").scrollTop = document.getElementById("content").scrollHeight;
                    });
                    if(temp == 3) {
                        that.chat_data.at(-1).content = that.languageData.tip115
                        if (!that.isPhone && that.isShare) {
                            destoryUserTipMessage()
                            showUserTipMessage(that.chat_data.at(-1).content)
                        }
                        temp = 0
                        that.chat_data.at(-1).content = that.chat_data.at(-1).content + "."
                        if (!that.isPhone && that.isShare) {
                            destoryUserTipMessage()
                            showUserTipMessage(that.chat_data.at(-1).content)
                        }
                        temp = temp + 1
                    }
                    else {
                        that.chat_data.at(-1).content = that.chat_data.at(-1).content + "."
                        if (!that.isPhone && that.isShare) {
                            destoryUserTipMessage()
                            showUserTipMessage(that.chat_data.at(-1).content)
                        }
                        temp = temp + 1
                    }
                    
                },1000)
            }
            else {
                that.chat_data.push({
                    userName: window.location.href.indexOf("localhost") != -1 || window.location.href.indexOf("192.168") != -1 ? '潘炜桐' : "用户",
                    userId: window.location.href.indexOf("localhost") != -1 || window.location.href.indexOf("192.168") != -1 ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01" : "",
                    content: that.languageData.tip115,
                    ai: false,
                    is_mind_map: false,
                    index: that.chat_data.length,
                    aiName: that.languageData.tip117
                });
                that.$nextTick(() => {
                    document.getElementById("content").scrollTop = document.getElementById("content").scrollHeight;
                });
                if (!that.isPhone && that.isShare) {
                    showUserTipMessage(that.chat_data.at(-1).content)
                }
                let temp = 0
                that.time = setInterval(function() {
                    that.$nextTick(() => {
                        document.getElementById("content").scrollTop = document.getElementById("content").scrollHeight;
                    });
                    if(temp == 3) {
                        that.chat_data.at(-1).content = that.languageData.tip115
                        if (!that.isPhone && that.isShare) {
                            destoryUserTipMessage()
                            showUserTipMessage(that.chat_data.at(-1).content)
                        }
                        temp = 0
                        that.chat_data.at(-1).content = that.chat_data.at(-1).content + "."
                        if (!that.isPhone && that.isShare) {
                            destoryUserTipMessage()
                            showUserTipMessage(that.chat_data.at(-1).content)
                        }
                        temp = temp + 1
                    }
                    else {
                        that.chat_data.at(-1).content = that.chat_data.at(-1).content + "."
                        if (!that.isPhone && that.isShare) {
                            destoryUserTipMessage()
                            showUserTipMessage(that.chat_data.at(-1).content)
                        }
                        temp = temp + 1
                    }
                    
                },1000)
            }
        },
        handle_blur() {
            let that = this;
            clearInterval(that.time)
            if (!that.isPhone && that.isShare) {
                destoryUserTipMessage()
            }
        },
        send_message() {
            let that = this;
            // that.$refs.chat_input_new.$refs.textarea.blur()
            if (that.chat_content == "") {
                that.$message({
                    message: that.languageData.searchImagesDesc,
                    type: "warning",
                });
                return;
            }
            else {
                clearInterval(that.time)
                if (!that.isPhone && that.isShare) {
                    destoryUserTipMessage()
                }
                document.getElementById("input").getElementsByTagName("textarea")[0].blur()
                that.disabled = true
                that.chat_data.at(-1).content = that.chat_content
                that.$nextTick(() => {
                    document.getElementById("content").scrollTop = document.getElementById("content").scrollHeight;
                });
                if (!that.isPhone && that.isShare) {
                    showUserMessage(that.chat_content)
                }
                // if (that.isPhone) {
                //     var config = {
                //         method: "POST",
                //         url: "https://gpt4.cocorobo.cn/ai_agent_chat",
                //         headers: {
                //             "Content-Type": "application/json",
                //         },
                //         data: {
                //             id: that.list[0].properties.id,
                //             userId: "",
                //             message: that.chat_content
                //         },
                //     };
                //     that.chat_content = ""
                //     that.loading = true
                //     that.chat_data.push({
                //         userName: "",
                //         userId: "",
                //         content: that.languageData.tip84,
                //         time: formatDateTime(new Date().getTime()),
                //         ai: true,
                //         is_mind_map: false,
                //         index: this.chat_data.length,
                //         aiName:that.list[0].properties.name
                //     });
                //     let temp = 0
                //     let time = setInterval(function() {
                //         if(temp == 3) {
                //             that.chat_data.at(-1).content = that.languageData.tip84
                //             destoryTipMessage()
                //             showTipMessage(that.list[0],that.chat_data.at(-1).content)
                //             temp = 0
                //             that.chat_data.at(-1).content = that.chat_data.at(-1).content + "."
                //             destoryTipMessage()
                //             showTipMessage(that.list[0],that.chat_data.at(-1).content)
                //             temp = temp + 1
                //         }
                //         else {
                //             that.chat_data.at(-1).content = that.chat_data.at(-1).content + "."
                //             destoryTipMessage()
                //             showTipMessage(that.list[0],that.chat_data.at(-1).content)
                //             temp = temp + 1
                //         }
                        
                //     },1000)
                //     that.$ajax(config)
                //         .then((res) => {
                //             console.log(res)
                //             that.loading = false
                //             clearInterval(time)
                //             destoryTipMessage()
                //             that.chat_data.at(-1).content = res.data.FunctionResponse.message
                //             // that.chat_data.push({
                //             //     userName: "",
                //             //     userId: "",
                //             //     content: res.data.FunctionResponse.message,
                //             //     time: formatDateTime(new Date().getTime()),
                //             //     ai: true,
                //             //     is_mind_map: false,
                //             //     index: this.chat_data.length,
                //             //     aiName:this.list[0].properties.name
                //             // });
                //             showAIMessage(this.list[0],res.data.FunctionResponse.message)
                //             that.disabled = false
                //         })
                //         .catch((err) => {
                //             console.log(err);
                //         });
                // }
                // else {
                    let uid = uuidv4();
                    var config = {
                        method: "POST",
                        url: "https://gpt4.cocorobo.cn/ai_agent_chat_new",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: {
                            id: that.list[0].properties.id,
                            userId: that.userId,
                            message: that.chat_content,
                            uid:uid
                        },
                    };
                    that.chat_content = ""
                    that.loading = true
                    that.chat_data.push({
                        userName: "",
                        userId: "",
                        content: that.languageData.tip84,
                        time: formatDateTime(new Date().getTime()),
                        ai: true,
                        is_mind_map: false,
                        index: this.chat_data.length,
                        aiName:that.list[0].properties.name,
                        uid: uid
                    });
                    that.$nextTick(() => {
                        document.getElementById("content").scrollTop = document.getElementById("content").scrollHeight;
                    });
                    that.$ajax(config)
                        .then((res) => {
                            console.log(res)
                            // that.loading = false
                            // clearInterval(time)
                            // destoryTipMessage()
                            // that.chat_data.at(-1).content = res.data.FunctionResponse.message
                            // // that.chat_data.push({
                            // //     userName: "",
                            // //     userId: "",
                            // //     content: res.data.FunctionResponse.message,
                            // //     time: formatDateTime(new Date().getTime()),
                            // //     ai: true,
                            // //     is_mind_map: false,
                            // //     index: this.chat_data.length,
                            // //     aiName:this.list[0].properties.name
                            // // });
                            // showAIMessage(this.list[0],res.data.FunctionResponse.message)
                            // that.disabled = false
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                        that.askAssistant(uid);
                // }
            }
        },
        askAssistant(uid) {
            let that = this;
            if (this.ai_source) {
                this.ai_source.close();
            }
            this.ai_source = new EventSource("https://gpt4.cocorobo.cn/question/" + uid);
            let alltext = "";
            let newalltext = "";
            const md = new MarkdownIt();
            let temp = 0
            let time = setInterval(function() {
                that.$nextTick(() => {
                    document.getElementById("content").scrollTop = document.getElementById("content").scrollHeight;
                });
                if(temp == 3) {
                    that.chat_data.at(-1).content = that.languageData.tip84
                    if(!that.isPhone && that.isShare) {
                        destoryTipMessage()
                        showTipMessage(that.list[0],that.chat_data.at(-1).content)
                    }
                    temp = 0
                    that.chat_data.at(-1).content = that.chat_data.at(-1).content + "."
                    if(!that.isPhone && that.isShare) {
                        destoryTipMessage()
                        showTipMessage(that.list[0],that.chat_data.at(-1).content)
                    }
                    temp = temp + 1
                }
                else {
                    that.chat_data.at(-1).content = that.chat_data.at(-1).content + "."
                    if(!that.isPhone && that.isShare) {
                        destoryTipMessage()
                        showTipMessage(that.list[0],that.chat_data.at(-1).content)
                    }
                    temp = temp + 1
                }
                
            },1000)
            //上传新文档的时候问题先隐藏掉
            this.ai_source.onmessage = function (e) {
                that.loading = false
                clearInterval(time)
                destoryTipMessage()
                let eData = JSON.parse(e.data);
                if (eData.content.replace("'", "").replace("'", "") == "[DONE]") {
                    newalltext = newalltext.replace("_", "");
                    newalltext = newalltext.replace("_", "");
                    that.chat_data.at(-1).content = newalltext;
                    that.disabled = false
                    if (that.isShare) {
                        showAIMessage(that.list[0],$("#"+ uid).text().replace("_",""))
                    }
                    //重置数据
                    that.ai_source.close();
                    alltext = "";
                    newalltext = "";
                    return;
                } else {
                    let a = eData.content.replace("'", "").replace("'", "");
                    // console.log(e.data)
                    that.prevChatResult = that.prevChatResult + a;
                    if (alltext == "") {
                        alltext = a.replace(/^\n+/, ""); //去掉回复消息中偶尔开头就存在的连续换行符
                    } else {
                        alltext += a;
                    }
                    newalltext = alltext + "_";
                    newalltext = newalltext.replace(/\\n/g, "\n");
                    newalltext = newalltext.replace(/\\/g, "");
                    if (alltext.split("```").length % 2 == 0) newalltext += "\n```\n";
                    newalltext = md.render(newalltext);
                    that.chat_data.at(-1).content = newalltext;
                    if(!that.isPhone && that.isShare) {
                        showAIMessage(that.list[0],$("#"+ uid).text().replace("_",""))
                    }
                    that.$nextTick(() => {
                        document.getElementById("content").scrollTop = document.getElementById("content").scrollHeight;
                    });
                }
            };
        },
        handleClose() {
            let that = this;
            this.clickRecovery();
            this.Radio = '';
            this.chat_content = ""
            this.$emit("info", false);
            if (!that.isPhone) {
                clearInterval(this.time)
                destoryUserTipMessage()
            }
            setIsTip()
        },
        // down置为true，记录鼠标点下的位置
        handleMousedown(e) {
            this.down = true
            // this.disX = e.clientX - this.$refs.handle.offsetLeft
            this.disY = e.clientY - this.$refs.handle.offsetTop
        },
        focusMax() {
            this.hoverMax = true;
            this.hoverRecovery = false;
        },
        blurMax() {
            this.hoverMax = false;
            this.hoverRecovery = false;
        },
        focusRecovery() {
            this.hoverMax = false;
            this.hoverRecovery = true;
        },
        blurRecovery() {
            this.hoverMax = false;
            this.hoverRecovery = false;
        },
        clickMax() {
            this.fullscreen = true;
            // $(".el-dialog").css({
            //     "height": "100%"
            // })
            $("#showResult .el-dialog__body").css({
                "height": "87.5%"
            })
        },
        clickRecovery() {
            this.fullscreen = false;
            // $(".el-dialog").css({
            //     "height": "435px",
            //     "width": "52%"
            // })
            $("#showResult .el-dialog__body").css({
                "height": "100%"
            })
        },
        setSelect(e) {
            this.Radio = e
        }
    }
};
</script>

<style scoped>
.send_icon {
  width: 52px;
  height: 30px;
  position: absolute;
  bottom: 12px;
  right: 12px;
  cursor: pointer;
  padding:4px 12px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.22);
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.22);
}

.send_icon[disabled] {
    cursor: not-allowed;
    opacity: .65;
}

.send_icon[disabled]:hover {
    background: rgba(255, 255, 255, 0.22);
    border: 1px solid rgba(255, 255, 255, 0.22);
    color: rgba(255, 255, 255, 0.9);
}

.send_icon:not([disabled]):hover {
    border: 1px solid rgba(54, 129, 252, 1);
    background: rgba(54, 129, 252, 1);
}

.content {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  padding: 10px 0px;
  line-height: 32px;
  position: relative;
}

.item_content {
  max-width: 80%;
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  line-height: 20px;
  background: #f3f7f3;
  word-wrap: break-word;
}
.item_content_other {
  background: #f3f7f3;
  padding: 12px;
  border-radius: 2px 8px 8px 8px;
}

.item_content_other >>> p{
  margin-top: 0;
  margin-bottom: 0;
}

.embedCode >>> iframe {
    width: 100%;
    height: 90%;
}

#showResult #content1 {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
    font-family: SimSun;
    font-style: normal;
    font-weight: 400;
    line-height: 22px !important;
    font-size: 20px !important;
}

#showResult #content1 p {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
    font-family: SimSun;
    font-style: normal;
    font-weight: 400;
    line-height: 22px !important;
    font-size: 20px !important;
    margin-top: 0;
    margin-bottom: 0;
}

#showResult #content1 p span {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
    font-family: SimSun;
    font-style: normal;
    font-weight: 400;
    line-height: 22px !important;
    font-size: 20px !important;
}

#showResult #content2 {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
    font-family: SimSun;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 22px !important;
}

#showResult #content2 p {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
    font-family: SimSun;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 22px !important;
    margin-top: 0;
    margin-bottom: 0;
}

#showResult #content2 p span {
    color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
    font-family: SimSun;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 22px !important;
}

#showResult .text {
    color: #FFF;text-align: center;font-family: SimSun;font-size: 18px;font-style: normal;font-weight: 600;line-height: 20px;letter-spacing: 2.76px;
}

.left-top-1{
    width: 30px;
    height: 200px;
}

.left-top-2{
    width: 200px;
    height: 30px;
}

.left-top-3 {
    width: 30px;
    height: 170px;
    bottom: 30px;
}

.themeContent {
    top: 140px;
    left: 240px;
    height:calc(100% - 280px);
    width:calc(100% - 480px);
}

.themeText {
    color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
    text-align: center;
    font-family: SimSun;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 6.36px;
}

.themeDiv {
    width: 100px;
    height: 40px;
    margin-bottom:24px;
}

.stageContent_1 {
    top: 150px;
    left: 100px;
    height:calc(100% - 300px);
    width:calc(100% - 200px);
}

.stageContent_2 {
    top: 150px;
    left: 100px;
    height:calc(100% - 300px);
    width:calc(100% - 200px);
}

.stageName {
    margin-bottom:32px;
    color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
    text-align: center;
    font-family: SimSun;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 5.6px;
}

.taskName {
    margin-bottom:16px;color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 22px;font-style: normal;font-weight: 400;line-height: 32px;letter-spacing: 3.68px;
}

.taskText {
    color: #FFF;text-align: center;font-family: SimSun;font-size: 18px;font-style: normal;font-weight: 600;line-height: 20px;letter-spacing: 2.76px;
}

.cardContent {
    top: 140px;
    left: 128px;
    height:calc(100% - 280px);
    width:calc(100% - 256px);
}

@media screen and (max-width: 960px){
    .left-top-1{
        width: 25px;
        height: 175px;
    }

    .left-top-2{
        width: 175px;
        height: 25px;
    }

    .left-top-3 {
        width: 25px;
        height: 175px;
        bottom: 25px;
    }


    .themeText {
        color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
        text-align: center;
        font-family: SimSun;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px; /* 128.571% */
        letter-spacing: 3.36px;
    }

    .themeContent {
        top: 71px;
        left: 50px;
        height: calc(100% - 142px);
        width: calc(100% - 100px);
    }

    .themeDiv {
        width: 110px;
        height: 33px;
        margin-bottom:15px;
    }

    .stageContent_1 {
        top: 71px;
        left: 50px;
        height: calc(100% - 142px);
        width: calc(100% - 100px);
    }

    .stageContent_2 {
        top: 71px;
        left: 50px;
        height: calc(100% - 142px);
        width: calc(100% - 100px);
    }

    #showResult .text {
        color: #FFF;text-align: center;font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 700;line-height: 20px;letter-spacing: 2.88px;
    }

    .stageName {
        margin-bottom:12.5px;
        color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
        text-align: center;
        font-family: SimSun;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 3.36px;
    }

    #showResult #content1 {
        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
        font-family: SimSun;
        font-style: normal;
        font-weight: 400;
        line-height: 20px !important;
        font-size: 14px !important;
    }

    #showResult #content1 p {
        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
        font-family: SimSun;
        font-style: normal;
        font-weight: 400;
        line-height: 20px !important;
        font-size: 14px !important;
        margin-top: 0;
        margin-bottom: 0;
    }

    #showResult #content1 p span {
        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
        font-family: SimSun;
        font-style: normal;
        font-weight: 400;
        line-height: 20px !important;
        font-size: 14px !important;
    }

    .taskName {
        margin-bottom:12.5px;
        color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
        font-family: SimSun;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 3.36px;
    }

    .taskText {
        color: #FFF;text-align: center;font-family: SimSun;font-size: 24px;font-style: normal;font-weight: 700;line-height: 32px;letter-spacing: 2.88px;
    }

    #showResult #content2 {
        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
        font-family: SimSun;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 20px !important;
    }

    #showResult #content2 p {
        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
        font-family: SimSun;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 20px !important;
        margin-top: 0;
        margin-bottom: 0;
    }

    #showResult #content2 p span {
        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.60));
        font-family: SimSun;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 20px !important;
    }

    .cardContent {
        top: 54px;
        left: 77px;
        height:calc(100% - 130px);
        width:calc(100% - 154px);
    }
}

.loading_div {
  width: 200px;
  height: 40px;
}

.item-myself {
    font-family: SimSun;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding: 12px;
    border-radius: 8px 2px 8px 8px;
    /* margin-right: 10px; */
}
</style>

<style>
.showResult .CloseImgTheme{
    top: 8px;
    right: 5px;
    font-size: 20px;
    position: absolute;
    cursor: pointer;
    color: #000000;
}
.showResult .CloseImgTheme:hover, .showResult .CloseImgTheme:active {
    color: #FE7777;
}
#showResult .el-dialog__wrapper{
    display: flex;
    align-items: center;
}
#showResult .el-dialog__header,
#showFileListResult .el-dialog__header,
#showImageListResult .el-dialog__header {
    display: none;
}

#showClose .el-dialog__header {
    display: block;
}

#showResult .el-dialog__body,
#showImageListResult .el-dialog__body {
    padding: 0;
    background: none;
}

#showFileListResult .el-dialog__body {
    padding: 30px 10px;
}

#showResult .el-dialog,
#showFileListResult .el-dialog,
#showImageListResult .el-dialog {
    background: none;
    box-shadow: none;
    height: 100% !important;
    width: 60%;
}

.max {
    position: absolute;
    top: 20px;
    right: 40px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
}

.el-dialog.is-fullscreen {
    margin-top: 0 !important;
}

#showResult .el-dialog,
#showImageListResult .el-dialog {
    margin-top: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

#showFileListResult .el-dialog {
    width: 98% !important;
    /* margin-top: 0!important; */
    height: 90% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

#showResult .el-dialog__body i:hover {
    color: #FE7777;
}

#showFileListResult .el-dialog__body i:hover {
    color: #FE7777;
}

#showImageListResult .el-dialog__body i:hover {
    color: #FE7777;
}

#showResult .el-dialog__body {
    /* height: 100%; */
    width: 100%;
    position: relative;
    height: 60%;
}

#showFileListResult .el-dialog__body {
    height: 100%;
    width: 100%;
    position: relative;
}

#showImageListResult .el-dialog__body {
    /* height: 100%; */
    /* width: 100%; */
    position: relative;
}

.el-dialog__header {
    width: 96.5%;
}

#handle {
    width: 100%;
    height: 20px;
    /* background-color: red; */
    position: absolute;
    right: 0;
    bottom: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: s-resize;
}

#showResult .el-loading-mask {
    background: none;
}

#showResult .input .el-textarea__inner{
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid rgba(54, 129, 252, 1);
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    font-size: 14px;
    border-radius: 8px;
    padding: 14px 75px 14px 12px;
}

#showResult .input .el-textarea__inner::-webkit-input-placeholder { 
    /* Chrome/Safari/Opera */  
    color: rgba(255, 255, 255, 0.35);
}
#showResult .input .el-textarea__inner:-moz-placeholder { 
    /* Firefox 4 - 18 */  
    color: rgba(255, 255, 255, 0.35);
}
#showResult .input .el-textarea__inner::-moz-placeholder { 
    /* Firefox 19+ */  
    color: rgba(255, 255, 255, 0.35);
    opacity: 1; 
}
#showResult .input .el-textarea__inner:-ms-input-placeholder { 
    /* IE 10+ 注意这里只有一个冒号 */  
    color: rgba(255, 255, 255, 0.35);
}
</style>