import { CurvedEdge, CurvedEdgeModel } from '@logicflow/extension'

// 圆角折线
class myCurvedEdgeModel extends CurvedEdgeModel {
  initEdgeData(data) {
    super.initEdgeData(data);
    this.radius = 5;
    this.text.editable = false; // 禁止节点文本编辑
  }
  getEdgeStyle() {
    const style = super.getEdgeStyle();
    style.strokeWidth = 1;
    const properties = this.properties;
    if(properties.isHidden == "2") {
      style.stroke = '#000000'
    }
    else if(properties.isHidden == "1") {
      style.stroke = 'none'
    }
    return style;
  }
  setAttributes() {
    this.isAnimation = true;
  }
  getEdgeAnimationStyle() {
    const style = super.getEdgeAnimationStyle();
    style.strokeDasharray = "none";
    // style.animationDuration = "10s";
    // style.stroke = '#000000'
    const properties = this.properties;
    if(properties.isHidden == "2") {
      style.stroke = '#000000'
    }
    else if(properties.isHidden == "1") {
      style.stroke = 'none'
    }
    return style;
  }
}

export default {
    type: 'pro-curved',
    view: CurvedEdge,
    model: myCurvedEdgeModel
}
