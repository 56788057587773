import { HtmlNodeModel, HtmlNode } from '@logicflow/core';
// import { HtmlResize } from '@logicflow/extension';
import '../../../uml.css'
import $ from 'jquery'
import { zhHansLanguage } from '../../../lang/zh-Hans.js'
import { zhHantLanguage } from '../../../lang/zh-Hant.js'
import { enLanguage } from '../../../lang/en.js'
import { sendUpdate, add_Operation } from '../../../tools/send.js'
import {
  MessageBox
} from 'element-ui'

class HomeWorkModel extends HtmlNodeModel {
  initNodeData(data) {
    super.initNodeData(data)
    this.text.editable = false; // 禁止节点文本编辑
    const width = 250;
    let height = 75;
    if (data.properties.homeworkContent && data.properties.homeworkContent != '<p></p>') {
      height = 154;
    }
    this.width = width;
    this.height = height;
    const unable_to_connect_with_oneself = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip43 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip43 : enLanguage.tip43),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor)
        return targetNode.id != sourceNode.id;
      },
    };
    this.sourceRules.push(unable_to_connect_with_oneself);
    const unable_to_connect_with_theme = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.unable_to_connect_with_theme : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.unable_to_connect_with_theme : enLanguage.unable_to_connect_with_theme),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceNode, sourceAnchor, targetAnchor)
        return targetNode.type != "icon-theme";
      },
    }
    this.sourceRules.push(unable_to_connect_with_theme);
    const unable_to_connect_with_other = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.unable_to_connect_with_other : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.unable_to_connect_with_other : enLanguage.unable_to_connect_with_other),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor)
        let edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id)
        let isConnect = false
        for (let i = 0; i < edges.length; i++) {
          if (edges[i].sourceNodeId == sourceNode.id && edges[i].targetNodeId == targetNode.id) {
            isConnect = true
            break
          }
        }
        return isConnect == false;
      },
    }
    this.sourceRules.push(unable_to_connect_with_other);
  }
  setAttributes() {
    if (this.properties.height == 154 || this.properties.height == 75) {
      this.height = this.properties.height;
    }
  }
}
class HomeWorkNode extends HtmlNode {
  setHtml(rootEl) {
    const { id, properties } = this.props.model;

    // var r = Math.floor(Math.random() * 256);
    // var g = Math.floor(Math.random() * 256);
    // var b = Math.floor(Math.random() * 256);
    // var color = "rgb(" + r + "," + g + "," + b + ")";
    var color = (properties.homeworkTitleColor && properties.homeworkTitleColor != '') ? properties.homeworkTitleColor : '#8BAEE3'

    const el = document.createElement('div');
    el.className = 'uml-wrapper';
    // el.style = "border: 1px solid " + color + ';';
    let that = this;
    if (properties.homeworkContent && properties.homeworkContent != "<p></p>") {
      let html = `
      <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
        <i class="el-icon-s-custom"></i>
        <div class="is_show_user_name">${properties.user_name}</div>
        <i class="el-icon-edit"></i>
        <div class="apostrophe"></div>
      </div>
      <div style="height:${!properties.is_show_user ? '100%' : (properties.height ? (properties.height - 27) + 'px' : 127 + 'px')};" class="task">
        <div class="uml-head" style="background-color:${color};">${properties.homeworkName ? properties.homeworkName : ''}</div>
        <i class="el-icon-circle-close closeImg" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
        <div style="height:${!properties.is_show_user ? (properties.height ? (properties.height - 32) + 'px' : 122 + 'px') : (properties.height ? (properties.height - 60) + 'px' : 94 + 'px')};overflow-y: auto;word-break: break-all;word-wrap: break-word;padding-left:4px;padding-right:4px;">
          ${properties.homeworkContent ? properties.homeworkContent : ''}
        </div>
      </div>`;

      el.innerHTML = html;
      // 需要先把之前渲染的子节点清除掉。
      rootEl.innerHTML = '';
      rootEl.appendChild(el);
    }
    else {
      let html = `
            <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
            <i class="el-icon-s-custom"></i>
            <div class="is_show_user_name">${properties.user_name}</div>
            <i class="el-icon-edit"></i>
            <div class="apostrophe"></div>
            </div>
            <div style="${properties.is_show_user ? 'height:48px;' : 'height:75px;'}">
            <i class="el-icon-circle-close closeImgTheme" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
            <div style="height:100%;display: flex;justify-content: center;align-items: center;flex-direction: column;">
                <div class="uml-select-body" style="border-bottom-left-radius: 8px;text-align: center;display: flex;flex-direction: row;justify-content: center;align-items: center;height:100%;width: 100%;padding:0;">
                    <div style="background:#8BAEE3;height:100%;color:#ffffff;width:75px;height:75px;border-top-left-radius: 8px;border-bottom-left-radius: 8px;display: flex;justify-content: center;align-items: center;color: #FFF;font-family: SimSun;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;"> 
                        ${window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.homeworkName : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.homeworkName : enLanguage.homeworkName)}
                    </div>
                    
                    <div class="el-input stage" style="width: 175px;">
                        <span data-placeholder="${window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.homeworkDesc : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.homeworkDesc : enLanguage.homeworkDesc)}" class="placeholder" id="text_${this.props.model.id}" style="display: block;color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));text-align: center;font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 400;line-height: normal;">${properties.homeworkName ? properties.homeworkName : ''}</span>
                        <input type="text" autocomplete="off" class="el-input__inner" id="input_${this.props.model.id}"
                        placeholder="${window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.homeworkDesc : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.homeworkDesc : enLanguage.homeworkDesc)}" 
                        style="text-align: center;border:none;display:none;color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));font-family: SimSun;font-size: 14px;font-style: normal;font-weight: 400;line-height: normal;" value="${properties.homeworkName ? properties.homeworkName : ''}" />
                    </div>
                </div>
            </div>
            </div>
            `;
      el.innerHTML = html;
      // 需要先把之前渲染的子节点清除掉。
      rootEl.innerHTML = '';
      rootEl.appendChild(el);

      $("#input_" + that.props.model.id).on("change", function (e) {
        that.props.graphModel.getNodeModelById(that.props.model.id).setProperties({
          homeworkName: e.currentTarget.value
        });
        let nodes = []
        nodes.push(that.props.graphModel.getNodeModelById(that.props.model.id).getData())
        sendUpdate(nodes, [], "update")
        let newData = {
          "nodes": nodes,
          "edges": [],
          "backgroundUrl": localStorage.getItem("background")
        }
        add_Operation(newData, "updateNode")
      });
    }
    if(document.getElementById(id)) {
      document.getElementById(id).addEventListener('click', (event) => {
        let tip18 = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip18 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip18 : enLanguage.tip18)
        let tip2 = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip2 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip2 : enLanguage.tip2)
        let confirm = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.confirm : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.confirm : enLanguage.confirm)
        let cancel = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.cancel : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.cancel : enLanguage.cancel)
        let nodes = []
        nodes.push(that.props.graphModel.getElement(id).getData())

        MessageBox.confirm(tip18, tip2, {
          confirmButtonText: confirm,
          cancelButtonText: cancel,
          type: 'warning'
        }).then(() => {
          let newEdges = []
          let outgoingEdgeModel = that.props.graphModel.getNodeOutgoingEdge(id);
          if (outgoingEdgeModel.length != 0) {
            for (let j = 0; j < outgoingEdgeModel.length; j++) {
              let edge = that.props.graphModel.getEdgeModelById(outgoingEdgeModel[j].id)
              newEdges.push(edge.getData())
            }
          }
          let incomingEdgeModel = that.props.graphModel.getNodeIncomingEdge(id);
          if (incomingEdgeModel.length != 0) {
            for (let j = 0; j < incomingEdgeModel.length; j++) {
              let edge = that.props.graphModel.getEdgeModelById(incomingEdgeModel[j].id)
              newEdges.push(edge.getData())
            }
          }
          sendUpdate(nodes, newEdges, "delete")
          that.props.graphModel.deleteNode(id)

          that.props.graphModel.clearSelectElements()
          let newData = {
            "nodes": nodes,
            "edges": newEdges,
            "backgroundUrl": localStorage.getItem("background")
          }
          add_Operation(newData, "deleteNode")
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
        event.stopPropagation();
      })
    }
  }
}

export default {
  type: 'icon-homework',
  view: HomeWorkNode,
  model: HomeWorkModel
}