import {
  HtmlResize
} from '@logicflow/extension';
import '../../../uml.css'
import img_pre from '../../../assets/title_pre.png'
import img_nor from '../../../assets/title_nor.png'
import axios from 'axios'
import {
  init_map
} from '../../../tools/utils.js'
import store from '../../../store';
// import $ from 'jquery'
import { zhHansLanguage } from '../../../lang/zh-Hans.js'
import { zhHantLanguage } from '../../../lang/zh-Hant.js'
import { enLanguage } from '../../../lang/en.js'
import { sendUpdate, add_Operation } from '../../../tools/send.js'
import {
  MessageBox
} from 'element-ui'

class MapModel extends HtmlResize.model {
  initNodeData(data) {
    super.initNodeData(data)
    this.text.editable = false; // 禁止节点文本编辑
    // 设置节点宽高和鼠标移动多少距离后开始缩放节点
    const width = 265;
    const height = 250;
    this.width = width;
    this.height = height;
    this.gridSize = 1;

    const unable_to_connect_with_oneself = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip43 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip43 : enLanguage.tip43),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor)
        return targetNode.id != sourceNode.id;
      },
    };
    this.sourceRules.push(unable_to_connect_with_oneself);
    const unable_to_connect_with_theme = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.unable_to_connect_with_theme : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.unable_to_connect_with_theme : enLanguage.unable_to_connect_with_theme),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceNode, sourceAnchor, targetAnchor)
        return targetNode.type != "icon-theme";
      },
    }
    this.sourceRules.push(unable_to_connect_with_theme);
    const unable_to_connect_with_other = {
      message: window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.unable_to_connect_with_other : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.unable_to_connect_with_other : enLanguage.unable_to_connect_with_other),
      validate: (sourceNode, targetNode, sourceAnchor, targetAnchor) => {
        console.log(sourceAnchor, targetAnchor)
        let edges = this.graphModel.getNodeOutgoingEdge(sourceNode.id)
        let isConnect = false
        for (let i = 0; i < edges.length; i++) {
          if (edges[i].sourceNodeId == sourceNode.id && edges[i].targetNodeId == targetNode.id) {
            isConnect = true
            break
          }
        }
        return isConnect == false;
      },
    }
    this.sourceRules.push(unable_to_connect_with_other);
  }
}
class MapNode extends HtmlResize.view {
  get_position(ip, id, lf) {
    axios.post("https://gpt.cocorobo.cn/get_map", {
      ip: ip
    })
      .then((res) => {
        try {
          let lat = res.data.result.location.lat;
          let lng = res.data.result.location.lng;
          store.commit("change_lat", { lat: lat, lng: lng, id: id, map_type: { type: 'vector' } })
          lf.graphModel.getNodeModelById(id).setProperties({
            lat: lat,
            lng: lng,
            map_type: { type: 'vector' }
          });
          init_map('map_container_' + id, lat, lng, { type: 'vector' });
        }
        catch {
          console.log()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  contentTitle(eleDom, textTips, lf, isShowDescribe) {
    const itemDom = document.createElement("div"); //增加提个DOM p标签，用于展示 自定义title
    itemDom.setAttribute('class', 'robotHeaderBar-Value'); //添加class命名，用于设置自定义title样式
    // itemDom.style = "border: 1px solid #ffffff;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
    itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
    const div = document.createElement("div");
    div.style = "background: #FFFFFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;";

    const img = document.createElement("img");
    img.src = img_nor;
    div.appendChild(img);
    itemDom.appendChild(div);

    eleDom.parentNode.style = "overflow: visible;";
    // let itemDomShow = false; //用于判断是否添加了节点
    let itemClickDomShow = isShowDescribe;
    const p = document.createElement("p");
    div.title = textTips;

    if (itemClickDomShow) {
      p.textContent = textTips; //参数textTips 代表需要展示的自定义title文本
      p.style = "margin-left:10px;margin-right:10px;"
      itemDom.appendChild(p);
      img.src = img_pre;
      // itemDom.style = "border: 1px solid #007BFF;left:" + (eleDom.getBoundingClientRect().width + 10) + "px;top:" + (eleDom.getBoundingClientRect().height - 40) + "px;";
      itemDom.style = "border: 1px solid #ffffff;left:104%;top:80%;";
      div.style = "background: #007BFF;width:30px;height:30px;display: flex;flex-direction: row;align-items: center;justify-content: center;border-bottom-left-radius: 6px;border-top-left-radius: 6px;border: 1px solid rgb(0, 123, 255);";
    }

    eleDom.appendChild(itemDom);
  }
  setHtml(rootEl) {
    const {
      id, properties
    } = this.props.model;
    var color = (properties.titleColor && properties.titleColor != '') ? properties.titleColor : '#3B51C6'
    let cardTitle = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.map_card : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.map_card : enLanguage.map_card)
    const el = document.createElement('div');
    el.className = 'uml-wrapper';
    // el.style = "border: 1px solid " + color + ';';
    var html = ``;
    if (properties.nodeSize && properties.nodeSize.height && parseInt(properties.nodeSize.height) <= 60 ||
      properties.nodeSize && properties.nodeSize.width && parseInt(properties.nodeSize.width) <= 60) {
      el.style = "border: 1px solid " + color + ';width:60px;height:60px;';
      if (properties.iconList && properties.iconList.length != 0) {
        html = `<div style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;">
          <img src="${properties.iconList[0].url}" draggable="false"/>
        </div>
        `
      }
    } else {
      html = `
      <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
        <i class="el-icon-s-custom"></i>
        <div class="is_show_user_name">${properties.user_name}</div>
        <i class="el-icon-edit"></i>
        <div class="apostrophe"></div>
      </div>
      <div>
        <div class="uml-head" style="background-color:${color};">${cardTitle}</div>
        <i class="el-icon-circle-close closeImg" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
        <div class="uml-body" style="width: 100%;height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 218 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 190 + 'px')};padding:0;border-top:none;border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;">
          <div style="width: 100%;height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 218 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 190 + 'px')};display:flex;align-items:center;justify-content: center;border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;">
            <div style="width: 100%;height:100%" id="map_container_${id}"></div>
          </div>
        </div>
      </div>
      `;
      let lf = this.props
      if (properties.lat && properties.lng && properties.map_url) {
        // $("#map_container_" + id).html("")
        // init_map('map_container_' + id, properties.lat, properties.lng,properties.map_type);
        html = `
        <div class="is_show_user" style="${properties.is_show_user ? 'display:flex;' : 'display:none;'}">
          <i class="el-icon-s-custom"></i>
          <div class="is_show_user_name">${properties.user_name}</div>
          <i class="el-icon-edit"></i>
          <div class="apostrophe"></div>
        </div>
        <div>
          <div class="uml-head" style="background-color:${color};">${cardTitle}</div>
          <i class="el-icon-circle-close closeImg" id="${id}" style="${properties.isShowDelete ? 'display:block;' : 'display:none;'}"></i>
          <div class="uml-body" style="width: 100%;height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 218 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 190 + 'px')};padding:0;border-top:none;border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;">
            <div style="width: 100%;height:${!properties.is_show_user ? (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 32) + 'px' : 218 + 'px') : (properties.nodeSize && properties.nodeSize.height ? (properties.nodeSize.height - 60) + 'px' : 190 + 'px')};display:flex;align-items:center;justify-content: center;border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;">
              <img draggable="false" src="${properties.map_url}" style="width: 919px;height:656px;object-fit: cover;"></img>
            </div>
          </div>
        </div>
        `;
        store.commit("change_lat", { lat: properties.lat, lng: properties.lng, id: id, map_type: properties.map_type })
      }
      else {
        //获取定位
        fetch("https://api.ipify.org?format=json")
          .then(response => response.json())
          .then(data => {
            let ip = data.ip;
            this.get_position(ip, id, lf)
          })
          .catch(error => console.error(error));
      }
    }
    el.innerHTML = html;
    // 需要先把之前渲染的子节点清除掉。
    rootEl.innerHTML = '';
    rootEl.appendChild(el);

    let that = this;
    if (document.getElementById(id)) {
      document.getElementById(id).addEventListener('click', (event) => {
        let tip18 = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip18 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip18 : enLanguage.tip18)
        let tip2 = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.tip2 : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.tip2 : enLanguage.tip2)
        let confirm = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.confirm : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.confirm : enLanguage.confirm)
        let cancel = window.location.href.indexOf("cocorobo.cn") != -1 ? zhHansLanguage.cancel : (window.location.href.indexOf("cocorobo.hk") != -1 ? zhHantLanguage.cancel : enLanguage.cancel)
        let nodes = []
        nodes.push(that.props.graphModel.getElement(id).getData())

        MessageBox.confirm(tip18, tip2, {
          confirmButtonText: confirm,
          cancelButtonText: cancel,
          type: 'warning'
        }).then(() => {
          let newEdges = []
          let outgoingEdgeModel = that.props.graphModel.getNodeOutgoingEdge(id);
          if (outgoingEdgeModel.length != 0) {
            for (let j = 0; j < outgoingEdgeModel.length; j++) {
              let edge = that.props.graphModel.getEdgeModelById(outgoingEdgeModel[j].id)
              newEdges.push(edge.getData())
            }
          }
          let incomingEdgeModel = that.props.graphModel.getNodeIncomingEdge(id);
          if (incomingEdgeModel.length != 0) {
            for (let j = 0; j < incomingEdgeModel.length; j++) {
              let edge = that.props.graphModel.getEdgeModelById(incomingEdgeModel[j].id)
              newEdges.push(edge.getData())
            }
          }
          sendUpdate(nodes, newEdges, "delete")
          that.props.graphModel.deleteNode(id)

          that.props.graphModel.clearSelectElements()
          let newData = {
            "nodes": nodes,
            "edges": newEdges,
            "backgroundUrl": localStorage.getItem("background")
          }
          add_Operation(newData, "deleteNode")
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
        event.stopPropagation();
      })
    }
  }
}

export default {
  type: 'icon-map',
  view: MapNode,
  model: MapModel
}