<template>
    <div>
        <div id="monitor">
            <el-dialog :visible.sync="dialogVisible" width="45%" :fullscreen="fullscreen" :modal="false" :show-close="false"
                :close-on-click-modal="false" :before-close="handleClose" v-dialogMoveDrag>
                <div slot="title" style="width: 100%; position: relative">
                    <span class="el-dialog__title">{{ languageData.dashboard }}</span>
                    <button type="button" class="max" v-if="!fullscreen" style="top: 4px;">
                        <img v-if="hoverMax" src="../../assets/max_pre.png" width="16" height="16" @mouseout="blurMax"
                            @click="clickMax" />
                        <img v-else src="../../assets/max_nor.png" width="16" height="16" @mouseover="focusMax" />
                    </button>
                    <button type="button" class="max" v-else-if="fullscreen" style="top: 4px;">
                        <img v-if="hoverRecovery" src="../../assets/recovery_pre.png" width="16" height="16"
                            @mouseout="blurRecovery" @click="clickRecovery" />
                        <img v-else src="../../assets/recovery_nor.png" width="16" height="16" @mouseover="focusRecovery" />
                    </button>

                    <button type="button" class="close" style="top: 4px;">
                        <img v-if="hoverClose" src="../../assets/close_pre.png" width="16" height="16" @mouseout="blurClose"
                            @click="handleClose" />
                        <img v-else src="../../assets/close_nor.png" width="16" height="16" @mouseover="focusClose" />
                    </button>

                </div>
                <span style="height: 100%;border-bottom-left-radius: 12px;border-bottom-right-radius: 12px;">
                    <div style="height: 100%;border-bottom-left-radius: 12px;border-bottom-right-radius: 12px;">
                        <el-tabs v-model="activeName" type="border-card">
                            <el-tab-pane :label="languageData.whole" name="all">
                                <div style="display: flex;flex-direction: row;width: 100%;height: 100%;">
                                    <div style="width:40%;overflow-y: auto;overflow-x: hidden;height: 100%;">
                                        <div class="flow_chart" @click="clickFlow"
                                            :style="activeIndex == 0 ? 'border-radius: 2px;border: 1px solid var(--brand-normal, #3681FC);' : 'border-radius: 2px;'">
                                            <div id="flow"
                                                style="width:calc(70%);height:200px;margin:0 auto;background-color:#fff;">
                                            </div>
                                            <div style="width:calc(30%);text-align:center;">
                                                <span class="refreshFrequency">{{ languageData.refreshFrequency
                                                }}:30s</span>
                                                <span :class="activeIndex == 0 ? 'active' : 'text'">{{
                                                    languageData.dashboardTip1 }}</span>
                                            </div>
                                        </div>
                                        <div class="participation_chart" @click="clickParticipation"
                                            :style="activeIndex == 1 ? 'border-radius: 2px;border: 1px solid var(--brand-normal, #3681FC);' : 'border-radius: 2px;'">
                                            <div id="participation"
                                                style="width:calc(70%);height:200px;margin:0 auto;background-color:#fff;">
                                            </div>
                                            <div style="width:calc(30%);text-align:center;">
                                                <span class="refreshFrequency">{{ languageData.refreshFrequency
                                                }}:30s</span>
                                                <span :class="activeIndex == 1 ? 'active' : 'text'">{{
                                                    languageData.dashboardTip2 }}</span>
                                            </div>
                                        </div>

                                        <div class="card_num_chart" @click="clickCardNum"
                                            :style="activeIndex == 2 ? 'border-radius: 2px;border: 1px solid var(--brand-normal, #3681FC);' : 'border-radius: 2px;'">
                                            <div id="card_num"
                                                style="width:calc(70%);height:200px;margin:0 auto;background-color:#fff;">
                                            </div>
                                            <div style="width:calc(30%);text-align:center;">
                                                <span class="refreshFrequency">{{ languageData.refreshFrequency
                                                }}:30s</span>
                                                <span :class="activeIndex == 2 ? 'active' : 'text'">{{
                                                    languageData.dashboardTip3 }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:60%;height: 100%;">
                                        <div id="flow_chart" class="flow_chart"
                                            style="display: flex;height: 100%;border: none;">
                                            <div id="flow_new"
                                                style="width:calc(100%);height:calc(100%);margin:0 auto;background-color:#fff;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane :label="languageData.character" name="character">
                                <character></character>
                            </el-tab-pane>
                        </el-tabs>
                    </div>

                </span>
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
import * as echarts from 'echarts';
import character from './Character.vue'
import $ from 'jquery'
export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: false,
        },
        allGroup: Array,
    },
    components:{
        character
    },
    data() {
        return {
            hoverMax: false,
            hoverRecovery: false,
            hoverDownload: false,
            hoverClose: false,
            fullscreen: false,
            languageData: {},
            activeName: 'all',
            time: null,
            currentUserId: "",
            flowCategoryData: [],
            flowValueData: [],
            flowOption: {},
            participationOption: {},
            cardNumOption: {},
            activeIndex: 0,
            modelSetinter: null,
            oldTime:null
        };
    },
    watch: {
        async dialogVisible(newVal) {
            let that = this
            if (newVal) {
                if (window.location.href.indexOf("?cid=") != -1 && window.location.href.indexOf("&gid=") != -1) {
                    // let gid = window.location.href.split("&gid=")[1];
                    that.click_Instrument_panel()
                }
            }
        }
    },
    mounted() {
        if (window.location.href.indexOf("cocorobo.cn") != -1) {
            this.languageData = this.zhHansLanguage;
        } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
            this.languageData = this.zhHantLanguage;
        } 
        else if (window.location.href.indexOf("cocorobo.com") != -1) {
            this.languageData = this.enLanguage;
        }
        else {
            this.languageData = this.zhHansLanguage;
        }
        const date = new Date();
        this.time = date
        this.oldTime = date
        this.currentUserId = window.location.href.indexOf("localhost") != -1 || window.location.href.indexOf("192.168") != -1 ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01" : window.parent.US.userInfo.userid
    },
    methods: {
        click_Instrument_panel(){
            let that = this
            that.oldTime = new Date()
            let participationOption = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: [],
                    show: false,
                    formatter: function (params) {
                        var newParamsName = params.length > 6 ? params.substring(0, 4) + '...' : params
                        return newParamsName
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: [],
                    axisLabel: {
                        fontSize: 8
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        fontSize: 8
                    },
                },
                series: [

                ]
            };
            that.Instrument_panel_loading(participationOption)
            if(that.activeIndex == 0 && JSON.stringify(that.flowOption) != "{}"){
                that.clickFlow()
            }else if(that.activeIndex == 1 && JSON.stringify(that.participationOption) != "{}"){
                that.clickParticipation()
            }else if(that.activeIndex == 2 && JSON.stringify(that.cardNumOption) != "{}"){
                that.clickCardNum()
            }
            that.modelSetinter = setInterval(async function () {
                that.time = new Date()
                that.Instrument_panel_loading(that.participationOption)
            }, 30000)
        },
        async Instrument_panel_loading(participationOption) {
            let cid = window.location.href.split("?cid=")[1].split("&gid=")[0];
            let that = this;
            let group = that.allGroup

            var sortBy = (function () {
                var toString = Object.prototype.toString,
                    // default parser function
                    parse = function (x) { return x; },
                    // gets the item to be sorted
                    getItem = function (x) {
                        var isObject = x != null && typeof x === "object";
                        var isProp = isObject && this.prop in x;
                        return this.parser(isProp ? x[this.prop] : x);
                    };

                /**
                 * Sorts an array of elements.
                 *
                 * @param {Array} array: the collection to sort
                 * @param {Object} cfg: the configuration options
                 * @property {String}   cfg.prop: property name (if it is an Array of objects)
                 * @property {Boolean}  cfg.desc: determines whether the sort is descending
                 * @property {Function} cfg.parser: function to parse the items to expected type
                 * @return {Array}
                 */
                return function sortby(array, cfg) {
                    if (!(array instanceof Array && array.length)) return [];
                    if (toString.call(cfg) !== "[object Object]") cfg = {};
                    if (typeof cfg.parser !== "function") cfg.parser = parse;
                    cfg.desc = cfg.desc ? -1 : 1;
                    return array.sort(function (a, b) {
                        a = getItem.call(cfg, a);
                        b = getItem.call(cfg, b);
                        return cfg.desc * (a < b ? -1 : +(a > b));
                    });
                };

            }());

            let newOpdata = []

            // 总活跃度首数据
            let params = "mode=" + [
                cid
            ].join(",");
            await that.$ajax
                .post("https://pbl.cocorobo.cn/api/pbl/select_CSCLDataByID", params)
                .then((res) => {
                    let opdata = res.data[1]
                    sortBy(opdata, {
                        prop: "create_at",
                        parser: function (item) {
                            return new Date(item);
                        }
                    })
                    for (let i = 0; i < opdata.length; i++) {
                        if (new Date(opdata[i].create_at) >= that.oldTime) {
                            newOpdata.push(opdata[i])
                        }
                    }
                    let num = 0

                    for (let i = 0; i < newOpdata.length; i++) {
                        num = num + 1
                    }

                    that.flowCategoryData.push(
                        echarts.format.formatTime('yyyy-MM-dd\nhh:mm:ss', that.time, false)
                    );
                    that.flowValueData.push(num)
                    that.$nextTick(() => {
                        // 总活跃度
                        let flowChartDom = document.getElementById('flow');
                        let flowChart = echarts.init(flowChartDom);
                        let flowOption = {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            grid: {
                                top: 20,
                                bottom: 90
                            },
                            dataZoom: [
                                {
                                    type: 'inside'
                                },
                                {
                                    type: 'slider'
                                }
                            ],
                            xAxis: {
                                data: that.flowCategoryData,
                                silent: false,
                                splitLine: {
                                    show: false
                                },
                                splitArea: {
                                    show: false
                                },
                                axisLabel: {
                                    fontSize: 8
                                }
                            },
                            yAxis: {
                                splitArea: {
                                    show: false
                                },
                                axisLine: {
                                    show: true
                                },
                                axisLabel: {
                                    fontSize: 8
                                }
                            },
                            series: [
                                {
                                    type: 'bar',
                                    data: that.flowValueData,
                                    // Set `large` for large data amount
                                    large: true
                                }
                            ]
                        };
                        flowOption && flowChart.setOption(flowOption);
                        that.flowOption = flowOption
                        if (that.activeIndex == 0) {
                            that.clickFlow()
                            // let flowNewChartDom = document.getElementById('flow_new');
                            // echarts.dispose(flowNewChartDom)
                            // let flowNewChart = echarts.init(flowNewChartDom);
                            // that.flowOption && flowNewChart.setOption(that.flowOption);
                        }
                        // let flowNewChartDom = document.getElementById('flow_new');
                        // let flowNewChart = echarts.init(flowNewChartDom);
                        // that.flowOption && flowNewChart.setOption(that.flowOption);
                    })

                })
                .catch((err) => {
                    console.error(err);
                });

            // 小组参与度首数据
            for (let i = 0; i < group.length; i++) {
                let params =
                    window.location.href.indexOf("localhost") != -1 || window.location.href.indexOf("192.168") != -1
                        ? "mode=" +
                        [
                            "getcscldata",
                            group[i].id,
                            "d7300d31-8cf6-38dc-e295-2c71622b4df1",
                            "1cf9dc4b-d95f-11ea-af4c-52540005ab01",
                        ].join(",")
                        : "mode=" +
                        [
                            "getcscldata",
                            group[i].id,
                            window.parent.US.pageId,
                            window.parent.US.userInfo.userid,
                        ].join(",");
                await that.$ajax
                    .post("https://poll.cocorobo.cn", params)
                    .then((res) => {
                        let data = {};
                        try {
                            data = JSON.parse(res.data[0][0].data);
                        } catch {
                            data = res.data[0][0].data;
                        }
                        if (data.nodes == null) {
                            group[i].cardNum = 0
                        }
                        else {
                            group[i].cardNum = data.nodes.length
                        }
                        let addNum = 0
                        let deleteNum = 0
                        for (let j = 0; j < newOpdata.length; j++) {
                            for (let k = 0; k < group[i].group_member.length; k++) {
                                if (newOpdata[j].userid == group[i].group_member[k].userid && JSON.parse(newOpdata[j].text).type == "addNode") {
                                    addNum += JSON.parse(newOpdata[j].text).data.nodes.length
                                }
                                if (newOpdata[j].userid == group[i].group_member[k].userid && JSON.parse(newOpdata[j].text).type == "deleteNode") {
                                    deleteNum += JSON.parse(newOpdata[j].text).data.nodes.length
                                }
                            }
                        }
                        group[i].addCardNum = addNum
                        group[i].deleteCardNum = deleteNum
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }

            let cardNumOption = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        let str = "";
                        params.forEach((item, idx) => {
                            if (idx <= 2) {
                                str += `${item.seriesName}:${Math.abs(item.value)}</br>`
                            } else {
                                str += `${item.seriesName}:${Math.abs(item.value)}`
                            }

                        })
                        return str
                    }
                },
                legend: {
                    data: [that.languageData.dashboardTip4, that.languageData.dashboardTip5, that.languageData.dashboardTip6]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            fontSize: 8
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            fontSize: 8,
                            formatter: function (params) {
                                var newParamsName = params.length > 6 ? params.substring(0, 4) + '...' : params
                                return newParamsName
                            }
                        },
                        data: []
                    }
                ],
                series: [
                    {
                        name: that.languageData.dashboardTip4,
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'inside'
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                    {
                        name: that.languageData.dashboardTip6,
                        type: 'bar',
                        stack: 'Total',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                    {
                        name: that.languageData.dashboardTip5,
                        type: 'bar',
                        stack: 'Total',
                        label: {
                            show: true,
                            position: 'left'
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    }
                ]
            };

            that.$nextTick(() => {
                let participationChartDom = document.getElementById('participation');
                let participationChart = echarts.init(participationChartDom);
                if(participationOption.series.length != group.length) {
                    for (let i = 0; i < group.length; i++) {
                        participationOption.legend.show = false
                        participationOption.legend.data.push(group[i].name)
                        participationOption.series.push({
                            name: group[i].name,
                            type: 'line',
                            data: [group[i].cardNum]
                        })
                    }
                    if (participationOption.series.length != 0) {
                        participationOption.xAxis.data.push(echarts.format.formatTime('yyyy-MM-dd\nhh:mm:ss', that.time, false))
                        participationOption && participationChart.setOption(participationOption);
                        that.participationOption = participationOption
                    }
                }
                else {
                    for (let i = 0; i < group.length; i++) {
                        participationOption.legend.show = false
                        participationOption.series[i].data.push(group[i].cardNum)
                    }
                    participationOption.xAxis.data.push(echarts.format.formatTime('yyyy-MM-dd\nhh:mm:ss', that.time, false))
                    participationOption && participationChart.setOption(participationOption);
                    that.participationOption = participationOption
                }

                let cardNumChartDom = document.getElementById('card_num');
                let cardNumChart = echarts.init(cardNumChartDom);
                for (let i = 0; i < group.length; i++) {
                    cardNumOption.yAxis[0].data.push(group[i].name)
                    cardNumOption.series[0].data.push(group[i].cardNum)
                    cardNumOption.series[1].data.push(group[i].addCardNum)
                    cardNumOption.series[2].data.push(group[i].deleteCardNum - group[i].deleteCardNum * 2)
                    if (group[i].addCardNum == 0) {
                        cardNumOption.series[1].label.show = false
                    }
                    if (group[i].deleteCardNum == 0) {
                        cardNumOption.series[2].label.show = false
                    }
                }
                if (that.activeIndex == 1) {
                    that.clickParticipation()
                }
                that.cardNumOption = cardNumOption
                if (that.activeIndex == 2) {
                    that.clickCardNum()
                }
                cardNumOption && cardNumChart.setOption(cardNumOption);
            })
        },
        clickFlow() {
            let that = this
            that.$nextTick(()=>{
                that.activeIndex = 0
                let flowNewChartDom = document.getElementById('flow_new');
                echarts.dispose(flowNewChartDom)
                let flowNewChart = echarts.init(flowNewChartDom);
                that.flowOption && flowNewChart.setOption(that.flowOption);
            })
        },
        clickParticipation() {
            let that = this
            that.$nextTick(()=>{
                that.activeIndex = 1
                // this.participationOption
                let optionData = that.participationOption
                optionData.legend.show = true
                let flowNewChartDom = document.getElementById('flow_new');
                echarts.dispose(flowNewChartDom)
                let flowNewChart = echarts.init(flowNewChartDom);
                optionData && flowNewChart.setOption(optionData);
            })
            
        },
        clickCardNum() {
            let that = this
            that.$nextTick(()=>{
                that.activeIndex = 2
                let flowNewChartDom = document.getElementById('flow_new');
                echarts.dispose(flowNewChartDom)
                let flowNewChart = echarts.init(flowNewChartDom);
                that.cardNumOption && flowNewChart.setOption(that.cardNumOption);
            })
        },
        looseEqual(a, b) {
            if (a === b) { //如果是绝对相等就直接返回true
                return true;
            }
            //如果不是绝对相等就哦按的他们是否有相同的形状
            var isObjectA = this.isObject(a);
            var isObjectB = this.isObject(b);
            if (isObjectA && isObjectB) {//两个均是对象
                try {
                    var isArrayA = Array.isArray(a);
                    var isArrayB = Array.isArray(b);
                    if (isArrayA && isArrayB) {//如果都是数组
                        if (a.length === b.length) {//如果长度相等
                            return a.every(function (e, i) {//用every和递归来比对a数组和b数组的每个元素，并返回
                                return this.looseEqual(e, b[i])
                            })
                        }
                        //长度都不等直接返回false
                        return false;
                    } else if (a instanceof Date && b instanceof Date) {//如果是Date 则直接getTime 比较
                        return a.getTime() === b.getTime()
                    } else if (!isArrayA && !isArrayB) {//对象的比较
                        //拿到两个对象的key
                        var keysA = Object.keys(a);
                        var keysB = Object.keys(b);
                        if (keysA.length === keysB.length) {//如果keys相等
                            return keysA.every(function (key) {//用every和递归来比对a对象和b对象的每个元素值，并返回
                                return this.looseEqual(a[key], b[key]);
                            })
                        }
                        //长度都不等直接返回false
                        return false;
                    } else {
                        return false
                    }
                } catch (e) {
                    return false
                }
            } else if (!isObjectA && !isObjectB) {//如果都不是对象则按String来处理
                return String(a) === String(b)
            } else {
                return false
            }
        },
        isObject(obj) {
            return obj !== null && typeof obj === 'object'
        },
        handleClose() {
            this.flowValueData = []
            this.flowCategoryData = []
            clearInterval(this.modelSetinter)
            this.$emit("info", false);
            // this.$emit("clearList", []);
        },
        focusMax() {
            this.hoverMax = true;
            this.hoverRecovery = false;
            this.hoverClose = false;
        },
        blurMax() {
            this.hoverMax = false;
            this.hoverRecovery = false;
            this.hoverClose = false;
        },
        focusRecovery() {
            this.hoverMax = false;
            this.hoverRecovery = true;
            this.hoverClose = false;
        },
        blurRecovery() {
            this.hoverMax = false;
            this.hoverRecovery = false;
            this.hoverClose = false;
        },
        focusClose() {
            this.hoverMax = false;
            this.hoverRecovery = false;
            this.hoverClose = true;
        },
        blurClose() {
            this.hoverMax = false;
            this.hoverRecovery = false;
            this.hoverClose = false;
        },
        clickMax() {
            let that = this;
            that.fullscreen = true;
            $("#monitor .el-dialog__body").css({
                height: "calc(100% - 41px)",
            });
            that.$nextTick(() => {
                if (that.activeIndex == 0) {
                    let flowNewChartDom = document.getElementById('flow_new');
                    echarts.dispose(flowNewChartDom)
                    let flowNewChart = echarts.init(flowNewChartDom);
                    that.flowOption && flowNewChart.setOption(that.flowOption);
                }
                if (that.activeIndex == 1) {
                    let flowNewChartDom = document.getElementById('flow_new');
                    echarts.dispose(flowNewChartDom)
                    let flowNewChart = echarts.init(flowNewChartDom);
                    that.participationOption && flowNewChart.setOption(that.participationOption);
                }
                if (that.activeIndex == 2) {
                    let flowNewChartDom = document.getElementById('flow_new');
                    echarts.dispose(flowNewChartDom)
                    let flowNewChart = echarts.init(flowNewChartDom);
                    that.cardNumOption && flowNewChart.setOption(that.cardNumOption);
                }
            })
        },
        clickRecovery() {
            let that = this;
            that.fullscreen = false;
            $("#monitor .el-dialog__body").css({
                height: "60%",
            });
            that.$nextTick(() => {
                if (that.activeIndex == 0) {
                    let flowNewChartDom = document.getElementById('flow_new');
                    echarts.dispose(flowNewChartDom)
                    let flowNewChart = echarts.init(flowNewChartDom);
                    that.flowOption && flowNewChart.setOption(that.flowOption);
                }
                if (that.activeIndex == 1) {
                    let flowNewChartDom = document.getElementById('flow_new');
                    echarts.dispose(flowNewChartDom)
                    let flowNewChart = echarts.init(flowNewChartDom);
                    that.participationOption && flowNewChart.setOption(that.participationOption);
                }
                if (that.activeIndex == 2) {
                    let flowNewChartDom = document.getElementById('flow_new');
                    echarts.dispose(flowNewChartDom)
                    let flowNewChart = echarts.init(flowNewChartDom);
                    that.cardNumOption && flowNewChart.setOption(that.cardNumOption);
                }
            })
        },
    },
};
</script>
  
<style>
.el-dialog {
    margin-top: 5vh !important;
}

.el-dialog__body {
    background: #f0f7fd;
}


#monitor .el-dialog {
    margin-top: 0 !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: none;
    box-shadow: none;
}

#monitor .el-dialog__header {
    width: calc(100% - 48px);
    border-bottom: 1px solid var(--bg3, #E7E7E7);
    background: var(--font-icon-white, #FFF);
    border-left: 1px solid var(--bg3, #E7E7E7);
    border-right: 1px solid var(--bg3, #E7E7E7);
    border-top: 1px solid var(--bg3, #E7E7E7);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 0px 8px 10px -5px #00000014;
    box-shadow: 0px 16px 24px 2px #0000000A;
    box-shadow: 0px 6px 30px 5px #0000000D;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 24px;
}

#monitor .el-dialog__body {
    width: 100%;
    height: 60%;
    border-left: 1px solid var(--bg3, #E7E7E7);
    border-right: 1px solid var(--bg3, #E7E7E7);
    border-bottom: 1px solid var(--bg3, #E7E7E7);
    box-shadow: 0px 8px 10px -5px #00000014;
    box-shadow: 0px 16px 24px 2px #0000000A;
    box-shadow: 0px 6px 30px 5px #0000000D;
    background: var(--font-icon-white, #FFF);
    padding: 0px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

#monitor .el-dialog__headerbtn {
    top: none;
}


#monitor i:hover {
    color: #5cb6ff;
}

#monitor .max {
    position: absolute;
    top: 0;
    right: 25px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
}

#monitor .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
}

#monitor .el-dialog.is-fullscreen {
    overflow: hidden;
}

#monitor .el-dialog__wrapper {
    pointer-events: none;
    overflow: hidden;
}

#monitor .el-dialog {
    pointer-events: auto;
    cursor: auto;
}

#monitor .el-tabs--border-card {
    height: 100%;
    border: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

#monitor .el-tab-pane {
    width: 100%;
    height: 100%;
}

#monitor .flow_chart,
#monitor .participation_chart,
#monitor .card_num_chart {
    width: 96%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #E7E7E7;
    margin-bottom: 15px;
    cursor: pointer;
    border-radius: 2px;
    position: relative;
}

#monitor .refreshFrequency {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
}

#monitor .el-tabs--border-card>.el-tabs__content {
    height: calc(100% - 69px);
}

#monitor .text {
    color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));
    text-align: center;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

#monitor .active {
    color: var(--brand-click, #0061FF);
    text-align: center;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
</style>