export const zhHansLanguage = {
    "search": "搜索",
    "project": "项目",
    "collect": "收藏区",
    "toolLibrary": "卡片库",
    "materialLibrary": "素材库",
    "background": "背景",
    "framework": "框架",
    "analysis": "学习分析",
    "box": "盒子",
    "myProject": "我的项目",
    "newProject": "新建项目",
    "createProject": "创建项目",
    "createGroup": "创建分组",
    "ungrouped": "未分组",
    "toolClass": "控制类",
    "start": "主题卡片",
    "themeDesc": "请输入主题",
    "stage": "阶段卡片",
    "stageName": "阶段",
    "stageDesc": "请输入阶段名称",
    "task": "任务卡片",
    "taskName": "任务",
    "taskTitle": "任务名称",
    "taskDesc": "请输入任务名称",
    "taskContent": "任务描述",
    "taskContentDesc": "请输入任务描述",
    "resources": "内容类",
    "card": "资源卡片",
    "select": "选项卡片",
    "group": "分组",
    "interactive": "互动类",
    "thinking": "思维类",
    "thinkingCard": "思维卡片",
    "evaluate": "评价类",
    "ai": "AI类",
    "aiCard": "AI卡片",
    "fullScreen": "全屏",
    "clear": "清空",
    "exitFullScreen": "退出全屏",
    "run": "生成",
    "projection": "演示",
    "exitProjection": "退出演示",
    "previousPage": "上一页",
    "nextPage": "下一页",
    "logout": "退出小组",
    "straightLine": "直线",
    "polyline": "折线",
    "filletLine": "圆角折线",
    "curve": "曲线",
    "enableBoxSelection": "启用框选",
    "Uncheck": "取消框选",
    "enlarge": "放大",
    "reduce": "缩小",
    "revoke": "撤销",
    "restore": "恢复",
    "minimap": "小地图",
    "import": "导入",
    "export": "导出",
    "pictureFiles": "图片文件",
    "jsonFiles": "Json文件",
    "close": "关 闭",
    "confirm": "确 定",
    "tip": "请选择导出其中一种文件",
    "editMode": "编辑模式",
    "readOnlyMode": "只读模式",
    "tip1": "此操作将清空画布中除了起始（主题卡片）外的所有元素, 是否继续?",
    "tip2": "提示",
    "cancel": "取 消",
    "tip3": "清空成功!",
    "tip4": "请从起始点（主题卡片）出发，顺序连接所有需要生成的卡片内容",
    "tip5": "不存在主题卡片,请加入",
    "tip6": "存在选择题卡片连线未设置路径,请认真检查修改后再生成",
    "tip7": "请从起始点（主题卡片）出发，顺序连接所有需要演示的卡片内容",
    "tip8": "存在选择题卡片连线未设置路径,请认真检查修改后再演示",
    "tip22": "请选择起始点（主题卡片）再生成",
    "tip24": "是否在此生成脚手架模板？",
    "tip23": "创建成功",
    "Backspace": "删除卡片（Backspace或者Delete）",
    "clone": "克隆卡片（Ctrl+C和Ctrl+V或者Cmd+C和Cmd+V）",
    "top": "置于顶层（Ctrl+Shift+]或者Cmd+Shift+]）",
    "bottom": "置于底层（Ctrl+Shift+[或者Cmd+Shift+[）",
    "forward": "上移一层（Ctrl+X或者Cmd+X）",
    "backward": "下移一层（Ctrl+B或者Cmd+B）",
    "previewMode": "预览模式",
    "tip9": "请先允许该网页使用麦克风",
    "title": "标题",
    "titleDesc": "请输入标题",
    "titleColor": "标题颜色",
    "describe": "描述",
    "describeDesc": "请输入描述",
    "notes": "备注",
    "notesDesc": "请输入备注",
    "icon": "图标",
    "upload": "点击上传",
    "uploadDesc": "只能上传jpg/png文件",
    "addIcon": "搜索图标",
    "transparentIcon": "透明图标",
    "text": "文本",
    "textDesc": "请输入文本",
    "iframe": "内置链接",
    "iframeDesc": "请输入内置链接",
    "embedCode": "嵌入代码",
    "embedCodeDesc": "请输入嵌入代码",
    "picture": "图片",
    "searchImages": "搜索图片",
    "searchImagesDesc": "请输入内容",
    "change": "换一组",
    "tip10": "拼命搜索中",
    "video": "视频",
    "CenterUpAndDown": "上下居中",
    "selfAdaption": "自适应",
    "voice": "语音",
    "startRecording": "开始录音",
    "endRecording": "结束录音并上传",
    "RecordingTime": "录音时长",
    "question": "正文",
    "option": "选项",
    "optionDesc": "请输入选项内容",
    "material": "素材",
    "selectTemplate": "选择模板",
    "preset": "预设",
    "edit": "编辑",
    "enterGroup": "进入分组",
    "groupName": "分组名",
    "enter": "进入",
    "tip11": "分组不存在",
    "topic": "主题",
    "enterTopic": "请输入主题",
    "gain": "获取",
    "description": "描述",
    "tip12": "是否获取关键词？",
    "tip13": "是",
    "tip14": "重新获取关键词",
    "tip15": "是否获取追加问题？",
    "tip16": "重新获取问题",
    "keyword": "关键词",
    "runResult": "生成结果",
    "directory": "目录",
    "nodeFiltering": "节点筛选",
    "all": "全部",
    "responsibleTasks": "负责任务",
    "participatingTasks": "参与任务",
    "projectName": "项目名称",
    "student": "学 生",
    "collaborate": "协 作",
    "tip17": "此功能暂未开通",
    "tip18": "此操作将删除选中的卡片以及关联的连线, 是否继续?",
    "question_new": "追问",
    "tip19": "选择路径",
    "tip21": "选择小组",
    "replace": "替换背景",
    "mindMap": "设置思维导图",
    "promptsList": [
        {
            "value": 0,
            "label": "智能阶段",
            "index": 0,
            "prompt": [
                {
                    "value": 0,
                    "label": "请你以段落的形式帮我设计“关于科学探究”的课程阶段，不需要包含课程名称，只需要包含阶段名称，不需要包含任务名称以及任务描述，例如 第一阶段：提出问题 第二阶段：背景研究 第三阶段：作出假设 第四阶段：设计实验进行探究 第五阶段：收集数据归类整理 第六阶段 分析数据形成观点"
                },
                {
                    "value": 1,
                    "label": "请你以段落的形式帮我设计“关于英语写作”的课程阶段，不需要包含课程名称，只需要包含阶段名称，不需要任务名称以及任务描述，例如 第一阶段：头脑风暴 第二阶段：框架编写 第三阶段：表达比较 第四阶段：自由创作 第五阶段：同伴互评 第六阶段：课堂总结"
                }
            ]
        },
        {
            "value": 1,
            "label": "智能任务",
            "index": 1,
            "prompt": [
                {
                    "value": 0,
                    "label": "请你以Markdown的形式帮我设计一个主题为“制作机器人”的课程活动，内容仅包含子任务名称以及任务描述，每个任务之间需要换行，不需要输出标题。 例如 任务一：XXX 任务描述：XXX 任务二：XXX 任务描述：XXX"
                },
                {
                    "value": 1,
                    "label": "请你以Markdown的形式帮我设计小学数学课里的“等腰三角形”的教学任务，任务内容包含任务名称以及任务描述，每个任务之间需要换行，不需要输出标题。例如 任务一：XXX；任务描述：XXX 任务二：XXX 任务描述：XXX"
                },
                {
                    "value": 2,
                    "label": "请你以Markdown的形式帮我设计通过科学探究的方式开展“研究滑动摩擦的大小跟哪些因素有关”的任务，任务内容包含任务名称以及任务描述，每个任务之间需要换行，例如 任务一：提出科学问题 任务描述：“影响物体所受滑动摩擦力大小的因素可能有哪些？” 任务二：建立科学假说 任务描述：“查阅一些相关的书籍或网络文献，根据已有的知识来推理，写下合理的有根据的猜测。” 任务三：设计并开展科学实验 任务描述：“实验设计需明确实验流程及步骤，包含实验材料、实验组与对照组、自变量与因变量以及需要记录的数据。设计完成后请按照计划开展实验。” 任务四：收集和分析实验数据 任务描述：“通过表格的形式整理实验中记录的数据。” 任务五：总结科学结论 任务描述：“请仔细分析收集到的数据，必要时通过统计检验来推导结论，总结实验结果。”"
                },
                {
                    "value": 3,
                    "label": "请你以Markdown的形式帮我设计通过看图作文教学的方式开展“燕子安家看图作文”的任务，任务内容包含任务名称以及任务描述，每个任务之间需要换行，例如 任务一：观察图片 任务描述：“仔细看图，理解图意，图片描述了一个什么样的故事？请大家注意观察图中的细小部分。”  任务二：发挥想象 任务描述：“展开合理想象，你觉得它们会说什么？会做什么？它们为什么会这样说，这样做？” 任务三：口述训练 任务描述：“选择合适的语气，说一说你看到的内容、它们之间的关系以及你的想象。”  任务四：组织成文 任务描述：“在语言描述的基础上，增加细节说明，以及你的思考，用文字编写完成一个完整的故事。注意文章需分段，并使用正确的标点。”"
                },
                {
                    "value": 4,
                    "label": "请你以Markdown的形式帮我设计通过历史学科探究的方式开展“战后资本主义世界经济体系的形成”的教学任务，任务内容包含任务名称以及任务描述，每个任务之间需要换行，例如 任务一：整体感知 任务描述：“你如何认识战后资本主义世界体系？自主阅读，找出与资本主义世界经济体系相关的内容。”  任务二：基础探究 任务描述：“找出国际货币基金组织和布雷顿森林体系的联系。”  任务三：深入探究 任务描述：“结合美国在战后资本主义经济体系的地位，分析布雷顿森林体系建立的背景。” 任务四：探究总结 任务描述：“将战后资本主义世界体系的结构绘制为框架图。” 任务五：拓展延伸 任务描述：“布雷顿森林体系的解体标志美元获得了黄金的生命，你认为这是否是美国衰落的象征，请从辩证的角度回答问题。”"
                },
                {
                    "value": 5,
                    "label": "请你以Markdown的形式帮我设计通过六顶帽子的方法来开展“如何解决校门口交通拥堵问题”的任务，任务内容包含任务名称以及任务描述，每个任务之间需要换行，例如 任务一：陈述问题 任务描述：“要想解决校门口的交通拥堵问题，你需要关于现状的什么信息？” 任务二：提出解决问题的方案 任务描述：“要想解决这个问题，你有什么想法和建议？你认为可能的解决办法和行动过程是什么？” 任务三：评估方案优点 任务描述：“你为什么认为这个方案有效？你认为它为什么值得做？” 任务四：列举方案缺点 任务描述：“你认为这个方案的缺点是什么？实施会遇到什么问题？” 任务五：进行方案的直觉判断 任务描述：“从直觉来考虑，你对于这个方案的评价如何？” 任务六：总结陈述与作出决策 任务描述：“1. 现在对于这个问题的解决，我们目前的讨论进度是什么？2. 如果要继续下一步推进，我们还需要做什么？”"
                },
                {
                    "value": 6,
                    "label": "请你以Markdown的形式帮我设计出通过专家拼图法来开展“整理各朝代绘画特点”的任务，任务内容包含任务名称以及任务描述，每个任务之间需要换行，例如 任务一：学生分组，划分拼图 任务描述：全班分为若干小组，各小组成员分别认领总任务中的各个子任务/子概念 任务二：领取拼图，自主探究 任务描述：小组成员单独思考并进行针对子任务/子概念的探究 任务三：统一拼图，交流分享 任务描述：领取相同子任务/子概念的同学组建成为专家小组，进行交流分享 任务四：合作交流，组建拼图 任务描述：每位小专家回到本组内进行交流，每位组员都需要讲解自己负责的拼图板块。小组成员整体进行交流与讨论后，形成指向总任务的解决方案，完成拼图。"
                },
                {
                    "value": 7,
                    "label": "请你以Markdown的形式帮我设计通过苏格拉底提问的方法来开展“什么时候你能感受到友谊”的任务，任务内容包含任务名称以及任务描述，每个任务之间需要换行，例如 任务一：这是什么？ 任务描述：你说的“友谊”具体是指什么？可不可以举个例子，说明你想要表达的概念？ 任务二：为什么？ 任务描述：你为什么会这么想？为什么会做这样的设想？你为什么相信这是真的？ 任务三：所以呢？ 任务描述：所以这意味着什么呢？所以你如何证明或推翻这个假设？ 任务四：还有别的可能吗？ 任务描述：别的不同观点可能会是什么？可以用其他的方式或方法来对待这件事吗？还可以有其他的可能吗？"
                }
            ]
        },
        {
            "value": 2,
            "label": "智能出题",
            "index": 2,
            "prompt": [
                {
                    "value": 0,
                    "label": "请你以富文本的形式给我出四个关于清朝历史的选择题，每个选择题选项不少于4个，需要答案，但不需要解释，符号请使用中文的"
                },
                {
                    "value": 1,
                    "label": "请你以富文本的形式给我出三个关于小学数学单位换算的选择题，每个选择题选项不少于4个，需要答案，但不需要解释，符号请使用中文的"
                }
            ]
        },
        {
            "value": 3,
            "label": "智能问答",
            "index": 3,
            "prompt": [
                {
                    "value": 0,
                    "label": "请你以Markdown的形式给我出一道关于清朝历史的问答题，符号请使用中文的，例如 问：清朝的建立时间是哪一年？由哪个族群建立？建立者的姓氏是什么？ 答：清朝建立于1644年，由满族建立，建立者的姓氏是努尔哈赤。"
                }
            ]
        },
        {
            "value": 12,
            "label": "跨学科学习",
            "index": 12,
            "prompt": [
                {
                    "value": 0,
                    "label": "我需要以“设计3天夏令营的健康饮食食谱”为主题，设计一个跨学科融合的课程。我需要你完成以下事情： 1、介绍该课程的基本信息 2、列出4个跨学科概念 3、逐一分解跨学科概念，将其分解为一系列学科概念。4、根据列出的概念群，列出一系列学生可以探究的问题"
                },
                {
                    "value": 1,
                    "label": "学科核心概念是在学科中，被认为具有最基本和最重要的概念，我们可以通过识别文件中的高频概念性名词来找到潜在的学科核心概念。现在，根据该文档中各个学段目标 ，总结出该学科的核心概念"
                },
                {
                    "value": 2,
                    "label": "学科核心概念是在学科中，被认为具有最基本和最重要的概念，核心概念应该在多个年级或发展阶段中具有持久的重要性，并且能够为学生提供理解更复杂或更高级概念的基础。因此，我们可以通过识别文件中的高频概念性名词 来找到潜在的学科核心概念。现在，根据该文档中各个 学段目标 ，总结出该学科的核心概念"
                }
            ]
        },
        {
            "value": 4,
            "label": "项目式学习",
            "index": 4,
            "prompt": [
                {
                    "value": 0,
                    "label": "请给我一些学生开展项目式学习可以使用的主题或问题参考，请说出学生将要解决的问题，以及学生要经历怎样的学习活动。"
                },
                {
                    "value": 1,
                    "label": "请将一个关于生态保护项目的项目式学习展开描述，你需要描述学生如何解决这个问题，你需要至少写出四个活动，这些活动需要按照前后逻辑关系排列。"
                },
                {
                    "value": 2,
                    "label": "请对驱动问题为“如何为学校建造一个富有特色的花坛？”的项目式学习进行子问题拆解，至少拆解为5个子问题，并根据子问题对应写出各环节的主要活动。"
                }
            ]
        },
        {
            "value": 5,
            "label": "教学评价",
            "index": 5,
            "prompt": [
                {
                    "value": 0,
                    "label": "如果需要给学生的社区服务进行评价，给出评价维度和至少3个等级的表现描述。"
                },
                {
                    "value": 1,
                    "label": "为6年级学生设计一份关于梧桐山研究报告的评估任务表，并给出参考的报告流程，至少包含8个步骤，并包括地图、图片和至少300个词。"
                },
                {
                    "value": 2,
                    "label": "创建一个给5年级学生使用的课堂小测试，包含5道多选题，评价学生对于太阳能这个概念的理解。你需要给出题目和正确答案。"
                }
            ]
        },
        {
            "value": 6,
            "label": "教学设计",
            "index": 6,
            "prompt": [
                {
                    "value": 0,
                    "label": "如果需要5年级学生感受“移步换景”的景观写作手法，你有什么合适的阅读材料推荐？你需要给出材料名称，以及材料的哪部分内容。"
                },
                {
                    "value": 1,
                    "label": "设计一个针对8年级学生且关于人类迁徙主题的地理课，并在课程中设计至少1项小组活动。"
                },
                {
                    "value": 2,
                    "label": "设计一个针对5年级学生的课程，课程综合科学和信息技术领域，解决生物与环境领域的生活问题，你需要给出完整的课程框架和活动。"
                },
                {
                    "value": 3,
                    "label": "如果3年级的学生不能理解光合作用的实现过程，需要你帮我设计一个支持他们理解的教学活动，需要包含活动的形式、实施材料和清单。"
                },
                {
                    "value": 4,
                    "label": "请基于贝叶斯定理为8年级学生出三道题目。"
                },
                {
                    "value": 5,
                    "label": "如果需要八年级学生了解尼罗河流域的文化发展史，你有哪些推荐的网站或参考书籍？"
                }
            ]
        },
        {
            "value": 7,
            "label": "班级管理",
            "index": 7,
            "prompt": [
                {
                    "value": 0,
                    "label": "创建一组给一年级学生使用的班级口号，要求大家注意卫生、保护环境，口号需要对仗工整，符合一年级学生的理解水平。"
                },
                {
                    "value": 1,
                    "label": "设计一套用于6年级学生的班级管理规章制度，内容需要包括学习、纪律、卫生、思想品德方面。"
                }
            ]
        },
        {
            "value": 8,
            "label": "课堂组织",
            "index": 8,
            "prompt": [
                {
                    "value": 0,
                    "label": "请为“制作垃圾分类宣传单”的小组活动设计小组分工表，每个小组的成员为4-6人。"
                },
                {
                    "value": 1,
                    "label": "请给5年级“校园植物图鉴”社团课程设计一份小组合作公约，需包含小组成员信息、小组项目目标、填写日期，总长度不超过300字，并且提供至少3处学生自行填写的部分。"
                },
                {
                    "value": 2,
                    "label": "请用苏格拉底提问的方式，引导5年级学生拆解驱动问题：如何解决教室黑板反光的问题？其中需包含对于反光原因的分析与实验探究。"
                }
            ]
        },
        {
            "value": 9,
            "label": "教师发展",
            "index": 9,
            "prompt": [
                {
                    "value": 0,
                    "label": "教师需要理解项目式学习的理论基础和基础概念，你需要生成一份阅读清单，要求内容为中文书籍或文献。"
                },
                {
                    "value": 1,
                    "label": "设计一个教师进行个人学期总结的框架，需要体现在教学、教研、个人学习方面的进步。"
                }
            ]
        },
        {
            "value": 10,
            "label": "专案式学习",
            "index": 10,
            "prompt": [
                {
                    "value": 0,
                    "label": "我希望你担任课程设计师。我将提供有关专案式学习的课程设计的一些要求，这些要求可能包括年级、学科、课时、内容等，而你的工作是给出符合要求的课程内容。这可能涉及分析专案式学习的主题、问题，将内容匹配到年级与学科，并且设计活动。我的第一个要求是“我需要一些和语文学科相关的专案式学习主题”。"
                },
                {
                    "value": 1,
                    "label": "如果围绕你给出的第一个主题“创作故事”开展专案式学习，请设计一个针对小学五年级学生的驱动性问题。"
                },
                {
                    "value": 2,
                    "label": "请将一个关于生态保护的专案式学习展开描述，你需要描述学生如何解决这个问题，你需要至少写出四个活动，这些活动需要按照前后逻辑关系排列。"
                },
                {
                    "value": 3,
                    "label": "请对驱动问题为“如何为学校建造一个富有特色的花坛？”的专案式学习进行活动设计，至少需要将问题拆解为5个活动，并按照实施顺序给出各活动的主要任务。"
                },
                {
                    "value": 4,
                    "label": "根据你给出的针对驱动问题为“如何创作一个引人入胜的故事，并且让读者产生共鸣？”的专案式学习活动设计，设计出匹配小学五年级语文学科的学习目标，你需要将学习目标分为3类，分别是学生将知道、学生将理解和学生将能够。请以序号加目标内容的方式给出学习目标。"
                },
                {
                    "value": 5,
                    "label": "你需要给出一个最终任务，学生需要完成这个任务来体现出自己对于“如何创作一个引人入胜的故事，并且让读者产生共鸣？”这个专案式学习课程的教学目标的达成，这个最终的任务是什么？"
                },
                {
                    "value": 6,
                    "label": "我需要知道怎么评价学生这个最终任务的完成情况，我需要知道评价的维度，以及每个维度的表现描述，最好以表格的形式输出你设计的评价量表。"
                }
            ]
        },
        {
            "value": 11,
            "label": "代码分析",
            "index": 11,
            "prompt": [
                {
                    "value": 0,
                    "label": "这段代码实现了什麽效果？"
                },
                {
                    "value": 1,
                    "label": "请描述这段代码。"
                },
                {
                    "value": 2,
                    "label": "根据这段代码，给我一些修改意见。"
                }
            ]
        }
    ],
    "my": "我的",
    "collect1": "收藏1",
    "collect2": "收藏2",
    "share": "共享",
    "share1": "共享1",
    "share2": "共享2",
    "share4": "别人共享",
    "scaffold": "脚手架",
    "resou": "资源",
    "AI": "AI",
    "scientific_inquiry": "科学探究",
    "Turmin": "图尔敏法",
    "SE":"5E教学设计",
    "STEM": "STEM",
    "SSE": "3SE课程设计模型",
    "SEX": "5EX模型",
    "generate": "生成",
    "generate2": "获取任务",
    "generate3": "获取资源",
    "prompts": "请输入prompts",
    "hide": "隐藏标题",
    "ai_analyze":"AI分析中...",
    "stageTip": "阶段:",
    "taskTip": "任务:",
    "chatroom_message_open":"接收通知",
    "chatroom_message_close":"通知:",
    "taskCourseTip": "正在创建任务式课程，请稍候...",
    "stageCourseTip": "正在创建阶段式课程，请稍候...",
    "stageProjectTip": "正在创建项目，请稍候...",
    "networkTip": "网络已连接，正在重新获取数据，请稍候...",
    "notNetworkTip": "网络连接异常。你可以在离线状态下继续编辑，内容将在连接网络后自动同步。",
	"whiteboard_card":"白板卡片",
    "file": "文档",
    "uploadTip": "只支持上传pdf, doc, docx, ppt, pptx, xls, xlsx文件",
    "taskTip2":"任务",
    "tip20":"生成的格式不正确，请保证内容中有‘任务X：’和‘任务描述：’的字样，如果没有，请修改或者再次获取生成！",
    "ArProse":"论辩文写作",
    "createErrorTip1": "存在多个主题卡片，请选择其中一个来创建课程",
    "createErrorTip2": "存在歧义，请修改相关连线后再创建课程",
    "createCourse": "创建课程",
    "runTip2": "存在歧义，请修改相关连线后再生成",
    "map_card":"地图卡片",
    "pre_stage": "上一阶段",
    "next_page": "下一阶段",
    "export_to": "导出为TXT",
    "chat_room": "聊天室",
    "my_group": "我的小组",
    "close_chat": "关闭聊天",
    "open_Collapse": "打开/收起",
    "clear_screen": "清屏",
    "pbl":"PBL",
    "subject":"学科类",
    "map_mind":"思维导图",
    "distribute_cards": "分发卡片（Ctrl+Q或者Cmd+Q）",
    "createErrorTip3": "无法创建非任务以及非阶段课程",
    "createErrorTip4": "存在多个主题卡片，请选择其中一个来创建项目",
    "createErrorTip5": "存在歧义，请修改相关连线后再创建项目",
    "createErrorTip6": "无法创建非阶段项目",
    "delete": "删除（Backspace或者Delete）",
    "send": "分发（Ctrl+Q或者Cmd+Q）",
    "analyseTip": "正在获取数据进行分析，请稍候...",
    "whole": "整体",
    "student_new": "个人",
    "collaborate_new": "小组",
    "achievement": "成果",
    "timeMachine":"时光机",
    "personalImage": "个人画像",
    "personalProfile": "行为概况",
    "resourceContribution": "资源贡献",
    "personnelRelations": "协作关系",
    "heatRelations":"活跃度热力图",
    "contentTip": "内容即将推出，敬请期待......",
    "selectTip": "请选择",
    "setMap": "设置地图",
    "studentTip1": "创建卡片",
    "studentTip2": "资源分享",
    "studentTip3": "编辑",
    "studentTip4": "发言",
    "studentTip5": "创建模版",
    "studentTip6": "连线",
    "studentTip7": "编辑其他人卡片的次数",
    "times": "次数",
    "studentTip8": "生成资源的次数",
    "camera_card":"摄像头",
    "groupShare": "贡献度",
    "confirmSend": "确认分发",
    "sendSuccess": "分发成功",
    "notSend": "请先选择小组",
    "beautifyAll": "一键美化",
    "beautifyAllNew": "一键美化（Ctrl+M）",
    "homeworkCard": "作业卡片",
    "homeworkName": "作业",
    "homeworkDesc": "请输入作业题目",
    "homeworkTitle": "作业题目",
    "homeworkContent": "作业描述",
    "pbl1": "PBL观察笔记",
    "pbl2": "PBL介绍",
    "pbl3": "PBL跨学科模板",
    "pbl4": "团队成员介绍模板",
    "pbl5": "POV表格",
    "pbl6": "康奈尔笔记法",
    "pbl7": "苏格拉底式追问",
    "six_thinking": "六顶思考帽",
    "English_Creative_Writing": "英语创意写作",
    "dashboard": "仪表盘",
    "dashboardTip1": "总活跃度",
    "dashboardTip2": "小组参与度",
    "dashboardTip3": "卡片数量",
    "dashboardTip4": "累计卡片数量",
    "dashboardTip5": "减少量",
    "dashboardTip6": "增加量",
    "create": "创建",
    "prd":"项目成果分布",
    "lfc":"登录频次图",
    "top6":"贡献度排行榜",
    "result_map":"项目成果地图",
    "immersive": "沉浸式",
    "cards": "卡片式",
    "stageTitle": "阶段名称",
    "stageContent": "阶段描述",
    "stageContentDesc": "请输入阶段描述",
    "restore_background": "恢复默认背景",
    "select_group_value":"请选择小组",
    "group_achievement":"小组成果排行榜",
    "global_relationship_echarts":"全局关系图",
    "select_personage_value":"请筛选",
    "Project_outcome":"项目成果图",
    "setCamera":"点击确定拍照",
    "resourceClass":"资源类别图",
    "image":"图片",
    "videoAndAudio":"音视频",
    "content":"文本",
    "fileContent":"文档",
    "urlAndIframe":"链接",
    "icfc":"个人创建频次图",
    "refreshFrequency":"刷新频率",
    "ofc":"操作频次图",
    "refresh":"刷新",
    "card2":"卡片",
    "loading":"加载中......",
    "Loaded":"已加载：",
    "save": "确定",
    "tip25": "拼命加载中",
    "vectorgraph": "矢量图",
    "satellite_image": "卫星图",
    "store": "保存",
    "tip26": "请输入地址",
    "tip27": "创作",
    "tip28": "样例",
    "download": "下载",
    "tip29": "新增了",
    "tip30": "张卡片",
    "tip31": "删除了",
    "tip32": "上线了",
    "tip33": "下线了",
    "tip34": "执行了清空操作！",
    "edit_Card": "编辑卡片",
    "call_word":"提示词",
    "group_list":"小组列表",
    "global_view":"全局查看",
    "look_last_card":"查看上一次编辑的卡片",
    "view_topic":"查看初始主题",
    "tip35": "单击左键选择",
    "tip36": "想要的脚手架资源卡片后，该",
    "tip37": "资源模版可跟随鼠标单击放置",
    "tip38": "在对应的位置哦！",
    "tip39": "单击选择后，鼠标跟随一个模",
    "tip40": "版缩略图，单击放置在画布对",
    "tip41": "应位置",
    "errorTip": "数据出现异常，请使用异常修复按钮进行修复",
    "repair": "异常修复",
    "errorTip1": "此修复操作将删除所有的连线, 是否继续?",
    "tip42": "修复成功",
    "save_map":"地图保存中...",
    "unable_to_connect_with_theme": "不支持连接主题卡片",
    "tip43": "不支持卡片连接自身",
    "Sun":"周日",
    "Mon":"周一",
    "Tues":"周二",
    "Wed":"周三",
    "Thur":"周四",
    "Fir":"周五",
    "Sat":"周六",
    "deleteGraps":"删除",
    "aidh":"(对话)：",
    "cardfx":"(卡片分析)：",
    "userxwfx":"(用户行为分析)：",
    "contribution_degree":"该成员贡献度表现为：",
    "contribution":"贡献占比为：",
    "ap":"该成员活跃度表现为：",
    "dynamic1":"非常活跃",
    "dynamic2":"较活跃",
    "dynamic3":"活跃",
    "dynamic4":"一般",
    "dynamic5":"不活跃",
    "zhang":"张",
    "add_node":"新增卡片：",
    "delete_card":"删除卡片：",
    "article":"条",
    "add_edge":"新增连线：",
    "updata_edge":"操作连线：",
    "delete_edge":"删除连线：",
    "operation_record":"操作记录",
    "sumbit_job": "提交作业",
    "requirements": "要求",
    "job_reception_area": "作业接收",
    "jobTip": "正在提交作业，请稍候...",
    "tip44": "提交成功",
    "tip45": "自适应",
    "CPOTE":"C-POTE模式案例",
    "CPOTE2":"C-POTE模式课程设计流程",
    "subject_icon":"各科义务教育课标",
    "educational_base":"未来教育基地第二期汇总",
    "Project_learning_curriculum_design_framework":"项目式学习课程设计框架",
    "lxdh":"连续对话:",
    "unable_to_connect_with_other": "此两卡片已经连接，不支持重新再连",
    "kwl": "KWL图",
    "w": "W图",
    "x": "X图",
    "y": "Y图",
    "we": "韦恩三圆圈图",
    "venn": "韦恩图",
    "fishbone_diagram": "鱼骨图",
    "launch": "展开",
    "closeItem": "收起",
    "tip46": "请给“",
    "tip47": "”打标签",
    "tip48": "发起人或贡献者",
    "title48": "对于某个主题或问题，给予新的意义或新的界定，或提出解决的办法。",
    "tip49": "信息探求者",
    "title49": "强调通过从其他人那里获取信息的背景,得到有关事实",
    "tip50": "意见探索者",
    "title50": "获取质量更高的数据类型,如态度、评价和情绪等",
    "tip51": "信息提供者",
    "title51": "提供经验或权威报道。",
    "tip52": "意见提供者",
    "title52": "对建议提出看法、意见、增补、修正、支持或反对。",
    "tip53": "推敲者",
    "title53": "举说明或提供合理的解释，或将意见推演。",
    "tip54": "协调者",
    "title54": "为各种意见搭起桥梁，并在各个团体间穿梭，尝试将意见与行为结合在一起。",
    "tip55": "导引者",
    "title55": "指出意见的意向，导引目标或提供讨论方向。",
    "tip56": "批判者",
    "title56": "提出一些标准来评估、咨询团体讲座的实施、逻辑或程序问题。",
    "tip57": "加油者",
    "title57": "鼓励团体决策或采取行动或刺激团体达到高潮。",
    "tip58": "行动专家",
    "title58": "促成团体行动、分配任务、管制进度与安排事务。",
    "tip59": "记录者",
    "title59": "草拟与记录提议，登载结论。",
    "tip60": "保存标签",
    "tip61": "双击卡片启用摄像头",
    "tip62": "修改了1",
    "tip63": "内容为：",
    "tip64": "的",
    "tip65": "标题为：",
    "tip66": "修改的内容为：",
    "tip67": "修改的标题为：",
    "tip68": "新增了连线",
    "tip69": "删除了连线",
    "tip70": "新增了1",
    "tip71": "删除了1",
    "tip72": "修改了连线",
    "tip73": "移动了1",
    "tip74": "",
    "tip75": "全选",
    "Editing": "编辑模式",
    "myGroup": "我的小组",
    "among":"其中，",
    "add_ap1":"非常大",
    "add_ap2":"较大",
    "add_ap3":"大",
    "add_ap4":"一般",
    "add_ap5":"小",
    "operation_card":"操作卡片：",
    "character":"角色标签",
    "tip76": "快来创作你的白板",
    "tip77": "开启麦克风",
    "tip78": "关闭麦克风",
    "ask_question": "提问",
    "answer_question": "回答",
    "sumbit_question": "提交",
    "sumbit_question_again": "继续提问",
    "tip79": "按 “/” 显示提示词",
    "tip80": "文件选择",
    "tip81": "生成文字",
    "tip82": "生成图片",
    "tip83": "生成音频",
    "tip84": "正在获取回答，请稍后",
    "tip85": "正在解析语音，请稍后",
    "tip86": "图片大小不能超过4MB",
    "tip87": "问题不能为空",
    "aite_ai_desc":"请输入内容，输入@选择AI能唤起AI功能",
    "tip88": "终止",
    "share3": "分享",
    "fzcg": "分享链接已生成！已将链接复制到剪切板中",
    "fzsb": "复制失败",
    "tip89": "进入演示模式？",
    "tip90": "进入",
    "tip91": "复制成功",
    "shareContent": "分享内容",
    "tip92": "仅演示",
    "tip93": "链接",
    "tip94": "复制链接",
    "tip95": "邀请码",
    "tip96": "复制邀请码",
    "tip97": "二维码",
    "tip98": "下载二维码",
    "tip99": "是否需要邀请码",
    "tip100": "需要",
    "tip101": "不需要",
    "tip102": "重新生成",
    "tip103": "邀请码不对，请询问获取正确的邀请码",
    "tip104": "选择小组以及文件格式",
    "tip105": "text文件",
    "tip106": "文件格式",
    "pocket_monster": "口袋妖怪",
    "pocket_monster_1": "妖怪1",
    "pocket_monster_2": "妖怪2",
    "move_key": "移动按键",
    "move_key_1": "向上",
    "move_key_2": "向下",
    "move_key_3": "向左",
    "move_key_4": "向右",
    "move_distance": "移动步长",
    "move_distance_x": "x轴",
    "move_distance_y": "y轴",
    "tip107": "背景颜色",
    "tip108": "背景渐变色",
    "tip109": "文本颜色",
    "tip110": "填充样式",
    "tip111": "获取不到项目cid，请使用刷新按钮刷新页面再重新点击分享",
    "tip112": "创建AI NPC",
    "tip113": "修改AI NPC",
    "prologue": "开场白",
    "isPublish": "发布权限",
    "knowledge_base": "专属知识库",
    "upload_file": "上传文件",
    "upload_file_desc": "上传文件的说明",
    "tip114": "保存成功",
    "upload_image": "上传图片",
    "select_local_file": "选择本地文件",
    "select_network_file": "选择网络文件",
    "sumbit_error_message": "请确保描述、prompt、开场白、名称不为空",
    "people": "人物",
    "sendButton": "发送",
    "tip115": "内容输入中",
    "tip116": "按下Enter键，开始对话",
    "tip117": "我",
    "agent": "(AI智能体)",
    "tip118": "：",
    "tip119": "白板编辑",
    "tip120": "设置背景",
    "tip121": "是否隐藏",
    "tip122": "不隐藏",
    "tip123": "隐藏",
    "geneTask": "生成任务大纲",
    "geneTasktext": "正在生成任务大纲，请稍后...",
    "geneResourse": "生成教案",
    "geneResoursetext": "正在生成教案，请稍后...",
}